import React, { useState, useEffect } from "react";
import "./UserManagement.scss";
import { ServiceUtils } from "../../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../../Shared/Utils/ToasterUtil";
import CustomTable from "../../../../Shared/Components/CustomTable/CustomTable";
import { Modal, Button } from "react-bootstrap";
import FormBuilder from "../../../../Shared/Components/FormBuilder/FormBuilder";
import moment from "moment";
import "../../../../../src/Shared/Styles/buttons.scss";
import { searchTable } from "../../../../Shared/Utils/CommonUtil";
import workInProgress from '../../../../Assets/images/work-in-progress.png';

export default function UserManagement() {
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [updateUserModal, setUpdateUserModal] = useState(false);
  const [user_id, setuserid] = useState("");
  const [selectedUserData, setSelectedUserData] = useState({});
  //** pagination for User List pavan */
  const [paginationConfig, setPaginationConfig] = useState({
    enable: true,
    totalRecords: "",
  });
  // const [pageValue, setPageValue] = useState(1);
  const [userTable, setUserTable] = useState({
    body: [],
    header: [],
    filteredData: [],
  });
  const [searchText, setSearchText] = useState("");
  //** delay search text */
  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     // Send Axios request here
  //     listUser(...Array(4),searchText)
  //   }, 0)

  //   return () => clearTimeout(delayDebounceFn)
  // }, [searchText])

  /** Table search logic */
  useEffect(() => {
    setUserTable({
      ...userTable,
      filteredData: searchTable(searchText, userTable?.body, userTable?.header),
    });
  }, [searchText]);

  // function for opening and closing of React modal
  const handleClose = () => {
    setshowDeleteModal(false);
    setUpdateUserModal(false);
    setAddUserModal(false);
  };
  const handleOpen = (arg) => {
    if (arg === "delete") {
      setshowDeleteModal(true);
    } else if (arg === "addUser") {
      setAddUserModal(true);
    } else if (arg === "update") {
      setUpdateUserModal(true);
    }
  };
  //** pagination */
  const mode = "admin";
  const [filterData, setFilterData] = useState({
    size: 25,
    page: 1,
    sort_order: "desc",
    sort_by: "date",
    filter_type: mode === "admin" ? "date-wise" : "individual",
  });

  // ------------------------------- for getting user role
  async function getUserRole() {
    ServiceUtils.postRequest("roleList", {}).then((_response) => {
      if (_response.status === 200) {
        if (_response.data.status === "success") {
          // assign the user role to user form
          formConfig.map((row) => {
            return row.map((el) => {
              if (el.key === "role_id") {
                el.options = _response.data.data.role_list || [];
              }
              return { ...el };
            });
          });

          // assign the user role to edit user form
          editUserformConfig.map((row) => {
            return row.map((el) => {
              if (el.key === "role_id") {
                el.options = _response.data.data.role_list || [];
              }
              return { ...el };
            });
          });
        } else {
          alertService.error(
            _response.data.message || `Error while fetching role list`
          );
        }
      }
    });
  }
  // ------------------------------- for create user
  async function createUser(userData) {
    ServiceUtils.postRequest("createUser", userData).then((_response) => {
      if (_response.status === 200) {
        if (_response.data.status === "success") {
          handleClose();
          alertService.success(
            _response.data.message || `User added successfully`
          );
          listUser();
        } else {
          alertService.error(
            _response.data.message || `Error while creating user`
          );
        }
      }
    });
  }
  // ------------------------------- for getting user list
  async function listUser(pageNo =1,pageSize = 25,sortOrder,sortBy,searchText='') {
    ServiceUtils.postRequest("userList", {
      "input_data": {
        "user_id": 0
      },
      "paginate": {
        "page": pageNo,
        "size": 25
      },
      "sort": {
        "sort_by": sortBy,
        "sort_order": sortOrder
      },
      "search": {
        "search_text": searchText
      }
    }).then((_response) => {
      if (_response.status === 200) {
        if (_response.data.status === "success") {
          const result = _response.data.data;
          paginationConfig.enable = true;
          paginationConfig.totalRecords = result?.meta_data?.total_records;
          setPaginationConfig(paginationConfig);
          // debugger;
          setUserTable({
            body: result.user_list,
            header: result.headers,
            filteredData: result.user_list,
            sort_order: sortOrder,
            sort_by: sortBy
          });
          
        } else {
          alertService.error(
            _response.data.message || `Error while fetching user list`
          );
        }
      }
    });
  }

  // -------------------------------- delete perticular user
  async function deleteUser() {
    let data = { user_id };
    ServiceUtils.postRequest("deleteUser", data).then((_response) => {
      if (_response.status === 200) {
        if (_response.data.status === "success") {
          handleClose();
          alertService.success(
            _response.data.message || `User deleted successfully`
          );
          listUser();
        } else {
          alertService.error(
            _response.data.message || `Error while deleting user`
          );
        }
      }
    });
  }

  // ------------------------------- for edit user
  async function updateUser(userData) {
    ServiceUtils.postRequest("updateUser", userData).then((_response) => {
      if (_response.status === 200) {
        if (_response.data.status === "success") {
          handleClose();
          alertService.success(
            _response.data.message || `User info updated successfully`
          );
          listUser();
        } else {
          alertService.error(
            _response.data.message || `Error while updating user info`
          );
        }
      }
    });
  }

  // ------------------------------- get perticular user details
  // useEffect(() => {
  //   // for rendring functions or state on loading or reloading of page
  //   getUserRole();
  //   listUser();
  // }, []);

  /** Table action buttons */
  const actionButtons = {
    key: "_actions",
    label: "Actions",
    buttons: [
      {
        key: "edit",
        icon: "edit-fill",
      },
      {
        key: "delete",
        icon: "delete-fill",
        "icon-position": "left",
      },
    ],
  };
  // ----------------------------- on clicking on buttons in table for perfoeming edit and delete user
  const buttonsAction = (action) => {
    setuserid(action.payload.user_id);
    setSelectedUserData({ ...action.payload });
    if (action.type === "delete") {
      handleOpen("delete");
    } else if (action.type === "edit" || action.type === "name") {
      handleOpen("update");
    }
    
    if (action?.type === 'pagination') {
      const tempFilter = { ...filterData, ...action?.payload };
      setFilterData(tempFilter);
      // setPageValue(action.payload?.page)
      listUser(action.payload?.page,tempFilter.size)
    }else if (action?.type === "sort") {   
      // debugger;
      const temp = {
        ...filterData,
        sort_by: action?.payload?.column,
        sort_order: action?.payload?.order,
      };
      setFilterData(temp);
      listUser(temp.page,temp.size,temp.sort_order,temp.sort_by);
    }
  }
  // ---------------------------------------------------- json for add and update user from
  const [formConfig] = useState([
    [
      {
        key: "first_name",
        label: "First Name",
        type: "text-box",
        placeholder: "Enter First Name",
        required: true,
        "grid-column": 4,
      },
      {
        key: "middle_name",
        label: "Middle Name",
        type: "text-box",
        placeholder: "Enter middle name",
        required: false,
        "grid-column": 4,
      },
      {
        key: "last_name",
        label: "Last Name",
        type: "text-box",
        placeholder: "Enter Last name",
        required: true,
        "grid-column": 4,
      },
      {
        key: "email",
        label: "Email",
        type: "text-box",
        placeholder: "Enter email",
        required: true,
        validation: ["email"],
        "grid-column": 6,
      },
      {
        key: "dob",
        label: "DOB",
        type: "date-picker",
        placeholder: "Select DOB",
        required: false,
        maxDate: new Date(),
        showMonth: true,
        showYear: true,
        "grid-column": 6,
      },
      {
        key: "password",
        label: "Password",
        type: "password",
        placeholder: "Enter Password",
        required: true,
        "grid-column": 6,
      },
      {
        key: "confirmPassword",
        label: "Confirm Password",
        type: "password",
        placeholder: "Confirm password",
        required: true,
        "grid-column": 6,
      },
      {
        key: "role_id",
        label: "Role",
        type: "select",
        placeholder: "Select role",
        required: true,
        "grid-column": 12,
        options: [],
      },
    ],
  ]);

  const [editUserformConfig] = useState([
    [
      {
        key: "first_name",
        label: "First Name",
        type: "text-box",
        placeholder: "Enter First Name",
        required: true,
        "grid-column": 4,
      },
      {
        key: "middle_name",
        label: "Middle Name",
        type: "text-box",
        placeholder: "Enter middle name",
        required: false,
        "grid-column": 4,
      },
      {
        key: "last_name",
        label: "Last Name",
        type: "text-box",
        placeholder: "Enter Last name",
        required: true,
        "grid-column": 4,
      },
      {
        key: "email",
        label: "Email",
        type: "text-box",
        placeholder: "Enter email",
        required: true,
        validation: ["email"],
        "grid-column": 6,
      },
      {
        key: "dob",
        label: "DOB",
        type: "date-picker",
        placeholder: "Select DOB",
        required: false,
        maxDate: new Date(),
        showMonth: true,
        showYear: true,
        "grid-column": 6,
      },
      {
        key: "role_id",
        label: "Role",
        type: "select",
        placeholder: "Select role",
        required: true,
        "grid-column": 12,
        options: [],
      },
    ],
  ]);

  /** Perform Add user action */
  const handleFormAction = ({ type, payload }) => {
    switch (type) {
      case "onSubmit":
        const dob = moment(payload.dob).format("YYYY-MM-DD");
        if (payload.password !== payload.confirmPassword) {
          alertService.error("Password mismatch");
          break;
        }
        const createUserData = {
          first_name: payload.first_name,
          middle_name: payload.middle_name,
          last_name: payload.last_name,
          email: payload.email,
          dob: dob,
          password: window.btoa(payload.password),
          role_id: payload.role_id,
        };
        createUser(createUserData);

        break;
      case "onChange":
        break;
      default:
    }
  };
  /** Perform Edit user action */
  const handleEditFormAction = ({ type, payload }) => {
    switch (type) {
      case "onSubmit":
        const dob = moment(payload.dob).format("YYYY-MM-DD");

        const editUserData = {
          first_name: payload.first_name,
          middle_name: payload.middle_name,
          last_name: payload.last_name,
          email: payload.email,
          dob: dob,
          role_id: payload.role_id,
          user_id: payload.user_id,
        };
        updateUser({ ...editUserData });

        break;
      case "onChange":
        break;
      default:
    }
  };

  return (
    <div className="p-2 mt-1 page-layout user-layout">
          <img className='mt-2 workInProgressImage' src={workInProgress} alt="" />

      {/* <div className="col-12">
        <div className="payment-of-tax">
          <div className="searchMainDiv mb-3">
            <div className="searchfilterDiv input-group ps-0">
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search...."
                onChange={(e) => {
                  setSearchText(e.target.value);

                  
                  listUser(searchText === e.target.value);
                }}
              />
            </div>
            <div className="searchfilterDiv text-right">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  handleOpen("addUser");
                }}
              >
                <i className="fa fa-user-plus cursor-pointer"> </i> Add User
              </button>
            </div>
          </div>
          <div className="user-table">
            <CustomTable
              data={userTable?.filteredData}
              header={userTable?.header}
              onAction={buttonsAction}
              actionButtons={actionButtons}
              sortBy={userTable?.sort_by}
              sortOrder={userTable?.sort_order}
              paginationConfig={{
                ...paginationConfig,
                size: filterData?.size,
                currentPage: filterData?.page,
                hidePage: true
              }}
              
              // hidePageSize={true}
              // paginationHandling={paginationHandling}
            />
          </div>
        </div>
      </div> */}

      {/* -------------------------- add user modal  */}
      <Modal size="lg" show={addUserModal} onHide={handleClose} tabIndex={null}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormBuilder
            id="form-create-user"
            data={{}}
            onAction={handleFormAction}
            config={formConfig}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            onClick={handleClose}
            className="btn btn-secondary"
          >
            Close
          </Button>
          <Button
            type="submit"
            form="form-create-user"
            className="btn btn-primary"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ----------------------------- update modal form  */}

      <Modal
        size="lg"
        show={updateUserModal}
        onHide={handleClose}
        type="tex /javascript"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update User </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormBuilder
            id="form-edit-user"
            data={selectedUserData}
            onAction={handleEditFormAction}
            config={editUserformConfig}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit" form="form-edit-user">
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ---------------------------------------- react delete confirmation modal  */}
      <Modal
        size="md"
        show={showDeleteModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you really want to delete the user:{" "}
            <b>{selectedUserData.name}</b> ?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteUser}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
