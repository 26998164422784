import { API_ENDPOINTS, API_ENVIRONMENT } from '../../Environments/config';
import { MESSAGES } from '../../Environments/constants';
import { BASE_PATH, DEPLOYED_ENVIRONMENT, ENVIRONMENTS } from '../../Environments/environments';
import axios from 'axios';
import { loaderService } from './Loader/Loader';
import { alertService } from './ToasterUtil';

axios.interceptors.request.use(
  (request) => {
    if (request.showLoader) loaderService.showLoader();
    return request;
  }
);
axios.interceptors.response.use(
  (response) => {
    if (response?.config?.showLoader) loaderService.hideLoader();
    return response || {};
  },
  ({response}) => { // Error
    if (response?.config?.showLoader) loaderService.hideLoader();
    if (response?.status === 401 || !response) {
      alertService.error({message: MESSAGES.invalidToken, preventDuplicate: true});
      const path = window.location.href.split('#');
      // sessionService.setSession({ssoAutoLoad: true, redirectUrl: path[1]}, 'sso-redirect');
      window.location.href = path[0] + '#/logout';
    } else {
      console.log('ERROR ' + response?.status +':', response?.config?.url?.split('klapps')?.[1], response?.data);
    }
    return response || {};
  }
)


const get = (urlKey,params, showLoader = true) => {
  let url;
  const environment = API_ENVIRONMENT[urlKey] || DEPLOYED_ENVIRONMENT;
  if (environment === 'local') {
    url = BASE_PATH + `/Assets/json/${urlKey}.json`;
  } else {
    url = params ? ENVIRONMENTS[environment].baseurl + API_ENDPOINTS[urlKey] + params : ENVIRONMENTS[environment].baseurl + API_ENDPOINTS[urlKey];
  }

  const config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    withCredentials:true
  };

  if(showLoader){
    config['showLoader'] = true;
  }

  return axios.get(url, config);
}

const post = (urlKey, params, showLoader = true, cancelToken = undefined, responseType = undefined) => {
  const environment = API_ENVIRONMENT[urlKey] || DEPLOYED_ENVIRONMENT;
  if (environment === 'local') return get(urlKey);
  let url = ENVIRONMENTS[environment].baseurl + API_ENDPOINTS[urlKey];
  const config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    withCredentials:true
  };
  if(showLoader){
    config['showLoader'] = true;
  }
  if (cancelToken !== undefined) {
    config.cancelToken = cancelToken;
  }

  if(responseType !== undefined) {
    config.responseType = responseType;
  }

  return axios.post(url, params, config);
}

const download = (urlKey,params, showLoader = true) => {
  let url;
  const environment = API_ENVIRONMENT[urlKey] || DEPLOYED_ENVIRONMENT;
  if (environment === 'local') {
    url = BASE_PATH + `/Assets/json/${urlKey}.json`;
  } else {
    url = params ? ENVIRONMENTS[environment].baseurl + API_ENDPOINTS[urlKey] + params : ENVIRONMENTS[environment].baseurl + API_ENDPOINTS[urlKey];
  }

  const config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    withCredentials:true,
    responseType:'blob'
  };

  if(showLoader){
    config['showLoader'] = true;
  }

  return axios.get(url, config);
}

const ServiceUtils = {
  getRequest: get,
  postRequest: post,
  downloadRequest: download,
};
export { ServiceUtils };
