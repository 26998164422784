import React, { useState, useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill'; //To build the editor
import 'react-quill/dist/quill.snow.css'; //React Quill Css package
import { ImageDrop } from 'quill-image-drop-module';
import './rich-editor.scss'; //CSS stylings
import { render } from '@testing-library/react';
import BlotFormatter from 'quill-blot-formatter';
import { QuillDivider } from 'quill-divider';

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
  'alt',
  'height',
  'width',
  'style'
];

class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

// Rich Text Editor Features
// Features Added to ToolBar:
//   1. Font size
//   2. Bold
//   3. Italic
//   4. Underline
//   5. Strikethrough
//   6. Align 
//   7. Divider 
//   8. Color 
//   9. Background Color
//   10. Header 
//   11. Ordered List 
//   12. Unordered List 
//   13. Indent -1
//   14. Indent +1
//   15. Link 
//   16. Video 
//   17. Image 
const QuillRichTextEditor = ({
  mode, editorContent, updateContent, editorStyleData,
  editorToolBarSetting, toolbarMode, scrollingContainer, ...props
}) => {
  const [content, setContent] = useState('');
  const [quillObj, setQuillObj] = useState();
  const [editorStyle, setEditorStyle] = useState({
    display: 'flex',
    flexDirection: 'column'
  });
  const [showEditor, setShowEditor] = useState(false);
  const textFormats = ['bold', 'italic', 'underline', 'list', 'align', 'color', 'background'];
  useEffect(() => {
    if (editorContent) {
      setContent(editorContent);
    } else {
      setContent('<p><br></p>');
    }
    setShowEditor(true);
  }, [editorContent]);

  // useEffect(() => {
  //   setEditorStyle(editorStyleData);
  // }, [editorStyleData]);

  /**Image upload handler */
  const imageHandler = () => {
    const ip = document.createElement('input');
    ip.setAttribute('type', 'file');
    ip.setAttribute('accept', 'image/png, image/jpeg'); // allowed image file formats
    ip.click();

    ip.onchange = async () => {
      // Save current cursor state
      const range = quillObj.quill.getSelection(true);

      // Remove current image
      quillObj.quill.deleteText(range.index, 1);

      const maxSize = 2097152; // 2 MB
      const fileSize = ip.files[0].size;
      if (fileSize <= maxSize) {
        const reader = new FileReader();
        render.onload = function () {

          // Insert uploaded image
          quillObj.quill.insertEmbed(range.index, 'image', reader.result);
        }
        reader.readAsDataURL(ip.files[0]);
      }
      else {
        alert('The file size exceeds the maximum limit of 2mb');
      }
      ip.remove();
    };
  }

  // const editorToolBarSetting = {
  //   // Toolbar customized according to our needs
  //   toolbar: {
  //     container: [
  //       ['bold', 'italic', 'underline', 'strike'],
  //       ['link', "image"],
  //       [{ 'align': [] }], ['divider'],
  //       [{ 'color': [] }, { 'background': [] }],
  //       [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  //       [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
  //     ],
  //     // handlers: { image: imageHandler() },
  //   },

  //   // Divider added separately (customized one)
  //   divider: { icon: "<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}><path d='M15.4529 8.21875H1.45292C1.37434 8.21875 1.31006 8.28304 1.31006 8.36161V9.36161C1.31006 9.44018 1.37434 9.50446 1.45292 9.50446H15.4529C15.5315 9.50446 15.5958 9.44018 15.5958 9.36161V8.36161C15.5958 8.28304 15.5315 8.21875 15.4529 8.21875Z' fill='#87848D' /></svg>" },

  //   // Image resize feature for the image uploaded to the editor
  //   imageDrop: true,
  //   blotFormatter: {},
  // };

  const handleChange = (value) => {
    try {
      if (typeof updateContent === 'function' && content !== value && showEditor) {
        updateContent(value);
      }
      setContent(value);
    } catch (error) {
      console.log("error", error);
    }
  }

  const quillref = () => {
    const input = document.querySelector("input[data-link]");
    if (input && input.length) {
      input.setAttribute('placeholder', 'https://www.site.com');
      input.setAttribute('data-link', 'https://www.site.com');
    }
  }

  return (
    <>
      {/* Container to contain the editor inside one proper structure */}
      <div className='editor-section' id="scrolling-container">
        {showEditor && <ReactQuill
          id="quill-container"
          scrollingContainer={scrollingContainer || 'body'}
          className={`${mode === 'view' ? "hide-toolbar" : "edit-mode"}`}
          ref={(el) => {
            setQuillObj(el);
            quillref();
          }}
          defaultValue={content}
          // Editor stylings
          style={editorStyle}

          readOnly={mode === 'view'}

          modules={mode === 'edit' ? editorToolBarSetting : {}}
          onChange={handleChange}
          formats={toolbarMode === 'text-only' ? textFormats : undefined}
        />}
      </div>
    </>
  );
};

Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register(ImageFormat, true);
export default QuillRichTextEditor;
