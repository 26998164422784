import React, { useState } from "react";
import "./Login.scss";
import loginBanner from "../../Assets/images/Braincase_login_image.png";
import { ServiceUtils } from "../../Shared/Utils/ServiceUtils";
import { alertService } from "../../Shared/Utils/ToasterUtil";
import { sessionService } from "../../Shared/Utils/SessionService";
import { VARIABLES } from "../../Environments/constants";
import SVGIcon from "../../Shared/Components/SVGIcon/SVGIcon";
import { Button } from "antd";
import { DEPLOYED_ENVIRONMENT } from "../../Environments/environments";
import { IDM_CONF } from "../../Environments/config";
import { useHistory } from "react-router-dom";
import { localStorageAsync } from "../../Shared/Utils/CommonUtil";

export default function Login(props) {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const idmConfig = IDM_CONF[DEPLOYED_ENVIRONMENT];
  const [clickedLogin, setClickedLogin] = useState(false);
  const history = useHistory();
  useState(() => {
    /** Check user-details in session */
    const userDetails = sessionService.getSession();
    if (
      userDetails &&
      userDetails[VARIABLES.userName] &&
      userDetails[VARIABLES.userRole]
    ) {
      props.updateAuth(userDetails);
    } else if (localStorage.getItem(VARIABLES.idmLoginInit)) {
      localStorage.clear();
      //navigate to Auth call back
      history.replace("authCallBack");
    }
  });
  async function loginUser() {
    setClickedLogin(true);
    if(username.trim() && password.trim()){
      const base64password = window.btoa(password);
      let data = { username, password: base64password };
      const userData = {
        [VARIABLES.userName]: username,
        [VARIABLES.email]: username,
      };
      setIsLoader(true);
      ServiceUtils.postRequest("login", data, false).then((_response) => {
        setIsLoader(false);
        try {
          if (_response.status === 200) {
            const resp = _response.data;
            if (resp.status === "success") {
              setClickedLogin(false);
              const session = {
                ...userData,
                [VARIABLES.fullName]: resp.data?.user_list?.name,
                [VARIABLES.userName]: resp.data?.user_list?.name,
                [VARIABLES.firstName]: resp.data?.user_list?.first_name,
                [VARIABLES.middleName]: resp.data?.user_list?.middle_name,
                [VARIABLES.lastName]: resp.data?.user_list?.last_name,
                [VARIABLES.userId]: resp.data?.user_list?.user_id,
                [VARIABLES.userRole]: resp.data?.user_list?.role,
                [VARIABLES.userRoleId]: resp.data?.user_list?.role_id,
                [VARIABLES.email]: resp.data?.user_list?.email,
                // [VARIABLES.accessToken]: resp.data?.user_list?.token,
              };
              sessionService.setSession(session, VARIABLES.userSession);
              props.updateAuth(session);
            } else {
              alertService.error(resp.message || `Error while login`);
            }
          } else {
            alertService.error(
              `Something went wrong. Please try after sometime.`
            );
          }
        } catch (error) {
          console.log("error", error);
        }
      });
    }
  }

  function onEnter(e) {
    try {
      if (e.charCode === 13) {
        loginUser();
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * Requesting for access
   */
  const onAccessRequest = () => {
    try {
      console.log("Clicked request for access");
    } catch (error) {
      console.log("error", error);
    }
  };

  const onForgetPassword = () => {
    try {
      console.log("Clicked on forget password");
    } catch (error) {
      console.log("error", error);
    }
  };

  const loginWithSso = () => {
    const url = `${idmConfig.idm_login_url}?ReturnUrl=${window.encodeURI(
      idmConfig.auth_callback_url
    )}`;
    localStorageAsync
      .set(VARIABLES.idmLoginInit, "idmLoginInit")
      .then(function () {
        window.location.href = url;
      });
  };

  return (
    <div className="login-signup">
      <div className="register-form">
        <div className="row  formDiv ">
          <div className="col-md-5  formMainDiv bg-white px-10 py-lg-5 vh-100">
            <div className="d-flex logoDiv">
              <SVGIcon icon={"logo-transparent"} className="logoTransparent" />
              <span className="pt-3 m-0 brainCase">Braincase</span>
            </div>
            {(["qa", "prod"].indexOf(DEPLOYED_ENVIRONMENT) > -1) && (
              <Button
                className="loginSSOButton"
                type="primary"
                onClick={loginWithSso}
              >
                LOG IN WITH IDM/SSO
              </Button>
            )}
            {(["localhost", "dev"].indexOf(DEPLOYED_ENVIRONMENT) > -1) && (
              <form className="form text-dark">
                <div className="form-group mt-3">
                  <label htmlFor="name">Username / ID</label>
                  <input
                    type="text"
                    onChange={(e) => setUserName(e.target.value)}
                    className="form-control"
                    onKeyPress={(event) => onEnter(event)}
                  />
                  {(clickedLogin && !username.trim()) && <h6 className="error-msg">Username / ID is required</h6>}
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control"
                    onKeyPress={(event) => onEnter(event)}
                  />
                  {(clickedLogin && !password.trim()) && <h6 className="error-msg">Password is required</h6>}
                </div>
                <div className="d-flex buttonLinkDiv">
                  <div className="form-group">
                    <a
                      className="text-decoration-none accessLink mt-2"
                      onClick={onForgetPassword}
                    >
                      Forgot Password?
                    </a>
                  </div>
                  <Button
                    type="primary"
                    onClick={() => loginUser()}
                    disabled={isLoader}
                    className="mt-3 loginButton"
                  >
                    LOG IN
                    {isLoader && (
                      <div
                        className="spinner-border spinner-border-sm ms-2"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </Button>
                </div>
              </form>
            )}
            <div className="form-group supportText">
              {(["dev"].indexOf(DEPLOYED_ENVIRONMENT) > -1) && (
                <a
                  className="text-decoration-none accessLink"
                  onClick={loginWithSso}
                >
                  Log in with IDM/SSO
                </a>
              )}
              <a
                className="text-decoration-none accessLink mt-2 pb-3"
                onClick={onAccessRequest}
              >
                Request KM Access
              </a>
              <p className="d-inline-block  ">
                For technical support or other related questions, <br /> email:
                support@placeholder.org
              </p>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${loginBanner})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
            className="col-md-7"
          ></div>
        </div>
      </div>
    </div>
  );
}
