import React, { useState, useEffect, useRef } from "react";
import "./Header.scss";
import { Navbar, Nav } from "react-bootstrap";
import { sessionService } from "../../Utils/SessionService";
import { useLocation } from "react-router-dom";
import SVGIcon from "../SVGIcon/SVGIcon";
import { Breadcrumb, Tooltip } from 'antd';
import moment from "moment";
import { CONSTANTS } from '../../../Environments/constants';
import CommentProjectsAttributes from "../../../Pages/Home/Projects/project-details/ProjectDetailsComment";

 
export default function Header({ projectData, showComments,navigateToHomeEvent, ...props }) {
  const location = useLocation();
  const userDetails = sessionService.getSession();
  const [pageTitle, setPageTitle] = useState("");
  const [subPageTitle, setSubPageTitle] = useState("");
  const [selectedTab, setSelectedTab] = useState("");
  const [pathData, setPath] = useState('');
  const [updatedBy, setUpdatedBy] = useState();
  const KM_CONSULTANT = CONSTANTS?.KM_CONSULTANT;
  const ABI_CONSULTANT = CONSTANTS?.ABI_CONSULTANT;
 
  useEffect(() => {
    const path = location.pathname;
    const route = path.split("/").pop();
    let routeData = path.split("/");
    setPath(routeData);
    if (
      route !== "project" &&
      route !== "details" &&
      route !== "biases" &&
      route !== "userManagement" &&
      route !== "admin" &&
      route !== "alerts"
    ) {
      setPageTitle("Bias-in-Action Repository");
    } else if (route === "project") {
      let role = userDetails?.role;
      if (role === KM_CONSULTANT) {
        setPageTitle("All Projects");
        setSubPageTitle("");
      } else if (role === ABI_CONSULTANT) {
        setPageTitle("My Projects");
        setSubPageTitle("");
      }
    } else if (route === "biases") {
      setPageTitle("Biases");
    } else if (route === "admin") {
      setPageTitle("Admin");
    } else {
      setPageTitle(route ? route.toUpperCase() : "");
    }
    if (
      routeData.length &&
      routeData[2] === "project" &&
      routeData[3] === "details"
    ) {
      let role = userDetails?.role;
      if (role === KM_CONSULTANT) {
        setPageTitle("All Projects");
      } else if (role === ABI_CONSULTANT) {
        setPageTitle("My Projects");
      }
      if (routeData.length === 4) {
        setSubPageTitle("Create New Project");
      } else if (routeData.length === 5) {
        setSubPageTitle(routeData[5]);
      }
    }
  }, [location]);
 
  useEffect(() => {
    if(projectData?.projectName){
      setSubPageTitle(projectData?.projectName);
    }
    setSelectedTab(projectData?.selectedTab);
    moment.locale('en');
    if(projectData?.last_updated_time){
      let tempValue = moment.utc(projectData?.last_updated_time).local()?.format("MM-DD-YYYY");
      setUpdatedBy(tempValue);
    }
  }, [projectData]);
 
  /**
   * To navigate project page
   */
  const navigateToHome = () => {
    try {
      if (typeof navigateToHomeEvent === 'function') navigateToHomeEvent({'navigateToHome': true});
    } catch (error) {
      console.log("error", error);
    }
  }
 
  const commentsProjectsAttributesRef = useRef(null);
  /**
   * To show comments
   */
  const showComment = () => {
    try {
      if (typeof showComments === "function")
      commentsProjectsAttributesRef.current.showCommentDrawer(projectData);
    } catch (error) {
      console.log("error", error);
    }
  };
  
    
 
  

  


  return (
    <div className="header-root">
      <CommentProjectsAttributes ref={commentsProjectsAttributesRef} />

      <Navbar
        collapseOnSelect
        bg="light"
        variant=""
        sticky="top"
        className="navbar"
      >
        <>
          <span className="text-white brainIcon">BRAINCASE</span>
          <Breadcrumb separator=">">
            <Breadcrumb.Item className={`${(subPageTitle && pathData[3] === "details") ? 'page-title-highlight':'page-title'}`} onClick={navigateToHome}>
              {pageTitle}
            </Breadcrumb.Item>
            {subPageTitle && pathData[3] === "details" && (
              <Breadcrumb.Item className="sub-title">
                  <Tooltip placement="bottomLeft" title={`${subPageTitle?.length > 35 ? subPageTitle : ""}`} overlayClassName={`tooltip-bg ${subPageTitle?.length > 200 ? "tooltip-w" : ""}`}>
                    <div className={`${subPageTitle?.length > 35 ? "sub-title-ellipse" : ""}`}>{subPageTitle}</div>
                  </Tooltip>
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto navbarStyle"></Nav>
            {pathData[2] === "project" && pathData[3] === "details" && (
              <Nav className="">
                {subPageTitle !== 'Create New Project' && <Nav.Link className="project-last-update-details align-vertical-center">
                {(updatedBy && projectData?.last_updated_by) && <><SVGIcon className="me-1" icon={'info-dark'} />
                  <span >Updated {updatedBy} | By {projectData?.last_updated_by}</span></>}
                </Nav.Link>}
                <Nav.Link onClick={() => showComment()} style={{ color: "#332b2b", borderLeft: "1px solid gray" }} className={`nav-inbox ${(subPageTitle === 'Create New Project' || (projectData?.selectedTab !== 'project_attributes' && projectData?.selectedTab !== 'candidate_biases')) ? "disable-icon" : ""}`}>
                  <i className=" position-relative ">
                    {/* <span className="position-absolute   translate-middle badge rounded-pill "> */}
                     {/* <span className="visually-hidden">unread messages</span> */}
                    {/* </span> */}
                  </i>
                  <SVGIcon icon={"comment-header"} />
                </Nav.Link>
              </Nav>
            )}
          </Navbar.Collapse>
        </>
      </Navbar>
    </div>
  );
}