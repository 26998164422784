export const MESSAGES = {
  filter_no_data: "No records present for the selected filters",
  invalidToken: "Session has expired. Please login."
};

export const VARIABLES = {
  userSession: "zs-braincase-user",
  accessToken: "tokenId",
  userName: "name",
  fullName: "full_name",
  firstName: "first_name",
  middleName: "middle_name",
  lastName: "last_name",
  email: "email",
  userId: "user_id",
  userRole: "role",
  userRoleId: "role_id",
  idmLoginInit:"idmLoginInit"
};
export const CONSTANTS = {
  tablePageSize: [10, 25, 50, 100, 200, 500],
  KM_CONSULTANT: "KM.Consultant",
  ABI_CONSULTANT: "ABI.Consultant"
};
export const TOASTER_TIMEOUT = 10000;
