import React from 'react'
import Comments from '../../../../../Shared/Components/Comments/Comments'
import AnalysisComments from '../../../../../Assets/json/Analysis.json';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../../Shared/Styles/buttons.scss";
import "./Analysis.scss";
import "../../../../../Shared/Styles/colors.scss";
import { faMessage} from "@fortawesome/free-solid-svg-icons";
import workInProgress from '../../../../../Assets/images/work-in-progress.png';



export default function Analysis() {
  
  return (
    // <div>
    //   <button className="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Analysis Respondant Name</button>
    //   <div className="cmt-Offcanvas offcanvas offcanvas-end " tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
       
    //       <header>
    //         <div className="offcanvas-header border-bottom">
    //           <h6 className='' id="offcanvasRightLabel">Analysis - Respondant Name</h6>
    //           <button type="button" className="btn-close float-end" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    //         </div>
    //       </header>
    //       <div className='analysis-comments'>
    //         <div className='commentIcon border-bottom vh-30' style={{ paddingTop: 7, paddingLeft: 14, paddingBottom: 4 }}>
    //           <h6>Comments</h6>
    //           &nbsp;&nbsp;
    //           <FontAwesomeIcon
    //             className="fontIcon"
    //             icon={faMessage}
    //           />
    //         </div>
    //         <Comments commentData={AnalysisComments?.data} />
    //       </div>
        
    //         <footer className="footer border-top p-1 " style={{
    //           "position": "relative", "height": "150vh"
    //         }}>
    //           <button
    //             style={{
    //               "position": "fixed",
    //               "bottom": "10px"
    //             }}
    //             type="button"

    //             className="btn btn-secondary ms-3" data-bs-dismiss="offcanvas"
    //           >Close
    //           </button>
    //         </footer>
         
       
    //   </div>
    // </div>
    <div className="form-container">
      <img className='mt-2 workInProgressImage' src={workInProgress} alt="" />
    </div>
  )
}

