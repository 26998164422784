import React, { useEffect, useState, useRef } from "react";
import { faCirclePlus, faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SVGIcon from "../../../../../../Shared/Components/SVGIcon/SVGIcon";
import './discussion-guide-builder.scss'
import { Droppable } from 'react-drag-and-drop'
import { Drawer, Button, Row, Col, Input, Tooltip, Menu, Modal, Avatar, Spin, Dropdown, Space } from 'antd';
import { ServiceUtils } from '../../../../../../Shared/Utils/ServiceUtils'
import { scrollToDiv, newLineHtmlContent, generateUniqueId } from '../../../../../../Shared/Utils/CommonUtil'
import { alertService } from "../../../../../../Shared/Utils/ToasterUtil";
import DiscussionGuideComments from "../DiscussionGuideComments";
import QuillRichTextEditor from "../../../../../../Shared/Components/RichEditor/rich-editor";


export default function DragDrop({ targetId, project_id, selectedMenu, navigateStep, updateEditedData, ...props }) {

    const [selectedSections, setSelectedSections] = useState({});
    const [itemDragged, setDarggedItem] = useState({});
    const [expandedData, setExpandedData] = useState({});
    const [expandedSectionsData, setExpandeSectionsData] = useState({});
    /**
     * Add and update part 
     */
    const [biasOptions, setBiasOptions] = useState([]);
    const [commentDrawer, setCommentDrawer] = useState(false);
    const [biasSort, setBiasSort] = useState('asc');
    const [bkpMod, setBkpMod] = useState([]);
    const descriptionText = "For each Target Audience, Braincase shows below (right hand side) Methods of Detection for testing each Bias with example questions under them.  Using the the right-hand as reference you can build your Discussion Guide draft on the left hand side by adding new sections or new question on your own, as well as ordering the sections and questions as needed. When finished, Export your draft Discussion Guide to format it and discuss with the client to finalize.";
    const commentsDiscussionGuideRef = useRef(null);
    const [countData, setCountData] = useState({});
    const [deleteInfo, setDeleteInfo] = useState({});
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [commentLoader, setCommentLoader] = useState(false);
    const { TextArea } = Input;
    const [editItemData, setEditItemData] = useState({});
    const [editBiasData, setEditBiasData] = useState({});
    const [sectionsBkpData, setSectionsBkpData] = useState({});
    const [editSaveClicked, setEditSaveClicked] = useState({});
    const [modData, setModData] = useState([]);
    const noModDataFound = "Please select biases under the 'Candidate Biases' tab to view Method of Detection data for this project.";
    const [modsFetchCalled, setModsFetchCalled] = useState(false);
    const intendTitle = 'This is the first question of this section and cannot be indented, please move the question down under another question for the indent option to become active'

    const editorToolBarSetting = {
        toolbar: {
            container: [
                ['bold', 'italic', 'underline', 'strike'],
                ['link', "image"],
                [{ 'align': [] }], ['divider'],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ],
        },
        divider: { icon: "" },
        imageDrop: true,
        blotFormatter: {},
    };

    useEffect(() => {
        getModsData();
        getSectionData(true);
        setExpandedData({});
        setExpandeSectionsData({});
        getAssociatedBis();
    }, [])

    /**
     * On mod data toggle
     * @param {*} id - element id
     */
    const toggleData = (id) => {
        try {
            if (expandedData[id]) {
                expandedData[id] = false;
            } else {
                expandedData[id] = true;
            }
            setExpandedData({ ...expandedData });
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * On section toggle
     * @param {*} data - section data
     */
    const toggleSection = (sectionId) => {
        try {
            if (expandedSectionsData[sectionId]) {
                expandedSectionsData[sectionId] = false;
            } else {
                expandedSectionsData[sectionId] = true;
            }
            setExpandeSectionsData({ ...expandedSectionsData });
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * On item Dragged
     * @param {*} parentSection - Dragged item parent 
     * @param {*} question - Question
     */
    function onItemDrag(parentSection, question, type, subQuestion) {
        try {
            let data = {
                "sectionData": parentSection,
                "question": question ? question : {},
                'subQuestion': subQuestion ? subQuestion : {},
                'type': type
            }
            setDarggedItem(data);
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * Drop event on question
     * @param {*} sectionData - Section data
     * @param {*} question - Question data
     */
    const onItemDrop = (sectionData, question, subQuestion) => {
        try {
            if (itemDragged && ((itemDragged?.type === 'questionReorder' && itemDragged.question && itemDragged.question?.q_id && sectionData?.id === itemDragged?.sectionData?.id) ||
                (itemDragged?.type === 'subQuestionReorder' && itemDragged?.subQuestion && (itemDragged?.subQuestion?.parent_id === subQuestion?.parent_id)))) {
                let current_order_id = itemDragged?.type === 'questionReorder' ? itemDragged.question?.q_order : itemDragged.subQuestion?.q_order;
                let new_order_id = itemDragged?.type === 'questionReorder' ? question?.q_order : subQuestion?.q_order;
                if (current_order_id !== new_order_id) {
                    let inputPayLoad = {
                        "sec_id": itemDragged?.sectionData?.id,
                        "q_id": itemDragged?.type === 'questionReorder' ? itemDragged.question?.q_id : itemDragged.subQuestion?.q_id,
                        "is_parent_question": itemDragged?.type === 'questionReorder' ? itemDragged.question?.is_parent_question : itemDragged.subQuestion?.is_parent_question,
                        "current_order_id": itemDragged?.type === 'questionReorder' ? itemDragged.question?.q_order : itemDragged.subQuestion?.q_order,
                        "new_order_id": itemDragged?.type === 'questionReorder' ? question?.q_order : subQuestion?.q_order,
                        "parent_id": itemDragged?.type === 'questionReorder' ? itemDragged.question?.parent_id : subQuestion?.parent_id,
                    }
                    if (inputPayLoad['parent_id'] === "null") {
                        inputPayLoad['parent_id'] = null;
                    }
                    ServiceUtils.postRequest("questionReorder", inputPayLoad).then((_response) => {
                        if (_response.status === 200) {
                            if (_response.data.status === "success") {
                                alertService.success(_response.data.message);
                                getSectionData();
                            }
                        }
                    });
                }
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * To load method of detections data
     */
    const getModsData = () => {
        try {
            ServiceUtils.postRequest("fetchModsData", { project_id: project_id, speciality_id: selectedMenu.sp_id }).then((_response) => {
                if (_response.status === 200) {
                    setModsFetchCalled(true);
                    if (_response.data.status === "success") {
                        if (_response?.data?.data?.bias_data) {
                            let modData = _response?.data?.data?.bias_data;
                            modData.sort((a, b) => a['bias_name'].toLowerCase() > b['bias_name'].toLowerCase() ? 1 : -1);
                            setModData(modData);
                            setBkpMod(JSON.parse(JSON.stringify(_response?.data?.data?.bias_data)));
                        }
                    }
                }
            });
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * To load sections data
     */
    const getSectionData = (initialLoad = false) => {
        try {
            ServiceUtils.postRequest("targetAudienceData", { project_aud_id: targetId }).then((_response) => {
                if (_response.status === 200) {
                    if (_response.data.status === "success") {
                        let sectionsData = _response.data.data?.section_data;
                        setCountData(_response.data.data?.count_data);
                        if (sectionsData && sectionsData?.length) {
                            let sectionsDataOnLoad = {};
                            sectionsData.forEach(section => {
                                let key = section.id
                                sectionsDataOnLoad['sec_' + key] = section
                            });
                            setSelectedSections({ ...sectionsDataOnLoad });
                            setSectionsBkpData(JSON.parse(JSON.stringify(sectionsDataOnLoad)));
                            if (initialLoad && sectionsData && sectionsData?.length && sectionsData[0]?.id) {
                                expandedSectionsData['sec_' + sectionsData[0]?.id] = true;
                                setExpandeSectionsData({ ...expandedSectionsData });
                            }
                            if (sectionsData && sectionsData?.length === 1 && sectionsData[0]?.question_list?.length === 0) {
                                handleAddNew("question", sectionsData[0], sectionsDataOnLoad);
                            }
                        }
                    } else {
                        alertService.error(
                            _response.data.message ||
                            `Failed to fetch discussion guide sections`
                        );
                    }
                }
            });
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
  * On click of add new
  * @param {*} type - Question or section
  * @param {*} sectionData - Section data
  */
    const handleAddNew = (type, sectionData, allSectionsData = undefined) => {
        try {
            let addNewExist = false;
            for (let sectionId in selectedSections) {
                if (selectedSections[sectionId]?.addNew) {
                    addNewExist = true;
                }
            }
            if (type === "section" && !addNewExist) {
                let newSecData = {
                    id: Number(generateUniqueId()?.slice(0, 8)),
                    section_label: "",
                    section_order: Object.keys(selectedSections)?.length + 1,
                    question_list: [],
                    addNew: true
                }
                selectedSections['sec_' + newSecData?.id] = newSecData;
                setSelectedSections({ ...selectedSections });
                editItemData['sec' + newSecData?.id] = true;
                setEditItemData({ ...editItemData });
                if (typeof updateEditedData === 'function') updateEditedData(editItemData);
                setTimeout(() => {
                    scrollToDiv('section_' + newSecData?.id);
                }, 500);
            } else {
                let allSectionData = allSectionsData ? allSectionsData : selectedSections;
                let len = allSectionData['sec_' + sectionData?.id]?.question_list?.length;
                if (len === 0 || !allSectionData['sec_' + sectionData?.id]?.question_list[len - 1]['addNew']) {
                    let newQueData = {
                        bias_id: null,
                        bias_name: null,
                        is_parent_question: true,
                        parent_id: null,
                        q_id: Number(generateUniqueId()?.slice(0, 8)),
                        q_order: sectionData?.question_list.length + 1,
                        question: "",
                        sub_questions: [],
                        addNew: true
                    }
                    let quesData = allSectionData['sec_' + sectionData?.id]?.question_list;
                    quesData.push(newQueData);
                    let secData = {
                        ...allSectionData['sec_' + sectionData?.id],
                        question_list: quesData
                    }
                    allSectionData['sec_' + sectionData?.id] = secData;
                    setSelectedSections({ ...allSectionData });
                    expandedSectionsData['sec_' + sectionData?.id] = true;
                    setExpandeSectionsData({ ...expandedSectionsData });
                    editItemData['que' + newQueData?.q_id] = true;
                    setEditItemData({ ...editItemData });
                    if (typeof updateEditedData === 'function') updateEditedData(editItemData);
                    editBiasData[newQueData?.q_id] = true;
                    setEditBiasData({ ...editBiasData });
                    setTimeout(() => {
                        scrollToDiv('question_' + newQueData?.q_id);
                    }, 500);
                }
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    /**
     * update section 
     */
    const saveSection = (secData) => {
        editSaveClicked[secData?.id] = true;
        setEditSaveClicked({ ...editSaveClicked });
        if (secData?.section_label) {
            const input = secData?.addNew ? {
                "proj_aud_id": targetId,
                "label": secData?.section_label,
                "sec_order_id": secData?.section_order
            } : {
                "id": secData?.id,
                "section_name": secData?.section_label,
                'proj_aud_id': targetId
            }
            ServiceUtils.postRequest(secData?.addNew ? "addSection" : "updateSection", input).then((response) => {
                try {
                    if (response.status === 200) {
                        if (response.data.status === "success") {
                            alertService.success(response.data.message);
                            if (editSaveClicked[secData?.id]) {
                                editSaveClicked[secData?.id] = false;
                                setEditSaveClicked({ ...editSaveClicked });
                            }
                            if (editItemData['sec' + secData?.id]) {
                                delete editItemData['sec' + secData?.id];
                                setEditItemData({ ...editItemData });
                                if (typeof updateEditedData === 'function') updateEditedData(editItemData);
                            }
                            if (secData?.addNew) {
                                let newItemData = response?.data?.data.section_details;
                                selectedSections['sec_' + newItemData?.id] = newItemData;
                                delete selectedSections['sec_' + secData?.id];
                                setSelectedSections({ ...selectedSections });
                                expandedSectionsData['sec_' + newItemData?.id] = true;
                                setExpandeSectionsData({ ...expandedSectionsData });
                                setSectionsBkpData(JSON.parse(JSON.stringify(selectedSections)));
                            } else {
                                let data = JSON.parse(JSON.stringify(sectionsBkpData));
                                data['sec_' + secData?.id] = secData;
                                setSectionsBkpData(JSON.parse(JSON.stringify(data)));
                            }
                        } else {
                            alertService.error(
                                response.data.message ||
                                `Failed to update section`
                            );
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            });
        }
    }

    /**
       * On bias selection for new question
       * @param {*} selectedValue - selected bias data
       */
    const onBiasSelection = (selectedValue, id = undefined, secData = undefined, queIndex = undefined, subQueIndex = undefined) => {
        try {
            const biasName = biasOptions?.filter((el) => {
                if (el?.key.toString() === selectedValue.key) return el;
                else return false;
            })
            editBiasData[id] = false;
            setEditBiasData({ ...editBiasData });
            let questionData = {};
            if (subQueIndex === undefined) {
                questionData = {
                    ...selectedSections['sec_' + secData?.id].question_list[queIndex],
                    'bias_id': biasName[0].key,
                    'bias_name': biasName[0].label
                }
            } else {
                let subQuestionData = {
                    ...selectedSections['sec_' + secData?.id].question_list[queIndex]?.sub_questions[subQueIndex],
                    'bias_id': biasName[0].key,
                    'bias_name': biasName[0].label
                }
                let subQuestionsData = selectedSections['sec_' + secData?.id].question_list[queIndex]?.sub_questions;
                subQuestionsData[subQueIndex] = subQuestionData;
                questionData = {
                    ...selectedSections['sec_' + secData?.id].question_list[queIndex],
                    'question_list': subQuestionsData
                }
            }
            secData['question_list'][queIndex] = questionData;
            selectedSections['sec_' + secData?.id] = secData;
            setSelectedSections({ ...selectedSections });
        } catch (error) {
            console.log("error", error);
        }
    };

    /**
     * On delete of section and question
     * @param {*} id - item Id
     * @param {*} type - section or question
     */
    const showDeleteConfirmation = (type, id) => {
        try {
            setDeleteInfo({
                id: id,
                type: type
            });
            setShowDeleteConfirm(true);
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * To delete item
     */
    const deletedItem = () => {
        try {
            ServiceUtils.postRequest(deleteInfo?.type === 'section' ? "deleteSection" : 'deleteQuestion', { id: deleteInfo?.id }).then((_response) => {
                if (_response.status === 200) {
                    if (_response.data.status === "success") {
                        alertService.success(_response.data.message);
                        setShowDeleteConfirm(false);
                        getSectionData();
                    } else {
                        alertService.error(
                            _response.data.message ||
                            `${deleteInfo?.type === 'question' ? "Failed to delete the discussion guide question details" : "Failed to delete the discussion guide section details"}`
                        );
                    }
                }
            });
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * To show comments
     */
    const showCommentDrawer = (id, type) => {
        try {
            setTimeout(() => {
                setCommentDrawer(true);
                setCommentLoader(true);
                commentsDiscussionGuideRef.current.discussionCommentDrawer(type, project_id, id);
            }, 500);
        } catch (error) {
            console.log("error", error);
        }
    };

    /**
     * On update question of each section 
     */
    const saveQuestion = (questionData, secData, queIndex) => {
        editSaveClicked[questionData?.q_id] = true;
        setEditSaveClicked({ ...editSaveClicked });
        if (questionData?.question) {
            const input = questionData?.addNew ? {
                "bias_id": questionData?.bias_id && questionData?.bias_id !== "null" ? questionData?.bias_id : null,
                "order_id": questionData?.q_order,
                "question": questionData?.question,
                "sec_id": secData?.id,
                "is_parent_question": true
            } : {
                "id": questionData?.q_id,
                "bias_id": questionData?.bias_id && questionData?.bias_id !== "null" ? questionData?.bias_id : null,
                "question": questionData?.question
            }
            ServiceUtils.postRequest(questionData?.addNew ? "addQuestion" : "updateQuestion", input).then((response) => {
                try {
                    if (response.status === 200) {
                        if (response.data.status === "success") {
                            if (editSaveClicked[questionData?.q_id]) {
                                editSaveClicked[questionData?.q_id] = false;
                                setEditSaveClicked({ ...editSaveClicked });
                            }
                            alertService.success(response.data.message);
                            setCountData(response.data.data?.count_data);
                            if (editItemData['que' + questionData?.q_id]) {
                                delete editItemData['que' + questionData?.q_id];
                                setEditItemData({ ...editItemData });
                                if (typeof updateEditedData === 'function') updateEditedData(editItemData);
                            }
                            editBiasData[questionData?.q_id] = false;
                            setEditBiasData({ ...editBiasData });
                            if (questionData?.addNew) {
                                let newItemData = response?.data?.data?.question_details;
                                // To update newly added item
                                const quesData = secData?.question_list.filter(que => {
                                    return que.q_id !== questionData?.q_id;
                                });
                                quesData.push(newItemData);
                                let sectionsdata = {
                                    ...selectedSections['sec_' + secData?.id],
                                    question_list: quesData
                                }
                                selectedSections['sec_' + secData?.id] = sectionsdata;
                                setSelectedSections({ ...selectedSections });
                                setSectionsBkpData(JSON.parse(JSON.stringify(selectedSections)));
                            } else {
                                let data = JSON.parse(JSON.stringify(sectionsBkpData));
                                let quesList = data['sec_' + secData?.id]?.question_list;
                                quesList[queIndex] = questionData;
                                let sectionsdata = {
                                    ...data['sec_' + secData?.id],
                                    question_list: quesList
                                }
                                setSectionsBkpData(JSON.parse(JSON.stringify(sectionsdata)));
                            }

                        } else {
                            alertService.error(
                                response.data.message ||
                                `Failed to edit the discussion guide question details`
                            );
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            });
        }
    }
    /**
     * Get Associsted bias 
     */
    const getAssociatedBis = () => {
        ServiceUtils.postRequest("associatedBias", { project_id: project_id, speciality_id: selectedMenu.sp_id }).then((response) => {
            try {
                if (response.status === 200) {
                    if (response.data.status === "success") {
                        const result = response?.data?.data?.associated_bias;
                        const biasList = [];
                        result.map((el) => {
                            biasList.push({
                                label: el.label,
                                key: el.key,
                            })
                            return el;
                        })
                        setBiasOptions(biasList)
                    } else {
                        alertService.error(
                            response.data.message ||
                            `Failed to fetch the associated biases`
                        );
                    }
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    /**
     * Subquestion functionality
     * @param {*} questionData - Question data
     * @param {*} parentId - Parent Id
     */
    const subQuestionFun = (questionData, parentId) => {
        try {
            let inputPayLoad = {
                "id": questionData?.q_id,
                "is_parent_question": !questionData?.is_parent_question,
                "parent_id": parentId
            }
            if (inputPayLoad['parent_id'] === "null") {
                inputPayLoad['parent_id'] = null;
            }
            ServiceUtils.postRequest("subQuestion", inputPayLoad).then((_response) => {
                if (_response.status === 200) {
                    if (_response.data.status === "success") {
                        alertService.success(_response.data.message);
                        getSectionData();
                    } else {
                        alertService.error(
                            _response.data.message ||
                            `Failed to update the question as child or vice versa`
                        );
                    }
                }
            });
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * Sort bias data
     */
    const sortData = () => {
        try {
            if (biasSort === "asc") { // descending
                modData.sort((a, b) => a['bias_name'].toLowerCase() < b['bias_name'].toLowerCase() ? 1 : -1);
                setModData([...modData]);
                setBiasSort('des')
            } else if (biasSort === "des") {
                setModData([...bkpMod]);
                setBiasSort('default')
            } else if (biasSort === "default") {// ascending
                modData.sort((a, b) => a['bias_name'].toLowerCase() > b['bias_name'].toLowerCase() ? 1 : -1);
                setModData([...modData]);
                setBiasSort('asc')
            }
        } catch (error) {
            console.log("error", error);
        }
    }
    /**
     * Discussion guide export 
     */
    const exportDg = () => {
        try {
            const url = `?project_id=${project_id}&speciality_id=${selectedMenu.sp_id}&name=${selectedMenu.target_audience}`
            ServiceUtils.downloadRequest("exportDiscussionGuide", url).then((_response) => {
                if (_response.data.status === "failure") {
                    alertService.error(
                        _response.data.message ||
                        `Failed to export the discussion guide`
                    );
                } else {
                    try {
                        const contentDisposition = _response.headers['content-disposition'];
                        let fileName = 'Discussion Guide - Export.docx'
                        if (contentDisposition) { // find file name from header
                            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            const matches = fileNameRegex.exec(contentDisposition);
                            if (matches != null && matches[1]) {
                                fileName = matches[1].replace(/['"]/g, '');
                            }
                        }
                        // downloding the file 
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(_response.data);
                        link.download = fileName;
                        link.click();
                    } catch (error) {
                        alertService.error(`Failed to export the discussion guide`);
                    }
                }
            });
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * To navigate on click of next
     */
    const navigateSteps = (type) => {
        try {
            if (typeof navigateStep === 'function') navigateStep({ 'navigate': type });
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * Count data tooltip
     */
    const countTooltip = () => {
        return (
            <React.Fragment>
                # of Questions that cover current Bias:
                <div className="d-grid">
                    {countData?.count_questions && countData?.count_questions.map((bias) => {
                        return (<span>{bias?.bias_name}: <span className="count-font-weight">{bias?.question_count}</span></span>)
                    })}
                </div>
            </React.Fragment>)
    }

    const menu = (id, secData, queIndex, subQueIndex = undefined) => (
        <Menu
            onClick={(e) => onBiasSelection(e, id, secData, queIndex, subQueIndex)}
            items={biasOptions}
            mode="horizontal"

        />
    );

    /**
     * To show data as html content
     * @param {*} data - string data
     * @returns - html content
     */
    const htmlData = (data) => {
        try {
            return (<React.Fragment>
                <div dangerouslySetInnerHTML={{ __html: data }} />
            </React.Fragment>)
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * To bring edit state of item
     * @param {*} itemId 
     */
    const onEditItem = (itemId, type) => {
        try {
            editItemData[type + itemId] = true;
            setEditItemData({ ...editItemData });
            if (typeof updateEditedData === 'function') updateEditedData(editItemData);
            if (type === 'sec') {
                scrollToDiv('section_' + itemId);
            } else {
                scrollToDiv('question_' + itemId);
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
    * On click of bias cancel to edit
     * @param {*} queId - question id
     * @param {*} secData - section Data
     * @param {*} queIndex - question index
     * @param {*} subQueIndex - sub question index
     */
    const onBiasEdit = (queId, secData, queIndex = undefined, subQueIndex = undefined) => {
        try {
            editBiasData[queId] = true;
            setEditBiasData({ ...editBiasData });
            let questionData = {};
            if (subQueIndex === undefined) {
                questionData = {
                    ...selectedSections['sec_' + secData?.id].question_list[queIndex],
                    'bias_id': null,
                    'bias_name': null
                }
            } else {
                let subQuestionData = {
                    ...selectedSections['sec_' + secData?.id].question_list[queIndex]?.sub_questions[subQueIndex],
                    'bias_id': null,
                    'bias_name': null
                }
                let subQuestionsData = selectedSections['sec_' + secData?.id].question_list[queIndex]?.sub_questions;
                subQuestionsData[subQueIndex] = subQuestionData;
                questionData = {
                    ...selectedSections['sec_' + secData?.id].question_list[queIndex],
                    'question_list': subQuestionsData
                }
            }
            secData['question_list'][queIndex] = questionData;
            selectedSections['sec_' + secData?.id] = secData;
            setSelectedSections({ ...selectedSections });
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * To update data on edit
     * @param {*} value - edited value
     * @param {*} secData - section data
     * @param {*} queIndex - question index
     * @param {*} subQueIndex - sub question index
     */
    const updateEditData = (value, secData, queIndex = undefined, subQueIndex = undefined) => {
        try {
            if (secData?.question_list?.length && queIndex !== undefined) {
                let questionData = {};
                if (subQueIndex === undefined) {
                    questionData = {
                        ...selectedSections['sec_' + secData?.id].question_list[queIndex],
                        'question': value
                    }
                } else {
                    let subQuestionData = {
                        ...selectedSections['sec_' + secData?.id].question_list[queIndex]?.sub_questions[subQueIndex],
                        'question': value
                    }
                    let subQuestionsData = selectedSections['sec_' + secData?.id].question_list[queIndex]?.sub_questions;
                    subQuestionsData[subQueIndex] = subQuestionData;
                    questionData = {
                        ...selectedSections['sec_' + secData?.id].question_list[queIndex],
                        'question_list': subQuestionsData
                    }
                }
                secData['question_list'][queIndex] = questionData;
            } else {
                secData['section_label'] = value;
            }
            selectedSections['sec_' + secData?.id] = secData;
            setSelectedSections({ ...selectedSections });
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * On edit cancel
     * @param {*} type - question or section
     * @param {*} itemId - edit item id
     * @param {*} secId - section id
     * @param {*} queIndex -question index
     * @param {*} subQueIndex - sub question index
     */
    const onEditCancel = (type, itemId, secId, queIndex, subQueIndex) => {
        try {
            if (editItemData[type + itemId]) {
                delete editItemData[type + itemId];
                setEditItemData({ ...editItemData });
                if (typeof updateEditedData === 'function') updateEditedData(editItemData);
            }
            if (editSaveClicked[itemId]) {
                editSaveClicked[itemId] = false;
                setEditSaveClicked({ ...editSaveClicked });
            }
            let secData = selectedSections[secId];
            if (!secData?.addNew && !secData?.question_list[queIndex]?.addNew) {
                if (type === 'que') {
                    editBiasData[itemId] = false;
                    setEditBiasData({ ...editBiasData });
                    let questionData = {};
                    if (subQueIndex === undefined) {
                        questionData = {
                            ...sectionsBkpData['sec_' + secData?.id].question_list[queIndex],
                        }
                    } else {
                        let subQuestionData = {
                            ...sectionsBkpData['sec_' + secData?.id].question_list[queIndex]?.sub_questions[subQueIndex],
                        }
                        let subQuestionsData = selectedSections['sec_' + secData?.id].question_list[queIndex]?.sub_questions;
                        subQuestionsData[subQueIndex] = subQuestionData;
                        questionData = {
                            ...selectedSections['sec_' + secData?.id].question_list[queIndex],
                            'question_list': subQuestionsData
                        }
                    }
                    secData['question_list'][queIndex] = questionData;
                } else {
                    secData = JSON.parse(JSON.stringify(sectionsBkpData['sec_' + secData?.id]));
                }
                selectedSections[secId] = secData;
            } else {
                if (secData?.addNew) {
                    delete selectedSections['sec_' + secData?.id];
                } else if (secData?.question_list[queIndex]?.addNew) {
                    const quesData = secData?.question_list.filter(que => {
                        return que.q_id !== itemId;
                    });
                    let sectionsdata = {
                        ...selectedSections['sec_' + secData?.id],
                        question_list: quesData
                    }
                    selectedSections['sec_' + secData?.id] = sectionsdata;
                }
            }
            setSelectedSections({ ...selectedSections });
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * Drop event on section
     * @param {*} section - section data
     */
    const onSectionDrop = (section) => {
        try {
            if (itemDragged && itemDragged?.type === 'sectionReorder') {
                if (section?.section_order !== itemDragged?.sectionData?.section_order) {
                    let inputPayLoad = {
                        "sec_id": itemDragged?.sectionData?.id,
                        "new_sec_order": section?.section_order,
                        "old_sec_order": itemDragged?.sectionData?.section_order,
                    }
                    ServiceUtils.postRequest("sectionReorder", inputPayLoad).then((_response) => {
                        if (_response.status === 200) {
                            if (_response.data.status === "success") {
                                alertService.success(_response.data.message);
                                getSectionData();
                            } else {
                                alertService.error(
                                    _response.data.message ||
                                    `Failed to update section order id`
                                );
                            }
                        }
                    });
                }
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * On item drag
     * @param {*} e - drag event
     */
    const onDragStart = (e) => {
        try {
            if (e.currentTarget?.parentElement?.parentElement) {
                const crt = e.currentTarget?.parentElement?.parentElement?.parentElement?.cloneNode(true);
                crt.style.width = "500px";
                crt.classList.add("dragged-item-div");
                document.body.appendChild(crt);
                e.dataTransfer.setDragImage(crt, 0, 0);
            } else {
                e.dataTransfer.setDragImage(new Image(), 0, 0); // to hide the image on drag
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * On item drop
     */
    const onDragStop = () => {
        try {
            const text = document.querySelectorAll('.dragged-item-div')
            for (const el of text) {
                el.parentNode.removeChild(el);
            }
        } catch (error) {
            console.log("error", error);
        }

    }

    return (
        <React.Fragment>
            <div className="custom-drag-n-drop">
                <Row className="main-section">
                    <Col span={12} className="summary">
                        For each Target Audience, Braincase shows below (right hand side) Methods of Detection for testing each Bias with example questions under them.  Using the the right-hand...
                        <Tooltip placement="topLeft" color={'black'} arrowPointAtCenter={true} overlayClassName={'project-attr-desc tooltip-bg'} title={descriptionText}>
                            <SVGIcon icon={'info-fill'} className="m-0 ps-1 fontCircleInfo" />
                        </Tooltip>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={15}>
                            </Col>
                            <Col span={9} className="align-vertical-right">
                                <Button onClick={exportDg} className={"exportButtonText"}><SVGIcon icon={'wrapper'} className={"exportIcon"} />Export Discussion Guide</Button>
                                {/* <Select placeholder="Import/Export Discussion Guide" options={importOptions} /> */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="main-section">
                    <Col span={14} className="left-panel">
                        <h6 className="section-headings">Discussion Guide Builder</h6>
                        <Row>
                            <Col span={12}>
                                <Button type="primary" className="m-t-10 mb-2" onClick={() => handleAddNew("section")}>
                                    <FontAwesomeIcon icon={faCirclePlus} className="me-1" />  Add Section
                                </Button>
                            </Col>
                            <Col span={12} className="align-right p-2">
                                Total # of Question: <span className="count-font-weight">{countData?.total_number_of_questions}</span>
                                <Tooltip placement="topLeft" color={"black"} title={countData?.total_number_of_questions ? countTooltip() : 'No questions have been added yet in the discussion guide builder.'} overlayClassName={countData?.total_number_of_questions ? 'count-tooltip' : ''}>
                                    <SVGIcon icon={'info-fill'} className="m-0 ps-1 fontCircleInfo" />
                                </Tooltip>
                            </Col>
                        </Row>
                        <div className="m-t-14 div-height">
                            {selectedSections && Object.keys(selectedSections).map(function (keyName) {
                                return (
                                    <React.Fragment key={selectedSections[keyName].id}>
                                        <Droppable onDrop={() => onSectionDrop(selectedSections[keyName])}>
                                            <div className="main">
                                                <Row className="p-8 align-vertical-center">
                                                    <Col span={1}>
                                                        <Tooltip placement="topRight" color="black" title={"Change the order of your sections by moving them up and down within your Discussion Guide Builder"}>
                                                            <SVGIcon className={Object?.keys(editItemData)?.length ? 'disabled-moveIcon' : "moveIcon"} icon={'drag'} draggable={Object?.keys(editItemData)?.length ? false : true} id={'section_' + selectedSections[keyName].id}
                                                                onDrag={() => onItemDrag(selectedSections[keyName], undefined, 'sectionReorder')}
                                                                onDragStart={onDragStart}
                                                                onDragEnd={() => onDragStop()} />
                                                        </Tooltip>
                                                    </Col>
                                                    {editItemData['sec' + selectedSections[keyName].id] && <Col span={23} className="p-l-2">
                                                        <Row>
                                                            <Col span={21}>
                                                                <span className="section-headings">{!selectedSections[keyName]?.addNew ? 'Edit' : 'Add'} Section</span>
                                                            </Col>
                                                            {!selectedSections[keyName]?.addNew && <Col span={3} className="align-right">
                                                                <Tooltip placement="topRight" title={'Comment'} overlayClassName={'tooltip-bg'}>
                                                                    <SVGIcon className="fontIcon" icon={'chat-fill'} onClick={() => showCommentDrawer(selectedSections[keyName].id, 'section')} />
                                                                </Tooltip>
                                                                <Tooltip placement="topRight" title={'Delete'} overlayClassName={'tooltip-bg'}>
                                                                    {Object?.keys(selectedSections)?.length !== 1 ? (<SVGIcon icon={'delete-fill'} className="fontIcon" onClick={() => showDeleteConfirmation('section', selectedSections[keyName]?.id)} />) :
                                                                        (<SVGIcon className="disabled-delete-icon" icon={'delete-disabled'} />)}
                                                                </Tooltip>
                                                            </Col>}
                                                        </Row>
                                                        <Row>
                                                            <Col span={24} className="p-r-12 mt-1">
                                                                <TextArea rows={3} value={selectedSections[keyName].section_label}
                                                                    onChange={(e) => { updateEditData(e.target.value.replace(/^\s+/g, ''), selectedSections[keyName]) }} />
                                                                {editSaveClicked[selectedSections[keyName].id] && !selectedSections[keyName].section_label && <h6 className="error-msg">Please Add a Section</h6>}
                                                            </Col>
                                                        </Row>
                                                        <div className="edit-sec-input-footer">
                                                            <Button type="primary" className="edit-button" onClick={() => saveSection(selectedSections[keyName])}>Save Section</Button>
                                                            <Button className="cancel-input" onClick={() => onEditCancel('sec', selectedSections[keyName]?.id, keyName)}>Cancel</Button>
                                                        </div>
                                                    </Col>}
                                                    {!editItemData['sec' + selectedSections[keyName].id] && <Col span={12} className="p-l-2 d-flex">
                                                        <FontAwesomeIcon className="p-r-10 pt-2 " icon={expandedSectionsData[keyName] ? faChevronDown : faChevronRight} onClick={() => toggleSection(keyName)}></FontAwesomeIcon>
                                                        <Tooltip placement="top" title={selectedSections[keyName].section_label.length > 30 ? htmlData(newLineHtmlContent(selectedSections[keyName].section_label)) : ''} overlayClassName={'tooltip-bg'}>
                                                            <label className={`font-weight-5 pt-1 ${selectedSections[keyName].section_label.length > 30 ? 'label-ellipse' : ''}`}
                                                            >{htmlData(newLineHtmlContent(selectedSections[keyName].section_label))}</label>
                                                        </Tooltip>
                                                    </Col>}
                                                    {!editItemData['sec' + selectedSections[keyName].id] && <Col span={11} className="align-right">
                                                        <span className="add-question cursor-pointer" onClick={() => handleAddNew("question", selectedSections[keyName])}><FontAwesomeIcon icon={faCirclePlus} /> Add Question</span>
                                                        <Tooltip placement="topRight" title={'Comment'} overlayClassName={'tooltip-bg'}>
                                                            <SVGIcon className="fontIcon" icon={'chat-fill'} onClick={() => showCommentDrawer(selectedSections[keyName].id, 'section')} />
                                                        </Tooltip>
                                                        <Tooltip placement="topRight" title={'Edit'} overlayClassName={'tooltip-bg'}>
                                                            <SVGIcon className="fontIcon" icon={'edit-fill'} onClick={() => onEditItem(selectedSections[keyName]?.id, 'sec')} />
                                                        </Tooltip>
                                                        <Tooltip placement="topRight" title={'Delete'} overlayClassName={'tooltip-bg'}>
                                                            {Object?.keys(selectedSections)?.length !== 1 ? (<SVGIcon icon={'delete-fill'} className="fontIcon" onClick={() => showDeleteConfirmation('section', selectedSections[keyName]?.id)} />) :
                                                                (<SVGIcon className="disabled-delete-icon" icon={'delete-disabled'} />)}
                                                        </Tooltip>
                                                    </Col>}
                                                </Row>
                                            </div>
                                            <Row>
                                                {expandedSectionsData[keyName] && <React.Fragment>
                                                    {selectedSections[keyName] && selectedSections[keyName]?.question_list && selectedSections[keyName].question_list.map((question, index) => <React.Fragment key={question.q_id}>
                                                        <Droppable className="w-100"
                                                            id={question.id}
                                                            onDrop={() => onItemDrop(selectedSections[keyName], question)}
                                                        >
                                                            <React.Fragment>
                                                                <div className="section">
                                                                    <Row className="p-8 align-vertical-center">
                                                                        <Col span={1}>
                                                                            <Tooltip placement="topRight" color="black" title={"Change the order of your questions by moving them up and down within your Discussion Guide Builder"}>
                                                                                <SVGIcon className={Object?.keys(editItemData)?.length ? 'disabled-moveIcon' : "moveIcon"} icon={'drag'} draggable={Object?.keys(editItemData)?.length ? false : true} id={'question_' + question?.q_id}
                                                                                    onDrag={() => onItemDrag(selectedSections[keyName], question, 'questionReorder')}
                                                                                    onDragStart={onDragStart}
                                                                                    onDragEnd={() => onDragStop()} />
                                                                            </Tooltip>
                                                                        </Col>
                                                                        {editItemData['que' + question?.q_id] && <Col span={23} className="question-padding">
                                                                            <Row>
                                                                                <Col span={21}>
                                                                                    <span className="section-headings">{question?.addNew ? 'Add' : 'Edit'} Question</span>
                                                                                </Col>
                                                                                {!question?.addNew && <Col span={3} className="align-right">
                                                                                    <Tooltip placement="topRight" title={'Comment'} overlayClassName={'tooltip-bg'}>
                                                                                        <SVGIcon className="fontIcon" icon={'chat-fill'} onClick={() => showCommentDrawer(question?.q_id, "question")} />
                                                                                    </Tooltip>
                                                                                    <Tooltip placement="topRight" title={'Delete'} overlayClassName={'tooltip-bg'}>
                                                                                        <SVGIcon icon={'delete-fill'} className="fontIcon" onClick={() => showDeleteConfirmation('question', question?.q_id)} />
                                                                                    </Tooltip>
                                                                                </Col>}
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24} className="p-r-12">
                                                                                    {!editBiasData[question?.q_id] && question?.bias_name && question?.bias_name !== "null" && <div className="m-b-5"><span className="bias-label">{question.bias_name}<SVGIcon icon={'cross-icon'} className="p-1 cursor-pointer" onClick={() => { onBiasEdit(question?.q_id, selectedSections[keyName], index) }} /></span></div>}
                                                                                    {(editBiasData[question?.q_id] || !question?.bias_name || question?.bias_name === "null") && <>
                                                                                        <div className="d-flex">
                                                                                            <div className="biasTag d-flex">
                                                                                                <SVGIcon icon={"tag-black"} className={'tagIcon'} />
                                                                                                <p className="ms-1">Add Associated Bias Tag</p>
                                                                                            </div>
                                                                                            <Space wrap className="bias-dropdown">
                                                                                                <Dropdown overlay={menu(question?.q_id, selectedSections[keyName], index)}>
                                                                                                    <Button className="border-0 text-muted p-1">
                                                                                                        <Space style={{ 'fontSize': '12px' }}>
                                                                                                            {question?.bias_name && question?.bias_name !== 'null' && question?.bias_name !== null ? question?.bias_name : "(Optional)"}
                                                                                                            <FontAwesomeIcon icon={faChevronDown} className="downIcon" />
                                                                                                        </Space>
                                                                                                    </Button>
                                                                                                </Dropdown>
                                                                                            </Space>
                                                                                        </div></>}
                                                                                    <TextArea rows={3} className={!editBiasData[question?.q_id] ? 'mt-2' : ''} value={question.question} onChange={(e) => { updateEditData(e.target.value.replace(/^\s+/g, ''), selectedSections[keyName], index) }} />
                                                                                    {editSaveClicked[question?.q_id] && !question.question && <h6 className="error-msg">Please Add a Question</h6>}
                                                                                </Col>
                                                                            </Row>
                                                                            <div className="edit-que-input-footer">
                                                                                <Button type="primary" className="edit-button" onClick={() => saveQuestion(question, selectedSections[keyName], index)}>Save Question</Button>
                                                                                <Button className="cancel-input" onClick={() => onEditCancel('que', question?.q_id, keyName, index)}>Cancel</Button>
                                                                            </div>
                                                                        </Col>}
                                                                        {!editItemData['que' + question?.q_id] && <Col span={23} className="question-padding">
                                                                            <Row>
                                                                                <Col span={14}>
                                                                                    {question?.bias_name && question?.bias_name !== "null" && <div className="m-b-5"><span className="bias-label">{question.bias_name}</span></div>}
                                                                                    {(question?.bias_name === "null" || !question?.bias_name) && <div className="m-b-5"><span className="general-question">General Question</span></div>}
                                                                                </Col>
                                                                                <Col span={10} className="align-right">
                                                                                    <Tooltip placement="topLeft" overlayClassName={`${index === 0 ? "sub-question-disabled-title" : ""} tooltip-bg`} title = {index === 0 ? intendTitle : 'Indent a question under a question'}>
                                                                                        <SVGIcon style={{ 'paddingRight': '7px' }} className={`${index === 0 || Object?.keys(editItemData)?.length ? "disabled-fontIcon" : "fontIcon"}`} icon={index === 0 || Object?.keys(editItemData)?.length ? 'align-right-transparent' : "align-right-fill"} onClick={() => index !== 0 && Object?.keys(editItemData)?.length === 0 && subQuestionFun(question, selectedSections[keyName].question_list[index - 1]?.q_id)} />
                                                                                    </Tooltip>
                                                                                    <Tooltip placement="topRight" title={'Comment'} overlayClassName={'tooltip-bg'}>
                                                                                        <SVGIcon className="fontIcon" icon={'chat-fill'} onClick={() => showCommentDrawer(question?.q_id, "question")} />
                                                                                    </Tooltip>
                                                                                    <Tooltip placement="topRight" title={'Edit'} overlayClassName={'tooltip-bg'}>
                                                                                        <SVGIcon className="fontIcon" icon={'edit-fill'} onClick={() => onEditItem(question?.q_id, 'que')} />
                                                                                    </Tooltip>
                                                                                    <Tooltip placement="topRight" title={'Delete'} overlayClassName={'tooltip-bg'}>
                                                                                        <SVGIcon icon={'delete-fill'} className="fontIcon" onClick={() => showDeleteConfirmation('question', question?.q_id)} />
                                                                                    </Tooltip>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24} className="p-r-12 mt-1">
                                                                                    <Tooltip placement="top" title={question.question.length > 160 ? htmlData(newLineHtmlContent(question.question)) : ''} overlayClassName={'tooltip-bg'}>
                                                                                        <label className={question.question.length > 160 ? "quesEllipse" : ""} > {htmlData(newLineHtmlContent(question.question))}</label>
                                                                                    </Tooltip>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>}
                                                                    </Row>
                                                                </div>
                                                                {question && question?.sub_questions && question.sub_questions.map((subQuestion, subIndex) => <React.Fragment key={subQuestion.q_id}>
                                                                    <Droppable className="w-100" onDrop={() => onItemDrop(selectedSections[keyName], question, subQuestion)}>
                                                                        <div className="sub-section">
                                                                            <Row className="align-vertical-center" id={'question_' + subQuestion?.q_id}>
                                                                                <Col span={2}>
                                                                                    <Tooltip placement="topRight" color="black" title={"Change the order of your questions by moving them up and down within your Discussion Guide Builder"}>
                                                                                        <SVGIcon className={Object?.keys(editItemData)?.length ? 'disabled-moveIcon' : "moveIcon"} icon={'drag'} draggable={Object?.keys(editItemData)?.length ? false : true} onDrag={() => onItemDrag(selectedSections[keyName], question, 'subQuestionReorder', subQuestion)}
                                                                                            onDragStart={onDragStart}
                                                                                            onDragEnd={() => onDragStop()}
                                                                                        />
                                                                                    </Tooltip>
                                                                                </Col>
                                                                                {editItemData['que' + subQuestion?.q_id] && <Col span={22} className="child mt-2">
                                                                                    <Row>
                                                                                        <Col span={21}>
                                                                                            <span className="section-headings">Edit Question</span>
                                                                                        </Col>
                                                                                        <Col span={3} className="align-right">
                                                                                            <Tooltip placement="topRight" title={'Comment'} overlayClassName={'tooltip-bg'}>
                                                                                                <SVGIcon className="fontIcon" icon={'chat-fill'} onClick={() => showCommentDrawer(subQuestion?.q_id, "question")} />
                                                                                            </Tooltip>
                                                                                            <Tooltip placement="topRight" title={'Delete'} overlayClassName={'tooltip-bg'}>
                                                                                                <SVGIcon icon={'delete-fill'} className="fontIcon" onClick={() => showDeleteConfirmation('question', subQuestion?.q_id)} />
                                                                                            </Tooltip>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col span={24} className="p-r-12">
                                                                                            {!editBiasData[subQuestion?.q_id] && subQuestion?.bias_name && subQuestion?.bias_name !== "null" && <div className="m-b-5"><span className="bias-label">{subQuestion.bias_name}<SVGIcon icon={'cross-icon'} className="p-1 cursor-pointer" onClick={() => { onBiasEdit(subQuestion?.q_id, selectedSections[keyName], index, subIndex) }} /></span></div>}
                                                                                            {(editBiasData[subQuestion?.q_id] || !subQuestion?.bias_name || subQuestion?.bias_name === "null") && <>
                                                                                                <div className="d-flex">
                                                                                                    <div className="biasTag d-flex">
                                                                                                        <SVGIcon icon={"tag-black"} className={'tagIcon'} />
                                                                                                        <p className="ms-1">Add Associated Bias Tag</p>
                                                                                                    </div>
                                                                                                    <Space wrap className="bias-dropdown">
                                                                                                        <Dropdown overlay={menu(subQuestion?.q_id, selectedSections[keyName], index, subIndex)}>
                                                                                                            <Button className="border-0 text-muted p-1">
                                                                                                                <Space style={{ 'fontSize': '12px' }}>
                                                                                                                    {subQuestion?.bias_name && subQuestion?.bias_name !== "null" && subQuestion?.bias_name !== null ? subQuestion?.bias_name : "(Optional)"}
                                                                                                                    <FontAwesomeIcon icon={faChevronDown} className="downIcon" />
                                                                                                                </Space>
                                                                                                            </Button>
                                                                                                        </Dropdown>
                                                                                                    </Space>
                                                                                                </div></>}
                                                                                            <TextArea rows={3} className={!editBiasData[subQuestion?.q_id] ? 'mt-2' : ''} value={subQuestion?.question} onChange={(e) => { updateEditData(e.target.value.replace(/^\s+/g, ''), selectedSections[keyName], index, subIndex) }} />
                                                                                            {editSaveClicked[subQuestion?.q_id] && !subQuestion.question && <h6 className="error-msg">Please Add a Question</h6>}
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <div className="edit-input-footer">
                                                                                        <Button type="primary" className="edit-button" onClick={() => saveQuestion(subQuestion)}>Save Edits</Button>
                                                                                        <Button className="cancel-input" onClick={() => onEditCancel('que', subQuestion?.q_id, keyName, index, subIndex)}>Cancel</Button>
                                                                                    </div>
                                                                                </Col>}
                                                                                {!editItemData['que' + subQuestion?.q_id] && <Col span={22} className="child mt-2">
                                                                                    <Row>
                                                                                        <Col span={14}>
                                                                                            {subQuestion?.bias_name && subQuestion?.bias_name !== "null" && <div className="m-b-5"><span className="bias-label">{subQuestion.bias_name}</span></div>}
                                                                                            {(subQuestion?.bias_name === "null" || !subQuestion?.bias_name) && <div className="m-b-5"><span className="general-question">General Question</span></div>}
                                                                                        </Col>
                                                                                        <Col span={10} className="align-right">
                                                                                            <Tooltip placement="top" overlayClassName={'tooltip-bg'} title = {'Unindent question'}>
                                                                                                <SVGIcon className={`${Object?.keys(editItemData)?.length ? "disabled-fontIcon" : "fontIcon"}`} icon={Object?.keys(editItemData)?.length ? 'align-left-transparent' : "align-left-fill"} onClick={() => Object?.keys(editItemData)?.length === 0 && subQuestionFun(subQuestion, subQuestion?.parent_id)} />
                                                                                            </Tooltip>
                                                                                            <Tooltip placement="topRight" title={'Comment'} overlayClassName={'tooltip-bg'}>
                                                                                                <SVGIcon className="fontIcon" icon={'chat-fill'} onClick={() => showCommentDrawer(subQuestion?.q_id, "question")} />
                                                                                            </Tooltip>
                                                                                            <Tooltip placement="topRight" title={'Edit'} overlayClassName={'tooltip-bg'}>
                                                                                                <SVGIcon className="fontIcon" icon={'edit-fill'} onClick={() => onEditItem(subQuestion?.q_id, 'que')} />
                                                                                            </Tooltip>
                                                                                            <Tooltip placement="topRight" title={'Delete'} overlayClassName={'tooltip-bg'}>
                                                                                                <SVGIcon icon={'delete-fill'} className="fontIcon" onClick={() => showDeleteConfirmation('question', subQuestion?.q_id)} />
                                                                                            </Tooltip>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col span={24} className="p-r-12 mt-1">
                                                                                            <Tooltip placement="top" title={subQuestion.question.length > 150 ? htmlData(newLineHtmlContent(subQuestion.question)) : ''} overlayClassName={'tooltip-bg'}>
                                                                                                <label className={subQuestion.question.length > 150 ? "quesEllipse" : ""} > {htmlData(newLineHtmlContent(subQuestion?.question))}</label>
                                                                                            </Tooltip>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>}
                                                                            </Row>
                                                                        </div >
                                                                    </Droppable >
                                                                </React.Fragment >
                                                                )
                                                                }
                                                            </React.Fragment >
                                                        </Droppable >
                                                    </React.Fragment >
                                                    )
                                                    }
                                                    {selectedSections[keyName] && selectedSections[keyName]?.question_list && selectedSections[keyName]?.question_list?.length === 0 &&
                                                        <div className="section w-100">
                                                            <Row className="no-ques-padding align-vertical-center">
                                                                <Col span={2}>
                                                                </Col>
                                                                <Col span={22}>
                                                                    <label className="no-data">No questions are added for this section. Please click on <span className="p-1"><FontAwesomeIcon icon={faCirclePlus} /> Add Question</span> to start adding questions.</label>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }
                                                </React.Fragment >}
                                            </Row>
                                        </Droppable>
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </Col >
                    <Col span={10} className="right-panel">
                        <h6 className="section-headings">Methods Of Detection(MOD)</h6>
                        <span onClick={() => sortData()}>
                            <label className="font-weight-5 bias-text-label">Bias
                            </label>
                            <span className="bias-sort-padding">
                                {biasSort === 'default' && <SVGIcon icon={"sort-default-new"} />}
                                {biasSort === 'asc' && <SVGIcon icon={"sort-up-new"} />}
                                {biasSort === 'des' && <SVGIcon icon={"sort-down-new"} />}
                            </span>
                        </span>
                        <div className="div-height bias-list">
                            {modData && modData?.length !== 0 && modData?.map((bias) => (
                                <React.Fragment key={bias.bias_id}>
                                    <Row className="main p-8 align-vertical-center">
                                        <Col span={1}>
                                            <FontAwesomeIcon className="p-r-10" icon={expandedData['bias'+ bias?.bias_id] ? faChevronDown : faChevronRight} onClick={() => toggleData('bias'+ bias?.bias_id)}></FontAwesomeIcon>
                                        </Col>
                                        <Col span={23} className="mod-data-labels d-flex">
                                            <Tooltip placement="top" title={bias.bias_name.length > 30 ? htmlData(newLineHtmlContent(bias.bias_name)) : ''} overlayClassName={'tooltip-bg'}>
                                                <label className={`${bias.bias_name.length > 30 ? 'label-ellipse' : ''}`}>{htmlData(newLineHtmlContent(bias.bias_name))}</label>
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                    {expandedData['bias'+ bias?.bias_id] && <React.Fragment>
                                        {bias?.mod_list && bias?.mod_list?.length !== 0 && bias?.mod_list.map((mod, index) => <div key={mod.mod_id} className="mod-section">
                                            <Row className="align-vertical-center bias-name-data">
                                                <Col span={1}>
                                                    <FontAwesomeIcon className="p-r-10" icon={expandedData['mod' + mod?.mod_id] ? faChevronDown : faChevronRight} onClick={() => toggleData('mod' + mod?.mod_id)}></FontAwesomeIcon>
                                                </Col>
                                                <Col span={23} className="mod-data-labels font-weight-4">
                                                    {'MOD ' + (index + 1)}
                                                </Col>
                                            </Row>
                                            {expandedData['mod' + mod?.mod_id] && <div className="mod-data">
                                                {mod?.mod_content && <QuillRichTextEditor mode={"view"} editorToolBarSetting={editorToolBarSetting} editorContent={mod?.mod_content} />}
                                                <div className="ex-que" style={index === bias?.mod_list?.length - 1 ? { paddingBottom: '10px' } : {}}>
                                                    <span className="eg-ques" onClick={() => toggleData('eg-q' + mod?.mod_id)}><a>Example Questions</a>
                                                    <FontAwesomeIcon className="pl-5 cursor-pointer" icon={expandedData['eg-q' + mod?.mod_id] ? faChevronDown : faChevronRight} /></span>
                                                </div>
                                                {expandedData['eg-q' + mod?.mod_id] && mod?.ex_ques && mod?.ex_ques?.length !== 0 && mod?.ex_ques.map((que, i) => <React.Fragment key={que.que_id}>
                                                    {que?.que_content?.trim()?.length !== 0 && <Row>
                                                        <Col span={1}>
                                                            <Avatar size={'small'}>{i + 1}</Avatar>
                                                        </Col>
                                                        <Col span={22}>
                                                            <QuillRichTextEditor mode={"view"} editorToolBarSetting={editorToolBarSetting} editorContent={que?.que_content} />
                                                        </Col>
                                                    </Row>}
                                                </React.Fragment>
                                                )}
                                                {expandedData['eg-q' + mod?.mod_id] && mod?.ex_ques && mod?.ex_ques?.length === 0 &&
                                                    <Row>
                                                        <Col span={24}>
                                                            <div className="no-data">No example questions found</div>
                                                        </Col>
                                                    </Row>
                                                }
                                            </div>}
                                        </div>
                                        )}
                                        {bias?.mod_list && bias?.mod_list?.length === 0 &&
                                        <div className="mod-section">
                                            <Row className="align-vertical-center bias-name-data">
                                                <Col span={24}>
                                                    <div className="no-data">No MODS found</div>
                                                </Col>
                                            </Row>
                                        </div>
                                        }
                                    </React.Fragment>}
                                </React.Fragment>
                            ))}
                            {modData && modData?.length === 0 && modsFetchCalled &&
                                <div className="p-8 mod-data-labels"><label className="no-data">{noModDataFound}</label></div>}
                        </div>
                    </Col>
                </Row >
            </div >
            <footer className="d-grid p-2 ms-2 d-md-flex pe-3 justify-content-md-end">
                <Button className="me-3" onClick={() => navigateSteps('back')}>
                    Back
                </Button>
                <Button
                    type="primary"
                    className="me-1"
                    onClick={() => navigateSteps('next')}
                >
                    Next
                </Button>
            </footer>

            {/* Comments Main */}
            <Drawer
                title="Discussion Design Comments"
                placement={"right"}
                width={"35%"}
                closable={"right"}
                onClose={() => setCommentDrawer(false)}
                open={commentDrawer}
                footer={
                    <>
                        <Button
                            className="me-1"
                            onClick={() => {
                                setCommentDrawer(false);
                            }}
                        >
                            Close
                        </Button>
                    </>
                }
            >
                <div>
                    <h6 className="mx-3 mt-2">
                        Comments <SVGIcon icon={"chat-black"} />
                        <div className="spinner-padding">
                            <Spin spinning={commentLoader} />
                        </div>
                    </h6>
                    {commentDrawer && <DiscussionGuideComments ref={commentsDiscussionGuideRef} fetchedData={(event) => setCommentLoader(event)} />}
                </div>
            </Drawer>

            <Modal
                centered
                closable={false}
                open={showDeleteConfirm}
                footer={null}
                width={400}
                zIndex={2010}
                onCancel={() => setShowDeleteConfirm(false)}
            >
                <h6  className="confirmation-header-text">Delete {deleteInfo?.type === 'question' ? 'Question' : 'Section'}</h6>
                <p className="text-muted pt-1">
                    Are you sure you would like to delete this {deleteInfo?.type === 'question' ? 'Question' : 'Section'}?
                </p>
                <Button type="primary" onClick={deletedItem} className="me-2 mt-3" >
                    Delete
                </Button>
                <Button onClick={() => { setShowDeleteConfirm(false) }} className='mt-3'>
                    {" "} Cancel{" "}
                </Button>
            </Modal>
        </React.Fragment >
    )
}