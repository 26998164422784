import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react'
import SVGIcon from "../../../Shared/Components/SVGIcon/SVGIcon";
import { Radio, Form, Select, Button, Row, Col, Modal, Collapse, Input, Image } from "antd";
import { deepClone, generateUniqueId } from "../../../Shared/Utils/CommonUtil";
import "./BiasInAction.scss";
import { ServiceUtils } from "../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../Shared/Utils/ToasterUtil";
import defaultImage from "../../../Assets/images/default.jpg";

const EditBiasInAction = forwardRef((props, ref) => {
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [showEditErrorMsg, setShowEditErrorMsg] = useState(false);
    const { TextArea } = Input;
    const [biasDD, setBiasDD] = useState([]);
    const { Panel } = Collapse;
    const [isEditBiasSaved, setIsEditBiasSaved] = useState(false);
    const [biasList, setBiasList] = useState(['']);
    const [changesConfirmModal, setChangesConfirmModal] = useState(false);
    const [addBiasForm, setAddBiasForm] = useState(
        {
            pharmaNonpharma: 'pharma',
            audience: 'physicians',
            formData: [],
            anotherFormData: [],
        }
    )

    useEffect(() => {
        biasDD.map((item) => {
            const fIndex = addBiasForm.anotherFormData.findIndex((el) => el.selected_bias === item.value);
            return item.disabled = (fIndex > -1 ? true : false);
        })
        biasDD.map((item) => {
            const fIndex = addBiasForm.formData.map((el) => {
                if (el.selected_bias === item.value)
                    return item.disabled = true;
            })
        })
        setBiasDD([...biasDD])
    }, [addBiasForm])

    /**
     * calling function from parent i.e On click of edit icon in BiasInAction.js file
     */
    useImperativeHandle(ref, () => ({
        showEditModal(imgData) {
            setShowEditErrorMsg(false);
            setBiasList([...props.biasList])
            setBiasDD([...props.biasList]);
            fetchBiaInActionMetaData(imgData, [...props.biasList])
        }
    }));
    
    /**
     * To fetch bias in action meta data
     * @param {*} imgData 
     * @param {*} biasList 
     */
    const fetchBiaInActionMetaData = (imgData, biasList) => {
        try {
            ServiceUtils.postRequest("biasInActionMetaDataFetch", { "image_id": imgData.img_id }).then((response) => {
                if (response.status === 200) {
                    if (response.data.status === "success") {
                        const selectedBiasData = response.data.data.bias_in_action_data.formData;
                        selectedBiasData.map((el) => {
                            biasList.map((item) => {
                                if (el.selected_bias === item.value) {
                                    item.disabled = true;
                                };
                                return item;
                            })
                            return el;
                        })
                        setBiasDD([...biasList]);
                        setAddBiasForm(response.data.data.bias_in_action_data);
                        setIsEditModalVisible(true); // open Edit modal
                    } else {
                        alertService.error(
                            response.data.message || `Error while adding new bias in action`
                        );
                    }
                }
            }); 
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * T fetch bias table data
     * @param {*} bias_id - bias id
     */
    const reloadBiasInActionData = (biasIds) => {
        if (biasIds) {
            if (typeof props.refreshTableData === 'function') props.refreshTableData({ biasIds });
        }
    }

    /**
     * On pharma and non-pharma changes
     * @param {*} payload - data
     * @param {*} type - type
     */
    const onChangepharmaNonPharma = (payload, type) => {
        try {
            setIsEditBiasSaved(true);
            if (type === 'pharmaNonpharma') {
                setAddBiasForm({
                    ...addBiasForm,
                    pharmaNonpharma: payload === 'pharma' ? true : false,
                    audience: payload === 'pharma' ? 'physicians' : 'consumer'
                })
            } else if (type === 'audience') {
                setAddBiasForm({
                    ...addBiasForm,
                    audience: payload
                })
            }  
        } catch (error) {
            console.log("error", error);  
        }
    }
    
    /**
     * To update bias in action
     */
    const updateBiasInAction = () => {
        try {
            let newData = deepClone(addBiasForm);
            delete newData.bia_image_path;
            newData.anotherFormData.map((el) => {
                if (el.id) {
                    delete el.id;
                }
                return el;
            })
            // call api
            ServiceUtils.postRequest("updateBiasInAction", { "bias_in_action_data": newData }).then((response) => {
                if (response.status === 200) {
                    if (response.data.status === "success") {
                        setIsEditModalVisible(false);
                        setIsEditBiasSaved(false);
                        alertService.success(response.data.message || 'Successfully added the bias in action');
                        if (addBiasForm?.formData?.length) {
                            const biasIds = addBiasForm?.formData.map((bias) => bias.selected_bias);
                            reloadBiasInActionData(biasIds);
                        }
                        if (addBiasForm?.anotherFormData?.length) {
                            const biasIds = addBiasForm?.anotherFormData.map((bias) => bias.selected_bias);
                            reloadBiasInActionData(biasIds);
                        }
                    } else {
                        alertService.error(
                            response.data.message || `Error while adding new bias in action`
                        );
                    }
                }
            });  
        } catch (error) {
            console.log("error", error);  
        }
    }

    const confirmSaved = () => {
        handleCancel(false);
        setChangesConfirmModal(false);
    }

    const handleCancel = (isCancel) => {
        try {
            if (isCancel && isEditBiasSaved) {
                setChangesConfirmModal(true);
            } else {
                setIsEditModalVisible(false);
                setShowEditErrorMsg(false);
                setIsEditBiasSaved(false);
            } 
        } catch (error) {
            console.log("error", error); 
        }
    };

    const onAddBiasModalClose = (s) => {
        try {
            setShowEditErrorMsg(false);
            setAddBiasForm({
                pharmaNonpharma: '',
                audience: '',
                formData: [],
                anotherFormData: []
            });
            setBiasDD([...biasList]); 
        } catch (error) {
            console.log("error", error); 
        }
    }

    const onEditBiasInActionFinish = () => {
        updateBiasInAction();
    }

    const onEditBiasInActionFinishFailed = () => {
        setShowEditErrorMsg(true);
    };

    const selectedBiasFieldValueChanges = (index, key, value) => {
        try {
            setIsEditBiasSaved(true);
            const form = addBiasForm.formData;
            form[index] = {
                ...form[index],
                [key]: value
            }
            setAddBiasForm({ ...addBiasForm, formData: form }); 
        } catch (error) {
            console.log("error", error); 
        }
    }

    const onFieldValueChange = (index, key, value) => {
        try {
            setIsEditBiasSaved(true);
            const form = addBiasForm.anotherFormData;
            form[index] = {
                ...form[index],
                [key]: value
            }
            setAddBiasForm({ ...addBiasForm, anotherFormData: form }); 
        } catch (error) {
            console.log("error", error); 
        }
    }

    /**
    * on click of add another bise in action dynamic form
    */
    const addAnotherBiasesInActionForm = () => {
        try {
            const form = addBiasForm.anotherFormData;
            form.push({
                selected_bias: "",
                example_type: "activating",
                demonstrate_bias: '',
                key_message: '',
                additional_info: '',
                id: generateUniqueId()
            })
            setAddBiasForm({ ...addBiasForm, anotherFormData: form });
        } catch (error) {
            console.log("error", error); 
        }
    }

    /**
     * deleting of perticular form collapse in add biase in action
     */
    const deleteSelectedBiasForm = (index) => {
        try {
            const form = addBiasForm.anotherFormData
            form.splice(index, 1);
            setAddBiasForm({ ...addBiasForm, anotherFormData: [...form] });
        } catch (error) {
            console.log("error", error);  
        }
    };

    return (
        <>
            <Modal
                className="modal-add-new-bias"
                centered
                title="Edit Bias-In-Action Example"
                open={isEditModalVisible}
                onCancel={() => { handleCancel(true) }}
                width={650}
                bodyStyle={{ height: 390, paddingTop: '1px' }}
                afterClose={onAddBiasModalClose}
                maskClosable={false}
                footer={
                    <>
                        <Button
                            type="primary"
                            htmlType="submit"
                            form="edit-bias-in-action-form"
                            className="me-2"
                        // disabled={biasImageFile ? false : true}
                        >
                            Save
                        </Button>
                        <Button onClick={() => handleCancel(true)}> Cancel </Button>
                        {showEditErrorMsg && <span className="text-danger ms-2">Please fill all mandatory fields</span>}
                    </>
                }
            >

                <>
                    <Row className="mb-3 mt-3" span={24}>
                        <Col>
                            <Image
                                id="uploadPreview"
                                src={addBiasForm?.bia_image_path}
                                alt="image"
                                className="img-size"
                                preview={false}
                                placeholder={
                                    <Image
                                        preview={false}
                                        src={defaultImage}
                                        className="img-size"
                                    />
                                }
                            />
                        </Col>
                        <Col span={8}>
                            <p className="pt-2">Pharma/Non-Pharma</p>
                            <Radio.Group
                                name="radiogroup"
                                onChange={(e) => onChangepharmaNonPharma(e.target.value, 'pharmaNonpharma')}
                                value={addBiasForm.pharmaNonpharma ? 'pharma' : 'non_pharma'}
                            >
                                <Radio value={'pharma'}>Pharma</Radio>
                                <Radio value={'non_pharma'}>Non-Pharma</Radio>
                            </Radio.Group>
                        </Col>
                        <Col span={8}>
                            <p className="pt-2">Audience</p>
                            {addBiasForm && addBiasForm.pharmaNonpharma === true &&
                                <Radio.Group
                                    name="radiogroup"
                                    onChange={(e) => onChangepharmaNonPharma(e.target.value, 'audience')}
                                    value={addBiasForm.audience}
                                >
                                    <Radio value={'physicians'}>Physicians</Radio>
                                    <Radio value={'patients'}>Patients</Radio>
                                </Radio.Group>
                            }
                            {addBiasForm && addBiasForm.pharmaNonpharma === false &&
                                <p className="">Consumer</p>
                            }
                        </Col>
                    </Row>
                    <Form
                        id="edit-bias-in-action-form"
                        layout="vertical"
                        requiredMark={'optional'}
                        onFinish={onEditBiasInActionFinish}
                        onFinishFailed={onEditBiasInActionFinishFailed}
                        onValuesChange={() => setShowEditErrorMsg(false)}
                    >
                        <div className="onclick-section-div">
                            <>
                                <div className="add-new-bias">
                                    {addBiasForm &&
                                        <>
                                            <Collapse key={'bias-association-colapse'} className="border-0" >
                                                {addBiasForm.formData.map((item, index) => (
                                                    <Panel
                                                        forceRender={true}
                                                        className="ps-1 mb-2"
                                                        header={item.selected_bias_name}
                                                        style={{ border: "1px solid #d9d9d9" }}
                                                        key={"key-" + item.bias_in_actions_id}
                                                    >
                                                        <div>
                                                            <div className="">
                                                                <Form.Item className="add-bias-form" required initialValue={item.example_type}>
                                                                    Example Type :
                                                                    <Radio.Group
                                                                        name={"example-type-" + item.bias_in_actions_id}
                                                                        className="ms-2"
                                                                        key={'example-type-' + item.bias_in_actions_id}
                                                                        value={item.example_type}
                                                                        rules={[{ "required": true, "message": "Please select the example type" }]}
                                                                        onChange={(e) => selectedBiasFieldValueChanges(index, 'example_type', e.target.value)}
                                                                    >
                                                                        <Radio value="activating">
                                                                            Activating
                                                                        </Radio>
                                                                        <Radio value="mitigating">
                                                                            Mitigating
                                                                        </Radio>
                                                                    </Radio.Group>
                                                                </Form.Item>
                                                            </div>
                                                            <div className="">
                                                                <Form.Item
                                                                    label="How Image Demonstrates Bias"
                                                                    key={'demonstrate-text-' + item.bias_in_actions_id}
                                                                    rules={[{ "required": true, "message": "Please enter the description" }]}
                                                                    required
                                                                    name={'demonstrate-bias-' + item.bias_in_actions_id}
                                                                    value={item.demonstrate_bias}
                                                                    initialValue={item.demonstrate_bias}
                                                                >
                                                                    <TextArea placeholder="Add Description" defaultValue={item.demonstrate_bias} autosize={true}
                                                                        onChange={(e) => selectedBiasFieldValueChanges(index, 'demonstrate_bias', e.target.value)}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    label="Bias-in-Action Key Message"
                                                                    key={'key-msg-text-' + item.bias_in_actions_id}
                                                                    value={item.key_message}
                                                                    name={'key-message-' + item.bias_in_actions_id}
                                                                    initialValue={item.key_message}
                                                                >
                                                                    <TextArea placeholder="Add Description" defaultValue={item.key_message} autosize={true}
                                                                        onChange={(e) => selectedBiasFieldValueChanges(index, 'key_message', e.target.value)} />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    label="Bias-in-Action Additional Info"
                                                                    key={'additional-info-text-' + item.bias_in_actions_id}
                                                                    name={'additional-info-' + item.bias_in_actions_id}
                                                                    value={item.additional_info}
                                                                    initialValue={item.additional_info}
                                                                >
                                                                    <TextArea placeholder="Add Description" defaultValue={item.additional_info} autosize={true}
                                                                        onChange={(e) => selectedBiasFieldValueChanges(index, 'additional_info', e.target.value)} />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </Panel>
                                                ))}
                                            </Collapse>
                                        </>
                                    }
                                </div>
                            </>
                        </div>
                        <div className="onclick-section-div">
                            {addBiasForm.anotherFormData.length > 0 &&
                                <>
                                    <div className="text-modal pt-2">
                                        <p>
                                            Choose a bias to associate it to its specific
                                            Bias-In-Action.You can add more than one Bias associated by
                                            adding a new associated Bias section.
                                        </p>
                                    </div>
                                    <div className="add-new-bias">
                                        {addBiasForm &&
                                            <>
                                                {addBiasForm.anotherFormData.map((item, index) => (
                                                    <Collapse key={'bias-association-colapse'} className="border-0" defaultActiveKey={['panel-' + index]}>
                                                        <Panel
                                                            forceRender={true}
                                                            className="ps-1 mb-2"
                                                            header="Select Bias : "
                                                            style={
                                                                {
                                                                    border: "1px solid #d9d9d9"
                                                                }
                                                            }
                                                            key={'panel-' + index}
                                                            extra={(<div className="d-flex" onClick={(event) => event.stopPropagation()}>
                                                                <Form.Item className="mb-0" required name={'bias-' + item.id} key={'bis-key-' + item.id}
                                                                    value={item.selected_bias}
                                                                    rules={[{ "required": true, "message": "Please select the bias" }]}>
                                                                    <Select
                                                                        className="formItemSelect me-2 ms-1"
                                                                        onChange={(e) => onFieldValueChange(index, 'selected_bias', e)}
                                                                        placeholder="Please select"
                                                                        options={biasDD}
                                                                        showSearch={true}
                                                                        filterOption={(input, option) =>
                                                                            option.label.toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                        autoClearSearchValue={false}
                                                                    >
                                                                    </Select>
                                                                </Form.Item>
                                                                <SVGIcon
                                                                    icon={addBiasForm.anotherFormData.length === 0 ? 'delete-disabled' : "delete-fill"}
                                                                    className={`pt-1 me-4 ${addBiasForm.anotherFormData.length === 1 ? 'desableCursor' : 'cursor-pointer'} `}
                                                                    onClick={() => deleteSelectedBiasForm(index)} />
                                                            </div>)}
                                                        >
                                                            <div>
                                                                <div className="">
                                                                    <Form.Item className="add-bias-form" required>
                                                                        Example Type :
                                                                        <Radio.Group
                                                                            name={"example-type-" + item.id}
                                                                            className="ms-2"
                                                                            key={'example-type-' + item.id}
                                                                            value={item.example_type}
                                                                            rules={[{ "required": true, "message": "Please select the example type" }]}
                                                                            onChange={(e) => onFieldValueChange(index, 'example_type', e.target.value)}
                                                                        >
                                                                            <Radio value="activating">
                                                                                Activating
                                                                            </Radio>
                                                                            <Radio value="mitigating">
                                                                                Mitigating
                                                                            </Radio>
                                                                        </Radio.Group>
                                                                    </Form.Item>
                                                                </div>
                                                                <div className="">
                                                                    <Form.Item
                                                                        label="How Image Demonstrates Bias"
                                                                        key={'demonstrate-text-' + item.id}
                                                                        rules={[{ "required": true, "message": "Please enter the description" }]}
                                                                        required
                                                                        name={'demonstrate-bias-' + item.id}
                                                                        value={item.demonstrate_bias}
                                                                    >
                                                                        <TextArea placeholder="Add Description"
                                                                            onChange={(e) => onFieldValueChange(index, 'demonstrate_bias', e.target.value)} />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        label="Bias-in-Action Key Message"
                                                                        key={'key-msg-text-' + item.id}
                                                                        value={item.key_message}
                                                                        name={'key-message-' + item.id}
                                                                    >
                                                                        <TextArea placeholder="Add Description"
                                                                            onChange={(e) => onFieldValueChange(index, 'key_message', e.target.value)} />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        label="Bias-in-Action Additional Info"
                                                                        key={'additional-info-text-' + item.id}
                                                                        name={'additional-info-' + item.id}
                                                                        value={item.additional_info}
                                                                    >
                                                                        <TextArea placeholder="Add Description"
                                                                            onChange={(e) => onFieldValueChange(index, 'additional_info', e.target.value)} />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </Panel>
                                                    </Collapse>
                                                ))}
                                            </>
                                        }
                                    </div>
                                </>}
                            <div className="pt-3 add-another-bias-section cursor-pointer ">
                                <span onClick={addAnotherBiasesInActionForm}>
                                    <SVGIcon icon={"add-circle-fill"} />
                                    <span className="add-another-bias ms-2">
                                        Add another Bias associated to this Bias-in-Action
                                    </span>
                                </span>
                            </div>
                        </div>
                    </Form>
                </>


            </Modal>

            <Modal
                centered
                closable={false}
                open={changesConfirmModal}
                footer={null}
                width={400}
                zIndex={2010}
                onCancel={() => setChangesConfirmModal(false)}
            >
                <h6  className="confirmation-header-text">Confirm</h6>
                <p className="text-muted pt-1">
                    There are unsaved changes in the screen. Are you sure you want to continue?
                </p>
                <Button type="primary" onClick={confirmSaved} className="me-2 mt-3" >
                    Yes
                </Button>
                <Button onClick={() => { setChangesConfirmModal(false) }} className='mt-3'>
                    {" "} No{" "}
                </Button>
            </Modal>

        </>
    );
});
export default EditBiasInAction;
