import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Drawer, Modal, Button } from "antd";
import SVGIcon from "../../../Shared/Components/SVGIcon/SVGIcon";
import CustomComment from "../../../Shared/Components/CustomComment/CustomComment";
import { ServiceUtils } from "../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../Shared/Utils/ToasterUtil";
import {VARIABLES} from "../../../Environments/constants";

const CommentBiasInAction = forwardRef((props, ref) => {
  const [biasInActionCommentsDrawer, setBiasInActionCommentsDrawer] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [userDetails] = useState(JSON.parse(localStorage.getItem(VARIABLES.userSession)));
  const [imgInfo, setImgInfo ] = useState({});
  const [commentsData, setCommentsData] = useState({
    id: "",
    comments: [],
    resolved_comments: [],

  });
  const resetCommentData = () => {
    setCommentsData({
      id: "",
      comments: [],
      resolved_comments: [],

    });
  };

  useImperativeHandle(ref, () => ({
    showModal(data) {
        setImgInfo({...data});
        getComments(data, true);
    },
  }));


  const onReplyToToggle = (action, comment) => {
    if (!action || !comment || !comment.id) return;
    if (comment.level >= 2) return; // levels are 0, 1, 2 so for level 2 there is no child
    const showFlag = action === "show" ? true : false;
    commentToggleAction("addComment", showFlag, comment, "comments");
  };

  const commentToggleAction = (toggleKey, toggleValue, comment, sectionKey) => {
    commentsData[sectionKey].map((c) => {
      if (comment?.level === 0) {
        if (c.id === comment.id) c[toggleKey] = toggleValue;
      } else if (comment?.level === 1) {
        c.reply.map((r) => {
          if (r.id === comment.id) r[toggleKey] = toggleValue;
          return r;
        });
      }
      return c;
    });
    setCommentsData({ ...commentsData });
  };

  const onLoadReplies = (action, comment, section) => {
    if (!action || !comment || !comment.id) return;
    if (comment.level >= 2) return; // levels are 0, 1, 2 so for level 2 there is no child
    const commentSectionKeyMapping = {
      comments: "comments",
      resolved: "resolved_comments",
    };
    if (!commentSectionKeyMapping[section]) return;
    const sectionKey = commentSectionKeyMapping[section];
    const showFlag = action === "show" ? true : false;
    commentToggleAction("showReply", showFlag, comment, sectionKey);
    if (action === "hide") return;
    commentToggleAction("isLoading", true, comment, sectionKey);
    try {
      ServiceUtils.postRequest("replyBiasInActionComments", { comment_id: comment.id, level: comment.level + 1 }, false ).then((response) => {
        try {
          if (response.status === 200) {
            if (response.data.status === "success") {
              const result = response?.data?.data?.replies;
              if (commentsData[sectionKey]) {
                pushReplyComments(comment, commentsData[sectionKey], result);
                setCommentsData({ ...commentsData });
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
        commentToggleAction("isLoading", false, comment, sectionKey);
        commentToggleAction("isLoadingNewComment", false, comment, sectionKey);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const pushReplyComments = (parentComment, comments, replies) => {
    try {
      if (!comments) return [];
      comments.map((f) => {
        if (parentComment.level === 0) {
          if (f.id === parentComment.id) {
            f.reply = mergeReplies(f.reply, replies);
          }
        } else {
          f.reply.map((c) => {
            if (c.id === parentComment.id) {
              c.reply = mergeReplies(c.reply, replies);
            }
            return c;
          });
        }
        return f;
      });
      return comments;
    } catch (error) {
      console.log(error);
    }
  };

  const mergeReplies = (oldReply, newReply) => {
    if (!oldReply || !newReply) return;
    if (oldReply.length === 0) return [...newReply];
    const oldReplyCommentIds = oldReply ? oldReply.map((i) => i.id) : [];
    const updatedReplyList = newReply.map((r) => {
      const indexVal = oldReplyCommentIds.indexOf(r.id);
      if (indexVal > -1) {
        r = oldReply[indexVal];
      }
      return r;
    });
    return [...updatedReplyList];
  };

  const onAddCommentAction = (parentComment, value, callback) => {
    try {
      let data = {
        id: imgInfo?.bias_id ,
        user_id: userDetails?.user_id,
        comment: value,
        parent_comment_id: 0,
        level: 0,
        owner_id: imgInfo?.bias_in_action_id,
        
      };
      if (parentComment) {
        data.parent_comment_id = parentComment.id;
        data.level = parentComment.level + 1;
      }
      ServiceUtils.postRequest("createBiasInActionComments", data, false).then((response) => {
          try {
            if (response.status === 200) {
              if (response.data.status === "success") {
                const savedComment = response?.data?.data?.comments;
                if (savedComment.level === 0) {
                  getComments(imgInfo);
                }
                if (typeof callback === "function")
                  callback(true, savedComment);
                commentToggleAction("isLoadingNewComment", true, parentComment, "comments");
              } else {
                if (typeof callback === "function") callback(false);
                alertService.error(
                  response.data.message || `Error while adding comment`
                );
              }
            } else {
              if (typeof callback === "function") callback(false);
              alertService.error(
                `Failed to adding comment. Please try after sometime.`
              );
            }
          } catch (error) {
            console.log(error);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  /**
   *
   * @param {*} id
   */

  const getComments = (id, initialLoad = false) => {
    if (commentsData && imgInfo !== id) {
      resetCommentData();
    }
    const showCommonLoader = initialLoad ? true : false;
    const input = {
      "id": id?.bias_in_action_id,
      "sections": ["Bias-In-Action", "Resolved"]
    }
    ServiceUtils.postRequest("fetchBiasInActionComments",  input , showCommonLoader).then((response) => {
      try {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data;
            result.id = id;
            let commentResult = {
              id: id?.bias_in_action_id,
              comments:[...result?.bias_in_action_comments],
              resolved_comments:[...result.resolved_comments]
              
            }
            if (initialLoad) {
              setCommentsData({...commentResult});
              setBiasInActionCommentsDrawer(true);
              setShowComments(true);
            } else {
              updateAllCommentSections({
                ...commentResult,
                // comment:result?.bias_in_action_comments
              });
            }
            
            
          } else {
            alertService.error(
              response.data.message || `Error while fetching comments`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  /**
   * calling function from parent i.e On click of edit icon in BiasInAction.js file
   */
  

  const handleCancel = () => {
    setBiasInActionCommentsDrawer(false);
    setTimeout(() => {
      setCommentsData({});
      setShowComments(false);
    }, 500);
  };

  const updateAllCommentSections = (newComments) => {
    const sectionKeys = ["comments", "resolved_comments"];
    for (const eachKey of sectionKeys) {
      const oldCommentIds = commentsData[eachKey]
        ? commentsData[eachKey].map((i) => i.id)
        : [];
      if (oldCommentIds.length === 0) {
        commentsData[eachKey] = newComments[eachKey];
      } else {
        commentsData[eachKey] = newComments[eachKey].map((c) => {
          const indexVal = oldCommentIds.indexOf(c.id);
          if (indexVal > -1) {
            c = commentsData[eachKey][indexVal];
          }
          return c;
        });
      }
    }
    setCommentsData({ ...commentsData });
  };

  const handleArchiveComment = ({ comment }) => {
    if (!comment || !comment.id) return;
    archiveComment({ commentId: comment.id });
  };
  const handleRestoreComment = ({ comment }) => {
    if (!comment || !comment.id) return;
    restoreComment({ commentId: comment.id });
  };

  const archiveComment = (data) => {
    archiveCommentConfirmation('archive',data?.commentId);
  };

  const restoreComment = (data) => {
    archiveCommentConfirmation('restore',data?.commentId) ;
  };

  /**To archive comment
   */
   const archiveCommentConfirmation = (type, id) => {
    const isResolved =  (type === "archive") ? true : false; 
    ServiceUtils.postRequest("archiveBiasInActionComments", {comment_id: id,is_resolved: isResolved}, true).then((_response) => {
      try {
        if (_response.status === 200) {
          if (_response.data.status === "success") {
            alertService.success(
              _response.data.message || `Successfully archive comment`
            );
            getComments(imgInfo); // display updated comment list
          } else {
            alertService.error(
              _response.data.message || `Error while archiving comment`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  return (
    <>
      <Drawer
        title={"Bias-in-Action - Comments"}
        placement={"right"}
        width={"40%"}
        closable={"right"}
        onClose={handleCancel}
        open={biasInActionCommentsDrawer}
        
      >
        <div className="biasInActionComment-Drawer">
          <div>
            <div className="border-bottom">
            <h6 className="mx-3 mt-2">
              Comments <SVGIcon icon={"chat-black"} />
            </h6>
            </div>
            <div>
            {commentsData?.comments &&

            <CustomComment
              visible={showComments}
              data={commentsData?.comments}
              isArchive={true}
                onArchiveComment={handleArchiveComment}
                onLoadReplyToggle={(action, comment) => onLoadReplies(action, comment, "comments")}
                onToggleReplyTo={(action, comment) => onReplyToToggle(action, comment)}
                onSaveComment={(comment, value, callback) => onAddCommentAction(comment, value, callback)}
            />
            }
            </div>
            <div>
              <h5 className="m-3">Resolved</h5>
              {commentsData?.resolved_comments &&
                <CustomComment
                  data={commentsData?.resolved_comments}
                  isRestore={true}
                  onRestoreComment={handleRestoreComment}
                  isAddNewComment={false}
                  onLoadReplyToggle={(action, comment) => onLoadReplies(action, comment, 'resolved')}
                />
              }
              {commentsData?.resolved_comments?.length === 0 &&
                <p className='text-muted ms-3'><i>No resolved comment found</i></p>
              }
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
});
export default CommentBiasInAction;
