import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import './AddBehaviouralSpark.scss';
import { Form, Row, Col, Select, Radio, Button } from 'antd';
import QuillRichTextEditor from "../../../../../Shared/Components/RichEditor/rich-editor";
import { extractContent } from "../../../../../Shared/Utils/CommonUtil"

const AddBehaviouralSpark = forwardRef(({ id, onSave, addBehaviouralSparkFields, data, ...props }, ref) => {

    const [content, setContent] = useState('');
    const [loadEditor, setLoadEditor] = useState(true);
    const [form] = Form.useForm();
    const [saveClicked, setSaveClicked] = useState(false);
    const initialFormData = {
        "beh_spark_aud_id": null,
        "category_id": null,
        "example_type": "activating"
    }
    const scrollDiv = "#addNewBehavioralSpark .ant-drawer-body";

    useImperativeHandle(ref, () => ({
        resetForm() {
            form.resetFields();
            form.setFieldsValue({ ...initialFormData });
            setSaveClicked(false);
            setLoadEditor(false);
            setTimeout(() => {
                setContent('');
                setLoadEditor(true);
            }, 100);
        },
    }));

    useEffect(() => {
        form.setFieldsValue({ ...initialFormData });
    }, [])

    const editorToolBarSetting = {
        // Toolbar customized according to our needs
        toolbar: {
            container: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'bullet' }],
                [{ 'align': [] }],
                [{ 'color': [] }, { 'background': [] }],
            ],
        }
    };

    const sparkBiasEditor = {
        height: "210px",
        display: 'flex',
        flexDirection: 'column'
    }

    /**
     * On save
     * @param {*} formData - new form data
     */
    const saveData = (formData) => {
        try {
            setSaveClicked(true);
            if (typeof onSave === "function") onSave({ type: "submit", formData: formData });
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * On editor value change
     * @param {*} value - content
     */
    const updateEditorContent = (value) => {
        try {
            setContent(value);
            if (typeof onSave === "function") onSave({ type: "onChange", content: value });
        } catch (error) {
            console.log("error", error);
        }
    }

    const onFormDataChange = (changedValues, allValues) => {
        if (typeof onSave === "function") onSave({ type: "edit" });
    };

    return (
        <>
            <div className="add-new-spark">
                <div className="add-new-form">
                    <h5 style={{paddingTop: '10px'}}>Add A New Behavioral Spark</h5>
                    <Form id={id} onFinish={saveData} onFinishFailed={() => setSaveClicked(true)} form={form} onValuesChange={onFormDataChange}>
                        <Row>
                            {addBehaviouralSparkFields.map((field) => {
                                return (
                                    <Col span={field?.col}>
                                        <label className="field-label">
                                            {field?.label}
                                        </label>
                                        {field?.type && field?.type === 'select' &&
                                            <Form.Item name={field?.key} rules={field?.rules}>
                                                <Select allowClear={true}
                                                    placeholder={field?.placeholder}
                                                    options={field?.options}
                                                    disabled={field?.disabled}
                                                    maxTagCount="responsive"
                                                    notFoundContent="No Data Found"

                                                ></Select>
                                            </Form.Item>
                                        }
                                        {field?.type && field?.type === "radio" &&
                                            <Form.Item>
                                                <Form.Item name={field?.key} rules={field?.rules}>
                                                    <Radio.Group>
                                                        {field?.options.map((option) => (
                                                            <Radio value={option?.value}>{option?.label}</Radio>
                                                        ))}
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Form.Item>}
                                    </Col>)
                            })}
                        </Row>
                        <Button className="submit-btn" type="primary" htmlType="submit"></Button>
                    </Form>
                    <label style={{paddingTop: '10px'}} className="field-label">
                        Behavioral Spark
                    </label>
                </div>
                <div>
                    {loadEditor &&
                        <div className="beh-editor-section">
                            <QuillRichTextEditor
                                mode={'edit'} toolbarMode={'text-only'}
                                editorContent={content} editorStyleData={sparkBiasEditor}
                                editorToolBarSetting={editorToolBarSetting}
                                updateContent={updateEditorContent}
                                scrollingContainer={scrollDiv}
                            />
                        </div>}
                    {saveClicked && (!content || extractContent(content).trim().length === 0) && <h6 className="error-msg">Please add Behavioral Spark</h6>}
                </div>
            </div>
        </>
    )
});

export default AddBehaviouralSpark;