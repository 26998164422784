import React, { useState, useEffect } from "react";
import { ServiceUtils } from "../../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../../Shared/Utils/ToasterUtil";
import "./QuantitativeTestDesign.scss";
import { Table, Spin, Drawer, Button, Row, Col, Modal, Form, Select, TreeSelect, Tooltip } from "antd";
import SVGIcon from "../../../../Shared/Components/SVGIcon/SVGIcon";
import { DebounceInput } from "react-debounce-input";
import { defaultAntSort } from "../../../../Shared/Utils/CommonUtil";
import FormBuilder from "../../../../Shared/Components/Ant-FormBuilder/Ant-FormBuilder";
import QuillRichTextEditor from "../../../../Shared/Components/RichEditor/rich-editor";
import TextArea from "antd/lib/input/TextArea";
import workInProgress from '../../../../Assets/images/work-in-progress.png';

export default function QuantitativeTestDesign() {
  const [showLoader, setShowLoader] = useState(false);
  const [addMode, setAddMode] = useState("");
  const [biasesFilterDrawer, setBiasesFilterDrawer] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [testDesignID, setTestDesignID] = useState({});
  const [quantDrawer, setQuantDrawer] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [tableDataText, setTableDataText] = useState("No data found");
  const [searchText, setSearchText] = useState("");
  const [addTestDesignEditorContent, setAddTestDesignEditorContent] = useState('');
  const [editorContent, setEditorContent] = useState({})
  const [filterPayload, setFilterPayload] = useState({});
  const [isTextEditor, setIsTextEditor] = useState(true)
  const [selectedFilterPayload, setSelectedFilterPayload] = useState({
    biases: [],
    target_audience: [],
    behavioral_objective_category: [],
    therapy_area: [],
  }
  )
  const [testDesignTable, setTestDesignTable] = useState({
    body: [],
    header: [],
    filteredData: [],
  });
  const dataSource = testDesignTable?.filteredData;
  const [addTAModal, setAddTAModal] = useState([]);
  const [addTAmodal, setAddTAmodal] = useState([]);
  const [addBOCModal, setAddBOCModal] = useState([]);
  const [biaslist, setBiasList] = useState([]);
  let noDataFaound = {
    emptyText: tableDataText,
  };
  const [form] = Form.useForm();
  const [form_edit] = Form.useForm();
  const editorToolBarSetting = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link', "image"],
        [{ 'align': [] }], ['divider'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ],
    },
    divider: { icon: "" },
    imageDrop: true,
    blotFormatter: {},
  };

  const panelHeader = () => (
    <React.Fragment>
      <div>
        <p className="p-1 mb-0">
          Test Design{" "}
          <div className="pb-2 mr-1 quant-Action" style={{ float: "right" }}>
            Action
          </div>{" "}
        </p>
      </div>
    </React.Fragment>
  );

  const columns = [
    {
      title: "Bias",
      dataIndex: "bias_name",
      key: "bias_name",
      width: 63,
      defaultSortOrder: "ascend",
      sorter: (a, b) => defaultAntSort(a.bias_name, b.bias_name),
      render: (_) => (
        <>
          <p>{_}</p>
        </>
      ),
    },
    {
      title: panelHeader(),
      dataIndex: "hypothesis_list",
      key: "hypothesis_list",
      width: 190,
      defaultSortOrder: "ascend",
      render: (_, data) => (
        <>
          {data.hypothesis_list.map((items, i) => {
            return (
              <div className="quant-quest">
                <Row
                  className={
                    data.hypothesis_list.length - 1 === i
                      ? "p-1"
                      : "border-bottom p-1"
                  }
                >
                  <Col span={14} className={"mb-1"}>
                    <Tooltip placement="topLeft" title={`${items.hypothesis.length > 200 ? items.hypothesis : ""}`} overlayClassName={'tooltip-bg tooltip-width'}>
                      <p className="testdesign-data">{items.hypothesis} </p>
                    </Tooltip>
                    <a
                      onClick={() => {
                        showEditDrawer(items);
                      }}
                      className="testdesign-link"
                    >
                      View Full Test Design
                    </a>
                    <br />
                  </Col>
                  <Col span={8}></Col>
                  <Col span={2}>
                    <div
                      className="pb-2 quant-quest-icon"
                      style={{ float: "right" }}
                    >
                      <SVGIcon
                        icon={"edit-fill"}
                        className="fontIcon"
                        onClick={() => {
                          showEditDrawer(items);
                        }}
                      />
                      <SVGIcon
                        icon={"delete-fill"}
                        className="fontIcon"
                        onClick={() => {
                          showDeleteModal(items);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
        </>
      ),
    },
  ];

  const [filterConf, setFilterConfig] = useState([
    [
      {
        key: "biases",
        label: "Bias",
        type: "multi-select",
        placeholder: "Please Select ",
        col: 24,
        maxTagCount: 1,
        options: [],
      },
      {
        key: "target_audience",
        label: "Target Audience",
        type: "tree-select",
        treeCheckable: true,
        placeholder: "Please Select ",
        col: 24,
        maxTagCount: 1,
        options: [],
      },
      {
        key: "behavioral_objective_category",
        label: "Behavioral Objective Category",
        type: "tree-select",
        treeCheckable: true,
        maxTagCount: 1,
        placeholder: "Please Select ",
        col: 24,
        options: [],
      },
      {
        key: "therapy_area",
        label: "Therapy Area",
        type: "tree-select",
        treeCheckable: true,
        maxTagCount: 1,
        placeholder: "Please Select ",
        col: 24,
        options: [],
      },
    ],
  ]);

  useEffect(() => {
    setIsTextEditor(false);
    setTimeout(() => {
      setIsTextEditor(true);
    }, 500);
  }, [editorContent])

  /** Table search logic */
  useEffect(() => {
    let body = JSON.parse(JSON.stringify(testDesignTable?.body));
    if (searchText.trim()) {
      const searchTerm = searchText.toString().toLowerCase();
      const searchData = body?.filter((value) => {
        let filtered = false;
        let childList = [];
        value?.hypothesis_list.filter((childData) => {
          if (childData?.hypothesis.toLowerCase()?.includes(searchTerm)) {
            filtered = true;
            childList.push(childData);
          }
          return childData;
        });
        if (filtered) {
          value["hypothesis_list"] = childList;
          return value;
        } else {
          return value?.bias_name?.toLowerCase()?.includes(searchTerm);
        }
      });
      setTestDesignTable({
        ...testDesignTable,
        filteredData: searchData,
      });
    } else {
      setTestDesignTable({
        ...testDesignTable,
        filteredData: body,
      });
    }
    if (testDesignTable?.filteredData) {
      setTimeout(() => {
        setSearchLoader(false);
      }, 500); // added set time out to see the effect
    }
  }, [searchText]);

  useEffect(() => {
    // fetchAudience();
    // fetchTherapyArea();
    // fetchBOCategory();
    // fetchBiasList();
    // getTestDesignList();
  }, []);

  /**
   * To show edit drawer
   * @param {*} value - question data
   */
  const showEditDrawer = (value) => {
    try {
      let input = {
        test_design_id: value?.test_design_id,
      };
      ServiceUtils.postRequest("testDesignQuestionList", input).then(
        (response) => {
          try {
            if (response.status === 200) {
              if (response.data.status === "success") {
                setTestDesignID(input);
                const result1 = response?.data?.data;
                setEditorContent({ ...result1 });
                form_edit.setFieldsValue({ ...result1 });
                setQuantDrawer(true);
              } else {
                alertService.error(response.data.message);
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  const showDeleteModal = (value) => {
    setDeleteModalVisible(true);
    setTestDesignID(value?.test_design_id);
  };

  const handleOk = () => {
    setAddModalVisible(false);
  };
  
  /**
   * On delete test design
   */
  const deleteTestDesign = () => {
    try {
      let input = {
        test_design_id: testDesignID,
      };
      ServiceUtils.postRequest("testDesignDelete", input).then((response) => {
        try {
          if (response.status === 200) {
            if (response.data.status === "success") {
              getTestDesignList();
              alertService.success(response.data.message);
              setDeleteModalVisible(false);
            } else {
              alertService.error(response.data.message);
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCancel = () => {
    setDeleteModalVisible(false);
    setAddModalVisible(false);
  };

  const onFilterDrawerClose = () => {
    setBiasesFilterDrawer(false);
  };

  /**
   * On search text change
   * @param {*} searchText - search text
   */
  const handleSearchText = (searchText) => {
    try {
      setSearchLoader(true);
      if (testDesignTable?.filteredData.length === 0) {
        setTableDataText("No result found"); // if there is no data in Test Design list
      } else {
        setTableDataText("No search result found"); // if  no search result found from Test Design list
      }
      setSearchText(searchText);
    } catch (error) {
      console.log("error", error);
    }
  };
  
  /**
   * Show filter drawer
   */
  const showFilterDrawer = () => {
    setFilterPayload({ ...selectedFilterPayload });
    setBiasesFilterDrawer(true);
  }
  
  /**
   * To apply qualitative filter
   */
  const applyQuantitativeFilter = () => {
    try {
      const selectedValue = selectedFilterPayload?.behavioral_objective_category.length === 0 && selectedFilterPayload?.biases.length === 0 && selectedFilterPayload?.target_audience.length === 0 && selectedFilterPayload?.therapy_area.length === 0;
      const payload = filterPayload?.behavioral_objective_category.length === 0 && filterPayload?.biases.length === 0 && filterPayload?.target_audience.length === 0 && filterPayload?.therapy_area.length === 0;
      if (selectedValue && payload) {
        setBiasesFilterDrawer(false);
      } else {
        setSelectedFilterPayload({ ...filterPayload });
        getTestDesignList(filterPayload);
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  
  /**
   * Reset filter
   */
  const resetFilter = () => {
    try {
      const resetFilterPayload = {
        biases: [],
        target_audience: [],
        behavioral_objective_category: [],
        therapy_area: [],
      }
      if (selectedFilterPayload?.behavioral_objective_category.length === 0 && selectedFilterPayload?.biases.length === 0 && selectedFilterPayload?.target_audience.length === 0 && selectedFilterPayload?.therapy_area.length === 0) {
        setBiasesFilterDrawer(false)
      } else {
        setSelectedFilterPayload(resetFilterPayload);
        getTestDesignList(resetFilterPayload);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To get test design list
   * @param {*} filterData - filtered data
   */
  const getTestDesignList = (filterData = null) => {
    try {
      ServiceUtils.postRequest("testDesignList", (filterData ? filterData : selectedFilterPayload)).then(
        (_response) => {
          if (_response.status === 200) {
            if (_response.data.status === "success") {
              const result = _response?.data?.data;
              const temp = {
                body: result.test_design_list,
                headers: [
                  {
                    key: "bias_name",
                    label: "Bias",
                  },
                  {
                    key: "hypothesis",
                    label: "Test Design",
                  },
                ],
              };
              temp.header = temp?.headers;
              temp.body = result?.test_design_list;
              temp.filteredData = result?.test_design_list;
              setTestDesignTable({ ...temp });
              setBiasesFilterDrawer(false);
            } else {
              alertService.error(
                _response.data.message || `Error while fetching biases list`
              );
            }
          }
        }
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * To fetch audience data
   */
  const fetchAudience = () => {
    try {
      ServiceUtils.getRequest("targetAudienceList").then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data;
            const audienceList = result.targeted_audience;
            filterConf?.map((row) => {
              return row.map((el) => {
                if (el.key === "target_audience") el.options = audienceList;
                return { ...el };
              });
            });
            setAddTAModal(audienceList);
          } else {
            alertService.error(
              response.data.message || `Error while fetching target audience list`
            );
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };
   
  /**
   * To fetch therapy area
   */
  const fetchTherapyArea = () => {
    try {
      ServiceUtils.getRequest("theraphyArea").then((response) => {
        try {
          if (response.status === 200) {
            if (response.data.status === "success") {
              const result = response?.data?.data;
              const audienceList = result.therapy_area;
              setAddTAmodal(audienceList);
              const temp = filterConf;
              temp?.map((row) => {
                return row.map((el) => {
                  if (el.key === "therapy_area") el.options = audienceList;
                  return { ...el };
                });
              });
              setFilterConfig([...temp]);
            } else {
              alertService.error(
                response.data.message ||
                `Error while fetching target audience list`
              );
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * To fetch behavioral objective category list data
   */
  const fetchBOCategory = () => {
    try {
      ServiceUtils.getRequest("boListGroup").then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data;
            const boCategory = result?.behavioral_objective_category_group;
            setAddBOCModal(boCategory);
            const temp = filterConf;
            temp?.map((row) => {
              return row.map((el) => {
                if (el.key === "behavioral_objective_category")
                  el.options = boCategory;
                return { ...el };
              });
            });
            setFilterConfig([...temp]);
          } else {
            alertService.error(
              response.data.message ||
              `Error while fetching behavioral objective category list`
            );
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };
   
  /**
   * To fetch bias data
   */
  const fetchBiasList = () => {
    try {
      ServiceUtils.getRequest("biasList").then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data?.bias_data;
            result.forEach((element) => {
              element["label"] = element["bias_name"];
              element["value"] = element["bias_id"];
            });
            const biasListquant = result;
            setBiasList(biasListquant);
            filterConf?.map((row) => {
              return row.map((el) => {
                if (el.key === "biases") el.options = biasListquant;
                return { ...el };
              });
            });
          } else {
            alertService.error(
              response.data.message || `Error while fetching project Type list`
            );
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * On add qualitative finish
   * @param {*} values - save data
   */
  const onAddQuantitativeFinish = (values) => {
    try {
      if (addMode === "save") {
        saveNewTestDesign(values);
      }
      if (addMode === "saveAll") {
        saveAllNewTestDesign(values);
      }
      form.resetFields();
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * On add qalitative finish save
   * @param {*} errorInfo - error info
   */
  const onAddQuantitativeFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  /**
   * On edit quantitative edit finish
   * @param {*} values 
   */
  const onEditQuantitativeFinish = (values) => {
    try {
      updateTestDesign(values);
      form_edit.resetFields();
    } catch (error) {
      console.log("error", error);
    }
  };

  const onFinishFailedEditQuantitative = (errorInfo) => {
    console.log("Failed:", errorInfo);
  }

  /**
   * On editor value change
   * @param {*} value - content
   */
  const updateEditorContent = (value) => {
    try {
      setAddTestDesignEditorContent(value);
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * TO save new test design
   * @param {*} values - add new data
   */
  const saveNewTestDesign = (values) => {
    try {
      ServiceUtils.postRequest("testDesignCreate", {
        bias_id: values?.bias_name,
        speciality_id_list: values?.target_audience,
        behavioral_objective_category_id_list:
          values?.behavioral_objective_category,
        disease_id_list: values?.therapy_area,
        test_design: addTestDesignEditorContent,
        hypothesis: values?.hypothesis,
      }).then((response) => {
        try {
          if (response.status === 200) {
            if (response.data.status === "success") {
              getTestDesignList();
              alertService.success(response.data.message);
              setAddModalVisible(false);
            } else {
              alertService.error(response.data.message);
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * To save all new test design
   * @param {*} values - test design data
   */
  const saveAllNewTestDesign = (values) => {
    try {
      const data = {
        bias_id: values?.bias_name,
        speciality_id_list: values?.target_audience,
        behavioral_objective_category_id_list:
          values?.behavioral_objective_category,
        disease_id_list: values?.therapy_area,
        test_design: addTestDesignEditorContent,
        hypothesis: values?.hypothesis,
      }
      ServiceUtils.postRequest("testDesignCreate", data).then((response) => {
        try {
          if (response.status === 200) {
            if (response.data.status === "success") {
              getTestDesignList();
              alertService.success(response.data.message);
              form.resetFields();
            } else {
              alertService.error(response.data.message);
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * To update test design
   * @param {*} values - test design values
   */
  const updateTestDesign = (values) => {
    try {
      const data = {
        bias_id: values?.bias_id,
        speciality_id_list: values?.target_audience,
        behavioral_objective_category_id_list:
          values?.behavioral_objective_category,
        disease_id_list: values?.therapy_area,
        test_design: addTestDesignEditorContent,
        hypothesis: values?.hypothesis,
        test_design_id: testDesignID?.test_design_id,
      }
      ServiceUtils.postRequest("testDesignUpdate", data).then((response) => {
        try {
          if (response.status === 200) {
            if (response.data.status === "success") {
              getTestDesignList();
              alertService.success(response.data.message);
              setAddModalVisible(false);
              setQuantDrawer(false);
            } else {
              alertService.error(response.data.message);
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleFilterFormAction = ({ type, payload }) => {
    changeKeyValuPareToValu(payload);
  };

  const changeKeyValuPareToValu = (payload) => {
    try {
      const formData = payload.data;
      if (formData.target_audience.length > 0) {
        let TauDropDownData = [];
        formData.target_audience.map((el) => {
          if (el?.value) {
            TauDropDownData.push(el?.value);
          } else {
            TauDropDownData.push(el);
          }
          return el;
        });
        formData.target_audience = TauDropDownData;
      }
      if (formData.behavioral_objective_category.length > 0) {
        let BocDropdownData = [];
        formData.behavioral_objective_category.map((el) => {
          if (el?.value) {
            BocDropdownData.push(el?.value);
          } else {
            BocDropdownData.push(el);
          }
          return el;
        });
        formData.behavioral_objective_category = BocDropdownData;
      }
      if (formData.therapy_area.length > 0) {
        let TADropDownData = [];
        formData.therapy_area.map((el) => {
          if (el?.value) {
            TADropDownData.push(el?.value);
          } else {
            TADropDownData.push(el);
          }
          return el;
        });
        formData.therapy_area = TADropDownData;
      }
      setFilterPayload(formData);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="p-2 mt-1 quantitativeTestDesign">
      <img className='mt-2 workInProgressImage' src={workInProgress} alt="" />

      {1 === 2 && <><Row>
        <Col span={7} className="searchFill ps-2 d-flex">
          <DebounceInput
            debounceTimeout={500}
            type="text"
            className="form-control form-control-sm"
            placeholder="Search"
            onChange={(e) => {
              handleSearchText(e.target.value);
            }}
          />
          <button className="searchButton text-muted me-2">
            {showLoader ? (
              <Spin size="small" />
            ) : (
              <SVGIcon icon={"search-transparent"} />
            )}
          </button>
          <div className="ps-2 pt-2">
            <span
              className="filtersButton"
              onClick={showFilterDrawer}
            >
              <SVGIcon icon={"filter"} />{" "}
              <span className="filter-text">Filters</span>
            </span>
          </div>
        </Col>
        <Col span={4} offset={13} className="ps-3 addQusBtn">
          <Button
            type="primary"
            className="mt-2 primary-btn main-add"
            onClick={() => setAddModalVisible(true)}
          >
            <SVGIcon icon={"add-circle-white"} />
            &nbsp;Add New Test Design
          </Button>
        </Col>
      </Row>

        <div className="p-0 mt-2 qualitative-table">
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            loading={searchLoader}
            scrollToFirstRowOnChange={true}
            scroll={{ y: "calc(100vh - 230px)" }}
            showSorterTooltip={false}
            locale={noDataFaound}
          />
        </div>
      </>}

      {/* filter antd drawer  */}
      <div className="quanitativeFilter">
        <Drawer
          title="Quantitave Test Design Filters"
          placement={"right"}
          width={"28%"}
          closable={"right"}
          onClose={onFilterDrawerClose}
          open={biasesFilterDrawer}
          footer={
            <>
              <Button
                type="primary"
                className="me-1"
                onClick={applyQuantitativeFilter}
              >
                Apply Filters
              </Button>
              <Button
                onClick={resetFilter}
                className="me-2"
              > Reset Filters
              </Button>
              <Button
                onClick={() => {
                  setBiasesFilterDrawer(false);
                }}
              >
                Cancel
              </Button>
            </>
          }
        >
          <div className="antFilterFormBuilder pe-2 ps-2">
            <FormBuilder
              id="form-biases-filter"
              data={filterPayload}
              onAction={handleFilterFormAction}
              config={filterConf}
            />
          </div>
        </Drawer>
      </div>

      {/* Add new Quantitative Ques */}
      <Modal
        className="quantAddModal"
        forceRender
        title="Add a Quantitative Test Design"
        open={addModalVisible}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        width={700}
        footer={[
          <div className="edit-action">
            <Button
              key="save"
              type="primary"
              htmlType="submit"
              form="add"
              className="pt-1 m-1"
              onClick={() => setAddMode("save")}
            >
              Save
            </Button>
            <Button
              key="saveAll"
              form="add"
              className="pt-1 m-1"
              htmlType="submit"
              onClick={() => setAddMode("saveAll")}
            >
              Save & Add New Test Design
            </Button>
            <Button key="cancel" className="pt-1" onClick={handleOk}>
              Cancel
            </Button>
          </div>,
        ]}
      >
        <div className="px-1 addnewtest-modal">
          <div className="formFData">
            <Form
              form={form}
              name="basic"
              id={"add"}
              layout={"vertical"}
              initialValues={{ remember: true }}
              onFinish={onAddQuantitativeFinish}
              onFinishFailed={onAddQuantitativeFinishFailed}
              autoComplete="off"
            >
              <Row>
                <Col span={12}>
                  <Form.Item
                    label="Biases"
                    key="bias_name"
                    name="bias_name"
                    rules={[
                      {
                        required: true,
                        message: "Please select Biases!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a Biases"
                      options={biaslist}
                      response
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Target Audiences"
                    key="target_audience"
                    name="target_audience"
                    rules={[
                      {
                        required: true,
                        message: "Please select Target Audiences!",
                      },
                    ]}
                  >
                    <TreeSelect
                      showSearch
                      placeholder="Please Select"
                      maxTagCount="responsive"
                      treeData={addTAModal}
                      allowClear
                      treeNodeFilterProp={"title"}
                      treeNodeLabelProp="label"
                      treeCheckable={true}
                      multiple
                      treeDefaultExpandAll
                    ></TreeSelect>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    label="Behavioral Objective Categories"
                    key="behavioral_objective_category"
                    name="behavioral_objective_category"
                    rules={[
                      {
                        required: true,
                        message: "Please select Behavioral Objective Categories!",
                      },
                    ]}
                  >
                    <TreeSelect
                      showSearch
                      placeholder="Please Select"
                      treeData={addBOCModal}
                      maxTagCount="responsive"
                      allowClear
                      treeNodeFilterProp={"title"}
                      treeNodeLabelProp="label"
                      treeCheckable={true}
                      multiple
                      treeDefaultExpandAll
                    ></TreeSelect>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Therapy Area"
                    key="therapy_area"
                    name="therapy_area"
                    rules={[
                      {
                        required: true,
                        message: "Please select Therapy Area!",
                      },
                    ]}
                  >
                    <TreeSelect
                      showSearch
                      placeholder="Please Select"
                      treeData={addTAmodal}
                      allowClear
                      maxTagCount="responsive"
                      treeNodeFilterProp={"title"}
                      treeNodeLabelProp="label"
                      treeCheckable={true}
                      multiple
                      treeDefaultExpandAll
                    ></TreeSelect>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Hypothesis"
                    key="hypothesis"
                    name="hypothesis"
                    rules={[{ required: true, message: "Please enter Hypothesis!" }]}
                  >
                    <TextArea width="100%" placeholder="Add Hypothesis"></TextArea>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <label className="pb-1" for="text-editor">Test Design</label>
                  <QuillRichTextEditor mode={"edit"} editorToolBarSetting={editorToolBarSetting} updateContent={updateEditorContent} editorStyleData={''} />
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Modal>

      {/* Delete Qualitative Ques */}
      <>
        <Modal
          open={deleteModalVisible}
          onOk={handleOk}
          centered
          width={400}
          onCancel={handleCancel}
          footer={null}
        >
          <h6  className="confirmation-header-text">Delete Test Design</h6>
          <p className="text-muted pt-1">
            Are you sure you would like to delete this Test Design?
          </p>
          <div className="mt-3 edit-action mt-3">
            <Button
              key="delete"
              type="primary"
              className="pt-1 m-1"
              onClick={deleteTestDesign}
            >
              Delete
            </Button>
            <Button
              key="cancel"
              className="pt-1"
              onClick={() => {
                setDeleteModalVisible(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>

        {/* Edit Drawer */}
        <Drawer
          className="quant-edit-drawer"
          title="Edit Affect Heuristic Test Design"
          placement="right"
          onClose={() => setQuantDrawer(false)}
          open={quantDrawer}
          width={"55%"}
          footer={
            <>
              <Button
                type="primary"
                htmlType="submit"
                form="edit"
                className="me-2"
              >
                Save
              </Button>
              <Button onClick={() => setQuantDrawer(false)}> Cancel </Button>
            </>
          }
        >
          <div className="ms-1 antQuantFormBuilder">
            <div className="formFData">
              <Form
                form={form_edit}
                id={"edit"}
                layout={"vertical"}
                initialValues={{ remember: true }}
                onFinish={onEditQuantitativeFinish}
                onFinishFailed={onFinishFailedEditQuantitative}
                autoComplete="off"
              >
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Biases"
                      key="bias_name"
                      name="bias_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input Biases!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Please Select"
                        options={biaslist}
                        response
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Target Audiences"
                      key="target_audience"
                      name="target_audience"
                      rules={[
                        {
                          required: true,
                          message: "Please select Target Audiences!",
                        },
                      ]}
                    >
                      <TreeSelect
                        showSearch
                        placeholder="Please Select"
                        maxTagCount="responsive"
                        treeData={addTAModal}
                        treeCheckable={"true"}
                      ></TreeSelect>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Behavioral Objective Categories"
                      key="behavioral_objective_category"
                      name="behavioral_objective_category"
                      rules={[
                        {
                          required: true,
                          message: "Please select Behavioral Objective Categories!",
                        },
                      ]}
                    >
                      <TreeSelect
                        showSearch
                        placeholder="Please Select"
                        treeData={addBOCModal}
                        treeCheckable={"true"}
                        maxTagCount="responsive"
                      ></TreeSelect>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Therapy Area"
                      key="therapy_area"
                      name="therapy_area"
                      rules={[
                        {
                          required: true,
                          message: "Please select Therapy Area!",
                        },
                      ]}
                    >
                      <TreeSelect
                        showSearch
                        placeholder="Please Select"
                        treeData={addTAmodal}
                        treeCheckable={"true"}
                        maxTagCount="responsive"
                      ></TreeSelect>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Hypothesis"
                      key="hypothesis"
                      name="hypothesis"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Hypothesis!",
                        },
                      ]}
                    >
                      <TextArea width="100%" rows={5}></TextArea>
                    </Form.Item>
                  </Col>
                </Row>

              </Form>
              <Row>
                <Col span={24}>
                  <label className="pb-1" for="text-editor">Test Design</label>
                  {isTextEditor && <QuillRichTextEditor mode={"edit"} editorToolBarSetting={editorToolBarSetting} editorContent={editorContent?.test_design} updateContent={updateEditorContent} editorStyleData={''} />}
                </Col>
              </Row>
            </div>
          </div>
        </Drawer>
      </>
    </div>
  );
}
