import React, { useEffect, useState, useRef } from "react";
import { ServiceUtils } from "../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../Shared/Utils/ToasterUtil";
import BiasesDetails from "../Biases/BiasesDetails/BiasesDetails";
import "./Biases.scss";
import filterFormData from "../../../Assets/json/biases-filter-form.json";
import { searchTable, defaultAntSort } from "../../../Shared/Utils/CommonUtil";
import SVGIcon from "../../../Shared/Components/SVGIcon/SVGIcon";
import { Table, Drawer, Button} from "antd";
import FormBuilder from "../../../Shared/Components/Ant-FormBuilder/Ant-FormBuilder";
import { DebounceInput } from 'react-debounce-input';
import { sessionService } from "../../../Shared/Utils/SessionService";
import { CONSTANTS } from '../../../Environments/constants';
import CustomParagraphToolTip from "../../../Shared/Components/CustomParagraphToolTip/CustomParagraphToolTip";

export default function Biases() {

  const [biasTable, setBiasTable] = useState({
    body: [],
    header: [],
    filteredData: [],
  });
  const [filterConf, setFilterConf] = useState([...filterFormData]);
  const [filterPayload, setFilterPayload] = useState({});
  const [selectedFilterPayload, setSelectedFilterPayload] = useState({
    target_audience: [],
    behavioral_objective_category: [],
    research_methodology: [],
  });
  const [searchLoader, setSearchLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const searchColumns = [
    {
      key: "name",
      label: "Bias",
    },
    {
      key: "description",
      label: "Bias Summary",
    },
  ];
  const [tableDataText, setTableDataText] = useState('No Data Found');


  /**
   * Drawer
   */
  const [biasesFilterDrawer, setBiasesFilterDrawer] = useState(false);
  const userDetails = sessionService.getSession();
  const KM_CONSULTANT = CONSTANTS?.KM_CONSULTANT;
  const childRef = useRef();



  const onDrawerClose = () => {
    setBiasesFilterDrawer(false);
  };
  const onSearchChange = (searchtext) => {
    if (biasTable?.filteredData.length === 0) {
      setTableDataText('No result Found');   // if there is no data in project list
    } else {
      setTableDataText('No search result returned. Please modify your search criteria to view biases.'); // if  no search result found from project list
    }
    setSearchText(searchtext)
  }


  /**Table search logic */
  useEffect(() => {
    if (searchText.trim().length === 0) {
      setBiasTable({
        ...biasTable,
        filteredData: biasTable?.body
      });
    } else {
      setSearchLoader(true);
      setBiasTable({
        ...biasTable,
        filteredData: searchTable(searchText, biasTable?.body, searchColumns),
      });
    }
    if (biasTable?.filteredData) {
      setTimeout(() => {
        setSearchLoader(false)
      }, 500); // added set time out to see the effect 
    }
  }, [searchText]);

  /**Load Filter form meta */
  useEffect(() => {
    getBiasesList(true);
    fetchAudience();
    fetchBOCategory();
    fetchResearchMethodology();
    if (userDetails?.role === KM_CONSULTANT) {
      let field = {
        "key": "therapy_area",
        "label": "Therapy Area",
        "type": "tree-select",
        "search": true,
        "placeholder": "Please Select",
        "allowClear": true,
        "col": 24,
        "treeCheckable": true,
        "options": []
      }
      const isTArea = filterConf[0].filter((el) => {
        return el.key === 'therapy_area';
      })
      if (isTArea.length === 0) {
        filterConf[0].splice(3, 0, field);
      }
      fetchTherapyArea();
    }
  }, []);



  const applyFilter = () => {
    setSelectedFilterPayload({ ...filterPayload })
    // getBiasesList(true, '', {...filterPayload})
    onDrawerClose()
  }

  const showFilterDrawer = () => {
    setFilterPayload({ ...selectedFilterPayload })
    setBiasesFilterDrawer(true);
  }
  /**
   * Fetch biases list
   */
  const getBiasesList = (loader, filterData = undefined) => {
    const payload = {};
    if (filterData) {
      payload.target_audience = filterData.target_audience
        ? filterData.target_audience
        : undefined;
      payload.behavioral_object_category =
        filterData.behavioral_objective_category
          ? filterData.behavioral_objective_category
          : undefined;

      payload.research_methodology = filterData.research_methodology
        ? filterData.research_methodology
        : undefined;
    }
    ServiceUtils.postRequest("biaseList", filterData ? filterData : filterPayload, loader).then((_response) => {
      if (_response.status === 200) {
        if (_response.data.status === "success") {
          const result = _response?.data?.data;
          result.biases.map((b, i) => b.key = i);
          setBiasTable({
            ...biasTable,
            body: result?.biases,
            filteredData: result?.biases,
            header: [
              {
                key: "name",
                label: "Bias",
                linkButton: true,
                sort: true,
              },
              {
                key: "description",
                label: "Bias Summary",
              },
              {
                key: "bias_tested_perc",
                label: "% of Projects Bias Tested",
                postfix: "%",
                sort: true,
              },
              {
                key: "final_readout_perc",
                label: "% of Project Bias Active",
                postfix: "%",
                sort: true,
              },
            ],
          });
          // To retain searched values
          let searchData = JSON.parse(JSON.stringify(searchText));
          setSearchText('');
          setSearchText(searchData);
          //
        } else {
          alertService.error(
            _response.data.message || `Error while fetching biases list`
          );
        }
      }
    });
  };

  /**
   * Fetch Countries.
   */

  const fetchAudience = () => {
    ServiceUtils.getRequest("targetAudienceList").then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          const result = response?.data?.data;
          const audienceList = result.targeted_audience;
          const temp = filterConf;
          temp?.map((row) => {
            return row.map((el) => {
              if (el.key === "target_audience") el.options = audienceList;
              return { ...el };
            });
          });
          setFilterConf([...temp]);
        } else {
          alertService.error(
            response.data.message || `Error while fetching target audience list`
          );
        }
      }
    });
  };

  /**
   * To fetch behavioral objective category list data
   */
  const fetchBOCategory = () => {
    ServiceUtils.getRequest("boListGroup").then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          const result = response?.data?.data;
          const boCategory = result?.behavioral_objective_category_group;
          const temp = filterConf;
          temp?.map((row) => {
            return row.map((el) => {
              if (el.key === "behavioral_objective_category")
                el.options = boCategory;
              return { ...el };
            });
          });
          setFilterConf([...temp]);
        } else {
          alertService.error(
            response.data.message ||
            `Error while fetching behavioral objective category list`
          );
        }
      }
    });
  };

  /**
   * To get research methodology data
   */
  const fetchResearchMethodology = () => {
    ServiceUtils.getRequest("researchMethodology").then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          const result = response?.data?.data;
          const researchMethodology = result.research_methodology;
          const temp = filterConf;
          temp?.map((row) => {
            return row.map((el) => {
              if (el.key === "research_methodology")
                el.options = researchMethodology;
              return { ...el };
            });
          });
          setFilterConf([...temp]);
        } else {
          alertService.error(
            response.data.message || `Error while fetching project Type list`
          );
        }
      }
    });
  };

  /** Get therapy area */
  const fetchTherapyArea = () => {
    ServiceUtils.getRequest("theraphyArea").then((response) => {
      try {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data;
            const audienceList = result.therapy_area;
            let tempData = filterConf;
            tempData?.map((row) => {
              return row.map((el) => {
                if (el.key === "therapy_area") el.options = audienceList;
                return { ...el };
              });
            });
            setFilterConf([...tempData])
          } else {
            alertService.error(
              response.data.message ||
              `Error while fetching Therapy area data`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  };


  /**Filter form actions */
  const handleFilterFormAction = ({ type, payload }) => {
    switch (type) {
      case "onSubmit":
        // handleBiasLinkClose();
        break;
      case "onChange":
        // setFilterPayload(payload?.data);
        changeKeyValuPareToValu(payload);
        break;
      default:
    }
  };

  const changeKeyValuPareToValu = (payload) => {
    const formData = payload.data;
    if (formData.behavioral_objective_category.length > 0) {
      let tADropdownData = [];
      formData.behavioral_objective_category.map((el) => {
        if (el?.value) {
          tADropdownData.push(el?.value);
        } else {
          tADropdownData.push(el);
        }
        return el;
      });
      formData.behavioral_objective_category = tADropdownData;
    }

    if (formData.target_audience.length > 0) {
      let TauDropDownData = [];
      formData.target_audience.map((el) => {
        if (el?.value) {
          TauDropDownData.push(el?.value);
        } else {
          TauDropDownData.push(el);
        }
        return el;
      });
      formData.target_audience = TauDropDownData;
    }
    setFilterPayload(formData)

  }

  const columns = [
    {
      title: "Bias",
      dataIndex: "name",
      sorter: (a, b) => defaultAntSort(a.name, b.name),
      render: (text, biasData) => (
        <span
          onClick={() => {
            showBias({ "bias_name": text, "bias_id": biasData?.bias_id });
          }} >
          <CustomParagraphToolTip className="text-clickable biasName" ellipsis={{ rows: 3 }} tooltipContent={text} paragrapgContent={text} />
        </span>
      ),
      width: 250,
    },
    {
      title: "Bias Summary",
      dataIndex: "description",
      render: (description) => (
        <CustomParagraphToolTip ellipsis={{ rows: 3 }} tooltipContent={description} paragrapgContent={description} />
      ),
    },
    {
      title: "% of Projects Bias Tested",
      dataIndex: "bias_tested_perc",
      sorter: (a, b) => defaultAntSort(a.bias_tested_perc, b.bias_tested_perc),
      width: 135,
      align: "right",
      defaultSortOrder: "descend",
      render: (value) => {
        return <span> {value}%</span>;
      },
    },
    {
      title: "% Projects Bias Active",
      dataIndex: "final_readout_perc",
      sorter: (a, b) => defaultAntSort(a.final_readout_perc, b.final_readout_perc),
      width: 135,
      align: "right",
      render: (value) => {
        return <span> {value}%</span>;
      },
    },
  ];
  const dataSource = biasTable?.filteredData;
  let noDataFaound = {
    emptyText: tableDataText,
  };

  const showBias = (biasData) => {
    childRef.current.showBias(biasData);
  }

  const resetFilter = () => {
    const resetFilterPayload = {
      target_audience: [],
      behavioral_objective_category: [],
      research_methodology: [],
      therapy_area: [],
    };
    onDrawerClose();
    setSelectedFilterPayload(resetFilterPayload);
    return;
  }


  return (
    <div className="page-layout biases-layout">
      <div className="col-12">
        <div className="searchMainDiv mb-3 d-flex">
          <div className="searchFilterDiv input-group ps-0">
            <DebounceInput
              debounceTimeout={500}
              type="text"
              className="form-control form-control-sm"
              placeholder="Search"
              value={searchText}
              onChange={(e) => {
                onSearchChange(e.target.value);
              }}
            />
            <button
              className="searchButton text-muted me-2"
            // onClick={searchProjects}
            >
              <SVGIcon icon={"search-transparent"} />
            </button>
            <div className="ps-2 pt-2">
              <span
                className="filtersButton"
                onClick={() => {
                  showFilterDrawer()
                }}
              >
                <SVGIcon icon={"filter"} />{" "}
                <span className="filter-text">Filters</span>
              </span>
            </div>
          </div>
          {userDetails?.role === KM_CONSULTANT && <Button type="primary" className="me-2 d-flex" onClick={() => showBias({ "bias_name": '', "bias_id": '' })}>
            <SVGIcon icon={"add-circle-white"} className="iconPositionInButton" />
            <span className="pb-1">Add Bias</span>
          </Button>}
        </div>
        <div className="biases-table">
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            loading={searchLoader}
            scrollToFirstRowOnChange={true}
            scroll={{ y: "calc(100vh - 200px)" }}
            showSorterTooltip={false}
            locale={noDataFaound}

          />
        </div>
      </div>
      <BiasesDetails ref={childRef} refreshBiasData={(data) => getBiasesList(data)} />
      {/* filter antd drawer  */}
      <Drawer
        title="Biases Filters"
        placement={"right"}
        width={'26%'}
        closable={"right"}
        onClose={onDrawerClose}
        open={biasesFilterDrawer}
        footer={
          <>
            <Button type="primary" className="me-1" onClick={applyFilter}>Apply Filters</Button>
            <Button className="me-2" onClick={resetFilter}>Reset Filters</Button>
            <Button
              onClick={() => {
                setBiasesFilterDrawer(false);
              }}
            >
              {" "}
              Cancel{" "}
            </Button>
          </>
        }
      >
        <div className="px-2 antFormBuilderBiases">
          <FormBuilder
            id="form-biases-filter"
            data={filterPayload}
            onAction={handleFilterFormAction}
            config={filterConf}
          />
        </div>
      </Drawer>

    </div>
  );
}
