import React, { useEffect } from 'react'
import { ServiceUtils } from "../../Shared/Utils/ServiceUtils";
import { alertService } from "../../Shared/Utils/ToasterUtil";
import { sessionService } from "../../Shared/Utils/SessionService";
import { VARIABLES } from "../../Environments/constants";
import {redirectToLogin} from "../../Shared/Utils/CommonUtil";

export default function AuthenticateUser(props) {
    const authenticateUser = () => {
        ServiceUtils.getRequest("loginIDM").then((_response) => {
            try {
                if (_response.status === 200) {
                    const resp = _response.data;
                    if (resp.status === "success") {
                        const session = {
                            // ...userData,
                            [VARIABLES.fullName]: resp.data?.user_details?.name,
                            [VARIABLES.firstName]: resp.data?.user_details?.first_name,
                            [VARIABLES.userName]: resp.data?.user_details?.name,
                            [VARIABLES.middleName]: resp.data?.user_details?.middle_name,
                            [VARIABLES.lastName]: resp.data?.user_details?.last_name,
                            [VARIABLES.userId]: resp.data?.user_details?.user_id,
                            [VARIABLES.userRole]: resp.data?.user_details?.role,
                            [VARIABLES.userRoleId]: resp.data?.user_details?.role_id,
                            [VARIABLES.email]: resp.data?.user_details?.email,
                        };
                        sessionService.setSession(session, VARIABLES.userSession);
                        props.updateAuth(session);
                    } else {
                        alertService.error(resp.message || `Error while login`);
                        redirectToLogin();
                    }
                } else {
                    alertService.error(`Something went wrong. Please try after sometime.`);
                    redirectToLogin();
                }
            } catch (error) {
                redirectToLogin();
                console.log("error", error);
            }
        });
    }

    useEffect(() => {
        authenticateUser();
    }, []);
    return (
        <div>
        </div>
    )
}
