import React from 'react';
import { TOASTER_TIMEOUT } from '../../Environments/constants';
import { Alert, Toast, ToastContainer } from 'react-bootstrap';
import { Subject } from 'rxjs';
import { generateUniqueId } from './CommonUtil'

const subject = new Subject();

const parseMessage = message => {
  if (typeof message === 'string') return { message: message };
  else if (typeof message === 'object') return message
}
export const alertService = {
  success: message => subject.next({ ...parseMessage(message), type: 'success' }),
  error: message => subject.next({ ...parseMessage(message), type: 'danger' }),
  warning: message => subject.next({ ...parseMessage(message), type: 'warning' }),
  info: message => subject.next({ ...parseMessage(message), type: 'info' }),
  message: message => subject.next({ ...message }),
};
// const clearMessages = () => subject.next(); // clear all messages
const getMessage = () => subject.asObservable();

export default class ToasterAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: [],
      alertTimeout: ''
    };
  }

  componentDidMount() {
    this.subscription = getMessage().subscribe(message => {
      if (message) {
        message.id = generateUniqueId();
        if(message?.preventDuplicate && this.state.messages?.length){
          let msgData = this.state.messages.filter(msg => msg?.message === message?.message)
          if(msgData && msgData?.length){
            return;
          }
        }
        let tempTimeout = setTimeout(() => {
          this.removeMessage(message)
        }, message.delay || TOASTER_TIMEOUT);
        this.setState({ messages: [...this.state.messages, message], alertTimeout: tempTimeout });
      }
    });
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  removeMessage(delMsg) {
    if (delMsg) {
      const messages = this.state.messages.filter(el => el.id !== delMsg?.id);
      this.setState({ messages: messages });
    }
  }

  toastHover(hover, el) {
    if (hover) {
      clearTimeout(this.state.alertTimeout);
    } else {
      setTimeout(() => {
        this.removeMessage(el);
      }, 1000);
    }
  }

  render() {
    const { messages } = this.state;
    return (
      messages?.length > 0 && <ToastContainer position="top-end" className="p-3 mt-5 toaster-main" >
        {messages.map((el, index) =>
          <Toast key={index} onClose={() => this.removeMessage(el)} >
            <Alert variant={el.type} style={{ marginBottom: 0 }} onMouseOver={() => this.toastHover(true, el)} onMouseOut={() => this.toastHover(false, el)}>
              <button type="button" className="btn-close" style={{ float: 'right' }} onClick={() => this.removeMessage(el)} aria-label="Close" data-dismiss="toast"></button>
              {el.title && <Alert.Heading>{el.title}</Alert.Heading>}
              {typeof el.message === 'string' ? el.message : el.message?.Error}
            </Alert>
          </Toast>
        )}
      </ToastContainer>
    );
  }
}



