import React, { useState, useEffect, useRef } from "react";
import './BehavioralSparks.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Drawer, Button, Radio, Tooltip, Modal } from 'antd';
import SVGIcon from "../../../../../Shared/Components/SVGIcon/SVGIcon";
import QuillRichTextEditor from "../../../../../Shared/Components/RichEditor/rich-editor";
import AddBehaviouralSpark from "../add-behavioural-spark/AddBehaviouralSpark";
import { ServiceUtils } from "../../../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../../../Shared/Utils/ToasterUtil";
import { extractContent } from "../../../../../Shared/Utils/CommonUtil";


export default function BehavioralSparks({ description, mode, selectedBiasData, biasDetailsData, refreshData, updateBehaviouralSparkData, saveClicked, ...props }) {

  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [behavioralSparkData, setBehavioralSparkData] = useState([]);
  const [newFormData, setNewFormData] = useState({});
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
  const [isBehavioralSparksEdited, setIsBehavioralSparksEdited] = useState(false);
  const [unsavedChangesModel, setUnsavedChangesModel] = useState(false);
  const childRef = useRef();
  const scrollDiv = "#biasDetailsDrawer .ant-drawer-body";
  
  const sparkBiasEditor = {
    height: "210px",
    display: 'flex',
    flexDirection: 'column'
  }
  const editorToolBarSetting = {
    // Toolbar customized according to our needs
    toolbar: {
      container: [
        ['bold', 'italic', 'underline'],
        [{ 'list': 'bullet' }],
        [{ 'align': [] }],
        [{ 'color': [] }, { 'background': [] }],
      ],
    }
  };

  const [addBehaviouralSparkFields, setAddBehaviouralSparkFields] = useState([
    {
      "key": "beh_spark_aud_id",
      "label": "Audience",
      "type": "select",
      "placeholder": "Select Audience",
      "col": 8,
      "rules": [{ "required": true, "message": "Please Select Audience" }],
      "options": [{
        "label": "HCPs Sparks",
        "value": "1"
      },
      {
        "label": "Patient Sparks",
        "value": "2"
      }]
    },
    {
      "key": "category_id",
      "label": "Spark Category",
      "type": "select",
      "placeholder": "Select Spark Category",
      "col": 8,
      "rules": [{ "required": true, "message": "Please Select Spark Category" }],
      "options": [{
        "label": "Messaging",
        "value": "1"
      },
      {
        "label": "Spark Cat",
        "value": "2"
      }]
    },
    {
      "key": "example_type",
      "label": "Example Type",
      "type": "radio",
      "col": 8,
      "rules": [{ "required": true, "message": "Please Select Example type" }],
      "options": [{
        "value": "activating",
        "label": "Activating"
      },
      {
        "value": "mitigating",
        "label": "Mitigating"
      }]
    }
  ]);


  useEffect(() => {
    setBehavioralSparkData(biasDetailsData?.behavioral_spark_details);
  }, [biasDetailsData])

  useEffect(() => {
    fetchBehaviouralSparkConfigData();
  }, [])

  useEffect(() => {
    setNewFormData({ ...newFormData, bias_id: selectedBiasData?.bias_id });
  }, [selectedBiasData])

  /**
   * Add new behavioural spark
   */
  const addNewBehSpark = () => {
    try {
      if (isBehavioralSparksEdited) {
        setUnsavedChangesModel(true);
      } else {
        setShowAddDrawer(true);
        if (childRef && childRef.current) {
          childRef.current.resetForm();
        }
      }
    } catch (error) {
      console.log("error, error");
    }
  }

  const onAddProceed = () => {
    try {
      setShowAddDrawer(true);
      if (childRef && childRef.current) {
        childRef.current.resetForm();
      }
      setUnsavedChangesModel(false);
      // if (typeof refreshData === "function") refreshData({});
    } catch (error) {
      console.log("error, error");
    }
  }

  /**
   * Delete behavioural spark
   */
  const deleteBehaviouralSpark = (behaviouralSparksId) => {
    try {
      setDeleteData({
        "behaviouralSparksId": behaviouralSparksId,
      });
      setDeleteConfirmation(true);
    } catch (error) {
      console.log("error, error");
    }
  }

  /**
   * On delete proceed
   */
  const proceedDelete = () => {
    try {
      ServiceUtils.postRequest("deleteBehaviouralSpark", { beh_spark_id: deleteData?.behaviouralSparksId }).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setDeleteConfirmation(false);
            if (typeof refreshData === "function") refreshData("");
            alertService.success(response.data.message || `Successfully deleted Behavioral Spark`);
          } else {
            alertService.error(response.data.message || `Error while deleting Behavioral Spark`);
          }
        }
      });
    } catch (error) {
      console.log("error, error");
    }
  }

  /**
   * Save new behavioural spark data
   * @param {*} data - form data
   */
  const saveBehaviouralSparkData = (data) => {
    try {
      if (data?.type === 'submit') {
        setNewFormData({ ...newFormData, ...data?.formData });
        if (newFormData?.content && extractContent(newFormData?.content).trim().length) {
          saveBehaviouralSpark({ ...newFormData, ...data?.formData });
        }
      } else if (data?.type === 'onChange') {
        setIsFormEdited(true);
        if (data?.content) {
          setNewFormData({ ...newFormData, content: data?.content });
        }
      } else {
        setIsFormEdited(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * Save new behavioural spark
   */
  const saveBehaviouralSpark = (newFormData) => {
    try {
      ServiceUtils.postRequest("createBehaviouralSpark", newFormData).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            alertService.success(response.data.message || `Successfully added new Behavioral Spark`);
            if (response?.data?.data?.behavioural_spark_data && response?.data?.data?.behavioural_spark_data?.beh_spark_id) {
              if (typeof refreshData === "function") refreshData(response?.data?.data?.behavioural_spark_data?.beh_spark_id);
            }
            setShowAddDrawer(false);
            setIsFormEdited(false);
          } else {
            alertService.error(response.data.message || `Error while adding new Behavioral Spark`);
          }
        }
      });
    } catch (error) {
      console.log("error, error");
    }
  }

  /**
* To load audience and spark category dropdown data
*/
  const fetchBehaviouralSparkConfigData = () => {
    try {
      ServiceUtils.getRequest("behaviouralSparkConfigData", undefined, false).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            let result = response?.data?.data?.behavioral_sparks_dropdowns;
            addBehaviouralSparkFields.map((field) => {
              if (field?.key === "beh_spark_aud_id") {
                field['options'] = result?.audience;
              } else if (field?.key === 'category_id') {
                field['options'] = result?.category;
              }
            })
            setAddBehaviouralSparkFields([...addBehaviouralSparkFields]);
          } else {
            alertService.error(response.data.message || `Error while fetching related bias data`);
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * On example type change 
   */
  const onBehaviouralSparkDataChange = (value, key, behSparkIndex, audienceIndex, sparkIndex) => {
    try {
      setIsBehavioralSparksEdited(true);
      if (behSparkIndex !== undefined && audienceIndex !== undefined && sparkIndex !== undefined) {
        behavioralSparkData[audienceIndex].spark_details[sparkIndex].behavioral_sparks[behSparkIndex][key] = value;
        setBehavioralSparkData([...behavioralSparkData]);
        if (typeof updateBehaviouralSparkData === 'function') updateBehaviouralSparkData({ 'type': 'behavioral_sparks', 'behavioral_spark_details': [...behavioralSparkData] });
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * On add cancel
   */
  const onCancel = () => {
    try {
      if (isFormEdited) {
        setUnsavedChangesModal(true);
      } else {
        setShowAddDrawer(false);
        if (childRef && childRef.current) {
          childRef.current.resetForm();
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * On cancel confirmation
   */
  const onCancelAdd = () => {
    try {
      setUnsavedChangesModal(false);
      if (childRef && childRef.current) {
        childRef.current.resetForm();
      }
      setIsFormEdited(false);
      setShowAddDrawer(false);
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <>
      <div className="behavioural-sparks">
        <div className="bias-tab-header">
          <Row className="border-bottom">
            <Col span={16}>
              <div className="p-1 text-muted description" dangerouslySetInnerHTML={{ __html: description }} ></div>
            </Col>
            {mode === 'edit' && <Col span={8}>
              {<div className="addLink p-1" >
                <span className="fontColor" onClick={addNewBehSpark}>
                  <FontAwesomeIcon className="fontIcon" icon={faPlusCircle} />
                  <span> Add Behavioral Spark</span>
                </span>
              </div>}
            </Col>}
          </Row>
        </div>
        <div className="p-2">
          {behavioralSparkData && behavioralSparkData?.length !== 0 && behavioralSparkData?.map((eachSpark, audienceIndex) => {
            return (<>
              <h5 className="p-2">{eachSpark?.audience_name}</h5>
              <div className="spark pt-2">
                {eachSpark?.spark_details && eachSpark?.spark_details.map((eachItem, sparkIndex) => {
                  return (<>
                    <h6 className="spark-category">{eachItem?.spark_cat}</h6>
                    <hr className="horizontal-line" />
                    {eachItem?.behavioral_sparks && eachItem?.behavioral_sparks.map((each, index) => {
                      return (<div className="behavioral-sparks-section" key={'BS_' + index} id={'BSID_' + each?.behavioral_spark_id}>
                        {
                          mode === 'edit' && <Row className="each-beh-spark">
                            <Col span={23}>
                              <label>Example Type : &nbsp;</label>
                              <Radio.Group value={each?.example_type} onChange={(event) => onBehaviouralSparkDataChange(event.target.value, 'example_type', index, audienceIndex, sparkIndex)}>
                                <Radio value={'activating'} >Activating</Radio>
                                <Radio value={'mitigating'} >Mitigating</Radio>
                              </Radio.Group>
                            </Col>
                            <Col span={1}>
                              <Tooltip placement="top" title={'Delete'} color={'black'}>
                                <SVGIcon icon={'delete-fill'} className="fontIcon" onClick={() => deleteBehaviouralSpark(each?.behavioral_spark_id)} />
                              </Tooltip>
                            </Col>
                          </Row>
                        }
                        <div className={`${mode === 'edit' ? "beh-editor-section" : ""}`}>
                          <QuillRichTextEditor
                            key={each?.behavioral_spark_id}
                            mode={mode} toolbarMode={'text-only'}
                            editorContent={each?.content}
                            editorStyleData={sparkBiasEditor}
                            editorToolBarSetting={editorToolBarSetting}
                            scrollingContainer={scrollDiv}
                            updateContent={(value) => onBehaviouralSparkDataChange(value, 'content', index, audienceIndex, sparkIndex)}
                          />
                        </div>
                        {saveClicked && !each?.content && <h6 className="error-msg">Please add Behavioral Spark</h6>}
                        {index !== eachItem?.behavioral_sparks?.length - 1 && mode === 'view' && <hr className="horizontal-line" />}
                      </div>)
                    })}
                    {sparkIndex !== eachSpark?.spark_details?.length - 1 && <hr className="horizontal-line" />}
                  </>
                  )
                })
                }
              </div></>
            )
          })}
        </div>
        <Drawer
          title={`Add Behavioral Spark for ${selectedBiasData?.bias_name}`}
          className="bias-details-offcanvas"
          width={'75%'}
          onClose={onCancel}
          open={showAddDrawer}
          size={"large"}
          zIndex={1050}
          id={"addNewBehavioralSpark"}
          footer={
            <>
              <Button type="primary" htmlType="submit" form="add-behavioural-spark" className="me-2" > Save Behavioral Spark & Go Back</Button>
              <Button onClick={onCancel}> Cancel & Go Back </Button>
            </>
          }
        >
          <AddBehaviouralSpark id={'add-behavioural-spark'} ref={childRef} onSave={saveBehaviouralSparkData} addBehaviouralSparkFields={addBehaviouralSparkFields} />
        </Drawer>
        <Modal
          centered
          closable={false}
          open={deleteConfirmation}
          footer={null}
          width={400}
          zIndex={2010}
          onCancel={() => setDeleteConfirmation(false)}
        >
          <h6  className="confirmation-header-text">Delete Confirm</h6>
          <p className="text-muted pt-1">
            Are you sure you want to delete this behavioral spark category?
          </p>
          <Button type="primary" onClick={proceedDelete} className="me-2 mt-3" >
            Delete
          </Button>
          <Button onClick={() => { setDeleteConfirmation(false) }} className='mt-3'>
            {" "} Cancel{" "}
          </Button>
        </Modal>

        <Modal
          centered
          closable={false}
          open={unsavedChangesModal}
          footer={null}
          width={400}
          zIndex={2010}
          onCancel={() => setUnsavedChangesModal(false)}
        >
          <h6  className="confirmation-header-text">Confirm</h6>
          <p className="text-muted pt-1">
            There are unsaved changes on the screen. Are you sure you want to continue?
          </p>
          <Button type="primary" onClick={onCancelAdd} className="me-2 mt-3" >
            Yes
          </Button>
          <Button onClick={() => { setUnsavedChangesModal(false) }} className='mt-3'>
            {" "} No{" "}
          </Button>
        </Modal>

        <Modal
          centered
          closable={false}
          open={unsavedChangesModel}
          footer={null}
          width={400}
          zIndex={2010}
          onCancel={() => setUnsavedChangesModel(false)}
        >
          <h6  className="confirmation-header-text">Confirm</h6>
          <p className="text-muted pt-1">
            There are some unsaved changes on this screen. Are you sure you want to continue?
          </p>
          <Button type="primary" onClick={onAddProceed} className="me-2 mt-3" >
            Yes
          </Button>
          <Button onClick={() => setUnsavedChangesModel(false)} className="me-2 mt-3" >
            No
          </Button>
        </Modal>
      </div >
    </>)
}
