import React, { useState, useEffect } from "react";
import "./RelatedBiases.scss";
import QuillRichTextEditor from "../../../../../Shared/Components/RichEditor/rich-editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Row, Select, Tooltip, Col, Modal, Button, Spin } from 'antd';
import SVGIcon from "../../../../../Shared/Components/SVGIcon/SVGIcon";
import { ServiceUtils } from "../../../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../../../Shared/Utils/ToasterUtil";
import { generateUniqueId, scrollToDiv } from '../../../../../Shared/Utils/CommonUtil'

export default function RelatedBiases({ biasDetailsData, selectedBiasData, relatedConfigData, updateRelateBiasData, description, mode, saveClicked, ...props }) {

  const [currentBiasContent, setCurrentBiasContent] = useState();
  const [relatedBiasData, setRelatedBias] = useState([]);
  const [relatedConfig, setRelatedConfig] = useState({});
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState();
  const currentBiasEditor = {
    height: "150px",
    display: 'flex',
    flexDirection: 'column'
  }
  const newBias = '<Please enter bias name>';
  const scrollDiv = "#biasDetailsDrawer .ant-drawer-body";

  const relatedBiasEditor = {
    height: "210px",
    display: 'flex',
    flexDirection: 'column'
  }
  const strengthMappingJson = {
    1: "Strong Relation",
    2: "Medium Relation",
    3: "Weak Relation"
  }

  const editorToolBarSetting = {
    // Toolbar customized according to our needs
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link', "image"],
        [{ 'align': [] }], ['divider'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ],
      // handlers: { image: imageHandler() },
    },

    // Divider added separately (customized one)
    divider: { icon: "" },

    // Image resize feature for the image uploaded to the editor
    imageDrop: true,
    blotFormatter: {},
  };

  useEffect(() => {
    setRelatedConfig(relatedConfigData);
    validationOnChange(undefined, relatedConfigData?.biases, relatedConfigData?.relation_strength);
  }, [relatedConfigData])

  useEffect(() => {
    setCurrentBiasContent(biasDetailsData?.content);
    setRelatedBias([...biasDetailsData?.related_biases]);
  }, [biasDetailsData])

  const addNewRelatedBias = () => {
    try {
      let biasData = {
        "bias_id": "",
        "id": "",
        "sec_name": "Related Biases",
        "related_bias_id": null,
        "relation_strength_id": null,
        "content": "",
        'container_id': generateUniqueId()
      }
      let relatedBias = relatedBiasData;
      relatedBias.push(biasData);
      setRelatedBias([...relatedBias]);
      if (typeof updateRelateBiasData === 'function') updateRelateBiasData({ 'type': 'related_biases', 'content': currentBiasContent, 'related_biases': relatedBias });
      setTimeout(() => {
        scrollToDiv(`RBID_${biasData?.container_id}`);
      }, 200)
    } catch (error) {
      console.log("error", error);
    }
  }

  const deleteRelatedBias = (relatedBiasId, relatedBiasIndex) => {
    try {
      setDeleteInfo({
        'relatedBiasId': relatedBiasId,
        'relatedBiasIndex': relatedBiasIndex
      });
      setDeleteConfirmation(true);
    } catch (error) {
      console.log("error", error);
    }

  }
  /**
   * Delete related bias
   */
  const proceedDelete = () => {
    try {
      if (!deleteInfo?.relatedBiasId) {
        deleteItem();
      } else {
        ServiceUtils.postRequest("relatedBiasDelete", { id: deleteInfo?.relatedBiasId }).then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              deleteItem();
              alertService.success(response.data.message || `Successfully deleted Related bias`);
            } else {
              alertService.error(response.data.message || `Error while deleted Related Bias`);
            }
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const deleteItem = () => {
    try {
      relatedBiasData.splice(deleteInfo?.relatedBiasIndex, 1);
      validationOnChange(relatedBiasData);
      setRelatedBias([...relatedBiasData]);
      setDeleteConfirmation(false)
      if (typeof updateRelateBiasData === 'function') updateRelateBiasData({ 'type': 'related_biases', 'related_biases': relatedBiasData });
    } catch (error) {
      console.log("error", error);
    }
  }

  const updateCurrentBiasContent = (content) => {
    try {
      setCurrentBiasContent(content);
      if (typeof updateRelateBiasData === 'function') updateRelateBiasData({ 'type': 'related_biases', 'content': content, 'related_biases': relatedBiasData });
    } catch (error) {
      console.log("error", error);
    }
  }

  const updateRelatedBiasContent = (content, index) => {
    try {
      let tempRelatedBiasData = JSON.parse(JSON.stringify(relatedBiasData));
      tempRelatedBiasData[index]['content'] = content;
      setRelatedBias([...tempRelatedBiasData]);
      if (typeof updateRelateBiasData === 'function') updateRelateBiasData({ 'type': 'related_biases', 'related_biases': tempRelatedBiasData });
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * On Related biases data selection
   * @param {*} event - selected value
   * @param {*} key - bias or strength
   * @param {*} index - related bias index
   */
  const onRelatedBiasChange = (event, key, index) => {
    try {
      let tempRelatedBiasData = JSON.parse(JSON.stringify(relatedBiasData));
      tempRelatedBiasData[index][key] = event;
      if (key === 'related_bias_id') {
        validationOnChange(tempRelatedBiasData);
      }
      setRelatedBias([...tempRelatedBiasData]);
      if (typeof updateRelateBiasData === 'function') updateRelateBiasData({ 'type': 'related_biases', 'content': currentBiasContent, 'related_biases': tempRelatedBiasData });
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To validate selected bias in options 
   * @param {*} relatedBiasesData 
   */
  const validationOnChange = (relatedBiasesData = undefined, optionsList = undefined, strengthData = undefined) => {
    try {
      relatedBiasesData = !relatedBiasesData ? JSON.parse(JSON.stringify(biasDetailsData?.related_biases)) : relatedBiasesData;
      if (relatedBiasesData && relatedBiasesData?.length) {
        let optionList = !optionsList ? JSON.parse(JSON.stringify(relatedConfig?.biases)) : optionsList;
        if (optionList && optionList.length) {
          optionList.map(option => {
            option['disabled'] = false;
            return option;
          });
          relatedBiasesData.forEach(data => {
            optionList.forEach(option => {
              if (option.value === data['related_bias_id']) {
                option['disabled'] = true;
              }
            })
          })
          let tempRelatedConfig = JSON.parse(JSON.stringify(relatedConfig));
          tempRelatedConfig['biases'] = optionList;
          tempRelatedConfig['relation_strength'] = strengthData ? strengthData : relatedConfig['relation_strength'];
          setRelatedConfig(tempRelatedConfig);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <>
      <div className="relatedBias">
        <div className="bias-tab-header">
          <Row className="border-bottom">
            <Col span={16}>
              <div className="p-1 text-muted description" dangerouslySetInnerHTML={{ __html: description }} ></div>
            </Col>
            <Col span={8}>
              {mode === 'edit' && <div className="addLink p-1" >
                <span className="fontColor" onClick={addNewRelatedBias}>
                  <FontAwesomeIcon className="fontIcon" icon={faPlusCircle} />
                  <span> Add Related Bias</span>
                </span>
              </div>}
            </Col>
          </Row>
        </div>
        <div className="related-bias-section mt-2">
          {selectedBiasData?.bias_id && currentBiasContent !== undefined && <div className={`p-2 current-bias`}>
            {selectedBiasData?.bias_name && <span className="biasData p-1 ps-3 bias-name-label">{selectedBiasData?.bias_name}</span>}
            {!selectedBiasData?.bias_name && <span className="biasData p-1">{newBias}</span>}
            <span className="divider">|</span>
            <span className="currentLabel p-1">Current Bias</span>
            <QuillRichTextEditor key={selectedBiasData?.bias_id}
              mode={mode} editorToolBarSetting={editorToolBarSetting}
              editorContent={currentBiasContent}
              editorStyleData={currentBiasEditor}
              updateContent={updateCurrentBiasContent}
              scrollingContainer={scrollDiv}
            />
          </div>}
          {relatedBiasData && relatedBiasData.length !== 0 &&
            relatedBiasData.map((biasData, index) => {
              return (
                <div className="p-2 related-bias" key={'RB_key_' + index} id={'RBID_' + biasData?.container_id}>
                  {mode === 'edit' && <div className="data-selection">
                    <div className="individual-select">
                      Biases
                      <div>
                        <Select style={{ width: '100%' }} placeholder="Select Bias" allowClear={true} value={biasData?.related_bias_id}
                          options={relatedConfig?.biases} onChange={(e) => onRelatedBiasChange(e, 'related_bias_id', index)}
                          showSearch={true}
                          filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                          }></Select>
                        {saveClicked && !biasData?.related_bias_id && <h6 className="error-msg">Please select Bias</h6>}
                      </div>
                    </div>
                    <div className="individual-select">
                      Relation Strength
                      <div>
                        <Select style={{ width: '100%' }} placeholder="Select Relation Strength" allowClear={true} value={biasData?.relation_strength_id} options={relatedConfig?.relation_strength} onChange={(e) => onRelatedBiasChange(e, 'relation_strength_id', index)}></Select>
                        {saveClicked && !biasData?.relation_strength_id && <h6 className="error-msg">Please select Relation Strength</h6>}
                      </div>
                    </div>
                    {mode === 'edit' && <div className="delete align-vertical-center" onClick={() => deleteRelatedBias(biasData?.id, index)}>
                      <Tooltip placement="top" title={'Delete'} color={'black'}>
                        <SVGIcon icon={'delete-fill'} className="fontIcon" />
                      </Tooltip>
                    </div>}
                  </div>}
                  {mode === 'view' &&
                    <div className="p-2">
                      <span className="related-bias-label p-1 f-w-100">{biasData?.related_biases}</span>
                      <span className="divider">|</span>
                      <span className={`f-w-100 ${biasData?.relation_strength_id === 3 ? 'weak-strength' : (biasData?.relation_strength_id === 2 ? 'medium-strngth' : 'strong-strength')} p-1`}>{strengthMappingJson[biasData?.relation_strength_id]}</span>
                    </div>
                  }
                  <div className={"editor " + mode}>
                    {<QuillRichTextEditor key={biasData?.id}
                      mode={mode} editorToolBarSetting={editorToolBarSetting}
                      editorContent={biasData?.content} editorStyleData={relatedBiasEditor}
                      updateContent={(content) => updateRelatedBiasContent(content, index)}
                      scrollingContainer={scrollDiv}
                    />}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <Modal
        centered
        closable={false}
        open={deleteConfirmation}
        footer={null}
        width={400}
        zIndex={2010}
        onCancel={() => setDeleteConfirmation(false)}
      >
        <h6  className="confirmation-header-text" >Delete Confirm</h6>
        <p className="text-muted pt-1">
          Are you sure you would like to delete this Related Bias?
        </p>
        <Button type="primary" onClick={proceedDelete} className="me-2 mt-3" >
          Delete
        </Button>
        <Button onClick={() => { setDeleteConfirmation(false) }} className='mt-3'>
          {" "} Cancel{" "}
        </Button>
      </Modal>
    </>
  );
}
