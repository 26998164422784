import React, { useState, useEffect } from "react";
import { TreeSelect, Input, notification } from 'antd';
import { ServiceUtils } from "../../../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../../../Shared/Utils/ToasterUtil";
import "./ProjectsAttributes.scss";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import SVGIcon from "../../../../../Shared/Components/SVGIcon/SVGIcon";
import { Button, Modal, Tooltip } from 'antd';
import { useLocation } from "react-router-dom";



export default function TargetAudienceBehavioralObject(props) {
    const [targetAudienceList, setTargetAudienceList] = useState([]);
    const [boCategoryList, setBoCategoryList] = useState([]);
    const { TextArea } = Input;
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [deleteItemData, setDeleteData] = useState({});
    const NOTIFY_DURATION = 5;
    const [audienceObjectiveFormConfig, setAudienceObjectiveFormConfig] =
        useState({
            formData: [
                {
                    specialty_id: null,
                    behavioral_objectives: [
                        {
                            behavioral_id: null,
                            description: "",
                            objective_id: 0
                        },
                    ],
                },
            ],
        });
    const location = useLocation();
    const path = location.pathname;
    const [showAlert, setShowAlert] = useState({ target: true, beh_obj: true });

    useEffect(() => {
        let routeData = path.split("/");
        if (routeData.length === 5 && routeData[3] === 'details') {
            fetchBehavioralObjectCategory(routeData[4]);
            fetchTargetAudience(routeData[4]);
        } else {
            fetchBehavioralObjectCategory();
            fetchTargetAudience();
        }
    }, [location]);


    useEffect(() => {
        if (props?.targetData && targetAudienceList?.length && boCategoryList?.length) {
            const form = props?.targetData;
            let filteredTargetAudienceList = validationOnChange(form, targetAudienceList, 'specialty_id');
            if (filteredTargetAudienceList) {
                setTargetAudienceList([...filteredTargetAudienceList]);
            } else {
                setTargetAudienceList([...targetAudienceList]);
            }
            form.map(obj => {
                let filteredBoCategoryList = validationOnChange(obj['behavioral_objectives'], boCategoryList, 'behavioral_id');
                obj['boCategoryList'] = filteredBoCategoryList;
            });
            setAudienceObjectiveFormConfig({
                ...audienceObjectiveFormConfig,
                formData: form,
            });
        }
    }, [props.targetData, targetAudienceList.length, boCategoryList.length])

    useEffect(() => {
        setShowAlert({ target: true, beh_obj: true });
    }, []);

    /**
     * To fetch Behavioral object category data
     */
    const fetchBehavioralObjectCategory = (projectId = undefined) => {
        let params = projectId ? `?project_id=${projectId}` : undefined;
        ServiceUtils.getRequest("boListGroup", params).then((response) => {
            try {
                if (response.status === 200) {
                    if (response.data.status === "success") {
                        const result = response?.data?.data;
                        const objective = result?.behavioral_objective_category_group;
                        objective.map(obj => {
                            obj['selectable'] = false;
                        });
                        setBoCategoryList(objective);
                        let data = audienceObjectiveFormConfig.formData;
                        data.map(obj => {
                            obj['boCategoryList'] = JSON.parse(JSON.stringify(objective));
                        });
                        setAudienceObjectiveFormConfig({ ...audienceObjectiveFormConfig, formData: data });
                    } else {
                        alertService.error(
                            response.data.message ||
                            `Error while fetching behavioral objective category list`
                        );
                    }
                }
            } catch (error) {
                console.log(error);
            }
        });
    };

    /**
     * To fetch target audience data
     */
    const fetchTargetAudience = (projectId = undefined) => {
        let params = projectId ? `?project_id=${projectId}` : undefined;
        ServiceUtils.getRequest("targetAudienceList", params).then((response) => {
            try {
                if (response.status === 200) {
                    if (response.data.status === "success") {
                        const result = response?.data?.data;
                        const objective = result?.targeted_audience;
                        objective.map(obj => {
                            obj['selectable'] = false;
                        });
                        setTargetAudienceList(objective);
                    } else {
                        alertService.error(
                            response.data.message ||
                            `Error while fetching behavioral objective category list`
                        );
                    }
                }
            } catch (error) {
                console.log(error);
            }
        });
    };

    /**
     * To add new target audience
     */
    const addTargetAudienceField = () => {
        try {
            const form = audienceObjectiveFormConfig?.formData;
            if (form) {
                form.push({
                    specialty_id: null,
                    boCategoryList: boCategoryList,
                    behavioral_objectives: [
                        {
                            behavioral_id: null,
                            description: "",
                            objective_id: 0
                        },
                    ],
                });
            }
            setAudienceObjectiveFormConfig({
                ...audienceObjectiveFormConfig,
                formData: form,
            });
            props.parentCall(validateInput(JSON.parse(JSON.stringify({ 'formData': form }))));
        } catch (error) {
            console.log("error", error);
        }

    };

    /**
     * To add new behavioral objective  
     * @param {*} index - target objective index
     * @returns 
     */
    const addBehavioralObjectiveInputField = (index) => {
        try {
            if (index < 0) {
                return;
            }
            const form = audienceObjectiveFormConfig?.formData;
            if (form[index]) {
                form[index]?.behavioral_objectives.push({
                    behavioral_id: null,
                    description: "",
                    objective_id: 0
                });
            }
            setAudienceObjectiveFormConfig({
                ...audienceObjectiveFormConfig,
                formData: form,
            });
            props.parentCall(validateInput(JSON.parse(JSON.stringify({ 'formData': form }))));
        } catch (error) {
            console.log("error", error);
        }

    };

    const deleteItem = () => {
        try {
            removeObjectiveField(deleteItemData);
            setDeleteConfirmation(false);
        } catch (error) {
            console.log("error", error);
        }
    }

    const removeItem = (parentIndex, index) => {
        try {
            let deleteData = {
                parentIndexData: parentIndex,
                indexData: index
            }
            setDeleteData(deleteData);
            if (props?.projectId) {
                setDeleteConfirmation(true);
            } else {
                removeObjectiveField(deleteData);
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * On delete of target or behavioral objective
     * @param {*} parentIndex - target index
     * @param {*} index - behavioral objective index
     * @returns 
     */
    const removeObjectiveField = (deleteData) => {
        try {
            let parentIndex = deleteData?.parentIndexData;
            let index = deleteData?.indexData;
            if (parentIndex < 0 || index < 0) {
                return;
            }
            const form = [...audienceObjectiveFormConfig?.formData];
            if (form[parentIndex] && form[parentIndex]?.behavioral_objectives.length > 1) {
                form[parentIndex]?.behavioral_objectives.splice(index, 1);
                let filteredBoCategoryList = validationOnChange(form[parentIndex]['behavioral_objectives'], form[parentIndex]['boCategoryList'], 'behavioral_id');
                form[parentIndex]['boCategoryList'] = filteredBoCategoryList;
            } else if (form[parentIndex]?.behavioral_objectives.length === 1) {
                form.splice(parentIndex, 1);
                let filteredTargetAudienceList = validationOnChange(form, targetAudienceList, 'specialty_id');
                setTargetAudienceList(filteredTargetAudienceList);
            }
            setAudienceObjectiveFormConfig({
                ...audienceObjectiveFormConfig,
                formData: form,
            });
            setDeleteData({});
            props.parentCall(validateInput(JSON.parse(JSON.stringify({ 'formData': form }))));
        } catch (error) {
            console.log("error", error)
        }

    };

    /**
     * On selection of values from tree select
     * @param {*} value - changes value
     * @param {*} parentIndex - target index
     * @param {*} childIndex - behavior objective index
     */
    const handleTargetSelect = (value, parentIndex, childIndex = undefined) => {
        try {
            const form = audienceObjectiveFormConfig?.formData;
            if (childIndex === undefined) {
                form[parentIndex]['specialty_id'] = value;
                let filteredTargetAudienceList = validationOnChange(form, targetAudienceList, 'specialty_id');
                setTargetAudienceList(filteredTargetAudienceList);
            } else {
                if (form[parentIndex]['behavioral_objectives'][childIndex]) {
                    form[parentIndex]['behavioral_objectives'][childIndex]['behavioral_id'] = value;
                }
                let filteredBoCategoryList = validationOnChange(form[parentIndex]['behavioral_objectives'], form[parentIndex]['boCategoryList'], 'behavioral_id');
                form[parentIndex]['boCategoryList'] = filteredBoCategoryList;
            }
            setAudienceObjectiveFormConfig({
                ...audienceObjectiveFormConfig,
                formData: form,
            });
            props.parentCall(validateInput(JSON.parse(JSON.stringify({ 'formData': form }))));
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * To validate options(disabling selected one)
     * @param {*} Data - target or behavioral objectives data
     * @param {*} optionList - option list
     * @param {*} key - key type
     * @returns 
     */
    const validationOnChange = (Data, optionList, key) => {
        try {
            if (optionList && optionList.length) {
                let tempOptionList = JSON.parse(JSON.stringify(optionList));
                tempOptionList.map(options => {
                    options?.children.map(option => {
                        option['disabled'] = false;
                    });
                })
                Data.forEach(targetData => {
                    tempOptionList.forEach(target => {
                        target?.children.map((ele) => {
                            if (ele.value === targetData[key]) {
                                ele['disabled'] = true;
                            }
                        });
                    })
                })
                return tempOptionList;
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * On value change of description
     * @param {*} event - change event
     * @param {*} parentIndex - target index
     * @param {*} childIndex - behavioral objective index
     */
    const onChange = (event, parentIndex, childIndex) => {
        try {
            const form = audienceObjectiveFormConfig?.formData;
            if (form[parentIndex]['behavioral_objectives'][childIndex]) {
                form[parentIndex]['behavioral_objectives'][childIndex]['description'] = event?.target?.value.replace(/^\s+/g, '');
            }
            setAudienceObjectiveFormConfig({
                ...audienceObjectiveFormConfig,
                formData: form,
            });
            props.parentCall(validateInput(JSON.parse(JSON.stringify({ 'formData': form }))));
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * To form create input
     * @param {*} data - input data
     * @returns - returns valid input
     */
    const validateInput = (data) => {
        try {
            data.formData.map(obj => {
                if (obj['boCategoryList']) {
                    delete obj['boCategoryList'];
                }
            })
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }

    /**
     * To show warning message in edit state
     * @param {*} type - target or behavioral objective
     */
    const onDropDownOpen = (open, type) => {
        try {
            if (!open) {
                return;
            }
            let isNotify = false;
            if (props?.projectId && showAlert?.target && type === 'target') {
                showAlert['target'] = false;
                isNotify = true;
            } else if (props?.projectId && showAlert?.beh_obj && type === 'beh_obj') {
                showAlert['beh_obj'] = false;
                isNotify = true;
            }
            if (isNotify) {
                notifyEditEffectMsg();
                setShowAlert({ ...showAlert });
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const notifyEditEffectMsg = () => {
        notification.destroy();
        notification.info({
            description:
                'Editing this field will effect the rest of the project',
            placement: 'top',
            style: {
                width: "calc(100vw - 25px)",
                backgroundColor: '#e2a935',
                color: 'white',
                marginLeft: '48px',
                padding: '10px 30px 15px',
            },
            top: 0,
            duration: NOTIFY_DURATION
        });
    }

    const behObjTitle = () => {
        return(
            <React.Fragment>
                <div>Input the behavioral objective for your project.</div>
                <div style={{height:'8px'}}></div>
                <div>Guidelines for writing behavioral objectives can be found below:</div>
                <div>1. Audience: "Oncologists treating second line lung cancer patients"</div>
                <div>2. Action: "will think of Product X first when they think of IO in 2L lung cancer"</div>
                <div>3. Outcome: "hence increasing Product X description"</div>
            </React.Fragment>
        )
    }

    return (
        <div className="target-audience">
            <p className="targetAudienceText">Target Audience and Behavioral Objective</p>
            <div className="row">
                <div className="col-3">
                    <label className="target-audience-label"> Target Audience</label>
                    <Tooltip placement="top" title={'The respondent type being studied in this research (e.g., HCP, Patient, Payer, etc.)'} overlayClassName={'tooltip-bg'}>
                        <SVGIcon icon={'info-fill'} className="m-0 ps-1 fontCircleInfo" />
                    </Tooltip>
                </div>
                <div className="col-9">
                    <div className="row">
                        <div className="col-4">
                            <label className="target-audience-label">Behavioral Objective Category</label>
                            <Tooltip placement="top" title={'Broad categorization that explains the high-level objective of your project'} overlayClassName={'tooltip-bg'}>
                                <SVGIcon icon={'info-fill'} className="m-0 ps-1 fontCircleInfo" />
                            </Tooltip>
                        </div>
                        <div className="col-7">
                            <label className="target-audience-label">Behavioral Objective</label>
                            <Tooltip placement="topLeft" title={behObjTitle()}  overlayClassName={'beh-obj-title tooltip-bg'}>
                                <SVGIcon icon={'info-fill'} className="m-0 ps-1 fontCircleInfo" />
                            </Tooltip>
                        </div>
                        <div className="col-1">

                        </div>

                    </div>

                </div>
            </div>
            <hr className="main-line"></hr>
            {audienceObjectiveFormConfig?.formData?.map((item, i) => {
                return (<>
                    <div className="row" key={'target_key' + i}>
                        <div className="col-3">
                            <div className={`p-24`}>
                                <TreeSelect showSearch style={{ width: '100%' }} dropdownStyle={{ maxHeight: 400, overflow: 'auto', }} className={`${(props?.saveClicked && !item?.specialty_id) ? 'error-section' : ''}`}
                                    treeNodeFilterProp={'title'} treeData={targetAudienceList} placeholder={props?.projectId ? "Target Audience- Specialty" : 'Select'} allowClear={true}
                                    value={item?.specialty_id} maxTagCount={1} treeNodeLabelProp='label'
                                    onChange={(value) => handleTargetSelect(value, i)} onDropdownVisibleChange={(open) => onDropDownOpen(open, 'target')}
                                />
                                {(props?.saveClicked && !item?.specialty_id) && <h6 className="error-msg">Please select at least one value for target audience-specialty</h6>}
                            </div>
                        </div>
                        <div className="col-9 row pe-0">
                            {item?.behavioral_objectives.map((items, index) => {
                                return (
                                    <>
                                        <div className={`mb-4 row `} key={'beh_obj_key' + index}>
                                            <div className={`col-4 p-24`} >
                                                <TreeSelect showSearch style={{ width: '100%' }} dropdownStyle={{ maxHeight: 400, overflow: 'auto', }}
                                                    treeNodeFilterProp={'title'} className={`${(props?.saveClicked && !items?.behavioral_id) ? 'error-section' : ''}`}
                                                    treeData={item?.boCategoryList} placeholder={props?.projectId ? "Behavioral Objective Category Group- Behavioral Objective Category" : 'Select'} allowClear={true}
                                                    value={items?.behavioral_id} maxTagCount={1} onDropdownVisibleChange={(open) => onDropDownOpen(open, 'beh_obj')}
                                                    onChange={(value) => handleTargetSelect(value, i, index)} treeNodeLabelProp='label'
                                                />
                                                {(props?.saveClicked && !items?.behavioral_id) && <h6 className="error-msg">Please select at least one value for behavioral objective category group- behavioral objective category</h6>}
                                            </div>
                                            <div className={`col-7 p-24`} >
                                                <TextArea rows={3} maxLength={200} placeholder="Enter Behavioral Objective" value={items?.description} onChange={(event) => onChange(event, i, index)}
                                                    className={`${(props?.saveClicked && !items?.description) ? 'error-section' : ''}`} />
                                                {(props?.saveClicked && !items?.description) && <h6 className="error-msg">Please enter the behavioral objective</h6>}
                                            </div>
                                            <div className={`col-1 p-24`}>
                                                {
                                                    (audienceObjectiveFormConfig?.formData?.length === 1 && audienceObjectiveFormConfig?.formData[0]['behavioral_objectives'].length === 1) ?
                                                        (<SVGIcon className="target-delete-icon" icon={'delete-disabled'} />) :
                                                        (
                                                            <Tooltip placement="top" title={'Delete'} overlayClassName={'tooltip-bg'}>
                                                                {<SVGIcon icon={'delete-fill'} className="fontIcon mr-1" onClick={() => { removeItem(i, index); }} />}
                                                            </Tooltip>)
                                                }
                                            </div>
                                        </div>
                                        <hr className="vertical-line"></hr>
                                    </>
                                );
                            })}
                            <div className="col-12 pt-1">
                                <div className="me-2 d-flex fontColor beh-obj" onClick={() => addBehavioralObjectiveInputField(i)}>
                                    <FontAwesomeIcon className="fontIcon" icon={faPlusCircle} />
                                    <span className="addText pb-1"> Add Behavioral Objective</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="target-line"></hr></>
                )
            })}
            <div className="pb-4 mb-4 mt-3">
                <Button className="me-2 d-flex fontColor" onClick={addTargetAudienceField}>
                    <FontAwesomeIcon icon={faCirclePlus} className="fontIcon text-light " />
                    <span className="addText pb-1"> Add Target Audience</span> </Button>
            </div>

            <Modal
                centered
                closable={false}
                open={deleteConfirmation}
                footer={null}
                width={400}
                zIndex={2010}
                onCancel={() => setDeleteConfirmation(false)}
            >
                <h6  className="confirmation-header-text">Delete Confirm</h6>
                <p className="text-muted pt-1">
                    Are you sure you would like to delete?
                </p>
                <Button type="primary" onClick={deleteItem} className="me-2 mt-3" >
                    Delete
                </Button>
                <Button onClick={() => { setDeleteConfirmation(false) }} className='mt-3'>
                    {" "} Cancel{" "}
                </Button>
            </Modal>
        </div>)
}
