import { Button, Comment, Form, Input, List, Spin, Tooltip } from 'antd';
import moment from 'moment';
import { useState, useEffect } from 'react';
import Avatar from "react-avatar";
import SVGIcon from "../SVGIcon/SVGIcon";
import { LoadingOutlined } from '@ant-design/icons';
import './CustomComment.scss';
import {VARIABLES} from "../../../Environments/constants";

const { TextArea } = Input;

const loadingAntIcon = (
    <>
        <div className='reply-loading-container pb-2 text-primary'>
            <LoadingOutlined
                style={{
                    fontSize: '1rem',
                    verticalAlign: 'middle',
                }}
                spin
            /> &nbsp; Loading replies
        </div>
    </>
);

const currentUserFullName = () => {
    const userDetails = JSON.parse(localStorage.getItem(VARIABLES.userSession));
    return `${userDetails?.first_name} ${userDetails?.last_name}`;
};

const getUserFullName = (comment) => {
    return `${comment?.user_first_name} ${comment?.user_last_name}`
}

const getFormatedDate = (dateTime) => {
    try {
        moment.locale('en');
        let tempValue = moment.utc(dateTime).local()?.format("MMM D, YYYY | h:mm:ss A");
        return tempValue?.toString()?.trim();
    } catch (error) {
        console.log("error", error);
    }
};

const getActions = (comment, onToggleLoadReplies, onToggleReplyToClick, isAddNewComment) => {

    const actions = [
        <span key="comment-date" className='cursor-text'>{getFormatedDate(comment?.time)}</span>
    ];
    if (comment.level <= 1) {
        if (isAddNewComment) {
            actions.push(<span key="comment-basic-reply-to" className='reply-btn-color' onClick={() => onToggleReplyToClick({ action: 'show', comment })}>Reply</span>);
        }
        actions.push(<SVGIcon icon={'horizontal-line'} />);
        actions.push(<span></span>);
        if (comment.showReply) {
            actions.push(<span key="comment-hide-replies" onClick={() => onToggleLoadReplies({ action: 'hide', comment })}>Hide replies</span>);
        } else {
            actions.push(<span key="comment-show-replies" onClick={() => onToggleLoadReplies({ action: 'show', comment })}>Show replies</span>);
        }
    }
    return actions;
};

const EachComment = ({ commentData, children, onToggleLoadReplies,
    onToggleReplyToClick, isAddNewComment, isRestore, isArchive,
    onArchive = undefined, onRestore = undefined
}) => (
    <Comment className='custom-comment'
        actions={getActions(commentData, onToggleLoadReplies, onToggleReplyToClick, isAddNewComment)}
        author={
            <>
                <span className='user-name'>{commentData?.user_name}</span>
                {isArchive &&
                    <>
                        <Tooltip
                            placement="left"
                            title={'Resolve'}
                        >
                            <SVGIcon icon={'archive-fill'} onClick={() => onArchive({ comment: commentData })} className={'pull-right cursor-pointer'}></SVGIcon>
                        </Tooltip>
                    </>
                }
                {isRestore &&
                    <>
                        <Tooltip
                            placement="left"
                            title={'Restore'}
                        >
                            <SVGIcon icon={'comment-restore'} onClick={() => onRestore({ comment: commentData })} className={'pull-right cursor-pointer'}></SVGIcon>
                        </Tooltip>
                    </>
                }
            </>
        }
        avatar={<Avatar className="avtar" name={getUserFullName(commentData)} maxInitials={2} />}
        content={
            <p>
                {commentData?.comment}
            </p>
        }
    >
        {children}
    </Comment>
);

const CommentList = ({
    comments, onChange, onSubmit, onLoadReplyToggle,
    onSaveComment, submitting, value, parentComment,
    onToggleLoadReplies, onToggleReplyTo, onToggleReplyToClick,
    isAddNewComment, isArchive, isRestore, onArchive, onRestore, visible }) => (
    <>
        {/* Reply loading icon */}
        {parentComment && comments && comments.length === 0 && parentComment.isLoading && <Spin indicator={loadingAntIcon} />}

        {/* No Replies message */}
        {parentComment && comments && comments.length === 0 && !parentComment.isLoading && parentComment.showReply && <p className='text-muted'><i>No replies found</i></p>}
        
        {/**New comments - parent comment*/}
        {isAddNewComment && parentComment && parentComment.addComment &&
            <>
                <NewComment onChange={onChange} onSubmit={onSubmit} submitting={submitting} value={value} parentComment={parentComment} onToggleReplyToClick={onToggleReplyToClick}/>
            </>
        }


        {/**Replies list */}
        {comments && comments.length > 0 && (!parentComment || parentComment.showReply) &&
            <>
                <Spin spinning={(parentComment?.isLoadingNewComment) ? true : false}>
                    <List
                        dataSource={comments}

                        itemLayout="horizontal"
                        renderItem={(item) => (
                            <>
                                <EachComment
                                    commentData={item}
                                    onToggleLoadReplies={onToggleLoadReplies}
                                    onToggleReplyToClick={onToggleReplyToClick}
                                    isAddNewComment={isAddNewComment}
                                    isArchive={isArchive}
                                    isRestore={isRestore}
                                    onArchive={onArchive}
                                    onRestore={onRestore}
                                    children={
                                        (item.level <= 1) ?

                                            <CustomComment
                                                visible={visible}
                                                data={item?.reply}
                                                onToggleReplyTo={onToggleReplyTo}
                                                onLoadReplyToggle={onLoadReplyToggle}
                                                onSaveComment={onSaveComment}
                                                parentComment={item}
                                                isAddNewComment={isAddNewComment}
                                            />
                                            :
                                            ''
                                    }
                                />
                            </>
                        )}
                    />
                </Spin>
            </>
        }

        {/**New comments - parent comment*/}
        {isAddNewComment && !parentComment &&
            <>
                <NewComment onChange={onChange} onSubmit={onSubmit} submitting={submitting} value={value} parentComment={parentComment} onToggleReplyToClick={onToggleReplyToClick}/>
            </>
        }
    </>
);

const NewComment = ({onChange, onSubmit, submitting, value, onToggleReplyToClick, parentComment}) => (
    <>
        <Comment
            avatar={<Avatar className="avtar" name={currentUserFullName()} maxInitials={2} />}
            content={
                <>
                    <Form.Item className='mb-0'>
                        <TextArea maxLength={200} rows={3} onChange={onChange} value={value} placeholder='Enter a comment here' />
                        <p className="count_para text-muted text-align-right">{value?.trim().length} / 200</p>
                    </Form.Item>
                    <Form.Item className='mb-0'>
                        <Button htmlType="submit" disabled={value?.trim().length === 0} loading={submitting} onClick={() => onSubmit({ parentComment })} type="primary">
                            Add Comment
                        </Button>
                        {parentComment &&
                            <Button htmlType="button" className='ms-2' onClick={() => onToggleReplyToClick({ action: 'hide', comment: parentComment })} type="secondary">
                                Close
                            </Button>
                        }
                    </Form.Item>
                </>
            }
        />
    </>
);

const CustomComment = ({
    data, parentComment, onSaveComment,
    onLoadReplyToggle, onToggleReplyTo, isAddNewComment = true,
    isArchive = false, isRestore = false, onArchiveComment, onRestoreComment, visible
}) => {
    const [comments, setComments] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [value, setValue] = useState('');

    const handleSubmit = ({
        parentComment
    }) => {
        if (!value) return;
        if (typeof onSaveComment === 'function') {
            setSubmitting(true);
            onSaveComment(
                parentComment,
                value.trim(),
                (isSuccess, savedComment) => {
                    setSubmitting(false);
                    if (!isSuccess) return;
                    setValue('');
                    if (parentComment) {
                        handleToggleLoadReplies({ action: 'show', comment: parentComment }); // load child comments
                    }
                },
            )
        }
    };

    useEffect(() => {
        setComments([...data]);
    }, [data])

    useEffect (() => {
        setValue("");
    }, [visible])

    
    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const handleToggleLoadReplies = ({ action, comment }) => {
        if (typeof onLoadReplyToggle === 'function') {
            onLoadReplyToggle(
                action,
                comment
            )
        }
    };

    const handleToggleReplyTo = ({ action, comment }) => {
        setValue("");
        if (typeof onToggleReplyTo === 'function') {
            onToggleReplyTo(
                action,
                comment
            )
        }
    };

    const handleArchive = ({ comment }) => {
        if (typeof onArchiveComment === 'function') {
            onArchiveComment(
                { comment }
            )
        }
    };

    const handleRestore = ({ comment }) => {
        if (typeof onRestoreComment === 'function') {
            onRestoreComment(
                { comment }
            )
        }
    };

    return (
        <>
            <div className={`custom-comment-layout ${!parentComment ? 'px-3' : ''}`} >
                {
                    <CommentList comments={comments} onChange={handleChange}
                        onSubmit={handleSubmit}
                        submitting={submitting}
                        parentComment={parentComment}
                        value={value}
                        onSaveComment={onSaveComment}
                        onLoadReplyToggle={onLoadReplyToggle}
                        onToggleReplyTo={onToggleReplyTo}
                        onToggleReplyToClick={handleToggleReplyTo}
                        onToggleLoadReplies={handleToggleLoadReplies}
                        isAddNewComment={isAddNewComment}
                        isArchive={isArchive}
                        isRestore={isRestore}
                        onArchive={handleArchive}
                        onRestore={handleRestore}
                        visible = {visible}
                    />
                }

            </div>
        </>
    );
};

export default CustomComment;