import React, { useEffect, useState, useRef } from "react";
import "./CandidateBiases.scss";
import { Button, Tooltip, Row, Col, Select, Input, Drawer, Table, Modal, Popover, Checkbox, Spin } from 'antd';
import { defaultAntSort, searchTable } from "../../../../../Shared/Utils/CommonUtil";
import SVGIcon from "../../../../../Shared/Components/SVGIcon/SVGIcon";
import { DebounceInput } from 'react-debounce-input';
import BiasesDetails from '../../../Biases/BiasesDetails/BiasesDetails';
import { ServiceUtils } from "../../../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../../../Shared/Utils/ToasterUtil";

export default function CandidateBiases({ projectId, navigateStep, ...props }) {

  const descriptionText = "The Suggested Biases below are based on historical projects with similar Project Attributes as this project, particularlly Target Audience and Behavioral Objective Category. You can adjust these filters below to   focus on Suggested Biases for a particulat target audience, for example, Select the suggested Biases that you want to include in your testing, For each suggested Bias, BrainCase also shows Related Biases; You can also select these related biases to test."
  const [targetAudienceList, setTargetAudienceList] = useState();
  const [boCategoryList, setBoCategoryList] = useState();
  const [searchValue, setSearchValue] = useState({ "target": "", "beh-obj": "" });
  const [refreshSelect, setRefreshSelect] = useState({ "target": true, "beh-obj": true });
  const [otherTargetSearch, setotherTargetSearch] = useState({});
  const [resetOtherTargetSearch, setResetOtherTargetSearch] = useState({});
  const [showSelectedBias, setShowSelectedBias] = useState(false);
  const [showOtherBias, setShowOtherBias] = useState(false);
  const [selectedBiases, setSelectedBiases] = useState({ "bkpData": [], "selectedData": [] });
  const [suggestedBiasData, setSuggestedBiasData] = useState([]);
  const [suggestedBkpData, setSuggestedBkpData] = useState([]);
  const [otherBiasData, setOtherBiasData] = useState({ "bkpData": [], "otherData": [] });
  const [tableDataText, setTableDataText] = useState('No Data Found');
  const [searchText, setSearchText] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState([]);
  const [selectedBeh, setSelectedBeh] = useState([]);
  const [sortIcon, setSortIcon] = useState({ "sortColumn": "", "sortOrder": "" });
  const [selectBiasCount, setSelectedBiasCount] = useState();
  const childRef = useRef();
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [targetsFilterList, setTargetsFilterList] = useState();
  const [filterList, setFilterList] = useState([]);
  const [filterBkpList, setFilterBkpList] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const [bkpBoCategoryList, setBkpBoCategoryList] = useState();
  const [suggestedBiasLoader, setSuggestedBiasLoader] = useState(true);

  /**
   * On filter popover close
   * @param {*} visible - true or false
   */
  const handleVisibleChange = (visible) => {
    setShowFilter(visible);
    if (!visible) {
      setFilterList([...filterBkpList]);
    }
  };

  /**
   * Apply filter
   */
  const applyFilter = () => {
    try {
      setFilterBkpList([...filterList]);
      filterBiasData(filterList);
      setShowFilter(false);
    } catch (error) {
      console.log("error", error);
    }
  }

  const filterBiasData = () => {
    try {
      if (filterList?.length && !filterList?.includes("all")) {
        let entireData = JSON.parse(JSON.stringify(selectedBiases?.bkpData));
        let filteredData = entireData?.filter((bias) => {
          let filtered = false;
          let biasList = [];
          bias?.details.filter((bias) => {
            if (filterList.includes(bias?.target_audience_id)) {
              filtered = true
              biasList.push(bias);
            }
          });
          if (filtered) {
            bias['details'] = biasList;
            return bias;
          }
        });
        setSelectedBiases({ ...selectedBiases, 'selectedData': [...filteredData] });
      } else {
        setSelectedBiases({ ...selectedBiases, 'selectedData': [...selectedBiases?.bkpData] });
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * On target select change
   * @param {*} selectedvalues - selected values
   */
  const onChange = (selectedvalues) => {
    if (filterList && (filterList?.length === 0 || (selectedvalues?.length === 1 && selectedvalues?.includes("all")) || (!filterList?.includes("all") && !selectedvalues?.includes("all")))) {
      setFilterList([...selectedvalues]);
    } else if (filterList?.length !== 0 && !filterList?.includes("all") && selectedvalues?.includes("all")) {
      setFilterList(["all"]);
    } else if (filterList?.length === 1 && filterList?.includes("all")) {
      let ids = selectedvalues.filter(id => id !== "all");
      setFilterList(ids);
    }
  };

  /**
   * Filter options
   * @returns - options
   */
  const filterContent = () => {
    return (<>
      <div className="options-list">
        {showFilter && <Checkbox.Group onChange={onChange} value={filterList}>
          {targetsFilterList && targetsFilterList.map(option => { return (<span style={{ display: 'block' }} title={option?.label} className={`${filterList && filterList?.length && filterList?.includes(option?.value) ? "checked-item" : ""}`}><Checkbox value={option?.value}>{option?.label}</Checkbox></span>) })}
        </Checkbox.Group>}
      </div>
      <hr style={{ padding: '0px 25px 0px 25px', margin: '0 0 0 0' }}></hr>
      <footer className="d-md-flex" style={{ padding: '10px 25px 10px 25px', color: 'grey' }}>
        <Button type="primary" className="me-3" onClick={applyFilter}>
          Add Filters
        </Button>
        <Button className="me-1" onClick={() => handleVisibleChange(false)}>
          Cancel
        </Button>
      </footer>
    </>
    )
  }

  /**
   * Custom columns of view selected all table
   * @returns 
   */
  const customColumns = () => {
    return (
      <Row>
        <Col span={5}>
          Association
        </Col>
        <Col span={5}>
          Confidence
        </Col>
        <Col span={12}>
          Target Audience
          <Popover
            placement="bottom"
            arrowPointAtCenter={true}
            overlayClassName="candidate-bias-filter-Popover"
            content={filterContent}
            title="Target Audience Filter"
            trigger="click"
            open={showFilter}
            onOpenChange={handleVisibleChange}
          >
            <SVGIcon className={`${filterBkpList?.length === 0 ? "filter-icon" : "filtered-icon"}`} icon={'filter'} />
          </Popover>
        </Col>
      </Row>
    )
  }

  /**
  * Delete selected bias
  * @param {*} index - bias index
  */
  const deleteSelectedBias = (biasIndex, index) => {
    try {
      setDeleteConfirmation(true);
      setDeleteInfo({ 'biasIndex': biasIndex, 'index': index });
    } catch (error) {
      console.log("error", error);
    }
  }

  const deleteBias = () => {
    try {
      let biasIndex = deleteInfo?.biasIndex;
      let index = deleteInfo?.index;
      if (selectedBiases?.selectedData[biasIndex]['details'] && selectedBiases?.selectedData[biasIndex]['details'][index]) {
        ServiceUtils.postRequest("deleteSelected", { "id": selectedBiases?.selectedData[biasIndex]['details'][index]['id'], "project_id": projectId }).then((response) => {
          try {
            if (response.status === 200) {
              if (response?.data?.status === "success") {
                setDeleteConfirmation(false);
                deleted.push(selectedBiases?.selectedData[biasIndex]['details'][index]['id']);
                setDeleted(deleted);
                setSelectedBiasCount(response?.data?.data?.count);
                let id = selectedBiases?.selectedData[biasIndex]['details'][index]?.id;
                selectedBiases?.selectedData[biasIndex]['details'].splice(index, 1);
                if (selectedBiases?.selectedData[biasIndex]['details'].length === 0) {
                  selectedBiases?.selectedData.splice(biasIndex, 1);
                }
                selectedBiases?.bkpData.map(biasList => {
                  biasList?.details.map((bias, index) => {
                    if (bias?.id === id) {
                      biasList?.details.splice(index, 1);
                    }
                  })
                  return biasList;
                })
                setSelectedBiases({ "bkpData": [...selectedBiases?.bkpData], "selectedData": [...selectedBiases?.selectedData] });
                alertService.success(response.data.message);
              } else {
                alertService.error(
                  response.data.message ||
                  `Error while deleting selected bias`
                );
              }
            }
          } catch (error) {
            console.log(error);
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  }


  const viewSelectedBiascolumns = [
    {
      title: "Biases",
      dataIndex: "bias_name",
      key: "bias_name",
      defaultSortOrder: "ascend",
      sorter: (a, b) => defaultAntSort(a.bias_name, b.bias_name),
      render: (_, record) => (<>
        {record?.bias_name}
        <Tooltip color={'black'} title={biasTooltip(record)}>
          <SVGIcon icon={'info-fill'} innerTag="span" className="m-0 ps-1 fontCircleInfo" />
        </Tooltip>
      </>),
      width: 60,
    },
    {
      title: customColumns(),
      dataIndex: "details",
      key: "details",
      width: 240,
      render: (_, record, biasIndex) => {
        return (<>
          {
            record?.details.map((bias, index) => {
              return (<>
                <Row>
                  <Col className="p-1" span={5}>
                    {bias?.association}
                  </Col>
                  <Col className="p-1" span={5}>
                    {bias?.confidence}
                  </Col>
                  <Col className="p-1" span={12}>
                    {bias?.target_audience}
                  </Col>
                  <Col className="p-1 align-vertical-right" span={2}>
                    <Tooltip title="Delete Bias" color={"black"}>
                      <SVGIcon icon={"delete-fill"} className="fontIcon" onClick={() => deleteSelectedBias(biasIndex, index)} />
                    </Tooltip>
                  </Col>
                </Row>
                {index !== record?.details.length - 1 && <hr style={{ paddingTop: '0', marginBottom: '0', marginTop: '8px', color: '#a5a5ad' }}></hr>}
              </>
              )
            })
          }
        </>
        )
      }
    }
  ];

  const otherBiasColumns = [
    {
      title: "Other Biases",
      dataIndex: "bias_name",
      key: "bias_name",
      defaultSortOrder: "ascend",
      sorter: (a, b) => defaultAntSort(a.bias_name, b.bias_name),
      render: (_, record) => (
        <>
          {record?.bias_name}
          <Tooltip color={'black'} title={biasTooltip(record)}>
            <SVGIcon icon={'info-fill'} innerTag="span" className="m-0 ps-1 fontCircleInfo" />
          </Tooltip>
        </>
      ),
      width: 120,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 250,
      render: (_, record, index) => (
        <Row>
          <Col span={6}>
            <Button type={record?.selected ? 'primary' : 'select-btn'} onClick={() => selectOtherBias(record)}>{record?.selected ? 'Selected' : 'Select'}</Button>
          </Col>
          {record?.selected && showOtherBias && <Col span={18}>
            <Select style={{ width: '100%' }} placeholder={"Select Target Audience"}
              className={`${(isSaveClicked && record?.target_audience?.length === 0) ? 'error-section' : ''}`}
              optionFilterProp="label"
              showSearch={false}
              searchValue={otherTargetSearch[record.bias_id]}
              mode="multiple"
              allowClear={true}
              showArrow={true}
              value={record?.target_audience}
              options={record?.targetAudienceList}
              maxTagCount="responsive"
              notFoundContent="No Data Found"
              autoClearSearchValue={false}
              onDropdownVisibleChange={(visible) => { onTargetDropdownVisibleChange(visible, record.bias_id) }}
              onChange={(event) => setSelectedTargetAudience(event, record)}
              dropdownRender={(menu) => (
                <>
                  {(record?.targetAudienceList && record?.targetAudienceList.length > 1) && <div className="select-search-box">
                    {resetOtherTargetSearch[record.bias_id] && <Input placeholder="Search for filter" onChange={(e) => setotherTargetSearch({ ...otherTargetSearch, [record.bias_id]: e?.target?.value })} style={{ width: '100%' }} />}
                  </div>}
                  {(!otherTargetSearch[record.bias_id] || (otherTargetSearch[record.bias_id] && 'All Target Audience'.toLowerCase()?.includes(otherTargetSearch[record.bias_id]))) && (record?.targetAudienceList && record?.targetAudienceList.length > 1) && <div style={{ paddingLeft: '14px', paddingTop: '5px', paddingBottom: "5px", cursor: 'pointer', color: 'black' }} onClick={() => selectAllTargets(record)}>All Target Audience</div>}
                  {menu}
                </>
              )}></Select>
            {isSaveClicked && record?.target_audience?.length === 0 && <h6 className="error-msg" style={{ textAligh: 'left', marginBottom: '0' }}>Please select at Target Audience</h6>}
          </Col>}
        </Row>
      ),
    }
  ];

  useEffect(() => {
    fetchDropdownData(projectId);
  }, [projectId])

  /**
   * To search other bias data
   */
  const searchData = () => {
    try {
      if (searchText.trim().length === 0) {
        setOtherBiasData({ ...otherBiasData, "otherData": [...otherBiasData['bkpData']] });
      } else {
        setSearchLoader(true);
        setOtherBiasData({
          ...otherBiasData, "otherData": searchTable(searchText, otherBiasData['bkpData'], otherBiasColumns),
        }
        );
      }
      setTimeout(() => {
        setSearchLoader(false)
      }, 500);
    } catch (error) {
      console.log("error", error);
    }
  }

  const fetchDropdownData = (project_id) => {
    if (project_id) {
      ServiceUtils.postRequest("fetchCandidateDropdownsdata", { "project_id": project_id }).then((response) => {
        try {
          if (response.status === 200) {
            if (response?.data?.status === "success") {
              if (response?.data?.data?.candidate_bias_dropdowns) {
                let targets = response?.data?.data?.candidate_bias_dropdowns?.target_audience;
                let beh_obj_cat = response?.data?.data?.candidate_bias_dropdowns?.beh_obj;
                setTargetAudienceList([...targets]);
                let targetData = JSON.parse(JSON.stringify(targets));
                let allOption = [{ "label": "All Target Audiences", "value": "all" }];
                setTargetsFilterList([...allOption, ...targetData])
                setBkpBoCategoryList(JSON.parse(JSON.stringify(beh_obj_cat)));
                selectAllTargetAudience(true, targets, beh_obj_cat, project_id);
              }
            } else {
              alertService.error(
                response.data.message ||
                `Error while fetching candidate bias dropdowns data`
              );
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    }
  }

  /**
   * To select all target audience
   */
  const selectAllTargetAudience = (initialLoad = false, targets, beh_obj_cat, project_id = undefined) => {
    try {
      let selectedtargets = [];
      let boCategoryList = [];
      targets.map(target => {
        selectedtargets.push(target?.value);
        if (beh_obj_cat[target?.value]) {
          boCategoryList = [...boCategoryList, ...beh_obj_cat[target?.value]];
        }
        return target;
      });
      setSelectedTarget([...selectedtargets]);
      const data = [...new Map(boCategoryList.map(item => [item.value, item])).values()]
      setBoCategoryList([...data]);
      let selectedBehs = [];
      if (initialLoad) {
        data.map(beh => {
          selectedBehs.push(beh?.value);
          return beh;
        });
        fetchSuggestedBiasdata(project_id, selectedtargets, selectedBehs);
      } else {
        if (selectedBeh && selectedBeh?.length) {
          selectedBeh.map(behId => {
            let index = data.findIndex(item => item.value === behId);
            if (index !== -1) {
              selectedBehs.push(behId)
            }
            return behId;
          })
        }
      }
      setSelectedBeh([...selectedBehs]);
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To fetch suggested bias data
   * @param {*} project_id 
   * @param {*} specialty_id 
   * @param {*} behavioral_id 
   */
  const fetchSuggestedBiasdata = (project_id, specialty_id, behavioral_id) => {
    try {
      setSuggestedBiasLoader(true);
      let payLoad = {
        "project_id": project_id,
        "speciality_id": specialty_id,
        "behavioural_objective": behavioral_id,
      }
      ServiceUtils.postRequest("fetchSuggestedBias", payLoad, false).then((response) => {
        try {
          if (response.status === 200) {
            setSuggestedBiasLoader(false);
            if (response?.data?.status === "success") {
              if (response?.data?.data && response?.data?.data?.candidate_biases) {
                let biasData = response?.data?.data?.candidate_biases;
                setSuggestedBkpData(JSON.parse(JSON.stringify(biasData)));
                setSelectedBiasCount(response?.data?.data?.count);
                setSortIcon({ "sortColumn": 'bias_name', "sortOrder": "asc" });
                if (biasData?.length) {
                  biasData.map(target => {
                    target['biases'].sort((a, b) => a['bias_name'].toLowerCase() > b['bias_name'].toLowerCase() ? 1 : -1);
                    return target;
                  });
                }
                setSuggestedBiasData([...biasData]);
              }
            } else {
              alertService.error(
                response.data.message ||
                `Error while fetching suggested biases data`
              );
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * On select and deselect save bias data
   * @param {*} biasData bias data
   */
  const biasSelectionChange = (biasData, speciality_id, targetIndex, biasIndex) => {
    let payLoad = {
      "project_id": projectId,
      "selected_bias_details": biasData
    }
    payLoad['selected_bias_details']['speciality_id'] = speciality_id;
    ServiceUtils.postRequest("selectDeselectBias", payLoad).then((response) => {
      try {
        if (response.status === 200) {
          if (response?.data?.status === "success") {
            alertService.success(response.data.message);
            setSelectedBiasCount(response?.data?.data?.selected_candidate_bias_count);
            if (biasData?.selected) {
              suggestedBiasData[targetIndex]['biases'][biasIndex]["id"] = response?.data?.data?.id;
            } else {
              suggestedBiasData[targetIndex]['biases'][biasIndex]["id"] = "";
            }
            setSuggestedBiasData([...suggestedBiasData]);
          } else {
            alertService.error(
              response.data.message ||
              `Error while saving suggested biases data`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  }

  /**
   * To fetch selected bias data
   */
  const fetchSelectedBias = () => {
    ServiceUtils.postRequest("fetchAllSelectedBias", { "project_id": projectId }).then((response) => {
      try {
        if (response.status === 200) {
          if (response?.data?.status === "success") {
            let selectedData = response?.data?.data?.selected_candidate_bias;
            if (selectedData) {
              setSelectedBiases({ "bkpData": [...selectedData], "selectedData": [...selectedData] });
            }
          } else {
            alertService.error(
              response.data.message ||
              `Error while fetching selected bias data`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  }

  /**
  * To fetch other bias data
  */
  const fetchOtherBiasesData = () => {
    ServiceUtils.postRequest("fetchOtherBiases", { "project_id": projectId }).then((response) => {
      try {
        if (response.status === 200) {
          if (response?.data?.status === "success") {
            if (response?.data?.data && response?.data?.data[0]?.biases) {
              let biasData = response?.data?.data[0]?.biases;
              let data = setTargetAudienceData(biasData);
              setOtherBiasData({ "bkpData": [...data], "otherData": [...data] });
              let tempData = {};
              data?.map(record => {
                tempData[record.bias_id] = true;
                return record;
              });
              setResetOtherTargetSearch({ ...tempData });
            }
          } else {
            alertService.error(
              response.data.message ||
              `Error while fetching other bias data`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  }

  /**
   * To set target list for biases
   * @param {*} biasData - bias Data
   * @returns - bias data with targetlist
   */
  const setTargetAudienceData = (biasData) => {
    try {
      let biasList = [];
      biasData.map((bias) => {
        let targetList = getTargetList(bias?.bias_id);
        let list = JSON.parse(JSON.stringify(targetAudienceList));
        let bkpList = JSON.parse(JSON.stringify(targetAudienceList));
        bkpList.map((target) => {
          if (targetList?.includes(target?.value)) {
            let ind = list.findIndex(item => item.value === target?.value);
            list.splice(ind, 1);
          }
          return target;
        });
        if (list && list?.length) {
          bias['targetAudienceList'] = [...list];
          let selectedList = JSON.parse(JSON.stringify(bias?.target_audience));
          selectedList.map((targetId) => {
            let index = list.findIndex(item => item.value === targetId);
            if (index === -1) {
              let ind = bias?.target_audience.findIndex(item => item === targetId);
              bias?.target_audience.splice(ind, 1);
            }
            return targetId;
          });
          biasList.push(bias);
        }
        return bias;
      });
      return biasList;
    } catch (error) {
      console.log(error, error);
    }
  }

  /**
   * To get targets of bias
   * @param {*} biasId - bias id
   * @returns - mapped targets
   */
  const getTargetList = (biasId) => {
    try {
      let targetList = [];
      suggestedBiasData.map((target) => {
        target?.biases.map((bias) => {
          if (bias?.bias_id === biasId) {
            targetList.push(target?.speciality_id);
          }
          return bias;
        });
        return target;
      });
      return targetList;
    } catch (error) {
      console.log(error, error);
    }
  }


  /**
   * On select all target audience
   * @param {*} biasIndex - bias index
   * @param {*} biasData - bias data
   */
  const selectAllTargets = (biasData) => {
    let targets = [];
    biasData?.targetAudienceList.map((target) => {
      targets.push(target?.value);
      return target;
    })
    let index = otherBiasData['bkpData'].findIndex(item => (item?.bias_name === biasData?.bias_name && item?.bias_id === biasData?.bias_id));
    let biasIndex = otherBiasData['otherData'].findIndex(item => (item?.bias_name === biasData?.bias_name && item?.bias_id === biasData?.bias_id));
    if (otherBiasData['otherData'][biasIndex]) {
      otherBiasData['otherData'][biasIndex]['target_audience'] = targets;
      if (index !== -1) {
        otherBiasData['bkpData'][index]['target_audience'] = targets;
      }
    }
    setOtherBiasData({ ...otherBiasData });
  }

  /**
   * On target selection
   * @param {*} targetList - selected targets
   * @param {*} biasIndex - bias in dex
   * @param {*} biasData - bias data
   */
  const setSelectedTargetAudience = (targetList, biasData) => {
    try {
      let index = otherBiasData['bkpData'].findIndex(item => (item?.bias_name === biasData?.bias_name && item?.bias_id === biasData?.bias_id));
      let biasIndex = otherBiasData['otherData'].findIndex(item => (item?.bias_name === biasData?.bias_name && item?.bias_id === biasData?.bias_id));
      if (otherBiasData['otherData'][biasIndex]) {
        otherBiasData['otherData'][biasIndex]['target_audience'] = targetList;
        if (index !== -1) {
          otherBiasData['bkpData'][index]['target_audience'] = targetList;
        }
      }
      setOtherBiasData({ ...otherBiasData });
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To select other bias
   */
  const selectOtherBias = (biasData) => {
    try {
      let bkpIndex = otherBiasData['bkpData'].findIndex(item => (item?.bias_id === biasData?.bias_id));
      let biasIndex = otherBiasData['otherData'].findIndex(item => (item?.bias_id === biasData?.bias_id));
      if (biasIndex !== -1 && otherBiasData['otherData'][biasIndex] && otherBiasData['otherData'][biasIndex]['selected']) {
        otherBiasData['otherData'][biasIndex]['selected'] = false;
        otherBiasData['otherData'][biasIndex]['target_audience'] = [];
        if (bkpIndex !== -1) {
          otherBiasData['bkpData'][bkpIndex]['selected'] = false;
          otherBiasData['bkpData'][bkpIndex]['target_audience'] = [];
        }
      } else {
        if (biasIndex !== -1) {
          otherBiasData['otherData'][biasIndex]['selected'] = true;
          otherBiasData['otherData'][biasIndex]['target_audience'] = [];
        }
        if (bkpIndex !== -1) {
          otherBiasData['bkpData'][bkpIndex]['selected'] = true;
          otherBiasData['bkpData'][bkpIndex]['target_audience'] = [];
        }
      }
      setOtherBiasData({ ...otherBiasData });
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * Custom tooltip for bias
   * @returns - tooltip
   */
  const biasTooltip = (data) => {
    return (
      <React.Fragment>
        <span onClick={event => event.stopPropagation()}>
          {data?.summary}
          <p className="viewBiasDetails" onClick={() => { childRef.current.showBias({ "bias_name": data?.bias_name, "bias_id": data?.bias_id }) }}>View Full Bias Details {">"} </p>
        </span>
      </React.Fragment>)
  }

  /**
   * On select and deselect of bias from suggested bias
   * @param {*} key - bias or related bias
   * @param {*} targetIndex - traget index
   * @param {*} biasIndex - bias index
   * @param {*} RelatedBiasindex - related bias index
   */
  const selectDeselectBias = (targetIndex, biasIndex, speciality_id) => {
    try {
      if (suggestedBiasData && suggestedBiasData[targetIndex] && suggestedBiasData[targetIndex]['biases'] && suggestedBiasData[targetIndex]['biases'][biasIndex] &&
        suggestedBiasData[targetIndex]['biases'][biasIndex]['selected']) {
        suggestedBiasData[targetIndex]['biases'][biasIndex]['selected'] = false;
      } else {
        if (suggestedBiasData[targetIndex]['biases'][biasIndex]) {
          suggestedBiasData[targetIndex]['biases'][biasIndex]['selected'] = true;
        }
      }
      setSuggestedBiasData([...suggestedBiasData]);
      biasSelectionChange(suggestedBiasData[targetIndex]['biases'][biasIndex], speciality_id, targetIndex, biasIndex);
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To set search values of select dropdowns
   * @param {*} value - value
   * @param {*} key - data key
   */
  const setSearchValues = (value, key) => {
    try {
      searchValue[key] = value;
      setSearchValue({ ...searchValue });
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To save selected other bias data
   */
  const saveOtherBiasData = () => {
    try {
      setIsSaveClicked(true);
      if (checkValdation()) {
        let selectedData = [];
        if (otherBiasData['bkpData'] && otherBiasData['bkpData'].length) {
          otherBiasData['bkpData'].map((bias) => {
            if (bias?.selected || bias?.existing) {
              selectedData.push(bias);
            }
            return bias;
          })
        }
        ServiceUtils.postRequest("saveOtherBiasData", { "select_other_bias_details": selectedData, "project_id": projectId }).then((response) => {
          try {
            if (response.status === 200) {
              if (response?.data?.status === "success") {
                alertService.success(response.data.message);
                setSelectedBiasCount(response?.data?.data?.count);
                onOtherBiasClose();
              } else {
                alertService.error(
                  response.data.message ||
                  `Error while saving other bias data`
                );
              }
            }
          } catch (error) {
            console.log(error);
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To check validations before savw
   */
  const checkValdation = () => {
    try {
      let valid = true;
      if (otherBiasData['bkpData'] && otherBiasData['bkpData'].length) {
        for (let bias of otherBiasData['bkpData']) {
          if (bias?.selected && bias?.target_audience && bias?.target_audience.length === 0) {
            valid = false;
            return;
          }
        }
      }
      return valid;
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To select all
   * @param {*} type - data type
   */
  const selectAll = (type) => {
    try {
      if (type === 'target') {
        let selectedtargets = [];
        if (selectedTarget && selectedTarget?.length !== targetAudienceList?.length) {
          selectAllTargetAudience(false, targetAudienceList, bkpBoCategoryList);
        }
      } else {
        if (selectedBeh && selectedBeh?.length !== boCategoryList?.length) {
          let selectedBehs = [];
          boCategoryList.map(beh => {
            selectedBehs.push(beh?.value);
            return beh;
          });
          setSelectedBeh([...selectedBehs]);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * On sorting
   * @param {*} key - column key
   */
  const sorting = (key) => {
    try {
      if (sortIcon?.sortColumn === key && sortIcon?.sortOrder === "asc") { // descending
        setSortIcon({ "sortColumn": key, "sortOrder": "des" });
        suggestedBiasData.map(target => {
          target['biases'].sort((a, b) => a[key].toLowerCase() < b[key].toLowerCase() ? 1 : -1);
          return target;
        });
        setSuggestedBiasData([...suggestedBiasData]);
      } else if (sortIcon?.sortColumn === key && sortIcon?.sortOrder === "des") {
        setSortIcon({ "": "bias", "sortOrder": "" });
        setSuggestedBiasData(JSON.parse(JSON.stringify(suggestedBkpData)));
      } else { // ascending
        setSortIcon({ "sortColumn": key, "sortOrder": "asc" });
        suggestedBiasData.map(target => {
          target['biases'].sort((a, b) => a[key].toLowerCase() > b[key].toLowerCase() ? 1 : -1);
          return target;
        });
        setSuggestedBiasData([...suggestedBiasData]);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * On other bias close
   */
  const onOtherBiasClose = () => {
    try {
      setShowOtherBias(false);
      setSearchText("");
      setotherTargetSearch({});
      setIsSaveClicked(false);
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To navigate on click of next
   */
  const navigateSteps = (type) => {
    try {
      if (typeof navigateStep === 'function') navigateStep({ 'navigate': type });
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * On view selected bias close
   */
  const onSelectedBiasClose = () => {
    try {
      setShowSelectedBias(false);
      setFilterBkpList([]);
      setFilterList([]);
      if (deleted && deleted?.length) {
        suggestedBiasData?.map(target => {
          target?.biases.map(bias => {
            if (bias?.selected) {
              if (deleted.includes(bias?.id)) {
                bias['id'] = "";
                bias['selected'] = false;
              }
            }
            return bias;
          })
          return target;
        });
        setSuggestedBiasData([...suggestedBiasData]);
        setDeleted([]);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * On target change
   * @param {*} event - change event
   */
  const onTargetChange = (event) => {
    try {
      setSelectedTarget(event);
      let targets = [];
      targetAudienceList.map(target => {
        if (event.includes(target?.value)) {
          targets.push(target);
        }
        return target;
      })
      selectAllTargetAudience(false, targets, bkpBoCategoryList);
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To filter suggested bias data on apply click
   */
  const onApplyFilters = () => {
    try {
      if (selectedTarget && selectedTarget?.length) {
        fetchSuggestedBiasdata(projectId, selectedTarget, selectedBeh);
      } else {
        alertService.info('Please select at least one target audience to view updated bias suggestions');
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * On dropdown open and close
   * @param {*} visible - is open or closed
   * @param {*} key - dropdown type key
   */
  const onDropdownVisibleChange = (visible, key) => {
    try {
      if (visible && searchValue[key]) {
        setTimeout(() => {
          setSearchValues("", key);
          refreshSelect[key] = false;
          setRefreshSelect({ ...refreshSelect });
          setTimeout(() => {
            refreshSelect[key] = true;
            setRefreshSelect({ ...refreshSelect });
          }, 1);
        }, 1);
      }
    } catch (error) {
    console.log("error", error);
  }
}

/**
 * On dropdown open and close
 * @param {*} visible - is open or closed
 * @param {*} key - dropdown type key
 */
const onTargetDropdownVisibleChange = (visible, key) => {
  try {
    if (visible && otherTargetSearch[key]) {
      setTimeout(() => {
        otherTargetSearch[key] = "";
        setotherTargetSearch({ ...otherTargetSearch });
        resetOtherTargetSearch[key] = false;
        setResetOtherTargetSearch({ ...searchValue });
        setTimeout(() => {
          resetOtherTargetSearch[key] = true;
          setResetOtherTargetSearch({ ...resetOtherTargetSearch });
        }, 1);
      }, 1);
    }
  } catch (error) {
    console.log("error", error);
  }
}

/**
 * On target and behavioural objective data reset
 */
const onResetFilters = () => {
  try {
    selectAllTargetAudience(true, targetAudienceList, bkpBoCategoryList, projectId);
  } catch (error) {
    console.log("error", error);
  }
}

return (
  <div className="m-1 p-1 w-90 candidateBiasLayout">
    <div className="m-0 p-0 d-flex heading">
      <h5 className="main-header">
        Suggested Biases
      </h5>
      <Button className="align-vertical-center" type={`${selectBiasCount ? 'primary' : ''}`} disabled={suggestedBiasLoader} onClick={() => { setShowSelectedBias(true); setDeleted([]); fetchSelectedBias() }}>
        <SVGIcon icon={"list-tree"} className="fontIcon" />
        &nbsp; View {selectBiasCount ? selectBiasCount : ''} Selected Biases
      </Button>
    </div>
    <div className="col-6 summary">
      <span>The Suggested Biases below are based on historical projects with similar Project Attributes as this project,
        particularlly Target Audience and Behavioral Objective Category. You can adjust these ... </span>
      <Tooltip placement="topLeft" color={'black'} arrowPointAtCenter={true} overlayClassName={'project-attr-desc tooltip-bg'} title={descriptionText}>
        <SVGIcon icon={'info-fill'} className="m-0 ps-1 fontCircleInfo" />
      </Tooltip>
    </div>
    <div className="p-0 dropDownFilter d-flex mt-3">
      <div className="dropDownForm">
        <Row gutter={{ xs: 16, sm: 12, md: 10, lg: 4 }}>
          <Col span={7}>
            <label className="field-name">Target Audience</label>
            <div>
              <Select style={{ width: '100%' }}
                placeholder={"Select"}
                optionFilterProp="label"
                showSearch={false}
                searchValue={searchValue["target"]}
                mode="multiple"
                allowClear={true}
                value={selectedTarget}
                showArrow={true}
                options={targetAudienceList}
                maxTagCount="responsive"
                notFoundContent="No Data Found"
                autoClearSearchValue={false}
                onChange={onTargetChange}
                onDropdownVisibleChange={(visible) => onDropdownVisibleChange(visible, 'target')}
                dropdownRender={(menu) => (
                  <>
                    {(targetAudienceList && targetAudienceList.length > 1) && <div className="select-search-box">
                      {refreshSelect['target'] && <Input placeholder="Search for filter" onChange={(e) => setSearchValues(e?.target?.value, "target")} style={{ width: '100%' }} />}
                    </div>}
                    {(!searchValue["target"] || (searchValue["target"] && 'All Target Audience'.toLowerCase()?.includes(searchValue["target"]))) && (targetAudienceList && targetAudienceList.length > 1) && <div style={{ paddingLeft: '14px', paddingTop: '5px', paddingBottom: "5px", cursor: 'pointer', color: 'black' }} onClick={() => selectAll('target')}>All Target Audience</div>}
                    {menu}
                  </>
                )}
              ></Select>
            </div>
          </Col>
          <Col span={7}>
            <label className="field-name">Behavioral Objective Category</label>
            <div>
              <Select style={{ width: '100%' }}
                placeholder={"Select"}
                optionFilterProp="label"
                showSearch={false}
                searchValue={searchValue["beh-obj"]}
                mode="multiple"
                allowClear={true}
                showArrow={true}
                value={selectedBeh}
                options={boCategoryList}
                onChange={(event) => { setSelectedBeh(event); }}
                maxTagCount="responsive"
                notFoundContent="No Data Found"
                autoClearSearchValue={false}
                onDropdownVisibleChange={(visible) => onDropdownVisibleChange(visible, "beh-obj")}
                dropdownRender={(menu) => (
                  <>
                    {(boCategoryList && boCategoryList.length > 1) && <div className="select-search-box">
                      {refreshSelect['beh-obj'] && <Input placeholder="Search for filter" onChange={(e) => setSearchValues(e?.target?.value, "beh-obj")} style={{ width: '100%' }} />}
                    </div>}
                    {(!searchValue["beh-obj"] || (searchValue["beh-obj"] && 'All Behavioral Objective Category'.toLowerCase()?.includes(searchValue["beh-obj"]))) && (boCategoryList && boCategoryList.length > 1) && <div style={{ paddingLeft: '14px', paddingTop: '5px', paddingBottom: "5px", cursor: 'pointer', color: 'black' }} onClick={() => selectAll('beh-obj')}>All Behavioral Objective Category</div>}
                    {menu}
                  </>
                )}
              ></Select>
            </div>
          </Col>
          <Col span={3}>
            <div className="other-add">
              <Button type="primary w-100" onClick={onApplyFilters} disabled={suggestedBiasLoader}>Apply Filters</Button>
            </div>
          </Col>
          <Col span={3}>
            <div className="other-add">
              <Button className="w-100" disabled={suggestedBiasLoader} onClick={onResetFilters}>Reset Filters</Button>
            </div>
          </Col>
          <Col span={2}>
          </Col>
        </Row>
      </div>
      {/* <div style={{'paddingTop': '23px'}}>
          <Button type="primary">
          Apply
        </Button>
        </div> */}
      <div className="other-add">
        <Button className="align-vertical-center" disabled={suggestedBiasLoader} onClick={() => { setShowOtherBias(true); fetchOtherBiasesData(); }}>
          <SVGIcon icon={"add-circle-fill"} className="fontIcon" />
          &nbsp; Add Other Biases
        </Button>
      </div>
    </div>
    <div className="candidateTable pt-2">
      <Row style={{ paddingTop: '10px' }}>
        <Col span={7}>
          <span className="column-label" onClick={() => sorting('bias_name')}>Suggested Biases{""}
            <span style={{ paddingLeft: '5px' }}>
              {(!sortIcon?.sortColumn || sortIcon?.sortColumn === 'confidence') && <SVGIcon icon={"sort-default-new"} />}
              {(sortIcon?.sortColumn === 'bias_name' && sortIcon?.sortOrder === 'asc') && <SVGIcon icon={"sort-up-new"} />}
              {(sortIcon?.sortColumn === 'bias_name' && sortIcon?.sortOrder === 'des') && <SVGIcon icon={"sort-down-new"} />}
            </span></span>
        </Col>
        <Col span={8}>
          <span className="column-label" onClick={() => sorting('confidence')}>Confidence{""}
            <span style={{ paddingLeft: '5px' }}>
              {(!sortIcon?.sortColumn || sortIcon?.sortColumn === 'bias_name') && <SVGIcon icon={"sort-default-new"} />}
              {(sortIcon?.sortColumn === 'confidence' && sortIcon?.sortOrder === 'asc') && <SVGIcon icon={"sort-up-new"} />}
              {(sortIcon?.sortColumn === 'confidence' && sortIcon?.sortOrder === 'des') && <SVGIcon icon={"sort-down-new"} />}
            </span></span>
        </Col>
      </Row>
      <Spin spinning={suggestedBiasLoader}>
        <div className="pt-2 data-section">
          {suggestedBiasData?.length !== 0 && suggestedBiasData?.map((target, targetIndex) => {
            return (<>
              <div style={{ paddingTop: '5px' }} key={'TA_key' + targetIndex}>
                <span className="data-label">{target?.name}</span>
                <hr style={{ paddingTop: '0', marginBottom: '0', marginTop: '8px', color: '#a5a5ad' }}></hr>
                {
                  target?.biases && target?.biases?.map((bias, biasIndex) => {
                    return (<>
                      <Row style={{ marginTop: '5px', fontSize: '14px' }} key={'bias_key' + biasIndex}>
                        <Col span={7} className="flex-display" style={{ alignItems: 'center', paddingLeft: "15px" }}>
                          {bias?.bias_name}
                          <Tooltip color={"black"} title={biasTooltip(bias)}>
                            <SVGIcon icon={'info-fill'} className="m-0 ps-1 fontCircleInfo" />
                          </Tooltip>
                        </Col>
                        <Col span={15} className="align-vertical-left">
                          <span>{bias?.confidence}</span>
                          <Tooltip color={"black"} title={bias?.percentage}>
                            <SVGIcon icon={'info-fill'} className="m-0 ps-1 fontCircleInfo" />
                          </Tooltip>
                        </Col>
                        <Col span={2} className="pe-2" style={{ display: 'flex', justifyContent: 'right' }}>
                          <Button type={bias?.selected ? "primary" : "select-btn"} onClick={() => selectDeselectBias(targetIndex, biasIndex, target?.speciality_id)}>{bias?.selected ? 'Selected' : 'Select'}</Button>
                        </Col>
                      </Row>
                      {<hr style={{ paddingTop: '0', marginBottom: '0', marginTop: '5px', color: '#a5a5ad' }}></hr>}
                    </>
                    )
                  })}
              </div>
            </>)
          })}
        </div>
      </Spin>
    </div>

    <BiasesDetails ref={childRef} viewMode="candidateBiasView" />

    <Drawer
      title="All Selected Bias"
      className="targetFilters"
      placement={"right"}
      width={'65%'}
      closable={"right"}
      onClose={onSelectedBiasClose}
      open={showSelectedBias}
      footer={
        <>
          <Button onClick={onSelectedBiasClose}>{" "}Close{" "}</Button>
        </>
      }
    >
      <div className="seleted-bias-table pt-3 pl-3">
        {showSelectedBias && <Table
          columns={viewSelectedBiascolumns}
          dataSource={selectedBiases?.selectedData}
          pagination={false}
          scroll={{ y: "calc(100vh - 160px)" }}
          showSorterTooltip={false}
        />}
      </div>
    </Drawer>

    <Drawer
      title="Other Biases"
      placement={"right"}
      width={'60%'}
      closable={"right"}
      onClose={onOtherBiasClose}
      open={showOtherBias}
      footer={
        <>
          <Button type="primary" className="me-1" onClick={saveOtherBiasData}>Save</Button>
          <Button onClick={onOtherBiasClose}>{" "}Cancel{" "}</Button>
        </>
      }
    >
      <Row className="pt-3 p-15">
        <Col span={8}>
          <div className="searchFilterDiv input-group ps-0">
            <DebounceInput
              value={searchText}
              type="text"
              className="form-control form-control-sm"
              placeholder="Search for Bias"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <button className="searchButton text-muted me-2" onClick={searchData}>
              <SVGIcon icon={"search-transparent"} />
            </button>
            <div className="ps-2 pt-2">
              <span className="filtersButton">
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <div className="other-bias-table pt-3">
        {showOtherBias && <Table
          columns={otherBiasColumns}
          dataSource={otherBiasData?.otherData}
          pagination={false}
          loading={searchLoader}
          locale={tableDataText}
          showSorterTooltip={false}
          scroll={{ y: "calc(100vh - 232px)" }}
        />}
      </div>
    </Drawer>

    <footer className="d-grid p-2 ms-2 d-md-flex justify-content-md-end">
      <Button className="me-3" onClick={() => navigateSteps('back')}>
        Back
      </Button>
      <Button
        type="primary"
        className="me-1"
        onClick={() => navigateSteps('next')}
      >
        Next
      </Button>
    </footer>
    {/* delete confirmation antd modal  */}
    <Modal
      centered
      closable={false}
      open={deleteConfirmation}
      footer={null}
      width={400}
      zIndex={2010}
      onCancel={() => setDeleteConfirmation(false)}
    >
      <h6  className="confirmation-header-text">Delete Bias</h6>
      <p className="text-muted pt-1">
        Are you sure you want to delete this bias?
      </p>
      <Button type="primary" onClick={deleteBias} className="me-2 mt-3" >
        Delete
      </Button>
      <Button onClick={() => setDeleteConfirmation(false)} className='mt-3'>
        {" "} Cancel{" "}
      </Button>
    </Modal>
  </div>
);
}
