import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import "./AddBiasInAction.scss";
import { Button, Upload, Row, Col, Input, Radio, Image } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import defaultImage from "../../../../../Assets/images/default.jpg";


const AddBiasInAction = forwardRef(({ description, mode, selectedBiasData, onSave, ...props }, ref) => {

  const [biasImageFile, setBiasImageFile] = useState();
  const [showImageSizeError, setShowImageSizeError] = useState(false);
  const [biasImageUrl, setBiasImageUrl] = useState(null);
  const [formData, setFormData] = useState({});
  const [clickedSave, setClickedSave] = useState(false);
  const bytesToMegaBytes = (bytes) => bytes / (1024 ** 2); //convert byte to MB
  let fileUpload = React.createRef();
  const { TextArea } = Input;
  const { Dragger } = Upload;

  const initialFormData = {
    "pharmaNonpharma": true,
    "audience": "physicians",
    "example_type": "activating",
    "demonstrate_bias": "",
    "key_message": "",
    "additional_info": ""
  }

  const formFields = [
    {
      "key": "pharmaNonpharma",
      "label": "Pharma/Non-Pharma",
      "type": "radio",
      "placeholder": "Select",
      "col": 8,
      "error-message": "Please Select Pharma",
      "options": [{
        "label": "Pharma",
        "value": true
      },
      {
        "label": "Non-Pharma",
        "value": false
      }]
    },
    {
      "key": "audience",
      "label": "Audience",
      "type": "radio",
      "placeholder": "Select",
      "col": 8,
      "error-message": "Please Select Audience",
      "options": [{
        "label": "Physicians",
        "value": "physicians"
      },
      {
        "label": "Patients",
        "value": "patients"
      }]
    },
    {
      "key": "example_type",
      "label": "Example Type",
      "type": "radio",
      "col": 8,
      "error-message": "Please Select Example Type",
      "options": [{
        "value": "activating",
        "label": "Activating"
      },
      {
        "value": "mitigating",
        "label": "Mitigating"
      }]
    },
    {
      "key": "demonstrate_bias",
      "label": "How Image Demonstrates Bias",
      "type": "textArea",
      "col": 24,
      "error-message": "Please add description",
      "placeholder": "Add Description"
    },
    {
      "key": "key_message",
      "label": "Bias-in-Action Key Message",
      "type": "textArea",
      "col": 24,
      "optional": true,
      "placeholder": "Add Description"
    },
    {
      "key": "additional_info",
      "label": "Bias-in-Action Additional Info",
      "type": "textArea",
      "col": 24,
      "optional": true,
      "placeholder": "Add Description"
    }
  ];

  const showFileUpload = () => {
    fileUpload.current.click();
  };

  useEffect(() => {
    resetData();
  }, [])

  useImperativeHandle(ref, () => ({
    resetForm() {
      resetData();
    },
    submitForm() {
      setClickedSave(true);
      if (checkValid()) {
        if (typeof onSave === "function") onSave({ type: "submit", formData: formData });
        setClickedSave(false);
      }
    }
  }));

  const resetData = () => {
    try {
      setFormData({ ...initialFormData });
      setBiasImageUrl(null);
      setBiasImageFile(null);
      setShowImageSizeError(false);
      setClickedSave(false);
    } catch (error) {
      console.log("error", error);
    }
  }

  const checkValid = () => {
    try {
      let valid = true;
      for (let fieldKey in formData) {
        if (!formData[fieldKey] && (fieldKey !== 'key_message' && fieldKey !== 'additional_info' && fieldKey !== 'pharmaNonpharma')) {
          valid = false;
        }
      }
      return valid;
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    let imageUrl = null;
    if (biasImageFile) {
      imageUrl = URL.createObjectURL(biasImageFile);
    }
    setBiasImageUrl(imageUrl);
  }, [biasImageFile])

  /**
  * Uploading image through browse from file button
  */
  const onImageChange = (e) => {
    if (!e.target.files.length) return;
    onImageFileSelection(e.target.files[0]);
  }

  const onImageFileSelection = (file) => {
    let isImageSizeValid = bytesToMegaBytes(file.size) > 2 ? false : true;
    setShowImageSizeError(isImageSizeValid ? false : true);
    if (isImageSizeValid) {
      setBiasImageFile(file);
      if (typeof onSave === "function") onSave({ type: "onChange", imgData: file });
    }
  }

  /**
   * Selecting img from dragger
   * @param {*} e 
   * @returns 
   */
  const onDragImageChange = (e) => {
    let isImageSizeValid = bytesToMegaBytes(e?.file?.size) > 2 ? false : true;
    setShowImageSizeError(isImageSizeValid ? false : true);
    var file = e.file;
    if (!file) return;
    if (file.uid) {
      delete file.uid
    }
    onImageFileSelection(file);
  };

  const onChange = (value, field) => {
    try {
      formData[field.key] = value;
      if (field.key === 'pharmaNonpharma' && !value) {
        formData['audience'] = 'consumer';
      } else if (field.key === 'pharmaNonpharma' && value) {
        formData['audience'] = 'physicians';
      }
      setFormData({ ...formData });
      if (typeof onSave === "function") onSave({ type: "edit" });
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <>
      <div className="add-bias-in-action" style={{ padding: '15px 0 0 15px' }} >
        <Button onClick={() => showFileUpload()}>
          Browse for files..{" "}
          <input
            type="file"
            accept=".png,.jpg,.jpeg,gif"
            style={{ display: "none" }}
            ref={fileUpload}
            onChange={onImageChange}
          />
        </Button>
      </div>
      <Row style={{ padding: '15px 0 0 15px' }}>
        {biasImageUrl && <Col span={5}>
          <Image
            src={biasImageUrl}
            alt="image"
            className="img-size"
            preview={false}
            placeholder={
              <Image
                preview={false}
                src={defaultImage}
                className="img-size"
              />
            }
          />
        </Col>}
        <Col span={!biasImageUrl ? 17 : 12} style={biasImageUrl ? { marginLeft: "12px", textAlign: "left" } : {}}>
          <Dragger
            className="dragAndDrop"
            type="file"
            style={{ width: "100%" }}
            listType="picture"
            accept=".png,jpeg,.jpg,.gif"
            beforeUpload={(file) => {
              return false;
            }}
            multiple={false}
            onChange={onDragImageChange}
            showUploadList={false}
          >
            {" "}
            <PlusOutlined />
            <p className="text-muted">
              {" "}
              Drag & Drop
              <br />
              Image
            </p>
          </Dragger>
        </Col>
        <Col span={6}>
          <p className="muted p-15" style={{ marginBottom: '0px' }}>
            Recommended resolution is 645 * 320 with file size less than
            2MB, keep visual elements centered.
          </p>
        </Col>
      </Row>
      {showImageSizeError &&
        <p className="text-danger p-15" style={{ marginBottom: '0px' }}>
          Image file size should be less than or equal to 2 MB.
        </p>
      }
      {
        clickedSave && !biasImageFile && <p className="text-danger p-15" style={{ marginBottom: '0px' }}>
          Please select image
        </p>
      }
      {biasImageFile && <div className="p-3">
        <Row>
          {formFields.map((field) => {
            return (
              <Col span={field?.col}>
                <label className="field-label">
                  {field?.label}{field?.optional && <span className="optional">(optional)</span>}
                </label>
                {field?.type && field?.type === 'textArea' &&
                  <TextArea
                    maxLength={200}
                    placeholder={field?.placeholder}
                    disabled={field?.disabled}
                    value={formData[field?.key]}
                    className={`mb-2 des-area ${(clickedSave && !formData[field?.key] && field?.key === 'demonstrate_bias') ? "error-section" : ""}`}
                    onChange={(event) => onChange(event?.target?.value, field)}
                  />
                }
                {field?.type && field?.type === "radio" && field?.key !== 'audience' &&
                  <div className="mb-3">
                    <Radio.Group value={formData[field?.key]} onChange={(event) => onChange(event?.target?.value, field)}>
                      {field?.options.map((option) => (
                        <Radio value={option?.value}>{option?.label}</Radio>
                      ))}
                    </Radio.Group>
                  </div>}
                {field?.type && field?.type === "radio" && field?.key === 'audience' && <>
                  {formData?.pharmaNonpharma &&
                    <div>
                      <Radio.Group value={formData[field?.key]} onChange={(event) => onChange(event?.target?.value, field)}>
                        {field?.options.map((option) => (
                          <Radio value={option?.value}>{option?.label}</Radio>
                        ))}
                      </Radio.Group></div>}
                  {
                    !formData?.pharmaNonpharma && <p>Consumer</p>
                  }
                </>}
                {clickedSave && (!formData[field?.key]) && <h6 className="error-msg">{field['error-message']}</h6>}
              </Col>)
          })}
        </Row>
      </div>}
    </>
  )
});

export default AddBiasInAction;