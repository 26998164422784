import React, { useState } from "react";
import "antd/dist/antd.min.css";
import "./Admin.scss";
import { Menu } from "antd";

import { DownOutlined } from "@ant-design/icons";
import BehavioralObjectiveCategory from "./ProjectAttributes/BehavioralObjectiveCategory/BehavioralObjectiveCategory";
import TargetAudienceAndSpecialty from "./ProjectAttributes/TargetAudienceAndSpecialty/TargetAudienceAndSpecialty";
import TherapyAreaAndDiseaseState from "./ProjectAttributes/TherapyAreaAndDiseaseState/TherapyAreaAndDiseaseState";
import QualitativeQuestions from "./QualitativeQuestions/QualitativeQuestions";
import QuantitativeTestDesign from "./QuantitativeTestDesign/QuantitativeTestDesign";
import UserManagement from "./UserManagement/UserManagement";

const AdminPage = () => {
  const [selectedItem, setSelectedItem] = useState(
    "behavioral_objective_category"
  );

  const onClickTopNav = (menuData) => {
    setSelectedItem(menuData.key);
  };

  const menu = [
    {
      label: "Project Attributes",
      icon: <DownOutlined />,
      key: "SubMenu",
      children: [
        {
          label: (
            <span className={"submenu"}>Behavioral Objective Category</span>
          ),
          key: "behavioral_objective_category",
        },
        {
          label: (
            <span className={"submenu"}>Target Audience and Specialty</span>
          ),
          key: "target_audience_specialty",
        },
        {
          label: (
            <span className={"submenu"}> Therapy Area and Disease State </span>
          ),
          key: "therapy_area_disease_state",
        },
      ],
    },
    {
      label: <span>Qualitative Questions</span>,
      key: "qualitative_questions",
    },
    {
      label: <span>Quantitative Test Design</span>,
      key: "quantitative_test_design",
    },
    {
      label: <span>User Management</span>,
      key: "user_management",
    },
  ];

  return (
    <div className="admin-page">
      <div className="admin-nav">
        <Menu
          className="admin-menu"
          onClick={onClickTopNav}
          selectedKeys={selectedItem}
          mode="horizontal"
          items={menu}
        />
      </div>
      {selectedItem === "behavioral_objective_category" && (
        <BehavioralObjectiveCategory />
      )}
      {selectedItem === "target_audience_specialty" && (
        <TargetAudienceAndSpecialty />
      )}
      {selectedItem === "therapy_area_disease_state" && (
        <TherapyAreaAndDiseaseState />
      )}
      {selectedItem === "qualitative_questions" && <QualitativeQuestions />}
      {selectedItem === "quantitative_test_design" && <QuantitativeTestDesign />}
      {selectedItem === "user_management" && <UserManagement />}
    </div>
  );
};
export default AdminPage;
