import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react'
import SVGIcon from "../../../Shared/Components/SVGIcon/SVGIcon";
import { Radio, Upload, Form, Select, Button, Row, Col, Modal, Collapse, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { deepClone, generateUniqueId } from "../../../Shared/Utils/CommonUtil";
import "./BiasInAction.scss";
import { ServiceUtils } from "../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../Shared/Utils/ToasterUtil";
const AddBiasInAction = forwardRef((props, ref) => {
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [showAddErrorMsg, setShowAddErrorMsg] = useState(false);
    const { TextArea } = Input;
    const [biasDD, setBiasDD] = useState([]);
    const { Panel } = Collapse;
    const { Dragger } = Upload;
    const [biasImageFile, setBiasImageFile] = useState();
    const [showImageSizeError, setShowImageSizeError] = useState(false);
    const [isAddBiasSaved, setIsAddBiasSaved] = useState(false);
    const [biasList, setBiasList] = useState(['']);
    const [changesConfirmModal, setChangesConfirmModal] = useState(false);
    const [biasImageUrl, setBiasImageUrl] = useState(null);
    let fileUpload = React.createRef();
    const [addBiasForm, setAddBiasForm] = useState({
        pharmaNonpharma: 'pharma',
        audience: 'physicians',
        formData: [
            {
                selected_bias: "",
                example_type: "activating",
                // descriptions: deepClone(addBiasConfig),
                demonstrate_bias: '',
                key_message: '',
                additional_info: '',
                id: generateUniqueId()
            }
        ]
    })

    const bytesToMegaBytes = (bytes) => bytes / (1024 ** 2); //conver byte to MB
    /**
     * uploading image through brows from file button
     * @param {*setUploadImageError} - enable/desable error message
     * @returns {*bytesToMegaBytes} - conver bute size to MB
     */
    useImperativeHandle(ref, () => ({
        showModal() {
            setShowAddErrorMsg(false);
            setIsAddModalVisible(true);
            setBiasList([...props.biasList])
            setBiasDD([...props.biasList]);
        }
    }));

    /**
     * T fetch bias table data
     * @param {*} bias_id - bias id
     */
    const reloadBiasInActionData = (biasIds) => {
        if (biasIds) {
            if (typeof props.refreshTableData === 'function') props.refreshTableData({ biasIds });
        }
    }

    /**
     * uploading image throughdrag and drop
     * @param {*setUploadImageError} - enable/desable error message
     * @returns {*bytesToMegaBytes} - conver bute size to MB
     */
    useEffect(() => {
        let imageUrl = null;
        if (biasImageFile) {
            imageUrl = URL.createObjectURL(biasImageFile);
        }
        setBiasImageUrl(imageUrl);
    }, [biasImageFile])

    useEffect(() => {
        biasDD.map((item) => {
            const fIndex = addBiasForm.formData.findIndex((el) => el.selected_bias === item.value);
            return item.disabled = (fIndex > -1 ? true : false);
        })
        setBiasDD([...biasDD])
    }, [addBiasForm])

    const showFileUpload = () => {
        fileUpload.current.click();
    };

    /**
    * uploading image through brows from file button
    */
    const onImageChange = (e) => {
        try {
            if (!e.target.files.length) return;
            onImageFileSelection(e.target.files[0]);
        } catch (error) {
            console.log("error", error);
        }
    }
    
    /**
     * On image selection
     * @param {*} file - selected file
     */
    const onImageFileSelection = (file) => {
        try {
            setIsAddBiasSaved(true)
            let isImageSizeValid = bytesToMegaBytes(file.size) > 2 ? false : true;
            setShowImageSizeError(isImageSizeValid ? false : true);
            if (isImageSizeValid) {
                setBiasImageFile(file);
            }
        } catch (error) {
            console.log("error", error);
        }
    }
    
    /**
     * On image change
     * @param {*} e - selected event 
     */
    const onDragImageChange = (e) => {
        try {
            let isImageSizeValid = bytesToMegaBytes(e?.file?.size) > 2 ? false : true;
            setShowImageSizeError(isImageSizeValid ? false : true);
            var file = e.file;
            if (!file) return;
            if (file.uid) {
                delete file.uid
            }
            onImageFileSelection(file);  
        } catch (error) {
            console.log("error", error); 
        }
    };

    const onChangepharmaNonPharma = (payload, type) => {
        try {
            setIsAddBiasSaved(true);
            if (type === 'pharmaNonpharma') {
                setAddBiasForm({
                    ...addBiasForm,
                    pharmaNonpharma: payload,
                    audience: payload === 'pharma' ? 'physicians' : 'consumer'
                })
            } else if (type === 'audience') {
                setAddBiasForm({
                    ...addBiasForm,
                    audience: payload
                })
            }
        } catch (error) {
            console.log("error", error); 
        }
    }

    /**
     * On save new bias in action
     */
    const saveNewBiasInAction = () => {
        try {
            let newData = deepClone(addBiasForm);
            newData.pharmaNonpharma = newData.pharmaNonpharma === 'pharma' ? true : false;
            newData.formData.map((el) => {
                if (el.id) {
                    delete el.id;
                }
                return el;
            })
            const postData = new FormData();
            postData.append('imageFile', biasImageFile);
            postData.append('biasInActionData', JSON.stringify(newData));
            // call api
            ServiceUtils.postRequest("addNewBias", postData).then((response) => {
                if (response.status === 200) {
                    if (response.data.status === "success") {
                        setIsAddModalVisible(false);
                        setIsAddBiasSaved(false);
                        alertService.success(response.data.message || 'Successfully added the bias in action');
                        if (addBiasForm?.formData?.length) {
                            const biasIds = addBiasForm?.formData.map((bias) => bias.selected_bias);
                            reloadBiasInActionData(biasIds);
                        }
                    } else {
                        alertService.error(
                            response.data.message || `Error while adding new bias in action`
                        );
                    }
                }
            }); 
        } catch (error) {
            console.log("error", error);  
        }
    }

    const confirmSaved = () => {
        try {
            handleCancel(false);
            setChangesConfirmModal(false); 
        } catch (error) {
            console.log("error", error); 
        }
    }

    const handleCancel = (isCancel) => {
        try {
            if (isCancel && isAddBiasSaved) {
                setChangesConfirmModal(true);
            } else {
                setIsAddModalVisible(false);
                setShowAddErrorMsg(false);
                setIsAddBiasSaved(false);
                setShowImageSizeError(false);
            }
        } catch (error) {
            console.log("error", error); 
        }
    };

    const ondismissAddBiasModal = (s) => {
        try {
            setBiasImageFile(null);
            setShowAddErrorMsg(false);
            setAddBiasForm({
                pharmaNonpharma: 'pharma',
                audience: 'physicians',
                formData: [
                    {
                        selected_bias: "",
                        example_type: "activating",
                        // descriptions: deepClone(addBiasConfig),
                        demonstrate_bias: '',
                        key_message: '',
                        additional_info: '',
                        id: generateUniqueId()
                    }
                ]
            });
            setBiasDD([...biasList]);
        } catch (error) {
            console.log("error", error);   
        }
    }
    
    const onAddBiasInActionFinish = () => {
        saveNewBiasInAction();
    }

    const onAddBiasInActionFinishFailed = () => {
        setShowAddErrorMsg(true);
    };

    const onFieldValueChange = (index, key, value) => {
        try {
            setIsAddBiasSaved(true);
            const form = addBiasForm.formData;
            form[index] = {
                ...form[index],
                [key]: value
            }
            setAddBiasForm({ ...addBiasForm, formData: form }) 
        } catch (error) {
            console.log("error", error); 
        }
    }
    /**
    * on click of add another bise in action dynamic form
    */
    const addAnotherBiasesInActionForm = () => {
        try {
            const form = addBiasForm.formData;
            form.push({
                selected_bias: "",
                example_type: "activating",
                // descriptions: deepClone(addBiasConfig),
                demonstrate_bias: '',
                key_message: '',
                additional_info: '',
                id: generateUniqueId()
            })
            setAddBiasForm({ ...addBiasForm, formData: form });
        } catch (error) {
            console.log("error", error); 
        }
    }

    /**
     * deleting of perticular form collapse in add biase in action
     */
    const deleteSelectedBiasForm = (index) => {
        try {
            if (addBiasForm.formData.length === 1) return;
            const form = addBiasForm.formData;
            form.splice(index, 1);
            setAddBiasForm({ ...addBiasForm, formData: [...form] });
        } catch (error) {
           console.log("error", error); 
        }
    };

    return (
        <>
            <Modal
                className="modal-add-new-bias"
                centered
                title="Add a Bias-In-Action Example"
                open={isAddModalVisible}
                onOk={() =>   setIsAddModalVisible(false)}
                onCancel={() => { handleCancel(true) }}
                width={650}
                bodyStyle={{ height: 390, paddingTop: '1px' }}
                afterClose={ondismissAddBiasModal}
                maskClosable={false}
                footer={
                    <>
                        <Button
                            type="primary"
                            htmlType="submit"
                            form="bias-in-action-form"
                            className="me-2"
                            disabled={biasImageFile ? false : true}
                        >
                            Save
                        </Button>
                        <Button onClick={() => handleCancel(true)}> Cancel </Button>
                        {showAddErrorMsg && <span className="text-danger ms-2">Please fill all mandatory fields</span>}
                    </>
                }
            >
                <div className="add-bias-modal-body">
                    <Button className="my-2" onClick={() => showFileUpload()}>
                        Browse for files..{" "}
                        <input
                            type="file"
                            id="uploadImage"
                            accept=".png,.jpg,.jpeg,gif"
                            style={{ display: "none" }}
                            ref={fileUpload}
                            onChange={onImageChange}
                        />
                    </Button>

                    <div className="d-flex ">
                        <Dragger
                            className="dragAndDrop"
                            type="file"
                            id="uploadImage"
                            style={{ width: "30em" }}
                            listType="picture"
                            accept=".png,jpeg,.jpg,.gif"
                            beforeUpload={(file) => {
                                return false;
                            }}
                            multiple={false}
                            onChange={onDragImageChange}
                        >
                            {" "}
                            <PlusOutlined />
                            <p className="text-muted">
                                {" "}
                                Drag & Drop
                                <br />
                                Image
                            </p>
                        </Dragger>
                        <p className="muted" style={{ marginLeft: "12px", textAlign: "left" }}>
                            Recommended resolution is 645 * 320 with file size less than
                            2MB, keep visual elements centered.
                        </p>
                    </div>
                    {showImageSizeError &&
                        <p className="text-danger">
                            Image file size should be less than or equal to 2 MB.
                        </p>
                    }
                    <hr />
                </div>
                {biasImageFile && (
                    <>
                        <Row className="" span={24}>
                            <Col span={6}>
                                <img
                                    id="uploadPreview"
                                    src={biasImageUrl}
                                    alt=""
                                    style={{
                                        height: "90px",
                                        width: '100%',
                                    }} />
                            </Col>
                            <Col span={9} push={1}>
                                <p className="pt-2">Pharma/Non-Pharma</p>
                                <Radio.Group
                                    name="radiogroup"
                                    onChange={(e) => onChangepharmaNonPharma(e.target.value, 'pharmaNonpharma')}
                                    value={addBiasForm.pharmaNonpharma}
                                >
                                    <Radio value={'pharma'}>Pharma</Radio>
                                    <Radio value={'non_pharma'}>Non-Pharma</Radio>
                                </Radio.Group>
                            </Col>
                            <Col span={9} push={1}>
                                <p className="pt-2">Audience</p>
                                {addBiasForm && addBiasForm.pharmaNonpharma === 'pharma' &&
                                    <Radio.Group
                                        name="radiogroup"
                                        onChange={(e) => onChangepharmaNonPharma(e.target.value, 'audience')}
                                        value={addBiasForm.audience}
                                    >
                                        <Radio value={'physicians'}>Physicians</Radio>
                                        <Radio value={'patients'}>Patients</Radio>
                                    </Radio.Group>
                                }
                                {addBiasForm && addBiasForm.pharmaNonpharma === 'non_pharma' &&
                                    <p className="">Consumer</p>
                                }
                            </Col>
                        </Row>
                        <div className="onclick-section-div">
                            <div className="text-modal pt-2">
                                <p>
                                    Choose a bias to associate it to its specific
                                    Bias-In-Action.You can add more than one Bias associated by
                                    adding a new associated Bias section.
                                </p>
                            </div>

                            <div className="add-new-bias">
                                {addBiasForm &&
                                    <>
                                        <Form
                                            id="bias-in-action-form"
                                            layout="vertical"
                                            requiredMark={'optional'}
                                            onFinish={onAddBiasInActionFinish}
                                            onFinishFailed={onAddBiasInActionFinishFailed}
                                            onValuesChange={() => setShowAddErrorMsg(false)}
                                        >
                                            {addBiasForm.formData.map((item, index) => (
                                                <Collapse key={'bias-association-colapse'} className="border-0" defaultActiveKey={['panel-' + index]}>
                                                    <Panel
                                                        forceRender={true}
                                                        className="ps-1 mb-2"
                                                        header="Select Bias : "
                                                        style={
                                                            {
                                                                border: "1px solid #d9d9d9"
                                                            }
                                                        }
                                                        key={'panel-' + index}
                                                        extra={(<div className="d-flex" onClick={(event) => event.stopPropagation()}>
                                                            <Form.Item className="mb-0" required name={'bias-' + item.id} key={'bis-key-' + item.id}
                                                                value={item.selected_bias}
                                                                rules={[{ "required": true, "message": "Please select the bias" }]}>
                                                                <Select
                                                                    className="formItemSelect me-2 ms-1"
                                                                    onChange={(e) => onFieldValueChange(index, 'selected_bias', e)}
                                                                    placeholder="Please select"
                                                                    options={biasDD}
                                                                    showSearch={true}
                                                                    filterOption={(input, option) =>
                                                                        option.label.toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                    autoClearSearchValue={false}
                                                                >
                                                                </Select>
                                                            </Form.Item>
                                                            <SVGIcon
                                                                icon={addBiasForm.formData.length === 1 ? 'delete-disabled' : "delete-fill"}
                                                                className={`pt-1 me-4 ${addBiasForm.formData.length === 1 ? 'desableCursor' : 'cursor-pointer'} `}
                                                                onClick={() => deleteSelectedBiasForm(index)} />
                                                        </div>)}
                                                    >
                                                        <div>
                                                            <div className="">
                                                                <Form.Item className="add-bias-form" required>
                                                                    Example Type :
                                                                    <Radio.Group
                                                                        name={"example-type-" + item.id}
                                                                        className="ms-2"
                                                                        key={'example-type-' + item.id}
                                                                        value={item.example_type}
                                                                        rules={[{ "required": true, "message": "Please select the example type" }]}
                                                                        onChange={(e) => onFieldValueChange(index, 'example_type', e.target.value)}
                                                                    >
                                                                        <Radio value="activating">
                                                                            Activating
                                                                        </Radio>
                                                                        <Radio value="mitigating">
                                                                            Mitigating
                                                                        </Radio>
                                                                    </Radio.Group>
                                                                </Form.Item>
                                                            </div>
                                                            <div className="">
                                                                <Form.Item
                                                                    label="How Image Demonstrates Bias"
                                                                    key={'demonstrate-text-' + item.id}
                                                                    rules={[{ "required": true, "message": "Please enter the description" , whitespace:true}]}
                                                                    required
                                                                    name={'demonstrate-bias-' + item.id}
                                                                    value={item.demonstrate_bias}
                                                                >
                                                                    <TextArea placeholder="Add Description"
                                                                        onChange={(e) => onFieldValueChange(index, 'demonstrate_bias', e.target.value)} />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    label="Bias-in-Action Key Message"
                                                                    key={'key-msg-text-' + item.id}
                                                                    value={item.key_message}
                                                                    name={'key-message-' + item.id}
                                                                >
                                                                    <TextArea placeholder="Add Description"
                                                                        onChange={(e) => onFieldValueChange(index, 'key_message', e.target.value)} />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    label="Bias-in-Action Additional Info"
                                                                    key={'additional-info-text-' + item.id}
                                                                    name={'additional-info-' + item.id}
                                                                    value={item.additional_info}
                                                                >
                                                                    <TextArea placeholder="Add Description"
                                                                        onChange={(e) => onFieldValueChange(index, 'additional_info', e.target.value)} />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </Panel>
                                                </Collapse>
                                            ))}
                                        </Form>
                                    </>
                                }
                            </div>

                            <div className="pt-3 add-another-bias-section cursor-pointer ">
                                <span onClick={addAnotherBiasesInActionForm}>
                                    <SVGIcon icon={"add-circle-fill"} />
                                    <span className="add-another-bias ms-2">
                                        Add another Bias associated to this Bias-in-Action
                                    </span>
                                </span>
                            </div>
                        </div>
                    </>
                )}
            </Modal>

            <Modal
                centered
                closable={false}
                open={changesConfirmModal}
                footer={null}
                width={400}
                zIndex={2010}
                onCancel={() => setChangesConfirmModal(false)}
            >
                <h6  className="confirmation-header-text">Confirm</h6>
                <p className="text-muted pt-2">
                    There are unsaved changes in the screen. Are you sure you want to continue?
                </p>
                <Button type="primary" onClick={confirmSaved} className="me-2 mt-3" >
                    Yes
                </Button>
                <Button onClick={() => { setChangesConfirmModal(false) }} className='mt-3'>
                    {" "} No{" "}
                </Button>
            </Modal>

        </>
    );
});
export default AddBiasInAction;
