import React, { createContext, useContext, useState } from "react";
import { Route, Redirect, useHistory, useLocation } from "react-router-dom";
import { VARIABLES } from "../../Environments/constants";
import Login from "../../Pages/Login/Login";
import { sessionService } from "./SessionService";
import menuConfig from '../../Assets/json/menu-top-nav.json';
import { checkAccess } from "./CommonUtil";
import { CONSTANTS } from '../../Environments/constants';
import AuthenticateUser from "../../Pages/Login/AuthenticateUser";

const authContext = createContext();

export function ProvideAuth({ children }) {
  const authProvider = useProvideAuth();
  return (
    <authContext.Provider value={authProvider}>
      {children}
    </authContext.Provider>
  );
}

export function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signIn = (data, callback) => {
    setUser(data);
    if (typeof callback === 'function') callback();
  };

  const signOut = callback => {
    setUser(null);
    sessionService.clearSession(VARIABLES.userSession);
    if (typeof callback === 'function') callback();
  };

  return {
    user,
    signIn,
    signOut
  };
}

function useAuth() {
  return useContext(authContext);
}

const userAccess = route => {
  const KM_CONSULTANT = CONSTANTS?.KM_CONSULTANT;

  // return true;
  const role = sessionService.getSession()?.[VARIABLES.userRole]; // ['admin', 'lead']  
  const routes = route.split('/'); // /home/status
  const settingsRoles = [KM_CONSULTANT, "KM Leadership"]
  if (routes[1] === 'settings') {
    return role && settingsRoles?.includes(role);
  } else if (routes[1] === 'home') { // home
    if (routes[2] === undefined) return true; // /home
    const menu = menuConfig.topMenu.filter(el => el?.key === routes[2])?.[0];
    if (menu === undefined) return false;
    const access = menu?.userAccess;
    return access === undefined || checkAccess(access);
  } else  return false;
}

/** A wrapper for <Route> that redirects to the login screen if you're not yet authenticated. */
export function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) => 
        (auth?.user 
          ? userAccess(rest?.location.pathname)
            ? children
            : <Redirect
                to={{
                pathname: "/home",
                state: { from: location }
                }}
              />
          : <Redirect
            to={{
            pathname: "/login",
            state: { from: location }
            }}
          />
          )
        }
    />
  )
}


export function SecureLogin(props) {
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();

  const [from, setFrom] = useState(location.state?.from || { pathname: "/" });

  useState(() => {
    /** Check user-details in session */
    const userDetails = sessionService.getSession();
    if (userDetails && userDetails[VARIABLES.userName] && userDetails[VARIABLES.userRole]) {
      auth.user = userDetails;
      if (typeof props.onLogin === 'function') props.onLogin(userDetails);
    }
  })

  let login = data => {
    if (data?.redirect) {
      setFrom({pathname: data?.redirect})
      history.replace({pathname: data?.redirect});
    } else {
      history.replace(from);
    }
    auth.signIn(data);
  };
  return (
    auth.user ? <Redirect to={from} /> : <Login updateAuth={login} />
  );
}
export function SecureIDMLogin(props) {
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();

  const [from, setFrom] = useState(location.state?.from || { pathname: "/" });

  useState(() => {
    /** Check user-details in session */
    const userDetails = sessionService.getSession();
    if (userDetails && userDetails[VARIABLES.userName] && userDetails[VARIABLES.userRole]) {
      auth.user = userDetails;
      if (typeof props.onLogin === 'function') props.onLogin(userDetails);
    }
  })

  let login = data => {
    if (data?.redirect) {
      setFrom({pathname: data?.redirect})
      history.replace({pathname: data?.redirect});
    } else {
      history.replace(from);
    }
    auth.signIn(data);
  };

  return (
    auth.user ? <Redirect to={from} /> : <AuthenticateUser updateAuth={login} />
  );
}
export function SecureLogout() {
  const history = useHistory();
  let auth = useAuth();
  auth.signOut(() => {
    setTimeout(() => {
      history.push('/login')
      window.location.reload();
    }, 500);
  });

  return (
    <></>
  )
}
