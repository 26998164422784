import React from "react";
import "./ReadoutPreparation.scss";
import workInProgress from '../../../../../Assets/images/work-in-progress.png';

export default function ReadoutPreparation() {
 
  return (
    // <div>
    //   <FixedTable />
    // </div>
    <div className="form-container">
      <img className='mt-2 workInProgressImage' src={workInProgress} alt="" />
    </div>
  )
}
