import React, { useState, useEffect } from "react";
import { ServiceUtils } from "../../../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../../../Shared/Utils/ToasterUtil";
import TargetAudienceBehavioralObject from "./TargetAudienceBehavioralObject";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import SVGIcon from "../../../../../Shared/Components/SVGIcon/SVGIcon";
import "./ProjectsAttributes.scss";
import FormBuilder from "../../../../../Shared/Components/Ant-FormBuilder/Ant-FormBuilder";
import { Button, Modal, Tooltip } from 'antd';
import formData from '../../../../../Assets/json/projectAttributesFormData.json';


export default function ProjectsAttributes({ saveEvent, ...props }) {
  const [projectId, setProjectId] = useState();
  const [projectFormData, setProjectFormData] = useState({
    client_name: "",
  });
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;
  const route = path.split("/").pop();
  const [
    targetAudienceBehavioralObjectiveData,
    setTargetAudienceBehavioralObjectiveData,
  ] = useState([]);
  const [targetAudienceList, setTargetAudienceList] = useState("");
  const [formConfig, setFormConfig] = useState([JSON.parse(JSON.stringify(formData?.data))]);
  const [formFlag, setFormFlag] = useState(true);
  /** Perform Add user action */
  const [projectCodeList, setProjectCodeList] = useState([]);
  const [saveClicked, setSaveClicked] = useState(false);
  const [navigateConfirmation, setNavigateConfirmation] = useState(false);
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [showAlertOnEdit, setShowAlertOnEdit] = useState(true);
  const [therapyAraBkp, setTherapyAreaBkp] = useState([]);
  const [savedTargetMappingJson, setSavedTargetMappingJson] = useState({});
  const [targetsLoaded, setTargetsloaded] = useState(false);

  useEffect(() => {
    let routeData = path.split("/");
    if (routeData.length === 5 && routeData[3] === 'details') {
      fetchTherapyArea(routeData[4]);
    } else {
      fetchTherapyArea();
      setFormConfig([JSON.parse(JSON.stringify(formData?.data))]);
    }
  }, [location]);

  useEffect(() => {
    fetchProjectCode();
    fetchResearchMethodology();
    fetchCountries();
    setFormConfig([JSON.parse(JSON.stringify(formData?.data))]);
    setShowAlertOnEdit(true);
  }, [])


  /** Project code */
  const fetchProjectCode = () => {
    ServiceUtils.getRequest("projectCode", props.projectId ? `?project_id=${props.projectId}` : '').then((_response) => {
      try {
        if (_response.status === 200) {
          if (_response.data.status === "success") {
            const result = _response?.data?.data;
            const projectCode = result.project_codes;
            setProjectCodeList([...projectCodeList, ...projectCode]);
            const temp = formConfig;
            temp?.map((row) => {
              return row.map((el) => {
                if (el.key === "project_code_id") el.options = projectCode;
                return { ...el };
              });
            });
            setFormConfig([...temp]);
          } else {
            alertService.error(
              _response.data.message || `Error while fetching project code`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  /** Research  methodology */
  const fetchResearchMethodology = () => {
    ServiceUtils.getRequest("researchMethodology").then((response) => {
      try {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data;
            const methodologyList = result.research_methodology;
            const temp = formConfig;
            temp?.map((row) => {
              return row.map((el) => {
                if (el.key === "research_methodology")
                  el.options = methodologyList;
                return { ...el };
              });
            });
            setFormConfig([...temp]);
          } else {
            alertService.error(
              response.data.message ||
              `Error while fetching research methodology list`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  /** Therapy Areas */
  const fetchTherapyArea = (projectId = undefined) => {
    let params = projectId ? `?project_id=${projectId}` : undefined;
    ServiceUtils.getRequest('theraphyArea', params).then((response) => {
      try {
        setTargetsloaded(true);
        if (response.status === 200) {
          if (response.data.status === "success") {
            if (projectId) {
              renderProjectDataInform(projectId);
            }
            const result = response?.data?.data;
            const therapyArea = result.therapy_area;
            const temp = formConfig;
            temp?.map((row) => {
              return row.map((el) => {
                if (el.key === "therapy_areas") el.options = therapyArea;
                return { ...el };
              });
            });
            setFormConfig([...temp]);
          } else {
            alertService.error(
              response.data.message ||
              `Error while fetching target audience list`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  /** Countries*/
  const fetchCountries = () => {
    ServiceUtils.getRequest("countryList").then((response) => {
      try {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data;
            const country = result.country;
            const temp = formConfig;
            temp?.map((row) => {
              return row.map((el) => {
                if (el.key === "country") el.options = country;
                return { ...el };
              });
            });
            setFormConfig([...temp]);
          } else {
            alertService.error(
              response.data.message || `Error while fetching country list`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  /**
   * To handle actions from form builder
   * @param {*} param0 
   */
  const handleFormAction = ({ type, payload }) => {
    switch (type) {
      case "onChange":
        setIsFormEdited(true);
        props?.setIsFormEdit(true);
        if (payload?.key === "project_code_id") {
          fetchABIProjectTeamMember(payload.value);
        }
        setProjectFormData({ ...payload["data"] });
        break;
      case "submit":
        setProjectFormData({ ...payload["data"] });
        if (checkTargetAudienceValidation() && checkDateValidation()) {
          saveProject();
        }
        break;
      default:
        break;
    }
  };

  /**
   * Check date validations
   * @returns valid - true or false
   */
  const checkDateValidation = () => {
    try {
      let valid = true;
      let startDate = projectFormData?.fielding_start_date;
      let endDate = projectFormData?.fielding_end_date;
      if (startDate && endDate) {
        let diff = moment(endDate) - moment(startDate);
        if (diff / (1000 * 60 * 60 * 24) < 0) {
          valid = false;
        }
      } else if (!startDate && endDate) {
        valid = false;
      }

      return valid;
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To check validation of target audience selection
   * @returns true or false
   */
  const checkTargetAudienceValidation = () => {
    try {
      let valid = true;
      if (targetAudienceBehavioralObjectiveData && targetAudienceBehavioralObjectiveData.length) {
        targetAudienceBehavioralObjectiveData.map(obj => {
          if (!obj['specialty_id']) {
            valid = false;
          }
          obj['behavioral_objectives'].map(item => {
            if (!item['behavioral_id'] || !item['description']) {
              valid = false;
            }
          })
        })
      }
      return valid;
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * ABI Primary Contact
   * ABI Project Team Member
   */
  const fetchABIProjectTeamMember = (projectID) => {
    ServiceUtils.postRequest("teamMemberDetails", {
      project_code_id: projectID,
    }).then((response) => {
      try {
        if (response.status === 200) {
          if (response?.data?.status === "success") {
            const result = response?.data?.data;
            const abi = result.abi_contact_list;
            const temp = formConfig;
            temp?.map((row) => {
              return row.map((el) => {
                if (el.key === "abi_project_team_members") {
                  el.options = abi;
                  el.disabled = false;
                }
                return { ...el };
              });
            });
            setFormConfig([...temp]);
            const temp1 = formConfig;
            temp1?.map((row) => {
              return row.map((el) => {
                if (el.key === "abi_primary_contact") {
                  el.options = abi;
                  el.disabled = false;
                }
                return { ...el };
              });
            });
            setFormConfig([...temp1]);
          } else {
            alertService.error(
              response.data.message ||
              `Error while fetching ABI team member list`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  /**
   * To update target audience and behavioral objectives data on change
   * @param {*} data 
   */
  const childCalled = (data) => {
    setIsFormEdited(true);
    props?.setIsFormEdit(true);
    setTargetAudienceBehavioralObjectiveData(data.formData);
  };


  /**
   * get particular project details from project ID
   */
  const renderProjectDataInform = (projectId) => {
    if (projectId) {
      setProjectId(projectId);
      ServiceUtils.postRequest("projectFetch", {
        project_id: projectId,
      }).then((response) => {
        try {
          if (response.status === 200) {
            if (response.data.status === "success") {
              const ta = response?.data?.data.projects_data?.target_audiences;
              setTargetAudienceBehavioralObjectiveData(ta);
              formTargetIdMappingData(ta);
              setTherapyAreaBkp(JSON.parse(JSON.stringify(response?.data?.data.projects_data?.therapy_areas)));
              setTargetAudienceList(ta);
              const temp = formConfig;
              temp?.map((row) => {
                return row.map((el) => {
                  if (
                    el.key === "research_methodology"
                  ) {
                    el.disabled = true;
                  }
                  if (
                    el.key === "therapy_areas"
                  ) {
                    el.placeholder = 'Therapy Area-Disease State';
                  }
                  return { ...el };
                });
              });
              setFormConfig([...temp]);
              const form = response?.data?.data?.projects_data;
              fetchABIProjectTeamMember(form?.project_code_id);
              setProjectFormData({
                project_name: form?.project_name,
                project_code_id: form?.project_code_id,
                client_name: form?.client_name,
                research_methodology: form?.research_methodology_id,
                therapy_areas: form?.therapy_areas,
                country: form?.country_id,
                fielding_start_date: form?.fielding_start_date ? moment.utc(form?.fielding_start_date, 'YYYY-MM-DD') : null,
                fielding_end_date: form?.fielding_end_date ? moment.utc(form?.fielding_end_date, 'YYYY-MM-DD') : null,
                abi_primary_contact: form?.abi_primary_contact,
                abi_project_team_members: form?.abi_project_team_members,
                nsize: form?.nsize,
              });
              if (typeof saveEvent === 'function') saveEvent({ 'projectData': response?.data?.data?.projects_data });
            } else {
              alertService.error(
                response.data.message || `Error while fetching project details`
              );
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    }
  };

  /** Save  project Method */
  const saveProject = () => {
    let formData = { ...projectFormData };
    formData['therapy_areas'] = updateNewIds(formData['therapy_areas']);
    let finalPayload = {
      ...formData,
      nsize: Number(formData.nsize),
      target_audiences: updateTargetIds(targetAudienceBehavioralObjectiveData)
    };
    if (route === "details") {
      /**
       * create new project
       */
      ServiceUtils.postRequest("createProject", finalPayload).then(
        (response) => {
          try {
            if (response.status === 200) {
              if (response.data.status === "success") {
                history.push({
                  pathname: `/home/project/details/${response?.data?.data?.projects.project_id}`,
                });
                alertService.success(
                  response?.data?.data?.message ||
                  `Successfully created project`
                );
                setSaveClicked(false);
                if (typeof saveEvent === 'function') saveEvent({ 'navigate': true, 'projectId': response?.data?.data?.projects.project_id });
              } else {
                alertService.error(
                  response.data.message || `Error while Creating Project`
                );
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      );
    } else {
      /**
       * Update existing project
       */
      let formData = { ...projectFormData };
      formData['therapy_areas'] = updateNewIds(formData['therapy_areas']);
      let finalPayload = {
        ...formData,
        project_id: projectId,
        nsize: Number(projectFormData.nsize),
        target_audiences: updateTargetIds(targetAudienceBehavioralObjectiveData),
      };
      ServiceUtils.postRequest("updateProject", finalPayload).then(
        (response) => {
          try {
            if (response.status === 200) {
              if (response.data.status === "success") {
                alertService.success(
                  response?.data?.data?.message ||
                  `Successfully updated project`
                );
                setSaveClicked(false);
                if (typeof saveEvent === 'function') saveEvent({ 'navigate': true, 'projectId': projectId });
              } else {
                alertService.error(
                  response.data.message || `Error while updating Project`
                );
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      );
    }
  };

  /** Cancel Project Create */
  const cancelProjectCreate = () => {
    try {
      if (isFormEdited) {
        setNavigateConfirmation(true);
      } else {
        history.push("/home/project");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const Navigate = () => {
    try {
      history.push("/home/project");
      setNavigateConfirmation(false);
      setIsFormEdited(false);
      props?.setIsFormEdit(false);
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To show notification
   */
  const showAlertEvent = (event) => {
    try {
      if (event?.showAlert && showAlertOnEdit && props?.projectId) {
        // notification.info({
        //   description:
        //     'Editing this field will effect the rest of the project',
        //   placement: 'top',
        //   style:{
        //     width: 1000,
        //     backgroundColor: '#e2a935',
        //     color: 'white'
        //   },
        //   top: 0,
        //   duration: 5,
        // });
        // commented this for now bcoz it's now required
        setShowAlertOnEdit(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To update selected therapy area data before save
   * @param {*} data - Therapy area data
   * @param {*} key - data key
   * @returns - updated data with ids for save input
   */
  const updateNewIds = (data) => {
    try {
      data.map((therapy, ind) => {
        let index = therapyAraBkp.findIndex(item => item.value === therapy?.value);
        if (index !== -1) {
          data[ind]['proj_therapy_id'] = therapyAraBkp[index]?.proj_therapy_id;
        } else {
          data[ind]['proj_therapy_id'] = 0;
        }
      })
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To update target ids data
   * @param {*} targetData - targets data
   * @returns - updated target data with ids
   */
  const updateTargetIds = (targetData) => {
    try {
      targetData.map((target) => {
        if (savedTargetMappingJson?.targets && savedTargetMappingJson?.targets?.length && savedTargetMappingJson?.targets?.includes(target?.specialty_id)) {
          target['is_new'] = false;
          target['proj_aud_id'] = savedTargetMappingJson?.mappingData[target?.specialty_id];
        } else {
          target['is_new'] = true;
          target['proj_aud_id'] = 0;
        }
        return target;
      });
      return targetData;
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To form save target data mapping json
   */
  const formTargetIdMappingData = (targetData) => {
    try {
      let targets = [];
      let mappingData = {};
      targetData.map(target => {
        targets.push(target?.specialty_id);
        mappingData[target?.specialty_id] = target?.proj_aud_id;
        return target;
      });
      setSavedTargetMappingJson({ 'targets': targets, 'mappingData': mappingData });
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <div className="project-attribute-section ps-2">
      { targetsLoaded && <div className="col-md-6 attributePageSuggestion ">
        <span>
          These required attributes define the scope and purpose of your
          project. They will also be used by Braincase to suggest biases,
          interview questions, etc, based on historical projects ...
        </span>
        <Tooltip placement="topLeft" color={'black'} arrowPointAtCenter={true} overlayClassName={'project-attr-desc tooltip-bg'} title={'These required attributes define the scope and purpose of your project. They will also be used by Braincase to suggest biases, interview questions, etc, based on historical projects with similar attributes.'}>
          <SVGIcon icon={'info-fill'} className="m-0 ps-1 fontCircleInfo" />
        </Tooltip>
      </div>}
      <div className="project-attr-form">
        {formFlag && (
          <div className="form-container">
            <div className="formDiv d-flex pl-2">
              <div className="formChild requestAttributeDiv  col-md-3 p">
              </div>
              <div className="formChild col-md-12">
                {targetsLoaded && <FormBuilder
                  id="create-project"
                  config={formConfig}
                  data={projectFormData}
                  onAction={handleFormAction}
                  projectId={props.projectId}
                  saveClicked={saveClicked}
                  showAlertEvent={showAlertEvent}
                />}
              </div>
            </div>
            <div className="formDiv  w-100">
              {targetsLoaded && <TargetAudienceBehavioralObject
                parentCall={(data) => childCalled(data)}
                targetData={targetAudienceList}
                saveClicked={saveClicked}
                projectId={props.projectId}
              />}
            </div>
          </div>
        )}
      </div>
      {targetsLoaded && <div className="col-12 m-3 text-align-right">
        <Button className="me-3" onClick={cancelProjectCreate}> Cancel </Button>
        <Button type="primary" htmlType="submit" form="create-project" className="me-4" onClick={() => setSaveClicked(true)}>Save</Button>
      </div>}
      <Modal
        centered
        closable={false}
        open={navigateConfirmation}
        footer={null}
        width={400}
        zIndex={2010}
        onCancel={() => setNavigateConfirmation(false)}
      >
        <h6  className="confirmation-header-text">Confirm</h6>
        <p className="text-muted pt-1">
          There are unsaved changes in the screen. Are you sure you want to continue?
        </p>
        <Button type="primary" onClick={Navigate} className="me-2 mt-3" >
          Yes
        </Button>
        <Button onClick={() => { setNavigateConfirmation(false) }} className='mt-3'>
          {" "} No{" "}
        </Button>
      </Modal>
    </div>
  );
}