import React, { useState, useEffect } from "react";
import { ServiceUtils } from "../../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../../Shared/Utils/ToasterUtil";
import "./QualitativeQuestions.scss";
import { Table, Spin, Drawer, Button, Row, Col, Modal, Tooltip, Form, Select, TreeSelect } from "antd";
import SVGIcon from "../../../../Shared/Components/SVGIcon/SVGIcon";
import { defaultAntSort } from "../../../../Shared/Utils/CommonUtil";
import { DebounceInput } from "react-debounce-input";
import filterFormData from "../../../../Assets/json/qualques-filter-form.json";
import FormBuilder from "../../../../Shared/Components/Ant-FormBuilder/Ant-FormBuilder";
import TextArea from "antd/lib/input/TextArea";
import CustomParagraphToolTip from "../../../../Shared/Components/CustomParagraphToolTip/CustomParagraphToolTip";

export default function QualitativeQuestions({ onAction }) {

  const [showLoader] = useState(false);
  const [biasesFilterDrawer, setBiasesFilterDrawer] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [question_id, setQuestion_id] = useState("");
  const [addMode, setAddMode] = useState("");
  const [form] = Form.useForm();
  const [filterConfiguration, setFilterConfiguration] =
    useState(filterFormData);
  const [popupMode, setPopupmode] = useState("");
  const [searchText, setSearchText] = useState("");
  const [tableDataText, setTableDataText] = useState("No Data Found");
  const [changesConfirmModal, setChangesConfirmModal] = useState(false);
  const [addQualSaved, setAddQualSaved] = useState(false);
  const [tableData, setTableData] = useState({
    body: [],
    header: [],
    filteredData: [],
  });
  const [filterPayload, setFilterPayload] = useState({});
  const dataSrc = tableData?.filteredData;
  const [textError, setTextError] = useState(false);
  const [selectedFilterPayload, setSelectedFilterPayload] = useState({
    bias: [],
    target_audience: [],
    behavioral_objective_category: [],
    therapy_area: [],
  });
  const [targetAudienceData, setTargetAudienceData] = useState([]);
  const [therapyAreaData, setTherapyAreaData] = useState([]);
  const [boCategoryData, setBoCategoryData] = useState([]);
  const [biasData, setBiasData] = useState([]);
  const [qnsId, setQnsId] = useState("");
  let noDataFound = {
    emptyText: tableDataText,
  };
  const panelHeader = () => (
    <React.Fragment>
      <Row>
        <Col className="p-2" span={14}>
          Question
        </Col>
        <Col span={8}></Col>
        <Col className="p-2" span={2}>
          Action
        </Col>
      </Row>
    </React.Fragment>
  );
  const columns = [
    {
      title: "Bias",
      dataIndex: "bias_name",
      key: "bias_name",
      width: 85,
      defaultSortOrder: "ascend",
      sorter: (a, b) => defaultAntSort(a.bias_name, b.bias_name),
    },
    {
      title: panelHeader(),
      dataIndex: "questions",
      key: "questions",
      width: 250,
      render: (_, data) => {
        return (
          <>
            {data.questions.map((item, i) => {
              return (
                <div>
                  <div
                    className={
                      data.questions.length - 1 === i
                        ? "p-2"
                        : "border-bottom p-2"
                    }
                  >
                    <Row>
                      <Col span={14}>
                        <CustomParagraphToolTip ellipsis={{ rows: 3 }} tooltipContent={item.question} paragrapgContent={item.question} />
                      </Col>
                      <Col span={8}></Col>
                      <Col span={2}>
                        <div style={{ float: "right", display: "flex" }}>
                          <Tooltip
                            placement="topRight"
                            overlayClassName={"tooltip-bg"}
                            title="Edit"
                          >
                            <SVGIcon
                              icon={"edit-fill"}
                              className="fontIcon"
                              onClick={() => showModal("edit", item)}
                            />
                          </Tooltip>
                          <Tooltip
                            placement="topRight"
                            overlayClassName={"tooltip-bg"}
                            title="Delete"
                          >
                            <SVGIcon
                              icon={"delete-fill"}
                              className="fontIcon"
                              onClick={() => showDeleteModal(item?.question_id)}
                            />
                          </Tooltip>
                        </div>{" "}
                      </Col>
                    </Row>
                  </div>
                </div>
              );
            })}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    let body = JSON.parse(JSON.stringify(tableData?.body));
    if (searchText.trim()) {
      const searchTerm = searchText.toString().toLowerCase();
      const searchData = body?.filter((value) => {
        let filtered = false;
        let childList = [];
        value?.questions.filter((childData) => {
          if (childData?.question.toLowerCase()?.includes(searchTerm)) {
            filtered = true;
            childList.push(childData);
          }
          return childData;
        });
        if (filtered) {
          value["questions"] = childList;
          return value;
        } else {
          return value?.bias_name?.toLowerCase()?.includes(searchTerm);
        }
      });
      setTableData({
        ...tableData,
        filteredData: searchData,
      });
    } else {
      setTableData({
        ...tableData,
        filteredData: body,
      });
    }
  }, [searchText]);

  useEffect(() => {
    if (filterConfiguration) {
      fetchAudience();
      fetchBOCategory();
      fetchTherapyArea();
      fetchBiasDropDown();
      getQuestionList();
    }
  }, []);

  /**
   * Navigate confirmation 
   */
  const navigateConfirmation = () => {
    try {
      handleCancel(false);
      setChangesConfirmModal(false);
      setModalVisible(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * To show delete confirmation modal
   * @param {*} question_id - question id
   */
  const showDeleteModal = (question_id) => {
    try {
      setDeleteModalVisible(true);
      setQuestion_id(question_id);
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * On search value change
   * @param {*} searchtext - search data
   */
  const onSearchChange = (searchtext) => {
    try {
      if (tableData?.filteredData.length === 0) {
        setTableDataText("No result Found"); // if there is no data in project list
      } else {
        setTableDataText(
          "No search result returned. Please modify your search criteria to view Qualitative Questions."
        ); // if  no search result found from project list
      }
      setSearchText(searchtext);
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * To show filter drawer
   */
  const showFilterDrawer = () => {
    try {
      setFilterPayload({ ...selectedFilterPayload });
      setBiasesFilterDrawer(true);
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * To apply quantitative filter
   */
  const applyQuantitativeFilter = () => {
    try {
      const payload =
        filterPayload?.behavioral_objective_category?.length === 0 &&
        filterPayload?.bias?.length === 0 &&
        filterPayload?.target_audience?.length === 0 &&
        filterPayload?.therapy_area?.length === 0;
      const selectedPayload =
        selectedFilterPayload?.behavioral_objective_category?.length === 0 &&
        selectedFilterPayload?.bias?.length === 0 &&
        selectedFilterPayload?.target_audience?.length === 0 &&
        selectedFilterPayload?.therapy_area?.length === 0;

      if (payload && selectedPayload) {
        setBiasesFilterDrawer(false);
      } else {
        setSelectedFilterPayload({ ...filterPayload });
        getQuestionList(filterPayload);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * To reset filters
   */
  const resetFilter = () => {
    try {
      const selectedPayload =
        selectedFilterPayload?.behavioral_objective_category?.length === 0 &&
        selectedFilterPayload?.bias?.length === 0 &&
        selectedFilterPayload?.target_audience?.length === 0 &&
        selectedFilterPayload?.therapy_area?.length === 0;
      const resetFilterPayload = {
        bias: [],
        target_audience: [],
        behavioral_objective_category: [],
        therapy_area: [],
      };
      if (selectedPayload) {
        setBiasesFilterDrawer(false);
      } else {
        getQuestionList(resetFilterPayload);
        setSelectedFilterPayload(resetFilterPayload);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * To get question list
   * @param {*} filterData - filter data
   * @param {*} loader - to show loader or not
   */
  const getQuestionList = (filterData = null, loader = true) => {
    try {
      ServiceUtils.postRequest(
        "tableQualQues",
        filterData ? filterData : selectedFilterPayload,
        loader
      ).then((_response) => {
        if (_response.status === 200) {
          if (_response.data.status === "success") {
            setTableData(_response.data);
            const result = _response?.data?.data;
            const temp = {
              body: result.question_list,
              headers: [
                {
                  key: "bias_name",
                  label: "Bias",
                  sort: true,
                },
                {
                  key: "questions_list",
                  label: "Question",
                },
                {
                  key: "actions",
                  label: "Action",
                },
              ],
            };
            temp.header = temp?.headers;
            temp.body = result?.question_list;
            temp.filteredData = result?.question_list;
            setTableData({ ...temp });
            setBiasesFilterDrawer(false);
          } else {
            alertService.error(
              _response.data.message || `Error while fetching biases list`
            );
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * To fetch audience data
   */
  const fetchAudience = () => {
    try {
      ServiceUtils.getRequest("targetAudienceList").then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data;
            const audienceList = result.targeted_audience;
            setTargetAudienceData(audienceList);
            filterConfiguration?.map((row) => {
              return row.map((el) => {
                if (el.key === "target_audience") el.options = audienceList;
                return { ...el };
              });
            });
          } else {
            alertService.error(
              response.data.message || `Error while fetching target audience list`
            );
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * To fetch therapy area data
   */
  const fetchTherapyArea = () => {
    try {
      ServiceUtils.getRequest("theraphyArea").then((response) => {
        try {
          if (response.status === 200) {
            if (response.data.status === "success") {
              const result = response?.data?.data;
              const therapyAreaData = result.therapy_area;
              setTherapyAreaData(therapyAreaData);
              const temp = filterConfiguration;
              temp?.map((row) => {
                return row.map((el) => {
                  if (el.key === "therapy_area") el.options = therapyAreaData;
                  return { ...el };
                });
              });
              setFilterConfiguration([...temp]);
            } else {
              alertService.error(
                response.data.message || `Error while fetching Therapy Area`
              );
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * To fetch BOCategory data
   */
  const fetchBOCategory = () => {
    try {
      ServiceUtils.getRequest("boListGroup").then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data;
            const boCategory = result?.behavioral_objective_category_group;
            setBoCategoryData(boCategory);
            const temp = filterConfiguration;
            temp?.map((row) => {
              return row.map((el) => {
                if (el.key === "behavioral_objective_category")
                  el.options = boCategory;
                return { ...el };
              });
            });
            setFilterConfiguration([...temp]);
          } else {
            alertService.error(
              response.data.message ||
              `Error while fetching behavioral objective category list`
            );
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * To fetch bias data
   */
  const fetchBiasDropDown = () => {
    try {
      ServiceUtils.getRequest("biasList").then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data?.bias_data;
            result.forEach((element) => {
              element["label"] = element["bias_name"];
              element["value"] = element["bias_id"];
            });
            const biasList = result;
            setBiasData(biasList);
            const temp = filterConfiguration;
            temp?.map((row) => {
              return row.map((el) => {
                if (el.key === "bias") el.options = biasList;
                return { ...el };
              });
            });
            setFilterConfiguration([...temp]);
          } else {
            alertService.error(
              response.data.message ||
              `Error while fetching behavioral objective category list`
            );
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * On delete question
   */
  const deleteQuestion = () => {
    try {
      ServiceUtils.postRequest("deleteQualQues", {
        question_id: question_id,
      }).then((_response) => {
        try {
          if (_response.status === 200) {
            setDeleteModalVisible(false);
            if (_response.data.status === "success") {
              alertService.success(
                _response.data.message || `Successfully delete question`
              );
              getQuestionList(); // Reload table data
            } else {
              alertService.error(
                _response.data.message || `Error while deleting question`
              );
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * On view edit questions
   * @param {*} questionID - question Id
   */
  const viewEditQuestion = (questionID) => {
    try {
      ServiceUtils.postRequest("viewEditQuestion", {
        question_id: questionID,
      }).then((_response) => {
        try {
          if (_response.status === 200) {
            setQnsId(questionID);
            const fieldData = _response?.data?.data;
            form.setFieldsValue({ ...fieldData });
            setModalVisible(true);
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * On add new question
   * @param {*} values - add new data
   */
  const addNewQuestion = (values) => {
    try {
      ServiceUtils.postRequest("addNewQuestion", {
        question: values?.question,
        bias: values?.bias,
        behavioral_objective_category: values?.behavioral_objective_category,
        therapy_area: values?.therapy_area,
        target_audience: values?.target_audience,
      }).then((response) => {
        try {
          if (response.status === 200) {
            if (response.data.status === "success") {
              getQuestionList();
              alertService.success(response.data.message);
              setModalVisible(false);
              setAddQualSaved();
            } else {
              alertService.error(response.data.message);
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * To save all questions
   * @param {*} values - questions data
   */
  const saveAllQuestion = (values) => {
    try {
      let input = {
        question: values?.question,
        bias: values?.bias,
        behavioral_objective_category: values?.behavioral_objective_category,
        therapy_area: values?.therapy_area,
        target_audience: values?.target_audience,
      };
      ServiceUtils.postRequest("addNewQuestion", input).then((response) => {
        try {
          if (response.status === 200) {
            if (response.data.status === "success") {
              getQuestionList("", false);
              alertService.success(response.data.message);
              setAddQualSaved();
              form.resetFields();
            } else {
              alertService.error(response.data.message);
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * To update edited question
   * @param {*} values - question data
   */
  const updateEditQuestion = (values) => {
    try {
      let input = {};
      input = {
        question: values?.question,
        bias: values?.bias,
        behavioral_objective_category: values?.behavioral_objective_category,
        therapy_area: values?.therapy_area,
        target_audience: values?.target_audience,
        question_id: qnsId,
      };
      ServiceUtils.postRequest("updateEditQuestion", input).then((response) => {
        try {
          if (response.status === 200) {
            if (response.data.status === "success") {
              getQuestionList();
              alertService.success(response.data.message);
              setModalVisible(false);
            } else {
              alertService.error(response.data.message);
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleFilterFormAction = ({ type, payload }) => {
    changeKeyValuPareToValu(payload);
  };

  const changeKeyValuPareToValu = (payload) => {
    try {
      const formData = payload.data;
      if (formData.target_audience.length > 0) {
        let TauDropDownData = [];
        formData.target_audience.map((el) => {
          if (el?.value) {
            TauDropDownData.push(el?.value);
          } else {
            TauDropDownData.push(el);
          }
          return el;
        });
        formData.target_audience = TauDropDownData;
      }
      if (formData.behavioral_objective_category.length > 0) {
        let BocDropdownData = [];
        formData.behavioral_objective_category.map((el) => {
          if (el?.value) {
            BocDropdownData.push(el?.value);
          } else {
            BocDropdownData.push(el);
          }
          return el;
        });
        formData.behavioral_objective_category = BocDropdownData;
      }
      if (formData.therapy_area.length > 0) {
        let TADropDownData = [];
        formData.therapy_area.map((el) => {
          if (el?.value) {
            TADropDownData.push(el?.value);
          } else {
            TADropDownData.push(el);
          }
          return el;
        });
        formData.therapy_area = TADropDownData;
      }
      setFilterPayload(formData);
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * To show modal
   * @param {*} mode - add or edit mode
   * @param {*} data - data
   */
  const showModal = (mode, data) => {
    try {
      form.resetFields();
      if (mode === "add") {
        const fieldData = {
          question: "",
          bias: [],
          target_audience: [],
          behavioral_objective_category: [],
          therapy_area: [],
          question_id: null,
        };
        setPopupmode(mode);
        setModalVisible(true);
      }
      if (mode === "edit") {
        setPopupmode(mode);
        viewEditQuestion(data?.question_id);
        setQuestion_id(data?.question_id);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleOk = () => {
    try {
      setDeleteModalVisible(false);
      setModalVisible(false);
      setTimeout(() => { }, 1000);
      setAddQualSaved();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCancel = (isCancel) => {
    try {
      setTextError(false);
      if (isCancel && addQualSaved) {
        setChangesConfirmModal(true);
      } else {
        setDeleteModalVisible(false);
        form.resetFields();
        setModalVisible(false);
        setAddQualSaved(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * On form finish
   * @param {*} e - finish event
   */
  const onFinishForm = (e) => {
    try {
      if (popupMode === "add") {
        if (addMode === "save") {
          addNewQuestion(e);
        }
        if (addMode === "saveAll") {
          saveAllQuestion(e);
        }
      }
      if (e.question.trim() !== "") {
        if (popupMode === "edit") {
          updateEditQuestion(e);
        }
      }
      else {
        alertService.error("Failed to create qualitative questions");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * On form finish fail
   * @param {*} e - fail event
   */
  const onFinishFailedForm = (e) => {
    try {
      if (e) {
        e.errorFields.map((el) => {
          if (el.name[0] === "question") {
            setTextError(true);
          } else {
            setTextError(false);
          }
          return el;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * On value changes in form
   * @param {*} changedValues - changed values
   * @param {*} allValues - all values
   */
  const onFormLayoutChange = (changedValues, allValues) => {
    try {
      const payload = {};
      payload["data"] = { ...allValues };
      setAddQualSaved(allValues);
      if (typeof onAction === "function") onAction({ type: "onChange", payload });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="p-2 mt-1 qualitativeQusContainer">
      <Row>
        <Col span={7} className="searchFill ps-2 d-flex">
          <DebounceInput
            debounceTimeout={500}
            type="text"
            className="form-control form-control-sm"
            placeholder="Search Qualitative Questions"
            onChange={(e) => {
              onSearchChange(e.target.value);
            }}
          />
          <button className="searchButton text-muted me-2">
            {showLoader ? (
              <Spin size="small" />
            ) : (
              <SVGIcon icon={"search-transparent"} />
            )}
          </button>
          <div className="ps-2 pt-2">
            <span className="filtersButton" onClick={showFilterDrawer}>
              <SVGIcon icon={"filter"} />{" "}
              <span className="filter-text">Filters</span>
            </span>
          </div>
        </Col>
        <Col span={4} offset={13} className="ps-3 addQusBtn">
          <Button
            type="primary"
            className="primary-btn main-add"
            onClick={() => showModal("add")}
          >
            <SVGIcon icon={"add-circle-white"} className="iconPositionInButton" />
            &nbsp;Add New Question
          </Button>
        </Col>
      </Row>

      <div className="p-2 qualitative-table">
        <Table
          dataSource={dataSrc}
          columns={columns}
          pagination={false}
          scrollToFirstRowOnChange={true}
          scroll={{ y: "calc(100vh - 220px)" }}
          showSorterTooltip={false}
          locale={noDataFound}
        />
      </div>

      {/* filter antd drawer  */}
      <div className="qualitativeFilter">
        <Drawer
          title="Qualitative Test Design Filters"
          placement={"right"}
          width={"28%"}
          closable={"right"}
          onClose={() => { setBiasesFilterDrawer(false); }}
          open={biasesFilterDrawer}
          footer={
            <>
              <Button
                type="primary"
                className="me-1"
                onClick={applyQuantitativeFilter}
              >
                Apply Filters
              </Button>
              <Button className="me-2" onClick={resetFilter}>
                Reset Filters
              </Button>
              <Button
                onClick={() => {
                  setBiasesFilterDrawer(false);
                }}
              >
                {" "}
                Cancel{" "}
              </Button>
            </>
          }
        >
          <div className="px-2 antFilterFormBuilder">
            <FormBuilder
              id="form-biases-filter"
              data={filterPayload}
              onAction={handleFilterFormAction}
              config={filterConfiguration}
            />
          </div>
        </Drawer>
      </div>

      {/* Add Edit Qualitative Questions */}
      <Modal
        centered
        title={
          popupMode === "edit"
            ? "Edit Qualitative Question"
            : "Add a Qualitative Question"
        }
        open={modalVisible}
        onOk={handleOk}
        onCancel={() => {
          handleCancel(true);
        }}
        bodyStyle={{ padding: "0.1rem 0.7rem 0rem 0.7rem" }}
        className="qualitative-question px-2"
        width={700}
        footer={[
          <div className="edit-action">
            <Button
              key="save"
              type="primary"
              form="forms"
              htmlType="submit"
              className="pt-1 m-1"
              onClick={() => setAddMode("save")}
            >
              Save
            </Button>
            {popupMode === "add" ? (
              <Button
                key="saveAll"
                form="forms"
                htmlType="submit"
                className="pt-1 m-1"
                onClick={() => setAddMode("saveAll")}
              >
                Save & Add New Question
              </Button>
            ) : null}
            <Button
              key="cancel"
              className="pt-1"
              onClick={() => handleCancel(true)}
            >
              Cancel
            </Button>
          </div>,
        ]}
      >
        <div className="pt-2">
          <Form
            id={"forms"}
            form={form}
            layout="vertical"
            onFinish={onFinishForm}
            onFinishFailed={onFinishFailedForm}
            onValuesChange={onFormLayoutChange}
            autoComplete="off"
          >
            <Row>
              <Col span={12} className='pe-2'>
                <Form.Item
                  key="bias"
                  name="bias"
                  label="Bias"
                  rules={[{ required: true, message: "Please Select Bias" }]}
                >
                  <Select
                    placeholder="Please select"
                    allowClear
                    maxTagCount="responsive"
                    options={biasData}
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={12} className='px-2' >
                <Form.Item
                  key="target_audience"
                  name="target_audience"
                  label="Target Audiences"
                  rules={[
                    { required: true, message: "Please Select Target Audience" },
                  ]}
                >
                  <TreeSelect
                    treeData={targetAudienceData}
                    showSearch
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    placeholder="Please select"
                    allowClear
                    maxTagCount="responsive"
                    treeNodeFilterProp={"title"}
                    treeNodeLabelProp="label"
                    treeCheckable={true}
                    multiple
                  ></TreeSelect>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} className='pe-2' >
                <Form.Item
                  key="behavioral_objective_category"
                  name="behavioral_objective_category"
                  label="Behavioral Objective Categories"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Behavioral Objective Categories",
                    },
                  ]}
                >
                  <TreeSelect
                    showSearch
                    treeData={boCategoryData}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    placeholder="Please select"
                    allowClear
                    maxTagCount="responsive"
                    treeNodeFilterProp={"title"}
                    treeNodeLabelProp="label"
                    treeCheckable={true}
                    multiple
                  ></TreeSelect>
                </Form.Item>
              </Col>
              <Col span={12} className='px-2'>
                <Form.Item
                  key="therapy_area"
                  name="therapy_area"
                  label="Therapy Areas"
                  rules={[
                    { required: true, message: "Please Select Therapy Area" },
                  ]}
                >
                  <TreeSelect
                    showSearch
                    treeData={therapyAreaData}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    placeholder="Please select"
                    allowClear
                    maxTagCount="responsive"
                    treeNodeFilterProp={"title"}
                    treeNodeLabelProp="label"
                    treeCheckable={true}
                    multiple
                  ></TreeSelect>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  key="question"
                  name="question"
                  label="Question"
                  rules={[{ required: true, message: "Please Add a Question", whitespace: true }]}
                >
                  <TextArea
                    placeholder="Add a Question"
                    rows={7}
                    className={`${textError ? "error-section" : ""}`}
                    onChange={() => setTextError(false)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>

      {/* Delete Qualitative Ques */}
      <>
        <Modal
          centered
          open={deleteModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={false}
          width={"30%"}
          zIndex={2010}
        >
          <div>
            <h6 className="confirmation-header-text">Delete Qualitative Question</h6>
          </div>
          <p className="text-muted pt-1">
            Are you sure you would like to delete this Qualitative Question?
          </p>
          <Button
            type="primary"
            className="me-2 mt-3"
            onClick={() => deleteQuestion()}
          >
            Delete
          </Button>
          <Button onClick={() => handleOk()} className='mt-3'> Cancel </Button>
        </Modal>
      </>

      {/* Confimation Modal */}
      <Modal
        centered
        closable={false}
        open={changesConfirmModal}
        footer={null}
        width={400}
        zIndex={2010}
        onCancel={() => setChangesConfirmModal(false)}
      >
        <h6  className="confirmation-header-text">Confirm</h6>
        <p className="text-muted pt-1">
          There are unsaved changes in the screen. Are you sure you want to
          continue?
        </p>
        <Button type="primary" onClick={navigateConfirmation} className="me-2 mt-3">
          Yes
        </Button>
        <Button
          onClick={() => {
            setChangesConfirmModal(false);
          }} className='mt-3'
        >
          {" "}
          No{" "}
        </Button>
      </Modal>
    </div>
  );
}
