import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Cascader,
  TreeSelect,
  Button,
  Checkbox,
  Radio,
  Tooltip
} from "antd";
import "./Ant-FormBuilder.scss";
import SVGIcon from "../SVGIcon/SVGIcon";
import moment from "moment";

// Form supports fields of type
// 1. Text
// 2. TextArea
// 3. Number
// 4. Password
// 5. Single Select
// 6. Multi Select
// 7. Single Select with custom search
// 8. Multi Select with custom search
// 9. Cascader
// 10. TreeSelect
// 11. DatePicker
// 12. Checkbox
// 13. Radio


export default function FormBuilder({ config, onAction, data, id, showAlertEvent, ...props }) {
  const [form] = Form.useForm();
  const formLayout = "vertical";
  const { TextArea } = Input;
  const [searchValue, setSearchValue] = useState({});
  const [resetField, setResetField] = useState({});

  useEffect(() => {
    if (data) {
      form.setFieldsValue({ ...data });
    }
  }, [data]);

  useEffect(() => {
    let searchObj = {};
    let fieldObj = {};
    let tempConfig = JSON.parse(JSON.stringify(config));
    tempConfig[0].map((field) => {
      if (field?.customSearch) {
        searchObj[field?.key] = '';
        fieldObj[field?.key] = true;
      }
    });
    setSearchValue(searchObj);
    setResetField(fieldObj);
  }, [config]);

  /**
   * On input value changes
   * @param {*} e - change event
   * @param {*} field - form field
   */
  const onSelectChange = (e, field) => {
    try {
      if (field?.key === "project_code_id") {
        let clientName = "";
        field?.options.map((option) => {
          if (option?.value === e) {
            clientName = option?.client_name;
          }
        });
        let tempData = data;
        tempData["project_code_id"] = e;
        tempData["client_name"] = clientName;
        tempData['abi_primary_contact'] = [];
        tempData['abi_project_team_members'] = []
        form.setFieldsValue({ ...tempData });
        const payload = {
          key: "project_code_id",
          value: e,
        };
        payload["data"] = { ...tempData };
        if (typeof onAction === "function")
          onAction({ type: "onChange", payload });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onFormLayoutChange = (changedValues, allValues) => {
    const payload = {};
    payload["data"] = { ...allValues };
    if (typeof onAction === "function") onAction({ type: "onChange", payload });
  };

  /**
   * Finish event of form
   * @param {*} formData - form data
   */
  const onFinish = (formData) => {
    try {
      const payload = {
        data: formData,
      };
      if (typeof onAction === "function") onAction({ type: "submit", payload });
    } catch (error) {
      console.log("error", error);
    }
  };

  const checkDateValidation = () => {
    try {
      let valid = true;
      let startDate = data?.fielding_start_date;
      let endDate = data?.fielding_end_date;
      let diff = moment(endDate) - moment(startDate);
      if (diff / (1000 * 60 * 60 * 24) >= 0) {
        valid = false;
      }
      return valid;
    } catch (error) {
      console.log("error", error);
    }
  }

  const onDropDownOpen = (open, field) => {
    try {
      if (!open) {
        return;
      }
      if (typeof showAlertEvent === 'function') showAlertEvent({ showAlert: field?.showAlertOnEdit });
    } catch (error) {
      console.log("error", error);
    }
  }

  const setSearchValues = (value, field) => {
    try {
      let tempSearch = JSON.parse(JSON.stringify(searchValue));
      tempSearch[field?.key] = value;
      setSearchValue(tempSearch);
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * On dropdown visible change event
   * @param {*} visible is visible or not
   * @param {*} field = field data
   */
  const onDropdownVisibleChange = (visible, field) => {
    try {
      if (visible && searchValue[field?.key]) {
        setTimeout(() => {
          setSearchValues("", field);
          resetField[field?.key] = false;
          setResetField({ ...resetField });
          setTimeout(() => {
            resetField[field?.key] = true;
            setResetField({ ...resetField });
          }, 1);
        }, 1);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <Form
      id={id}
      form={form}
      layout={formLayout}
      onFinish={onFinish}
      onValuesChange={onFormLayoutChange}
      className="form-builder"
    >
      <Row>
        {config[0].map((field) => {
          return (
            <Col span={field?.col} key={field?.key}>
              {field?.type && (
                <>
                  <label className="field-label">
                    {field?.label}
                    {field.optional && (
                      <span className="optional">(optional)</span>
                    )}
                  </label>
                  {field?.tooltip && (
                    <>
                      <Tooltip placement="top" title={field?.tip} overlayClassName={'tooltip-bg'}>
                        <SVGIcon icon={'info-fill'} className="m-0 ps-1 fontCircleInfo" />
                      </Tooltip>
                    </>
                  )}
                </>
              )}
              {field?.type && field?.type === "input" && (
                <>
                  <Form.Item name={field?.key} rules={field?.rules}>
                    <Input
                      placeholder={field?.placeholder}
                      disabled={field?.disabled}
                    />
                  </Form.Item>
                </>
              )}
              {field?.type && field?.type === "select" && (
                <>
                  <Form.Item name={field?.key} rules={field?.rules}>
                    <Select allowClear={field?.allowClear !== undefined ? field?.allowClear : true}
                      placeholder={field?.placeholder}
                      onChange={(e) => onSelectChange(e, field)}
                      options={field?.options}
                      disabled={field?.disabled}
                      maxTagCount="responsive"
                      notFoundContent="No Data Found"
                      showSearch={field?.search ? field?.search : false}
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                    ></Select>
                  </Form.Item>
                </>
              )}
              {field?.type && field?.type === "multi-select" && (
                <>
                  <Form.Item name={field?.key} rules={field?.rules}>
                    <Select
                      placeholder={field?.placeholder}
                      mode="multiple"
                      allowClear={field?.allowClear !== undefined ? field?.allowClear : true}
                      showArrow={true}
                      options={field?.options}
                      disabled={field?.disabled}
                      maxTagCount="responsive"
                      notFoundContent="No Data Found"
                      showSearch={field?.search ? field?.search : false}
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                    ></Select>
                  </Form.Item>
                </>
              )}
              {field?.type && field?.type === "select_custom-search" && (
                <>
                  <Form.Item name={field?.key} rules={field?.rules}>
                    <Select
                      placeholder={field?.placeholder}
                      optionFilterProp="label"
                      showSearch={false}
                      searchValue={searchValue[field?.key]}
                      allowClear={field?.allowClear !== undefined ? field?.allowClear : true}
                      showArrow={true}
                      options={field?.options}
                      disabled={field?.disabled}
                      maxTagCount="responsive"
                      notFoundContent="No Data Found"
                      autoClearSearchValue={false}
                      onDropdownVisibleChange={(visible) => onDropdownVisibleChange(visible, field)}
                      dropdownRender={(menu) => (
                        <>
                          {<div className="select-search-box">
                            {resetField[field?.key] && <Input placeholder="Search for filter" onChange={(e) => setSearchValues(e?.target?.value, field)} style={{ width: '100%' }} />}
                          </div>}
                          {menu}
                        </>
                      )}
                    ></Select>
                  </Form.Item>
                </>
              )}
              {field?.type && field?.type === "multi-select_custom-search" && (
                <>
                  <Form.Item name={field?.key} rules={field?.rules}>
                    <Select
                      placeholder={field?.placeholder}
                      optionFilterProp="label"
                      showSearch={false}
                      searchValue={searchValue[field?.key]}
                      mode="multiple"
                      allowClear={field?.allowClear !== undefined ? field?.allowClear : true}
                      showArrow={true}
                      options={field?.options}
                      disabled={field?.disabled}
                      maxTagCount="responsive"
                      notFoundContent="No Data Found"
                      autoClearSearchValue={false}
                      onDropdownVisibleChange={(visible) => onDropdownVisibleChange(visible, field)}
                      dropdownRender={(menu) => (
                        <>
                          {<div className="select-search-box">
                            {resetField[field?.key] && <Input placeholder="Search for filter" onChange={(e) => setSearchValues(e?.target?.value, field)} style={{ width: '100%' }} />}
                          </div>}
                          {menu}
                        </>
                      )}
                    ></Select>
                  </Form.Item>
                </>
              )}
              {field?.type && field?.type === "date-picker" && (
                <>
                  <Form.Item name={field?.key} rules={field?.rules} style={{ marginBottom: '0px' }}>
                    <DatePicker
                      style={{ width: "100%" }}
                      format={field?.format}
                      placeholder={field?.placeholder}
                      inputReadOnly={true}
                    />
                  </Form.Item>
                  {props?.saveClicked && (data?.fielding_start_date && data?.fielding_end_date) && checkDateValidation() && (field?.key === 'fielding_end_date') && <h6 className="error-msg">Please enter date greater than or equal to Fielding Start Date</h6>}
                  {props?.saveClicked && (!data?.fielding_start_date && data?.fielding_end_date) && (field?.key === 'fielding_start_date') && <h6 className="error-msg">Fielding Start Date is required</h6>}
                </>
              )}
              {field?.type && field?.type === "positiveNumber" && (
                <>
                  <Form.Item name={field?.key} rules={field?.rules}>
                    <Input type="number" min={field?.min} max={field?.max}
                      style={{ width: "100%" }} onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                      placeholder={field?.placeholder}
                      disabled={field?.disabled}
                    />
                  </Form.Item>
                </>
              )}
              {field?.type && field?.type === "Number" && (
                <>
                  <Form.Item name={field?.key} rules={field?.rules} >
                    <Input type="number" min={field?.min} max={field?.max}
                      style={{ width: "100%" }}
                      placeholder={field?.placeholder}
                      disabled={field?.disabled}
                    />
                  </Form.Item>
                </>
              )}
              {field?.type && field?.type === "Select-Cascader" && (
                <Form.Item name={field?.key} rules={field?.rules}>
                  <Cascader
                    style={{ width: "100%" }}
                    placeholder={field?.placeholder}
                    maxTagCount="responsive"
                    options={field?.options}
                    multiple={field?.type === "Select_Multi_Cascader"}
                    showSearch={true}
                  />
                </Form.Item>
              )}
              {field?.type && field?.type === "tree-select" && (
                <>
                  <Form.Item name={field?.key} rules={field?.rules}>
                    <TreeSelect
                      placeholder={field?.placeholder}
                      disabled={field?.disabled}
                      showArrow
                      showSearch={field?.search}
                      treeNodeLabelProp='label'
                      style={{ width: "100%" }}
                      treeData={field?.options}
                      treeCheckable={field?.treeCheckable}
                      allowClear={true}
                      labelInValue={true}
                      multiple={true}
                      maxTagCount="responsive"
                      treeNodeFilterProp={'title'}
                      value={field?.value}
                      onDropdownVisibleChange={(open) => onDropDownOpen(open, field)}
                    />
                  </Form.Item>
                </>
              )}
              {field?.type && field?.type === "textArea" && (
                <Form.Item name={field?.key} rules={field?.rules}>
                  <TextArea
                    rows={3}
                    maxLength={200}
                    placeholder={field?.placeholder}
                    disabled={field?.disabled}
                  />
                </Form.Item>
              )}
              {field?.type && field?.type === "password" && (
                <Form.Item name={field?.key} rules={field?.rules}>
                  <Input.Password
                    placeholder={field?.placeholder}
                    disabled={field?.disabled}
                  />
                </Form.Item>
              )}
              {field?.type && field?.type === "checkbox" && (
                <Form.Item name={field?.key} rules={field?.rules}>
                  <Checkbox.Group options={field?.options} />
                </Form.Item>
              )}
              {field?.type && field?.type === "radio" && (
                <Form.Item name={field?.key} rules={field?.rules}>
                  <Radio.Group>
                    {field?.options.map((option) => (
                      <Radio key={'option' + option?.value} value={option?.value}>{option?.label}</Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              )}
            </Col>
          );
        })}
      </Row>
      <Button className="submit-btn" type="primary" htmlType="submit"></Button>
    </Form>
  );
}
