import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import "./BiasesDetails.scss";
import { Input, Row, Col, Button, Modal, Drawer, Spin } from 'antd';
import BiasDetailsSideNav from "./BiasDetailsSideNav/BiasDetailsSideNav";
import RelatedBiases from "../BiasesDetails/related-biases/RelatedBiases";
import BehavioralSparks from "../BiasesDetails/behavioral-sparks/BehavioralSparks";
import BiasInAction from "../BiasesDetails/bias-in-action/BiasInAction";
import BiasDetailsEditors from "../../Biases/BiasesDetails/bias-details-editors/BiasDetailsEditors";
import { ServiceUtils } from "../../../../Shared/Utils/ServiceUtils";
import { sessionService } from "../../../../Shared/Utils/SessionService";
import { alertService } from "../../../../Shared/Utils/ToasterUtil";
import { CONSTANTS } from '../../../../Environments/constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { extractContent, scrollToDiv } from "../../../../Shared/Utils/CommonUtil";

const BiasesDetails = forwardRef(({ refreshBiasData, viewMode, ...props }, ref) => {
  const [selectedMenu, setMenu] = useState({
    editor: false,
    heading: "<p>The <span class='fw-bold'>Summary Section</span> is used to add a summary that is a definition or explanation of a given bias.</p>",
    key: "summary",
    title: "Summary",
  });

  const [selectedMenuIndex, setMenuIndex] = useState(0);
  const [sidebarData, setSidebarData] = useState([]);
  const { TextArea } = Input;
  const userDetails = sessionService.getSession();
  const [relatedConfig, setRelatedConfig] = useState({});
  const [showBiasLink, setShowBiasLink] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [isBiasEdited, setIsBiasEdited] = useState(false);
  const [biasDetailsData, setBiasDetailsData] = useState([]);
  const [selectedBiasData, setSelectedBiasData] = useState({ "bias_name": '', "bias_id": '' });
  const KM_CONSULTANT = CONSTANTS?.KM_CONSULTANT;
  const [mode, setMode] = useState('view');
  const [isEdit, setIsEdit] = useState(false);
  const [navigateConfirmation, setNavigateConfirmation] = useState(false);
  const [loadRelatedBias, setLoadRelatedBias] = useState(false);
  const [loadBehaviouralSparks, setLoadBehaviouralSparks] = useState(false);
  const [loadBiasInAction, setloadBiasInAction] = useState(false);
  useImperativeHandle(ref, () => ({
    showBias(biasData) {
      fetchBiasDetails(biasData?.bias_id);
      setSelectedBiasData(biasData);
    },
  }));

  useEffect(() => {
    if (isEdit) {
      if (userDetails?.role === KM_CONSULTANT) {
        setMode('edit');
      }
    } else {
      setMode('view');
    }
  }, [isEdit])


  const onMenuSelect = (selected) => {
    setMenu(selected);
    setSelectedTabIndex(biasDetailsData, selected?.key);
  };

  const updateBiasTabData = (data) => {
    try {
      setIsBiasEdited(true);
      if (data?.type === 'editable_nav') {
        biasDetailsData[selectedMenuIndex]['content'] = data?.content;
      } else if (data?.type === 'related_biases') {
        if (data?.content) {
          biasDetailsData[selectedMenuIndex]['content'] = data?.content;
        }
        biasDetailsData[selectedMenuIndex]['related_biases'] = data?.related_biases;
      } else if (data?.type === 'behavioral_sparks') {
        biasDetailsData[selectedMenuIndex]['behavioral_spark_details'] = data?.behavioral_spark_details;
      } else if (data?.type === 'bias-in-action') {
        if (data?.formData) {
          biasDetailsData[selectedMenuIndex]['bias_in_action_data'] = { ...biasDetailsData[selectedMenuIndex]['bias_in_action_data'], formData: data?.formData };
        }
        if (data?.anotherFormData) {
          biasDetailsData[selectedMenuIndex]['bias_in_action_data'] = { ...biasDetailsData[selectedMenuIndex]['bias_in_action_data'], anotherFormData: data?.anotherFormData };
        }
      }
      setBiasDetailsData([...biasDetailsData]);
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To load related bias and strength dropdown data
   */
  const fetchRelatedBiasData = (bias_id) => {
    try {
      ServiceUtils.getRequest("relatedBiasData", bias_id ? `/${bias_id}` : '/0', false).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            let result = response?.data?.data?.related_bias_details;
            let data = {
              biases: result?.biases,
              relation_strength: result?.relation_strength
            }
            setRelatedConfig(data);
          } else {
            alertService.error(response.data.message || `Error while fetching related bias data`);
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To fetch related bias saved data
   * @param {*} bias_id - bias id
   */
  const fetchBiasRelatedData = (bias_id, biasesData) => {
    try {
      if (!bias_id) return;
      setLoadRelatedBias(false);
      ServiceUtils.postRequest("relatedBiases", { bias_id }, false).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            let data = response?.data?.data?.related_biases;
            let biasData = biasesData ? biasesData : biasDetailsData;
            biasData.map(item => {
              if (item.key === 'related_biases') {
                item['related_biases'] = [...data];
              }
              return item;
            });
            setBiasDetailsData([...biasData]);
          } else {
            alertService.error(response.data.message || `Error while fetching saved related bias data`);
          }
        }
        setLoadRelatedBias(true);
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To fetch behavioural sparks saved data
   * @param {*} bias_id - bias id
   */
  const fetchBehaviouralSparksData = (bias_id, biasesData, newBehId) => {
    try {
      if (!bias_id) return;
      setLoadBehaviouralSparks(false);
      ServiceUtils.postRequest("fetchBehaviouralSparkData", { bias_id }, false).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            let data = response?.data?.data?.behavioral_spark_details;
            let biasData = biasesData ? biasesData : biasDetailsData;
            biasData.map(item => {
              if (item.key === 'behavioral_sparks') {
                item['behavioral_spark_details'] = [...data];
              }
              return item;
            });
            setBiasDetailsData([...biasData]);
            if(newBehId){
              setTimeout(() => {
                scrollToDiv(`BSID_${newBehId}`);
              }, 500);
            }
          } else {
            alertService.error(response.data.message || `Error while fetching behavioral sparks data`);
          }
        }
        setLoadBehaviouralSparks(true);
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To fetch bias in action saved data
   * @param {*} bias_id - bias id
   */
  const fetchBiasInActionData = (bias_id, biasesData, newBiasId) => {
    try {
      if (!bias_id) return;
      setloadBiasInAction(false);
      ServiceUtils.postRequest("fetchBiasInAction", { bias_id }, false).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            let data = response?.data?.data?.bias_in_action_data;
            let biasData = biasesData ? biasesData : biasDetailsData;
            biasData.map(item => {
              if (item.key === 'bias-in-action') {
                item['bias_in_action_data'] = { ...data };
              }
              return item;
            });
            setBiasDetailsData([...biasData]);
            if(newBiasId){
              setTimeout(() => {
                scrollToDiv(`BIAID_${newBiasId}`);
              }, 500);
            }
          } else {
            alertService.error(response.data.message || `Error while fetching bias in action data`);
          }
        }
        setloadBiasInAction(true);
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  const checkChanges = () => {
    try {
      if (mode === 'edit' && isBiasEdited) {
        setNavigateConfirmation(true);
      } else {
        handleBiasLinkClose();
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleBiasLinkClose = () => {
    setShowBiasLink(false);
    setSelectedBiasData({ "bias_name": '', "bias_id": '' });
    setSaveClicked(false);
    setIsEdit(false);
    setNavigateConfirmation(false);
    setIsBiasEdited(false);
    setMode('view');
    setMenu({
      editor: false,
      heading: "<p>The <span class='fw-bold'>Summary Section</span> is used to add a summary that is a definition or explanation of a given bias.</p>",
      key: "summary",
      title: "Summary",
    });
    setSelectedTabIndex(biasDetailsData, "summary");
  };

  /**
   * On save of bias data
   */
  const saveBiasData = (close) => {
    try {
      setSaveClicked(true);
      if (!checkDataValidation()) {
        return;
      }
      if (selectedBiasData?.bias_name?.trim().length === 0) {
        return
      }
      let payLoad = {
        bias_details: biasDetailsData,
        bias_info: selectedBiasData
      }
      ServiceUtils.postRequest("biasDetailsCreate", payLoad).then((_response) => {
        if (_response.status === 200) {
          if (_response.data.status === "success") {
            if (_response.data?.data?.bias_info) {
              let bias_info = _response.data?.data?.bias_info;
              setSelectedBiasData(bias_info);
              if (close) {
                handleBiasLinkClose();
                if (typeof refreshBiasData === 'function') refreshBiasData(true);
              } else {
                setSaveClicked(false);
                setIsBiasEdited(false);
                fetchBiasRelatedData(bias_info?.bias_id);
                fetchBehaviouralSparksData(bias_info?.bias_id);
                fetchBiasInActionData(bias_info?.bias_id);
                if (typeof refreshBiasData === 'function') refreshBiasData(false);
              }
              alertService.success(_response.data.message || `SuccessFully saved bias data`);
            }
          } else {
            alertService.error(_response.data.message || `Error while saving bias data`);
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  const onEnableEdit = () => {
    setIsEdit(true);
  }

  /**
   * Data validation before save
   * @returns true or false
   */
  const checkDataValidation = () => {
    try {
      let valid = true;
      let RelatedTabInd = sidebarData.findIndex(item => item.key === 'related_biases');
      let BiasInd = sidebarData.findIndex(item => item.key === 'bias-in-action');
      let BehSparkInd = sidebarData.findIndex(item => item.key === 'behavioral_sparks');
      if (!checkRelatedBiasValidation()) {
        valid = false;
        sidebarData[RelatedTabInd]['invalid'] = true;
      } else {
        sidebarData[RelatedTabInd]['invalid'] = false;
      }
      if (!checkBiasInActionValidation()) {
        valid = false;
        sidebarData[BiasInd]['invalid'] = true;
      } else {
        sidebarData[BiasInd]['invalid'] = false;
      }
      if (!checkBehavioralSparksValidation()) {
        valid = false;
        sidebarData[BehSparkInd]['invalid'] = true;
      } else {
        sidebarData[BehSparkInd]['invalid'] = false;
      }
      setSidebarData([...sidebarData]);
      return valid;
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
  * TO check related bias data validation
  */
  const checkRelatedBiasValidation = () => {
    try {
      let index = biasDetailsData.findIndex(item => item.key === 'related_biases');
      if (index !== -1) {
        let tempData = biasDetailsData[index];
        if (tempData?.key === 'related_biases' && tempData?.related_biases?.length) {
          let valid = true;
          tempData?.related_biases.map((bias) => {
            if (!bias?.related_bias_id || !bias?.relation_strength_id) {
              valid = false;
            }
            return bias;
          });
          return valid;
        } else {
          return true;
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
  * To check data validation in behavioral sparks tab
  */
  const checkBehavioralSparksValidation = () => {
    try {
      let index = biasDetailsData.findIndex(item => item.key === 'behavioral_sparks');
      if (index !== -1) {
        let tempData = biasDetailsData[index];
        if (tempData?.key === 'behavioral_sparks' && tempData?.behavioral_spark_details?.length) {
          let valid = true;
          tempData?.behavioral_spark_details.map((bias) => {
            bias?.spark_details.map((spark) => {
              spark?.behavioral_sparks.map(beh_spark => {
                if (!extractContent(beh_spark?.content).trim().length) {
                  valid = false;
                }
                return beh_spark;
              })
              return spark;
            })
            return bias;
          });
          return valid;
        } else {
          return true;
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * TO check data validation in bias in action tab
   * @returns true or false
   */
  const checkBiasInActionValidation = () => {
    try {
      let index = biasDetailsData.findIndex(item => item.key === 'bias-in-action');
      if (index !== -1) {
        let tempData = biasDetailsData[index];
        if (tempData?.key === 'bias-in-action') {
          let valid = true;
          if (tempData?.bias_in_action_data?.anotherFormData?.length) {
            tempData?.bias_in_action_data?.anotherFormData.map((bias) => {
              if (!bias?.demonstrate_bias?.trim()?.length) {
                valid = false;
              }
              return bias;
            });
          }
          if (valid && tempData?.bias_in_action_data?.formData?.length) {
            tempData?.bias_in_action_data?.formData.map((bias) => {
              if (!bias?.demonstrate_bias?.trim()?.length) {
                valid = false;
              }
              return bias;
            });
          }
          return valid;
        } else {
          return true;
        }
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  /**
* To get bias data
* @param {*} biasId - biasId
*/
  const fetchBiasDetails = (biasId) => {
    try {
      if (!biasId) {
        setIsEdit(true);
      }
      ServiceUtils.postRequest("biasDetailsFetch", { bias_id: biasId ? biasId : 0 }).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data?.bias_details;
            setBiasDetailsData(result);
            setSelectedTabIndex(result);
            fetchRelatedBiasData(biasId);
            fetchBiasRelatedData(biasId, result);
            fetchBehaviouralSparksData(biasId, result);
            fetchBiasInActionData(biasId, result);
            loadSidebarData();
          } else {
            alertService.error(response.data.message || `Error while fetching bias details`);
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To load sidebar data
   */
  const loadSidebarData = () => {
    try {
      ServiceUtils.getRequest("biasSidebar").then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            let data = response?.data?.data?.bias_sidebar_details;
            setSidebarData([...data]);
            setShowBiasLink(true);
          } else {
            alertService.error(response.data.message || `Error while fetching sidebar data`);
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
  * On Edit bias name
  * @param {*} value - bias name
  */
  const onBiasnameChange = (value) => {
    try {
      setIsBiasEdited(true);
      setSelectedBiasData({ ...selectedBiasData, 'bias_name': value });
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To set selected tab index
   * @param {*} data 
   */
  const setSelectedTabIndex = (data, key = undefined) => {
    let value = key ? key : selectedMenu?.key;
    let index = data.findIndex(item => item.key === value);
    if (index > -1) {
      setMenuIndex(index);
    }
  }


  return (
    <div className="bias-details">
      <Drawer
        title="Bias Details"
        className="bias-details-offcanvas"
        placement="right"
        width={'75%'}
        onClose={checkChanges}
        open={showBiasLink}
        size={"large"}
        push={false}
        id="biasDetailsDrawer"
        zIndex={1030}
        footer={
          <>
            {userDetails?.role === KM_CONSULTANT && mode === 'view' && viewMode !== 'candidateBiasView' && <Button type="primary" className="me-2" onClick={() => onEnableEdit()}> Edit </Button>}
            {userDetails?.role === KM_CONSULTANT && mode === 'edit' && viewMode !== 'candidateBiasView' && <Button type="primary" className="me-2" onClick={() => saveBiasData(false)}> Save </Button>}
            {(userDetails?.role === KM_CONSULTANT && mode === 'edit') && viewMode !== 'candidateBiasView' && <Button className="me-2" onClick={() => saveBiasData(true)}> Save & Close </Button>}
            <Button onClick={checkChanges}> {mode === 'view' ? 'Close' : 'Cancel'} </Button>
          </>
        }
      >
        <div className="bias-details-drawer-body">
          <div className="bias-name-section position-sticky top-0">
            {mode === 'view' &&
              <Row className="p-2"><h5 className="biasLabel">{selectedBiasData?.bias_name}</h5></Row>}
            <>
              {
                (mode === 'edit') &&
                <Row className="p-2">
                  <Col span={3}>
                    <label className="biasLabel">Bias Name:</label>
                  </Col>
                  <Col span={21}>
                    <div className="bias">
                      <Input className={`bias-input ${!selectedBiasData?.bias_name?.trim() && saveClicked ? "input-required" : ''}`} value={selectedBiasData?.bias_name} placeholder={"Enter Bias name"} onChange={(e) => onBiasnameChange(e?.target?.value)} />
                      {(!selectedBiasData?.bias_name?.trim() && saveClicked) && <span className="error-msg">Please enter a unique value for Bias Name</span>}
                    </div>
                  </Col>
                </Row>
              }
            </>
            <hr style={{ margin: '0 0 0 0' }} />
          </div>
          <div className="bias-details-layout">
            <Row>
              <Col span={5} className="side-nav bias-details-side-nav" id="bias-details-side-nav-id">
                <div className="m-0 p-0 h-100">
                  <BiasDetailsSideNav
                    active={selectedMenu}
                    onClick={onMenuSelect}
                    sidebarData={sidebarData}
                    biasId={selectedBiasData?.bias_id}
                  />
                </div>
              </Col>
              <Col span={19}>
                {(sidebarData && sidebarData.length !== 0) && <div className="m-0 p-0">
                  {selectedMenu.key === "bias-in-action" && loadBiasInAction && (
                    <BiasInAction description={selectedMenu?.heading} mode={mode} selectedBiasData={selectedBiasData} savedData={biasDetailsData?.[selectedMenuIndex]?.bias_in_action_data?.formData} newRepoData={biasDetailsData?.[selectedMenuIndex]?.bias_in_action_data?.anotherFormData} refreshData={(newBiasId) => fetchBiasInActionData(selectedBiasData?.bias_id, undefined, newBiasId)} updateBiasInActionData={updateBiasTabData} saveClicked={saveClicked} />
                  )}
                  {mode === 'edit' && selectedMenu.key === "bias-in-action" && !loadBiasInAction && <>
                    <div className="bias-tab-header">
                      <Row className="border-bottom">
                        <Col span={16}>
                          <div className="p-1 text-muted description" dangerouslySetInnerHTML={{ __html: selectedMenu?.heading }} ></div>
                        </Col>
                        {mode === 'edit' && <Col span={8}>
                          {<div className="addLink p-1" >
                            <span className="fontColor">
                              <FontAwesomeIcon className="fontIcon" icon={faPlusCircle} />
                              <span> Add Bias-in-Action</span>
                            </span>
                          </div>}
                        </Col>}
                      </Row>
                    </div>
                    <Spin style={{ padding: '15px' }} />
                  </>}
                  {(selectedMenu.editor) && (
                    <BiasDetailsEditors selectedTabIndex={selectedMenuIndex} description={selectedMenu?.heading} mode={mode} biasDetailsData={biasDetailsData[selectedMenuIndex]} updateEditorValue={updateBiasTabData} />
                  )}
                  {selectedMenu.key === "summary" && (<>
                    <div className="p-1 border-bottom text-muted mainData" dangerouslySetInnerHTML={{ __html: selectedMenu?.heading }} ></div>
                    {mode === 'edit' && <TextArea className="p-3" style={{ height: "calc(100vh - 160px)" }}
                      value={biasDetailsData[selectedMenuIndex]?.content}
                      onChange={(e) => updateBiasTabData({ 'type': 'editable_nav', 'content': e?.target?.value.replace(/^\s+/g, '') })} />}
                    {(mode === 'view' && biasDetailsData?.[selectedMenuIndex]) && <TextArea className="p-3" style={{ height: "calc(100vh - 160px)" }}
                      value={biasDetailsData[selectedMenuIndex]?.content} readOnly={true} />}
                  </>
                  )}
                  {selectedMenu.key === "related_biases" && loadRelatedBias && (
                    <RelatedBiases description={selectedMenu?.heading} mode={mode} selectedBiasData={selectedBiasData} relatedConfigData={relatedConfig} biasDetailsData={biasDetailsData?.[selectedMenuIndex]} updateRelateBiasData={updateBiasTabData} saveClicked={saveClicked} />
                  )}
                  {mode === 'edit' && selectedMenu.key === "related_biases" && !loadRelatedBias && <>
                    <div className="bias-tab-header">
                      <Row className="border-bottom">
                        <Col span={16}>
                          <div className="p-1 text-muted description" dangerouslySetInnerHTML={{ __html: selectedMenu?.heading }} ></div>
                        </Col>
                        <Col span={8}>
                          {mode === 'edit' && <div className="addLink p-1" >
                            <span className="fontColor">
                              <FontAwesomeIcon className="fontIcon" icon={faPlusCircle} />
                              <span> Add Related Bias</span>
                            </span>
                          </div>}
                        </Col>
                      </Row>
                    </div>
                    <Spin style={{ padding: '15px' }} />
                  </>}
                  {selectedMenu.key === "behavioral_sparks" && loadBehaviouralSparks && (
                    <BehavioralSparks description={selectedMenu?.heading} mode={mode} selectedBiasData={selectedBiasData} biasDetailsData={biasDetailsData?.[selectedMenuIndex]} refreshData={(newId) => {fetchBehaviouralSparksData(selectedBiasData?.bias_id,undefined,newId)}} updateBehaviouralSparkData={updateBiasTabData} saveClicked={saveClicked} />
                  )}
                  {mode === 'edit' && selectedMenu.key === "behavioral_sparks" && !loadBehaviouralSparks && <>
                    <div className="bias-tab-header">
                      <Row className="border-bottom">
                        <Col span={16}>
                          <div className="p-1 text-muted description" dangerouslySetInnerHTML={{ __html: selectedMenu?.heading }} ></div>
                        </Col>
                        {mode === 'edit' && <Col span={8}>
                          {<div className="addLink p-1" >
                            <span className="fontColor">
                              <FontAwesomeIcon className="fontIcon" icon={faPlusCircle} />
                              <span> Add Behavioral Spark</span>
                            </span>
                          </div>}
                        </Col>}
                      </Row>
                    </div>
                    <Spin style={{ padding: '15px' }} />
                  </>}
                </div>}
              </Col>
            </Row>
          </div>
        </div>
      </Drawer>
      <Modal
        centered
        closable={false}
        open={navigateConfirmation}
        footer={null}
        width={400}
        zIndex={2010}
        onCancel={() => setNavigateConfirmation(false)}
      >
        <h6  className="confirmation-header-text" >Confirm</h6>
        <p className="text-muted p1-3">
          There are unsaved changes in the screen. Are you sure you want to continue?
        </p>
        <Button type="primary" onClick={handleBiasLinkClose} className="me-2 mt-3" >
          Yes
        </Button>
        <Button onClick={() => { setNavigateConfirmation(false) }}  className='mt-3'>
          {" "} No{" "}
        </Button>
      </Modal>

    </div>
  );
});

export default BiasesDetails;
