import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { alertService } from "../../../../Shared/Utils/ToasterUtil";
import { ServiceUtils } from "../../../../Shared/Utils/ServiceUtils";
import { Button, Modal, Tabs } from 'antd';
import ProjectsAttributes from "./project-attributes/ProjectsAttributes";
import CandidateBiases from "./candidate-biases/CandidateBiases";
import HypothesisStatement from "./hypothesis-statement/HypothesisStatement";
import DiscussionGuide from "./discussion-guide/DiscussionGuide";
import Analysis from "./analysis/Analysis";
import ReadoutPreparation from "./readout-preparation/ReadoutPreparation";
import Transcripts from "./transcripts/Transcripts";
import ResearchReport from './research-report/researchReport';
import SurveyBuilder from "./survey-builder/surveyBuilder";
import Deliverable from "./deliverable/deliverable";
import './ProjectDetails.scss'
import { DownOutlined } from "@ant-design/icons";

const ProjectDetails = forwardRef(({ getProjectData, showComments, hideComments, ...props }, ref) => {
  const [selectedTab, setSelectedTab] = useState('');
  const [projectId, setProjectId] = useState();
  const [projectData, setProjectData] = useState();
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [projectSteps, setProjectSteps] = useState([]);
  const [navigateConfirmation, setNavigateConfirmation] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;
  const route = path.split("/").pop();
  const [clickedTab, setClickedTab] = useState('');
  const [currentEditData, setCurrentEditData] = useState({});
  const commonConfirmMsg = "There are unsaved changes in the screen. Are you sure you want to continue?";
  const discussionGuideConfirmMsg = "There may be unsaved changes or blank mandatory inputs in the screen. Are you sure you want to continue and leave the screen?";

  useImperativeHandle(ref, () => ({
    navigateToHome(event) {
      if (event?.navigateToHome) {
        if ((isFormEdited && selectedTab === "project_attributes") || (selectedTab === "discussion_guide" && Object?.keys(currentEditData)?.length)) {
          setNavigateConfirmation(true);
          setClickedTab('');
        } else {
          history.push("/home/project");
        }
      }
    },
  }));

  useEffect(() => {
    if (route && route !== "details") {
      renderProjectDataInform(route, true);
    } else {
      setSelectedTab('project_attributes');
    }
  }, []);

  useEffect(() => {
    let routeData = path.split("/");
    if (routeData.length === 5 && routeData[3] === 'details') {
      getProjectStep(routeData[4]);
    } else {
      getProjectStep(0);
    }
  }, [location]);

  /** Get project step [ status step]  */
  const getProjectStep = (value) => {
    ServiceUtils.getRequest("projectStep", `/${value}`).then((response) => {
      try {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data;
            const projectStep = result?.topMenu;
            setProjectSteps(projectStep);
          } else {
            alertService.error(
              response.data.message ||
              `Error while fetching project steps list`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  }

  const Navigate = () => {
    try {
      if (clickedTab) {
        setSelectedTab(clickedTab);
        renderProjectDataInform(projectId, false, clickedTab);
      } else if (!clickedTab) {
        history.push("/home/project");
      }
      setNavigateConfirmation(false);
      setIsFormEdit(false);
      setCurrentEditData({});
    } catch (error) {
      console.log(error);
    }
  }



  const renderProjectDataInform = (project_id, initialLoad = false, currentStep = undefined) => {
    if (project_id) {
      setProjectId(project_id);
      ServiceUtils.postRequest("projectMetaData", { "project_id": project_id }, initialLoad).then((response) => {
        try {
          if (response.status === 200) {
            if (response.data.status === "success") {
              const pmd = response?.data?.data?.project_basic_data;
              setProjectData(pmd);
              if (initialLoad) {
                if (pmd?.status_step) {
                  setSelectedTab(pmd?.status_step);
                  if (typeof getProjectData === 'function') getProjectData({ 'projectName': pmd?.project_name, 'project_id': project_id, 'selectedTab': pmd?.status_step, 'last_updated_by': pmd?.last_updated_by, 'last_updated_time': pmd?.last_updated_time });
                } else {
                  setSelectedTab('project_attributes');
                  if (typeof getProjectData === 'function') getProjectData({ 'projectName': pmd?.project_name, 'project_id': project_id, 'selectedTab': 'project_attributes', 'last_updated_by': pmd?.last_updated_by, 'last_updated_time': pmd?.last_updated_time });
                }
              } else {
                if (typeof getProjectData === 'function' && currentStep) {
                  getProjectData({ 'projectName': pmd?.project_name, 'project_id': project_id, 'selectedTab': currentStep, 'last_updated_by': pmd?.last_updated_by, 'last_updated_time': pmd?.last_updated_time });
                }
              }
            } else {
              alertService.error(
                response.data.message ||
                `Error while fetching project details`
              );
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    }
  }



  const setIsFormEdit = (value) => {
    setIsFormEdited(value);
  }

  const onProjectAttributeSave = (event) => {
    let index = projectSteps.findIndex(tab => tab?.key === selectedTab);
    let nextStep = projectSteps[index + 1];
    if (event?.navigate) {
      setSelectedTab(nextStep?.key);
      setIsFormEdit(false);
      renderProjectDataInform(event?.projectId, false, nextStep?.key);
    }
    if (event?.projectData) {
      setProjectId(event?.projectData?.project_id);
      setProjectData(event?.projectData);
    }
  }

  const navigateStep = (event) => {
    try {
      let index = projectSteps.findIndex(tab => tab?.key === selectedTab);
      let nextStep = projectSteps[index + 1];
      let prevStep = projectSteps[index - 1];
      if (event?.navigate === 'next') {
        if (Object?.keys(currentEditData)?.length) {
          setNavigateConfirmation(true);
          setClickedTab(nextStep?.key);
        } else {
          ServiceUtils.postRequest("nextStepUpdate", { "project_id": projectId, "step": selectedTab }).then((response) => {
            try {
              if (response.status === 200) {
                if (response?.data?.status === "success") {
                  alertService.success(response.data.message);
                  setSelectedTab(nextStep?.key);
                  renderProjectDataInform(projectId, false, nextStep?.key);
                } else {
                  alertService.error(
                    response.data.message ||
                    `Error while navigating to next step`
                  );
                }
              }
            } catch (error) {
              console.log(error);
            }
          });
        }
      } else {
        if (Object?.keys(currentEditData)?.length) {
          setNavigateConfirmation(true);
          setClickedTab(prevStep?.key);
        } else {
          setSelectedTab(prevStep?.key);
          renderProjectDataInform(projectId, false, prevStep?.key);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * Custom more icon of tabs
   * @returns 
   */
  const customMore = () => {
    return <React.Fragment>
      <span style={{ 'display': 'flex' }}>More<DownOutlined className="custom-more-icon" /></span>
    </React.Fragment>
  }

  /**
   * On Tab change
   * @param {*} tabKey - tab key
   */
  const onTabChange = (tabKey) => {
    try {
      if ((isFormEdited && selectedTab === "project_attributes") || (selectedTab === "discussion_guide" && Object?.keys(currentEditData)?.length)) {
        setNavigateConfirmation(true);
        setClickedTab(tabKey);
      } else {
        if (route && route !== "details") {
          setSelectedTab(tabKey);
          renderProjectDataInform(projectId, false, tabKey);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  
  /**
   * To update target edited data
   * @param {*} data - edit data info
   */
  const updateCurrentTabEditData = (data) => {
    try {
      setCurrentEditData(data);
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <div className="m-0 row createproject">
      <div className="m-0 p-0 top-side-nav">
        {projectSteps && <Tabs className="target-list"
          onChange={(tabKey) => onTabChange(tabKey)}
          activeKey={selectedTab}
          tabPosition={'top'}
          moreIcon={customMore()}
        >
          {projectSteps.map((tab) => {
            return (
              <Tabs.TabPane tab={tab?.label} key={tab?.key} disabled={!projectId && tab?.key !== 'project_attributes'} />
            )
          })}</Tabs>}
        <>{selectedTab === "project_attributes" && <ProjectsAttributes projectId={projectId} setIsFormEdit={setIsFormEdit} saveEvent={onProjectAttributeSave} />}
          {selectedTab === "candidate_biases" && <CandidateBiases projectId={projectId} navigateStep={navigateStep} />}
          {selectedTab === "hypothesis" && <HypothesisStatement />}
          {selectedTab === "discussion_guide" && <DiscussionGuide projectId={projectId} navigateStep={navigateStep} currentTabEditData={updateCurrentTabEditData} />}
          {selectedTab === "transcripts" && <Transcripts />}
          {selectedTab === "analysis" && <Analysis />}
          {selectedTab === "readout" && <ReadoutPreparation />}
          {selectedTab === "deliverable" && <Deliverable />}
          {selectedTab === "survey_builder" && <SurveyBuilder />}
          {selectedTab === "research_report" && <ResearchReport />}</>
      </div>
      <Modal
        centered
        closable={false}
        open={navigateConfirmation}
        footer={null}
        width={400}
        zIndex={2010}
        onCancel={() => setNavigateConfirmation(false)}
      >
        <h6  className="confirmation-header-text">Confirm</h6>
        <p className="text-muted pt-1">
         {(isFormEdited && selectedTab === "project_attributes") ? commonConfirmMsg : discussionGuideConfirmMsg}
        </p>
        <Button type="primary" onClick={Navigate} className="me-2 mt-3" >
          Yes
        </Button>
        <Button onClick={() => { setNavigateConfirmation(false) }} className='mt-3'>
          {" "} No{" "}
        </Button>
      </Modal>
    </div>
  );
});

export default ProjectDetails;