import React, { useState, useEffect } from "react";
import SVGIcon from "../../../../../Shared/Components/SVGIcon/SVGIcon";
import { Row, Col, Button, Input, PageHeader, Modal, Collapse, Tooltip, Spin } from 'antd';
import "../TargetAudienceAndSpecialty/TargetAudienceAndSpecialty.scss"
import { ServiceUtils } from "../../../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../../../Shared/Utils/ToasterUtil";
import { DebounceInput } from 'react-debounce-input';

const { Panel } = Collapse;


const TargetAudienceAndSpecialty = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [modalValue, setModalValue] = useState('');
  const [mainData, setMainData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [clickedSave, setClickedSave] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [initialLoad, setInitialLoad] = useState(true);


  useEffect(() => {
    loadData();
  }, [])

  /**
   * To load target audience data
   */
  const loadData = () => {
    try {
      ServiceUtils.postRequest("fetchAdminData", {
        "screen": "target_audience"
      }).then(
        (response) => {
          try {
            if (response.status === 200) {
              if (response.data.status === "success") {
                const result = response?.data?.data?.target_audience_list;
                if (initialLoad) {
                  if (result && result[0] && result[0]['key']) {
                    setExpanded([result[0]['key']]);
                  }
                  setInitialLoad(false);
                }
                setMainData(result);
                setFilterData(result);
                if (searchValue) {
                  searchData(JSON.parse(JSON.stringify(result)), searchValue);
                }
              } else {
                alertService.error(response.data.message);
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  const isTargetDelete = (targetId) => {
    if (mainData.filter((el) => el.key === targetId)?.length) {
      return (mainData.filter((el) => el.key === targetId)?.[0].children?.length) === 0;
    }
  };

  const genExtra = (data) => (
    <>
      <Tooltip placement="topRight" title={'Edit Target Audience'} overlayClassName={'tooltip-bg'}>
        <SVGIcon icon={'edit-fill'} className="icons" onClick={() => handleModalData('target', 'Edit', data)} />
      </Tooltip>
      &nbsp;&nbsp;
      {
        isTargetDelete(data.key) ? (
          <Tooltip placement="topRight" title={'Delete Target Audience'} overlayClassName={'tooltip-bg'}>
            <SVGIcon icon={'delete-fill'} className="icons" onClick={() => deleteModal(true, 'target', data)} />
          </Tooltip>) :
          (<Tooltip placement="topRight" overlayClassName={'tooltip-bg'} title={'A Target Audience Group cannot be deleted unless there are no specialties under that target audience group'}><SVGIcon className="target-delete-icon" icon={'delete-disabled'}
            onClick={() => deleteModal(false, 'target', data)} /></Tooltip>)
      }
    </>
  );

  /**
   * On searching
   * @param {*} searchText - search value
   */
  const handleSearchText = (searchText) => {
    setSearchValue(searchText);
    setShowLoader(true);
    searchData(JSON.parse(JSON.stringify(mainData)), searchText);
  };

  /**
   * To search value over data
   * @param {*} array -  data
   * @param {*} keyword -  search value
   */
  const searchData = (array, keyword) => {
    try {
      if (keyword.trim()) {
        const searchTerm = JSON.parse(JSON.stringify(keyword.toString().toLowerCase()));
        const searchData = array.filter((value) => {
          let filtered = false;
          let childList = [];
          value?.children.filter((childData) => {
            if (childData?.value.toLowerCase()?.includes(searchTerm)) {
              filtered = true
              childList.push(childData);
            }
          });
          if (filtered) {
            value['children'] = childList;
            return value
          } else {
            return value.header.toLowerCase()?.includes(searchTerm);
          }
        });
        const searchResultKeys = searchData.map(function (el) { return el.key; })
        setFilterData(searchData);
        setExpanded(searchResultKeys);
      } else {
        setFilterData(array);
        setExpanded([]);
      }
    } catch (error) {
      console.log("error", error);
    }
    setShowLoader(false);
  };

  const handleModal = (value) => {
    try {
      if (!value) {
        setModalValue('');
        setModalData({});
        setClickedSave(false);
      }
      setShowModal(value);
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To handle edit
   * @param {*} level - item level
   * @param {*} type - action type
   * @param {*} itemData - Item data
   */
  const handleModalData = (level, type, itemData = undefined, parentData = undefined) => {
    try {
      handleModal(true);
      let data = {};
      if (type === 'Edit') {
        let value = level === 'target' ? itemData?.header : itemData?.value
        setModalValue(value);
      }
      if (level === 'target') {
        data = {
          "title": `${type} Target Audience Value`,
          "label": `${type} Target Audience`,
          "placeholder": type === 'Edit' ? "Update the new Target Audience name" : "Enter the new Target Audience name",
          "id": itemData?.key ? itemData?.key : '',
          "newErrorMsg": "Please enter the new target audience name.",
          "updateError": "Please enter the updated target audience name",
          actionType: type,
          dataLevel: level

        }
      } else {
        data = {
          "title": `${type} Specialty Value`,
          "label": `${type} Specialty`,
          "placeholder": type === 'Edit' ? "Update the new Specialty name" : "Enter the new Specialty name",
          "id": itemData?.key ? itemData?.key : '',
          "parentId": parentData?.key ? parentData?.key : '',
          "newErrorMsg": "Please enter the new specialty name.",
          "updateError": "Please enter the updated specialty name",
          actionType: type,
          dataLevel: level
        }
      }
      setModalData(data);
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To handle delete
   * @param {*} value - true/false
   * @param {*} level - data level
   * @param {*} itemData - item data
   */
  const deleteModal = (value, level = undefined, itemData = undefined) => {
    try {
      if (!value) {
        setDeleteData({});
      } else {
        let data = {
          level: level,
          id: itemData?.key,
          data: level === 'target' ? 'Are you sure you want to delete this target audience?' : 'Are you sure you want to delete this specialty?'
        };
        setDeleteData(data);
      }
      setShowDeleteModal(value);
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To hide and show modal
   * @param {*} value 
   */
  const handleModalValue = (value) => {
    try {
      setModalValue(value);
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To create new target audience or specialty
   */
  const saveData = () => {
    try {
      setClickedSave(true);
      if (modalValue && modalValue.trim()) {
        let api = '';
        let inputData = {};
        if (modalData?.dataLevel === 'target') {
          api = modalData?.actionType === 'Add' ? 'targetAudienceCreate' : 'targetAudienceUpdate';
          inputData = {
            "name": modalValue.trim(),
          }
          if (modalData?.id) {
            inputData["target_audience_id"] = modalData?.id;
          }
        } else {
          api = modalData?.actionType === 'Add' ? 'specialtyCreate' : 'specialtyUpdate';
          inputData = {
            "name": modalValue.trim(),
          }
          if (modalData?.actionType === 'Add') {
            inputData["target_audience_id"] = modalData?.id;
          } else {
            inputData["target_audience_id"] = modalData?.parentId;
            inputData["speciality_id"] = modalData?.id;
          }
        }
        ServiceUtils.postRequest(api, inputData).then(
          (response) => {
            try {
              if (response.status === 200) {
                if (response.data.status === "success") {
                  handleModal(false);
                  loadData();
                  setClickedSave(false);
                  alertService.success(response.data.message);
                } else {
                  alertService.error(response.data.message);
                }
              }
            } catch (error) {
              console.log(error);
            }
          }
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To delete target audience or specialty
   */
  const deleteItem = () => {
    try {
      let api = deleteData?.level === 'target' ? 'targetAudienceDelete' : 'specialtyDelete';
      let input = {};
      if (deleteData?.level === 'target') {
        input = {
          "target_audience_id": deleteData?.id
        }
      } else {
        input = {
          "speciality_id": deleteData?.id
        }
      }
      ServiceUtils.postRequest(api, input).then(
        (response) => {
          try {
            if (response.status === 200) {
              if (response.data.status === "success") {
                deleteModal(false);
                loadData();
                alertService.success(response.data.message);
              } else {
                alertService.error(response.data.message);
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      );
    } catch (error) {

    }
  }

  const panelHeader = (data) => (
    <React.Fragment>
      <SVGIcon icon={expanded.length && expanded.includes(data?.key) ? 'down-arrow-transparent' : 'right-arrow-transparent'} className="arrowIcon" />
      <span className="panelHeader" style={{ fontWeight: "500" }} onClick={event => event.stopPropagation()}>{data?.header}</span>
    </React.Fragment>
  )

  const collapseChangeAction = (event) => {
    const expandedData = event.map(ele => parseInt(ele));
    setExpanded(expandedData);
  }

  return (
    <div className="targetAudienceMain">
      {(
        <div className=" mainSection p-0">
          <PageHeader className="behavioralHeader" title={"Target Audience & Specialty"} />
          <div className="adminSearchBtn">
            <div className="searchFill ps-2 d-flex">
              <DebounceInput
                debounceTimeout={500}
                type="text"
                className="form-control form-control-sm"
                placeholder={"Search Target Audience & Specialty"}
                onChange={(e) => {
                  handleSearchText(e.target.value);
                }} />
              <button className="searchButton text-muted me-2">
                {showLoader ? (<Spin size="small" />) : (<SVGIcon icon={"search-transparent"} />)}
              </button>
            </div>
            <Button
              type="primary"
              className="primary-btn main-add"
              onClick={() => handleModalData('target', 'Add')}
            >
              <SVGIcon icon={"add-circle-white"}  className="iconPositionInButton"/>
              &nbsp;Add Target Audience
            </Button>
          </div>
          {/* Add category grouping (button) */}
          <Row span={24} className="grid-header">
            <Col span={10}>Target Audience</Col>
            <Col span={4}></Col>
            <Col span={10}></Col>
          </Row>
          <div className="text-border"></div>
          {mainData.length !== 0 && <div className="targetCollapse">
            <Collapse activeKey={expanded} onChange={(event) => collapseChangeAction(event)}>
              {filterData.map((dataSource) => (
                <Panel showArrow={false} header={panelHeader(dataSource)} key={dataSource?.key} collapsible="header" extra={genExtra(dataSource)}>
                  <div className="targetPanel mb-3">
                    <div className="specialty-section">
                      <div className="row-header">
                        <span className="header-data-p">Specialty</span>
                      </div>
                      <Button
                        type="primary"
                        className="categoryBtn primary-btn"
                        onClick={() => handleModalData('specialty', 'Add', dataSource)}
                      >
                        <SVGIcon icon={"add-circle-white"} className="iconPositionInButton"/>
                        &nbsp;Add Specialty
                      </Button>
                    </div>
                  </div>
                  {dataSource?.children?.map((child, ind) => {
                    return (
                      <>
                        <div className="targetPanel mb-3">
                          <div className="specialty-section">
                            <div className="specialty-item">
                              <span>{child?.value}</span>
                            </div>
                            <Tooltip placement="topRight" title={'Edit Specialty'} overlayClassName={'tooltip-bg'}>
                              <SVGIcon icon={'edit-fill'} className="icons" onClick={() => handleModalData('specialty', 'Edit', child, dataSource)} />
                            </Tooltip>
                            &nbsp;&nbsp;
                            <Tooltip placement="topRight" title={'Delete Specialty'} overlayClassName={'tooltip-bg'}>
                              <SVGIcon icon={'delete-fill'} className="icons" onClick={() => deleteModal(true, 'specialty', child)} />
                            </Tooltip>
                          </div>
                          {(ind !== dataSource?.children.length - 1) && <hr className="divider"></hr>}
                        </div>
                      </>
                    )
                  })}
                </Panel>
              ))}
              {filterData.length == 0 && <p className="text-center p-5 no-result">No search results returned. Please modify your search criteria to view Target Audience & Specialty values.</p>}
            </Collapse>
          </div>}
          {mainData.length == 0 && <p className="text-center p-5 no-result">No Data Found</p>}
          <div>
            <Modal
              title={modalData?.title}
              bodyStyle={{ height: 230 }}
              centered
              open={showModal}
              footer={[
                <Button key="submit" type="primary" onClick={saveData}>
                  Save
                </Button>,
                <Button key="back" onClick={() => handleModal(false)}>
                  Cancel
                </Button>,
              ]}
              onCancel={() => handleModal(false)}
            >
              <div>
                {modalData?.label}
                <br></br>
                <Input placeholder={modalData?.placeholder} value={modalValue} onChange={(e) => {
                  handleModalValue(e.target.value.replace(/^\s+/g, ''));
                }}
                  status={(clickedSave && (!modalValue && modalData?.actionType === 'Add')) || (clickedSave && (modalData?.actionType === 'Edit' && !modalValue)) ? "error" : ''}
                />
                {(clickedSave && (!modalValue && modalData?.actionType === 'Add')) && <p className="mb-0" style={{ color: '#DC3545' }}>{modalData?.newErrorMsg}</p>}
                {(clickedSave && (modalData?.actionType === 'Edit' && !modalValue)) && <p className="mb-0" style={{ color: '#DC3545' }}>{modalData?.updateError}</p>}
              </div>
            </Modal>
          </div>

          {/* Delete Confirmation Modal*/}
          <Modal
            closable={false}
            centered
            open={showDeleteModal}
            width={400}
            zIndex={2010}
            footer={null}
            onCancel={() => deleteModal(false)}
          >
            <h6>{deleteData?.level === 'target' ? 'Delete Target Audience' : 'Delete Specialty'}</h6>
            <p className="text-muted">
              {deleteData?.data}
            </p>
            <Button type="primary" onClick={() => deleteItem()} className="me-2" >
              Delete
            </Button>
            <Button onClick={() => deleteModal(false)}>
              {" "} Cancel{" "}
            </Button>
          </Modal>
        </div>
      )
      }
    </div >
  );
};
export default TargetAudienceAndSpecialty;
