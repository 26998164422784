import React, { useState, useEffect } from "react";
import './DiscussionGuide.scss'
import { ServiceUtils } from '../../../../../Shared/Utils/ServiceUtils'
import { Tabs, Modal, Button  } from 'antd';
import { DownOutlined } from "@ant-design/icons";
import DiscussionGuideBuilder from "./Discussion-Guide-Builder/discussion-guide-builder"

export default function DiscussionGuide({ projectId, navigateStep, currentTabEditData, ...props }) {

  const [selectedTab, setSelectedTab] = useState({});
  const [targetAudienceList, setTargetAudienceList] = useState([]);
  const [navigateConfirmation, setNavigateConfirmation] = useState(false);
  const [clickedTab, setClickedTab] = useState('');
  const [editData, setEditData] = useState({});

  useEffect(() => {
    getTargetAudienceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * To get list of target audience
   */
  const getTargetAudienceList = () => {
    try {
      ServiceUtils.postRequest("listTargetAudience", { 'project_id': projectId }).then((_response) => {
        if (_response.status === 200) {
          if (_response.data.status === "success") {
            let audienceList = _response.data.data.targetsList;
            setTargetAudienceList([...audienceList]);
            if (audienceList?.length) {
              setSelectedTab(String(audienceList[0]?.proj_aud_id));
            }
          }
        }
      });
    } catch (error) {
      console.log("error");
    }
  }

  /**
   * To navigate other steps
   * @param {*} event - step data
   */
  const navigateSteps = (event) => {
    try {
      if (typeof navigateStep === 'function') navigateStep(event);
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * Custom more icon of tabs
   * @returns 
   */
  const customMore = () => {
    return <React.Fragment>
      <span style={{ 'display': 'flex' }}>More<DownOutlined className="custom-more-icon" /></span>
    </React.Fragment>
  }


    /**
   * On Tab change
   * @param {*} tabKey - tab key
   */
     const onTabChange = (tabKey) => {
      try {
        if (editData[selectedTab] && Object?.keys(editData[selectedTab])?.length) {
          setNavigateConfirmation(true);
          setClickedTab(tabKey);
        } else {
          setSelectedTab(tabKey);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
    
    /**
     * To update edit state data form builder
     * @param {*} editData 
     */
    const updateEditedData = (data) => {
      try {
        editData[selectedTab] = data;
        setEditData({...editData});
        if (typeof currentTabEditData === 'function') currentTabEditData(data);
      } catch (error) {
        console.log("error", error);
      }
    }
    
    /**
     * On click of yes to navigate
     */
    const Navigate = () =>{
      try {
        setSelectedTab(clickedTab);
        setNavigateConfirmation(false);
      } catch (error) {
        console.log("error", error);
      }
    }

  return (
    <div className="discussion-guide">
      <Tabs
        defaultActiveKey={selectedTab}
        tabPosition={'top'}
        activeKey={selectedTab}
        onChange={(tabKey) => onTabChange(tabKey)}
        moreIcon={customMore()}
        items={targetAudienceList.map((target) => {
          const id = String(target?.proj_aud_id);
          return {
            label: target?.target_audience,
            key: id,
            children: <div className="m-0 p-0 top-container">
              <DiscussionGuideBuilder targetId={target?.proj_aud_id} project_id={projectId} selectedMenu={target} navigateStep={navigateSteps} updateEditedData={updateEditedData}/>
            </div>,
          };
        })}
      />
        <Modal
        centered
        closable={false}
        open={navigateConfirmation}
        footer={null}
        width={400}
        zIndex={2010}
        onCancel={() => setNavigateConfirmation(false)}
      >
        <h6  className="confirmation-header-text">Confirm</h6>
        <p className="text-muted">
          There may be unsaved changes or blank mandatory inputs in the screen. Are you sure you want to continue and leave the screen?
        </p>
        <Button type="primary" onClick={Navigate} className="me-2" >
          Yes
        </Button>
        <Button onClick={() => { setNavigateConfirmation(false) }}>
          {" "} No{" "}
        </Button>
      </Modal>
    </div>
  )
}