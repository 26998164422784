import React, { useEffect, useState, useRef } from "react";
import "./Home.scss";
import Header from "../../Shared/Components/Header/Header";
import SideNav from "../../Shared/Components/SideNav/SideNav";
import Biases from "./Biases/Biases";
import { useHistory, useLocation } from "react-router-dom";
import menuConfig from "../../Assets/json/sidebar-menu.json";
import { sessionService } from "../../Shared/Utils/SessionService";
import Projects from "./Projects/Projects";
import ProjectDetails from "./Projects/project-details/ProjectDetails";
 
export default function Home(props) {
  const [selectedMenu, setMenu] = useState({});
  const location = useLocation();
  const history = useHistory();
  const [childMenu, setChildMenu] = useState("");
  const [showComment, setShowComment] = useState(false);
  const userDetails = sessionService.getSession();
  const [projectData, setProjectData] = useState({});
  const role = userDetails?.role;
  const childRef = useRef();
 
  useEffect(() => {
      const path = location.pathname;
      const route = path.split("/").pop();
      setChildMenu("");
      if (route === "home") {
        history.replace("home/project");
        setMenu({ key: "Project" });
      } else if (path.includes("/project/details")) {
        setMenu({ key: "project" });
        setChildMenu("project-details");
      }
      else setMenu({ key: route });
  }, [location]);
 
  const onMenuSelect = (selected) => {
    setMenu(selected);
  };
 
  /**
   * To get project data
   * @param {*} data - project details
   */
  const getProjectData = (data) => {
    try {
      setProjectData(data);
    } catch (error) {
      console.log("error", error);
    }
  }
 
  /**
   * To Show Comments
   * @param {*} data - showComments- true
   */
  const showComments =(data) =>{
    try {
      setShowComment(data?.showComments);
    } catch (error) {
      console.log("error", error);
    }
  }
 
  /**
   * To Hide comments
   * @param {*} data - showComments- false
   */
  const hideComments = (data) => {
    try {
      setShowComment(data?.showComments);
    } catch (error) {
      console.log("error", error);
    }
  }
 
  const navigateToHomeEvent=(event) => {
    childRef.current.navigateToHome(event);
  }
 
  return (
    <div className="m-0 row">
      <div className="m-0 p-0 page-side-nav">
        <SideNav
          active={selectedMenu}
          onClick={onMenuSelect}
          menuConfig={menuConfig?.data[role]}
        />
      </div>
      <div className="m-0 p-0 page-container">
        <Header projectData={projectData} showComments={showComments} navigateToHomeEvent={navigateToHomeEvent}/>
        {selectedMenu.key === "biases" && <Biases />}
        {selectedMenu.key === "project" && childMenu === '' && <Projects />}
        {selectedMenu.key === "project" && childMenu === 'project-details' && <ProjectDetails ref={childRef} getProjectData={getProjectData} showComments={showComment} hideComments={hideComments}/>}
      </div>
    </div>
  );
}
 
 
 

