import React, { useState } from "react";
import Paragraph  from "antd/es/typography/Paragraph";
import { Tooltip } from "antd";
const  CustomParagraphToolTip  = ({ children, ellipsis, tooltipContent,paragrapgContent, ...props }) => {
  const [truncated, setTruncated] = useState(false);
  return (
    <Tooltip  title={truncated ? tooltipContent : undefined} placement="topLeft" overlayClassName={`${tooltipContent?.length > 150 ? 'tooltip-width': ''} tooltip-bg`}>
      <Paragraph {...props} ellipsis={{ ...ellipsis, onEllipsis: setTruncated }} >
        {/* NOTE: Fragment is necessary to avoid showing the title */}
        <>{paragrapgContent}</>
      </Paragraph>
    </Tooltip>
  );
}

export default CustomParagraphToolTip;