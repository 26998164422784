export const API_ENDPOINTS = {
  login: "login",
  logoutUser: "logout",
  loginIDM: 'login_idm',
  // User management
  roleList: "user/roles",
  userList: "user/fetch",
  createUser: "user/create",
  updateUser: "user/update",
  deleteUser: "user/delete",

  // biases Endpoint
  biaseList: "bias/fetch",
  biasDetailsFetch: "bias/bias_details/fetch",
  biasDetailsCreate: "bias/bias_details/save",
  relatedBiasData: "metadata/related_bias",
  biasSidebar: 'metadata/biases_sidebar/fetch',
  relatedBiases: 'bias/bias_details/related_bias/fetch',
  relatedBiasDelete: 'bias/bias_details/related_bias/delete',

  //Behavioural Sparks
  createBehaviouralSpark: 'bias/behavioral_spark/create',
  deleteBehaviouralSpark: 'bias/behavioral_spark/delete',
  fetchBehaviouralSparkData: 'bias/behavioral_spark/fetch',
  behaviouralSparkConfigData: 'metadata/beh_spark/dropdown/fetch',

  //Biases- Bias-in-action
  createBiasInAction: 'bias/bias_details/bias_in_action/create',
  deleteBiasInAction: 'bias/bias_details/bias_in_action/delete',
  fetchBiasInAction: 'bias/bias_in_action/fetch',
  fetchBiasInActionRepository: 'bias/bias_in_action_repository/fetch',


  // project
  listProject: "project/fetch",
  createProject: "project/create",
  deleteProject: "project/delete",
  archieveProject: "project/archive",
  projectFetch: "project/details/fetch",
  updateProject: "project/update",
  // meta data
  targetAudienceList: "metadata/targeted_audience/speciality",
  boList: "metadata/behavioral_object_category",
  boListGroup: "metadata/behavioral_object_category/group",
  theraphyArea: "metadata/therapy_area/disease",
  researchMethodology: "metadata/research_methodology",
  projectCode: "project/project_code/fetch",
  projectCodes: "metadata/project_codes",
  countryList: "metadata/country",
  teamMemberDetails: "project/team_member_details/fetch",
  projectStep: "metadata/project_steps",
  projectStepFilter: 'metadata/project_steps/filter',
  primaryContact: 'metadata/primary_contact',
  clientList: "metadata/clients",

  //Candidate Bias
  projectDetails: "project/meta_data/fetch",
  projectMetaData: "project/basic_details/fetch",
  otherBiasList: "project/bias/other/fetch",
  fetchCandidateDropdownsdata: "project/candidate_bias/dropdowns/fetch",
  selectDeselectBias: "project/candidate_bias/suggested/save",
  fetchSuggestedBias: "project/candidate_bias/suggested/fetch",
  fetchAllSelectedBias: "project/candidate_bias/view_selected/fetch",
  deleteSelected: "project/candidate_bias/view_selected/delete",
  fetchOtherBiases: "project/candidate_bias/other/fetch",
  saveOtherBiasData: "project/candidate_bias/other/save",
  nextStepUpdate: "project/current_step/update",

  //Projects Comments
  commentsList: "project/comments/fetch",
  createComments: "project/comments/create",
  archiveComment: "project/comments/archive",
  projectCommentReply: 'project/comments/view_reply',

  //Discussion Builder
  listTargetAudience: "discussion_guide/target_audience/fetch",
  targetAudienceData: "discussion_guide/fetch",
  targetAudienceDataSave: "discussion_guide/create",
  fetchBiasData: "discussion_guide/bias/fetch",
  addSection: "discussion_guide/section/add",
  updateSection: "discussion_guide/section/update",
  updateQuestion: "discussion_guide/question/update",
  addQuestion: "discussion_guide/question/add",
  deleteQuestion: 'discussion_guide/question/delete',
  deleteSection: 'discussion_guide/section/delete',
  questionReorder: 'discussion_guide/question/order/update',
  sectionReorder: 'discussion_guide/section/order/update',
  associatedBias: "discussion_guide/associated_bias/fetch",
  subQuestion: 'discussion_guide/child/update',
  exportDiscussionGuide:'discussion_guide/export',
  fetchModsData: 'discussion_guide/discussion_guide_mod/fetch',

  //Discussion Guide Comments
  fetchQuestionComment:"discussion_guide/question/comment/fetch",
  createQuestionComment:"discussion_guide/question/comment/add",
  fetchSectionComment:"discussion_guide/section/comment/fetch",
  createSectionComment:"discussion_guide/section/comment/add",
  archiveDiscussionGuideComment: "discussion_guide/comment/archive",
  replyDiscussionGuideComment: 'discussion_guide/comments/viewreply',

  //Admin pages
  targetAudienceCreate: "admin/target_audience/create",
  targetAudienceUpdate: "admin/target_audience/update",
  targetAudienceDelete: "admin/target_audience/delete",
  specialtyCreate: "admin/speciality/create",
  specialtyUpdate: "admin/speciality/update",
  specialtyDelete: "admin/speciality/delete",
  therapyAreaCreate: "admin/therapy_area/create",
  therapyAreaUpdate: "admin/therapy_area/update",
  therapyAreaDelete: "admin/therapy_area/delete",
  diseaseCreate: "admin/disease/create",
  diseaseUpdate: "admin/disease/update",
  diseaseDelete: "admin/disease/delete",

  //Qualitative APIs Admin
  tableQualQues: "admin/qualitative_questions/fetch",
  deleteQualQues: "admin/qualitative_questions/delete",
  addNewQuestion: "admin/qualitative_questions/create",
  viewEditQuestion: "admin/qualitative_questions/view_edit_page",
  updateEditQuestion: "admin/qualitative_questions/update",

  //Quantitative APIs Admin
  testDesignCreate: "admin/quantitative_test_designs/create",
  testDesignList: "admin/quantitative_test_designs/fetch",
  testDesignQuestionList: "admin/quantitative_test_designs/test_design/fetch",
  testDesignUpdate: "admin/quantitative_test_designs/update",
  testDesignDelete: "admin/quantitative_test_designs/delete",

  // admin /behavioral objective group 
  behavioralOBJGroupCreate: 'admin/beha_obj_cate_group/create',
  behavioralOBJGroupUpdate: 'admin/beha_obj_cate_group/update',
  behavioralOBJGroupDelete: 'admin/beha_obj_cate_group/delete',
  behavioralOBJCreate: 'admin/beha_obj_cate/create',
  behavioralOBJUpdate: 'admin/beha_obj_cate/update',
  behavioralOBJdelete: 'admin/beha_obj_cate/delete',

  // admin / Bias-In-Action 
  biasList: "metadata/bias_list",
  biasInActionTableList: "bias_in_action/list",
  biasInActionDelete: 'bias_in_action/delete',
  addNewBias: "bias_in_action/create",
  fetchAdminData: "admin/project_attributes/fetch",
  deleteBiaseDetails: "bias_in_action/delete_bias_details/fetch",

  //admin / Bias-In-Action // Comments
  createBiasInActionComments: "bias_in_action/comments/create",
  fetchBiasInActionComments: "bias_in_action/comments/fetch",
  replyBiasInActionComments: "bias_in_action/comments/view_reply",
  archiveBiasInActionComments: "bias_in_action/bias_in_action/comments/archive",

  biasInActionMetaDataFetch: "bias_in_action/metadata/fetch",
  updateBiasInAction: "bias_in_action/update",
  biasInActionBiasList: "bias_in_action/bias/list"
};

export const API_ENVIRONMENT = {

};

export const IDM_CONF = {
  local: {
    idm_login_url: "",
    app_login_url: ""
  },
  dev: {
    idm_login_url: "https://idm.dev.zsservices.com/ABIB/IdentityManager/app/Web/login.aspx",
    app_login_url: "https://braincase.dev.zsservices.com/#/login",
    auth_callback_url: "https://braincase.dev.zsservices.com/#/authCallBack"
  },
  qa: {
    idm_login_url: "https://idm.qa.zsservices.com/ABIB/IdentityManager/app/Web/login.aspx",
    app_login_url: "https://braincase.qa.zsservices.com/#/login",
    auth_callback_url: "https://braincase.qa.zsservices.com/#/authCallBack"
  },
  prod: {
    idm_login_url: "https://javelin.zsservices.com/ABIB/IdentityManager-SSO/app/Web/login.aspx",
    app_login_url: "https://braincase.zsservices.com/#/login",
    auth_callback_url: "https://braincase.zsservices.com/#/authCallBack"
  }
}