import "./BehavioralObjectiveCategory.scss";
import React, { useState, useEffect } from "react";
import SVGIcon from "../../../../../Shared/Components/SVGIcon/SVGIcon";
import { Row, Col, Button, Input, PageHeader, Modal, Collapse, Tooltip, Spin } from 'antd';
import { ServiceUtils } from "../../../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../../../Shared/Utils/ToasterUtil";
import { DebounceInput } from 'react-debounce-input';
import CustomParagraphToolTip from "../../../../../Shared/Components/CustomParagraphToolTip/CustomParagraphToolTip";

const { Panel } = Collapse;

export default function BehavioralObjectiveCategory() {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [CatGroupValue, setCatGroupValue] = useState({
    cat: '',
    description: ''
  })
  const [mainData, setMainData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [clickedSave, setClickedSave] = useState({ cat: false, description: false })
  const [showLoader, setShowLoader] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    loadData();
  }, [])

  /**
   * To load Behavioral Objective Category data
   */
  const loadData = () => {
    try {
      ServiceUtils.postRequest("fetchAdminData", {
        "screen": "behavior_objective_category"
      }).then(
        (response) => {
          try {
            if (response.status === 200) {
              if (response.data.status === "success") {
                const result = response?.data?.data?.behavioral_objective_list;
                if (initialLoad) {
                  if (result && result[0] && result[0]['key']) {
                    setExpanded([result[0]['key']]);
                  }
                  setInitialLoad(false);
                }
                setMainData(result);
                setFilterData(result);
                if (searchValue) {
                  searchData(JSON.parse(JSON.stringify(result)), searchValue);
                }
              } else {
                alertService.error(response.data.message);
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      );
    } catch (error) {
      console.log("error", error);
    }
  }

  const isCategoryDelete = (categoryId) => {
    if (mainData.filter((el) => el.key === categoryId)?.length) {
      return (mainData.filter((el) => el.key === categoryId)?.[0].children?.length) === 0;
    }
  };

  const customHeaderExtra = () => {
    return (
      <div className="collapse-header" onClick={event => event.stopPropagation()}>
        <div className=" Data-descriptio grid-header">
          <CustomParagraphToolTip className="p-header ps-2" tooltipContent={"Data Field Description"} paragrapgContent={"Data Field Description"} />
        </div>
      </div>
    )
  }

  const genExtra = (data) => {
    const selectedField = filterData.find((field) => field.key === data.key);
    return (
      <div className=" collapse-header">
        <div className=" Data-description">
          <CustomParagraphToolTip className="p-header ps-2" ellipsis={{ rows: 2 }} tooltipContent={selectedField.description} paragrapgContent={selectedField.description} />
        </div>
        <div className="header-icon pe-2">
          <Tooltip placement="topRight" title="Edit Behavioral Objective Category Grouping" overlayClassName={'tooltip-bg'}>
            <SVGIcon
              icon={"edit-fill"} className="icons"
              onClick={() => handleModalData('BOGroup', 'Edit', data)}
            />
          </Tooltip>
          &nbsp;&nbsp;
          {isCategoryDelete(data.key) ? (
            <Tooltip placement="topRight" title="Delete Behavioral Objective Category Grouping" overlayClassName={'tooltip-bg'}>
              <SVGIcon
                className="target-disbaled-delete icons"
                icon={"delete-fill"}
                onClick={() => deleteModal(true, 'BOGroup', data, 'Delete Behavioral Objective Category Group', 'Are you sure you want to delete this behavioral objective category group?')}
              /></Tooltip>
          ) : (
            <Tooltip
              overlayClassName={'tooltip-bg'}
              placement="topRight"
              title="A Behavioral Objective Category Group cannot be deleted unless there are no behavioral objective categories under
               the behavioral objective category group"
            >
              <SVGIcon
                className="target-delete-icon"
                icon={"delete-disabled"}
              />
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  /**
   * On searching
   * @param {*} searchText - search value
   */
  const handleSearchText = (searchText) => {
    setSearchValue(searchText);
    setShowLoader(true);
    searchData(JSON.parse(JSON.stringify(mainData)), searchText);
  };

  /**
   * To search value over data
   * @param {*} array -  data
   * @param {*} keyword -  search value
   */
  const searchData = (array, keyword) => {
    try {
      if (keyword.trim()) {
        const searchTerm = JSON.parse(JSON.stringify(keyword.toString().toLowerCase()));
        const searchData = array.filter((value) => {
          let filtered = false;
          let childList = [];
          value?.children.filter((childData) => {
            if (childData?.value.toLowerCase()?.includes(searchTerm)) {
              filtered = true
              childList.push(childData);
            }
          });
          if (filtered) {
            value['children'] = childList;
            return value
          }
        });
        const searchResultKeys = searchData.map(function (el) { return el.key; })
        setFilterData(searchData);
        setExpanded(searchResultKeys);
      } else {
        setFilterData(array);
        setExpanded([]);
      }
    } catch (error) {
      console.log("error", error);
    }
    setShowLoader(false);
  };

  const handleModal = (value) => {
    try {
      setShowModal(value)
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To handle edit
   * @param {*} level - item level
   * @param {*} type - action type
   * @param {*} itemData - Item data
   */
  const handleModalData = (level, type, itemData = undefined, parentData) => {
    setClickedSave({ cat: false, description: false });
    try {
      handleModal(true);
      if (type === 'Edit') {
        var valueDescription = '';
        if (itemData?.value) {
          valueDescription = itemData?.value;
        } else {
          valueDescription = itemData?.header;
        }
        setCatGroupValue({
          cat: valueDescription,
          existingValueCat: valueDescription,
          description: itemData?.description,
          existingValueDescription: itemData?.description
        });
      } else {
        setCatGroupValue({
          cat: '',
          description: ''
        });
      }
      let data = {};
      if (level === 'BOGroup') {
        data = {
          "title": `${type} Behavioral Objective Category Group Value`,
          "label": `${type} Category Group`,
          "placeholder": type === 'Edit' ? "Update the Type" : "Add Type",
          "id": itemData?.key ? itemData?.key : '',
          "newCatErrorMsg": "Please enter the new category group",
          "updateCatError": "Please enter the updated category group",
          "newDescriptionErrorMsg": "Please enter the new data field description",
          "updateDescriptionError": "Please enter the updated data field description",
          actionType: type,
          dataLevel: level,
          "label2": `${type} Data Field Description`,
          "placeholder2": type === 'Edit' ? "Update data field description" : "Enter the new data field description",
          "id2": itemData?.key ? itemData?.key : '',
        }
      } else {
        data = {
          "title": `${type} Behavioral Objective Category value`,
          "label": `${type} Behavioral Objective Category`,
          "placeholder": "Enter the new Behavioral Objective Category name",
          "id": itemData?.key ? itemData?.key : '',
          "groupId": parentData?.key,
          "newCatErrorMsg": "Please enter the new category",
          "updateCatError": "Please enter the updated category",
          actionType: type,
          dataLevel: level
        }
      }
      setModalData(data);
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To handle delete
   * @param {*} value - true/false
   * @param {*} level - data level
   * @param {*} itemData - item data
   */
  const deleteModal = (value, level = undefined, itemData = undefined, title, message) => {
    try {
      let data = {
        level: level,
        id: itemData?.key,
        heading: title,
        message: message
      };
      setDeleteData(data);
      setShowDeleteModal(value);
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To hide and show modal
   * @param {*} value 
   */
  const handleCatGroupValue = (value, type) => {
    try {
      if (!value) {
        setClickedSave({ cat: false, description: false });
      }
      if (type === 'cat') {
        setCatGroupValue({
          ...CatGroupValue,
          cat: value,
        });
      } else {
        setCatGroupValue({
          ...CatGroupValue,
          description: value
        });
      }

    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To create new behavioral objective  group
   */
  const saveData = () => {
    try {
      let error = true;
      setClickedSave({ cat: true, description: true });
      let api = '';
      let inputData = {};
      if (modalData?.dataLevel === 'BOGroup') {
        api = modalData?.actionType === 'Add' ? 'behavioralOBJGroupCreate' : 'behavioralOBJGroupUpdate';
        inputData = {
          "name": CatGroupValue.cat,
          "description": CatGroupValue.description
        }
        if (modalData?.id) {
          inputData = {
            "behavioral_group_id": modalData?.id,
            "name": CatGroupValue.cat,
            "description": CatGroupValue.description
          }
        }
      } else {
        api = modalData?.actionType === 'Add' ? 'behavioralOBJCreate' : 'behavioralOBJUpdate';
        if (modalData?.actionType === 'Add') {
          inputData = {
            "behavioral_group_id": modalData?.id,
            "name": CatGroupValue.cat
          }
        } else {
          inputData = {
            "beha_obj_cate_id": modalData?.id,
            "behavioral_group_id": modalData?.groupId,
            "name": CatGroupValue.cat
          }
        }
      }
      if ((inputData?.name?.trim() === "" && inputData?.description?.trim() === "")) {
        error = false;
        setClickedSave({ cat: true, description: true });
      } else if (inputData?.name?.trim() === "") {
        error = false;
        setClickedSave({ cat: true, description: false });
      } else if (inputData?.description?.trim() === "") {
        error = false;
        setClickedSave({ cat: false, description: true });
      } else {
        error = true;
        setClickedSave({ cat: false, description: false });
      }
      {
        error && ServiceUtils.postRequest(api, inputData).then(
          (response) => {
            try {
              if (response.status === 200) {
                if (response.data.status === "success") {
                  handleModal(false);
                  loadData();
                  setCatGroupValue({});
                  setModalData({});
                  setClickedSave({ cat: false, description: false });
                  alertService.success(response.data.message);
                } else {
                  alertService.error(response.data.message);
                }
              }

            } catch (error) {
              console.log(error);
            }
          }
        );
      }

    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To delete behavioral objective  group
   */
  const deleteItem = () => {
    try {
      let api = deleteData?.level === 'BOGroup' ? 'behavioralOBJGroupDelete' : 'behavioralOBJdelete';
      let input = {};
      if (deleteData?.level === 'BOGroup') {
        input = {
          "beha_group_id": deleteData?.id
        }
      } else {
        input = {
          "beha_obj_cate_id": deleteData?.id
        }
      }
      ServiceUtils.postRequest(api, input).then(
        (response) => {
          try {
            if (response.status === 200) {
              if (response.data.status === "success") {
                deleteModal(false);
                setDeleteData({});
                loadData();
                alertService.success(response.data.message);
              } else {
                alertService.error(response.data.message);
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      );
    } catch (error) {

    }
  }
  const panelHeader = (data) => (
    <React.Fragment>
      <SVGIcon icon={expanded.length && expanded.includes(data?.key) ? 'down-arrow-transparent' : 'right-arrow-transparent'} className="arrowIcon" />
      <span className="panelHeader" style={{ fontWeight: "500", width: '250px' }} onClick={event => event.stopPropagation()}>
        <CustomParagraphToolTip ellipsis={{ rows: 2 }} tooltipContent={data?.header} paragrapgContent={data?.header} />
      </span>
    </React.Fragment>
  )

  const customHeader = () => (
    <React.Fragment>
      <span className="grid-header" onClick={event => event.stopPropagation()}>
        Behavioral Objective Category Group
      </span>
    </React.Fragment>
  )

  const collapseChangeAction = (event) => {
    const expandedData = event.map(ele => parseInt(ele));
    setExpanded(expandedData);
  }

  return (
    <div className="behavioral-category-objective-layout">
      {(
        <div className=" temp p-0">
          <PageHeader className="behavioralHeader" title="Behavioral Objective Category" />
          <div className="search-section">
            <div className="searchFill  d-flex">
              <DebounceInput
                debounceTimeout={500}
                type="text"
                className="form-control form-control-sm"
                placeholder="Search Behavioral Objective Category"
                onChange={(e) => {
                  handleSearchText(e.target.value);
                }}
              />
              <button className="searchButton text-muted me-2">
                {showLoader ? (<Spin size="small" />) : (<SVGIcon icon={"search-transparent"} />)}
              </button>
            </div>
            <Button
              type="primary"
              className="categoryBtn primary-btn"
              onClick={() => handleModalData('BOGroup', 'Add')}
            >
              <SVGIcon icon={"add-circle-white"} className="iconPositionInButton" />
              &nbsp;Add Category Grouping
            </Button>
          </div>
          {/* Add category grouping (button) */}
          <div className="collepse-main-section">
            {mainData.length !== 0 && <div className="behavioralCollapse">
              <Collapse activeKey={expanded} onChange={(event) => collapseChangeAction(event)}>
                <Panel className="header-position" showArrow={false} header={customHeader()} extra={customHeaderExtra()}>
                </Panel>
                <div className="text-border header-position-div"></div>
                {filterData.map((dataSource, index) => (
                  <Panel
                    showArrow={false}
                    className="panel-source"
                    header={panelHeader(dataSource)}
                    key={dataSource?.key}
                    collapsible="header"
                    extra={genExtra(dataSource, index)}
                  ><p className="Behavioral-pannel">
                      <div className="category-section">
                        <div className="row-header">
                          <span className="header-data-p"> {'Behavioral Objective Category '}</span>
                        </div>
                        <Button
                          type="primary"
                          className="button-Behavioral-Objective"
                          onClick={() => handleModalData('BOCategory', 'Add', dataSource)}
                        >
                          <SVGIcon icon={"add-circle-white"} className="behavioral-add iconPositionInButton" />
                          &nbsp; Add Behavioral Objective Category
                        </Button>
                      </div>
                    </p>
                    {dataSource?.children?.map((child, ind) => {
                      return (
                        <p className="Behavioral-pannel">
                          <Row className=" pb-3" style={{ borderBottom: `${(ind !== dataSource?.children.length - 1) ? 1 : 0}px solid rgb(187 187 187 / 44%)` }}>
                            <Col span={10}>
                              <CustomParagraphToolTip ellipsis={{ rows: 2 }} tooltipContent={child?.value} paragrapgContent={child?.value} />
                            </Col>
                            <Col span={12}></Col>
                            <Col span={2} className={'behavioralOBJEditDeleteIcon ps2'}>
                              <Tooltip placement="topRight" overlayClassName={'tooltip-bg'} title="Edit Behavioral Objective Category" >
                                <SVGIcon icon={'edit-fill'} className="icons" onClick={() => handleModalData('BOCategory', 'Edit', child, dataSource)} />
                              </Tooltip>
                              &nbsp;&nbsp;
                              <Tooltip placement="topRight" overlayClassName={'tooltip-bg'} title="Delete Behavioral Objective Category" >
                                <SVGIcon icon={'delete-fill'} className="icons" onClick={() => deleteModal(true, 'BOCategory', child, 'Delete Behavioral Objective Category', 'Are you sure you want to delete this behavioral objective category?')} />
                              </Tooltip>
                            </Col>
                          </Row>
                        </p>
                      )
                    })}

                  </Panel>
                ))}
                {filterData.length == 0 && <p className="text-center p-5 no-result">No search results returned. Please modify your search criteria to view behavioral objective categories.</p>}
              </Collapse>

            </div>}
            {mainData.length == 0 && <p className="text-center p-5 no-result">No Data Found</p>}
          </div>
          <div>

            {/* add Behavioral Objective Category */}
            <Modal
              title={modalData?.title}
              bodyStyle={{ height: 230 }}
              centered
              open={showModal}
              footer={[
                <Button key="submit" type="primary" onClick={saveData}>
                  Save
                </Button>,
                <Button key="back" onClick={() => handleModal(false)}>
                  Cancel
                </Button>,
              ]}
              onCancel={() => handleModal(false)}
            >
              <div>
                <div className="pb-4">
                  {modalData?.label}
                  <div className="pt-2">
                    <Input placeholder={modalData?.placeholder} value={CatGroupValue.cat} onChange={(e) => {
                      handleCatGroupValue(e.target.value.replace(/^\s+/g, ''), 'cat');
                    }}
                      status={(clickedSave?.cat && (!CatGroupValue.cat && modalData?.actionType === 'Add')) || (clickedSave?.cat && (!CatGroupValue.cat && modalData?.actionType === 'Edit')) ? "error" : ''}
                    />
                    {(clickedSave?.cat && (!CatGroupValue.cat && modalData?.actionType === 'Add')) && <p className="mb-0" style={{ color: '#DC3545' }}>{modalData?.newCatErrorMsg}</p>}
                    {(clickedSave?.cat && (!CatGroupValue.cat && modalData?.actionType === 'Edit')) && <p className="mb-0" style={{ color: '#DC3545' }}>{modalData?.updateCatError}</p>}

                  </div>
                </div>
                {modalData?.dataLevel === 'BOGroup' &&
                  <div>
                    {modalData?.label2}
                    <div className="pt-2">
                      <Input placeholder={modalData?.placeholder2} value={CatGroupValue.description} onChange={(e) => {
                        handleCatGroupValue(e.target.value.replace(/^\s+/g, ''), 'description');
                      }}
                        status={(clickedSave?.description && (!CatGroupValue.description && modalData?.actionType === 'Add')) || (clickedSave?.description && (!CatGroupValue.description && modalData?.actionType === 'Edit')) ? "error" : ""}
                      />
                      {(clickedSave?.description && (!CatGroupValue.description && modalData?.actionType === 'Add')) && <p className="mb-0" style={{ color: '#DC3545' }}>{modalData?.newDescriptionErrorMsg}</p>}
                      {(clickedSave?.description && (!CatGroupValue.description && modalData?.actionType === 'Edit')) && <p className="mb-0" style={{ color: '#DC3545' }}>{modalData?.updateDescriptionError}</p>}
                    </div>
                  </div>
                }
              </div>
            </Modal>
          </div>

          {/* Delete Confirmation Modal*/}
          <Modal
            centered
            closable={false}
            open={showDeleteModal}
            footer={<>
              <Button type="primary" className="me-2" onClick={() => deleteItem()}>
                Delete
              </Button>
              <Button onClick={() => deleteModal(false)}>
                {" "} Cancel{" "}
              </Button>
            </>}
            width={400}
            zIndex={2010}
            onCancel={() => deleteModal(false)}
          >
            <h6 >{deleteData?.heading} </h6>
            <p className="text-muted">
              {deleteData?.message}
            </p>
          </Modal>
        </div>
      )
      }
    </div >
  );
};

