import React, { useState, useEffect } from 'react';
import "./BiasDetailsEditors.scss";
import QuillRichTextEditor from "../../../../../Shared/Components/RichEditor/rich-editor";
import { Spin } from 'antd';

export default function BiasDetailsEditors({ description, biasDetailsData, updateEditorValue, mode, selectedTabIndex, ...props }) {

  const scrollDiv = "#biasDetailsDrawer .ant-drawer-body";

  const editorStyleData = {
    height: '350px'
  }
  const [isLoaded, setIsLoaded] = useState(false);
  const editorToolBarSetting = {
    // Toolbar customized according to our needs
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link', "image"],
        [{ 'align': [] }], ['divider'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ],
      // handlers: { image: imageHandler() },
    },

    // Divider added separately (customized one)
    divider: { icon: "" },

    // Image resize feature for the image uploaded to the editor
    imageDrop: true,
    blotFormatter: {},
  };

  useEffect(() => {
    setIsLoaded(false);
    setTimeout(() => {
      setIsLoaded(true);
    }, 300);
  }, [selectedTabIndex])

  const updateContent = (content) => {
    try {
      if (typeof updateEditorValue === 'function') updateEditorValue({ 'type': 'editable_nav', 'content': content });
    } catch (error) {
      console.log("error", error);
    }
  }

  return (<div className='bias-edit-tabs'>
    <div className="p-1 border-bottom text-muted mainData" dangerouslySetInnerHTML={{ __html: description }} ></div>
    <div className='bias-editors'>
      {!isLoaded && <Spin style={{ padding: '15px' }} />}
      {isLoaded &&
        <QuillRichTextEditor
          mode={mode} editorToolBarSetting={editorToolBarSetting}
          editorContent={biasDetailsData?.content}
          updateContent={updateContent}
          editorStyleData={editorStyleData}
          scrollingContainer={scrollDiv}
        />}
    </div>
  </div>
  )
}
