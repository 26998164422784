export const ENVIRONMENTS = {
  localhost: {
    baseurl: "/BrainCase/",
  },
  dev: {
    baseurl: "https://api-braincase.dev.zsservices.com/BrainCase/",
  },
  qa: {
    baseurl: "https://api-braincase.qa.zsservices.com/BrainCase/"
  },
  prod: {
    baseurl: "https://api-braincase.zsservices.com/BrainCase/"
  }
};

export const DEPLOYED_ENVIRONMENT = process.env.REACT_APP_DEPLOYED_ENVIRONMENT;
export const BASE_PATH = "/braincase"; 
