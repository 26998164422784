import React, { useState, useEffect } from 'react';
import './HypothesisStatement.scss';
// import bootstrap from 'bootstrap';
import workInProgress from '../../../../../Assets/images/work-in-progress.png';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
export default function HypothesisStatement() {
  // const [sortOrder, setSortOrder] = useState('') // ascending or descending oredr
  // const [sortBy, setSortBy] = useState('') // coloumn
  // const updateSorting = (col_name) => {
  //   if (col_name === sortBy) {
  //     setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
  //   } else {
  //     setSortBy(col_name);
  //     setSortOrder('asc');
  //   }
  // }
  // useEffect(() => {
  //   // try {
  //   //     if (!sortBy || !sortOrder) return;
  //   //     const sorted = [...projectTable?.filteredData].sort((a, b) => {
  //   //         if (sortOrder === "asc") {
  //   //             return a[sortBy].toLowerCase() < b[sortBy].toLowerCase() ? 1 : -1
  //   //         } else {
  //   //             return a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1
  //   //         }
  //   //     }
  //   //     );
  //   //     setProjectTable({
  //   //         ...projectTable,
  //   //         filteredData: sorted
  //   //     })
  //   // } catch (error) {

  //   // }
  // }, [sortBy, sortOrder]);
  return (
    // <div>
    //   <div className='w-100 text-center pt-5'>
    //   <img className='mt-5 workInProgressImage' src={workInProgress} alt="" />
    // </div>
    //   {/* <div>
    //     <table className="table  table-custom-responsive" >
    //       <thead>
    //         <tr>
    //           <th className="" onClick={() => updateSorting('project_name')}>
    //             Candidate Biases  &nbsp;&nbsp;
    //             {sortBy !== 'project_name' ? <FontAwesomeIcon className="sortIcon " icon={faSort} /> :
    //               (sortOrder === 'asc' ? <FontAwesomeIcon className="sortIcon " icon={faSortUp} /> :
    //                 <FontAwesomeIcon className="sortIcon  iconDown" icon={faSortDown} />)}
    //             <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-filter" viewBox="0 0 16 16">
    //               <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
    //             </svg>
    //           </th>
    //           <th colSpan="2" onClick={() => updateSorting('target_audience')}>Last Modified &nbsp;&nbsp;
    //             {sortBy !== 'target_audience' ? <FontAwesomeIcon className="sortIcon " icon={faSort} /> :
    //               (sortOrder === 'asc' ? <FontAwesomeIcon className="sortIcon " icon={faSortUp} /> :
    //                 <FontAwesomeIcon className="sortIcon  iconDown" icon={faSortDown} />)}
    //           </th>
    //           <th onClick={() => updateSorting('therapy_area')}>Last Modified By &nbsp;&nbsp;
    //             {sortBy !== 'therapy_area' ? <FontAwesomeIcon className="sortIcon " icon={faSort} /> :
    //               (sortOrder === 'asc' ? <FontAwesomeIcon className="sortIcon " icon={faSortUp} /> :
    //                 <FontAwesomeIcon className="sortIcon  iconDown" icon={faSortDown} />)}
    //           </th>
    //           <th>Action</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         <tr>
    //           <td colSpan={5}>
    //           <div class="accordion accordion-flush" id="accordionFlushExample">
    //     <div class="accordion-item">
    //       <h2 class="accordion-header" id="flush-headingOne">
    //         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
    //           <tr>
    //             <td>Ambiguity A version</td>
    //             <td>Apr 14, 2022</td>
    //             <td>Sam Sally</td>
    //             <td>Action</td>
    //           </tr>
    //         </button>
    //       </h2>
    //       <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
    //         <div class="accordion-body">
    //         <table className="table  table-custom-responsive" >
    //       <thead>
    //         <tr>
    //           <th className="" onClick={() => updateSorting('project_name')}>
    //             Target Audience  &nbsp;&nbsp;
    //             {sortBy !== 'project_name' ? <FontAwesomeIcon className="sortIcon " icon={faSort} /> :
    //               (sortOrder === 'asc' ? <FontAwesomeIcon className="sortIcon " icon={faSortUp} /> :
    //                 <FontAwesomeIcon className="sortIcon  iconDown" icon={faSortDown} />)}
    //             <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-filter" viewBox="0 0 16 16">
    //               <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
    //             </svg>
    //           </th>
    //           <th colSpan="2" onClick={() => updateSorting('target_audience')}>Behavioral Objective &nbsp;&nbsp;
    //             {sortBy !== 'target_audience' ? <FontAwesomeIcon className="sortIcon " icon={faSort} /> :
    //               (sortOrder === 'asc' ? <FontAwesomeIcon className="sortIcon " icon={faSortUp} /> :
    //                 <FontAwesomeIcon className="sortIcon  iconDown" icon={faSortDown} />)}
    //           </th>
    //           <th onClick={() => updateSorting('therapy_area')}>Hypothesis &nbsp;&nbsp;
    //             {sortBy !== 'therapy_area' ? <FontAwesomeIcon className="sortIcon " icon={faSort} /> :
    //               (sortOrder === 'asc' ? <FontAwesomeIcon className="sortIcon " icon={faSortUp} /> :
    //                 <FontAwesomeIcon className="sortIcon  iconDown" icon={faSortDown} />)}
    //           </th>
    //           <th onClick={() => updateSorting('therapy_area')}>Driver/Barrier &nbsp;&nbsp;
    //             {sortBy !== 'therapy_area' ? <FontAwesomeIcon className="sortIcon " icon={faSort} /> :
    //               (sortOrder === 'asc' ? <FontAwesomeIcon className="sortIcon " icon={faSortUp} /> :
    //                 <FontAwesomeIcon className="sortIcon  iconDown" icon={faSortDown} />)}
    //           </th>
              
    //         </tr>
    //       </thead>
    //       <tbody>
    //       </tbody>
    //     </table>
    //         </div>
    //       </div>
    //     </div>
       
    //   </div> 
    //           </td>
    //         </tr>
         
    //       </tbody>
    //     </table>
    //   </div> */}
     
     
    // </div>
    <div className="form-container">
      <img className='mt-2 workInProgressImage' src={workInProgress} alt="" />
    </div>
  )
}
