import React from "react";
import classNames from "classnames";
import "./SideNav.scss";
import { useHistory } from "react-router-dom";
import { checkAccess } from "../../Utils/CommonUtil";
import SVGIcon from "../../Components/SVGIcon/SVGIcon";
import { ServiceUtils } from "../../Utils/ServiceUtils";
import { alertService } from "../../Utils/ToasterUtil";
import { Space, Layout, Popover } from "antd";

export default function SideNav({ onClick, active, menuConfig, ...props }) {
  const history = useHistory();
  const { Sider } = Layout;
  const onSelect = (menu) => {
    if (typeof onClick === "function") onClick(menu);
    if (menu.route) {
      history.push(menu.route);
    }
  };

  /**
   * To navigate home page
   */
  const navigateToHomePage = () => {
    history.push("/home");
  };

  /**
   * On logout
   */
  const logout = () => {
    try {
      let userData = {};
      ServiceUtils.postRequest("logoutUser", userData).then((_response) => {
        if (_response.status === 200) {
          if (_response.data.status === "success") {
            localStorage.clear();
            history.replace("/logout");
            alertService.success(
              _response.data.message || `User logout successfully`
            );
          } else {
            alertService.error(
              _response.data.message || `Error while logout user`
            );
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
    // }

  };

  return (
    <div className="nav-main" data-test="header-container">
      <Layout>
        <Sider trigger={null} collapsible>
          <div className="nav-logo">
            <SVGIcon
              icon={"braincase-icon"}
              onClick={navigateToHomePage}
              className="cursor-pointer"
            />
          </div>
          {menuConfig &&
            menuConfig?.topMenu?.map(
              (el) =>
                (!el?.userAccess || checkAccess(el?.userAccess)) && (
                  <div
                    key={el.key}
                    className={classNames(
                      "menu-item",
                      "text-center",
                      { active: active?.key === el.key },
                      { disabledSection: el.disabledSection }
                    )}
                    onClick={() => onSelect(el)}
                  >
                    <SVGIcon icon={el.icon} className="menu-icon" />
                    <p className="menu-text">{el.label}</p>
                  </div>
                )
            )}
          <div className="buttom-item text-center ">
            <div
              data-toggle="tooltip"
              className={classNames(
                "customPaddingForIcon",
                "menu-item-bottom",
                "text-center",
                "dropdown"
              )}
            >
              <div className={classNames("logout-allign")}>
                <Popover
                  placement="right"
                  overlayClassName="logout-popover"
                  content={
                    <a
                      className="ms-1 text-decoration-none"
                      style={{ color: "black" }}
                      onClick={logout}
                    >
                      Logout
                    </a>
                  }
                  trigger="click"
                >
                  <a>
                    <Space>
                      <SVGIcon
                        className="svgIconBottom menu-icon"
                        icon={"user-fill"}
                        dropdown-toggle="true"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      />
                    </Space>
                  </a>
                </Popover>
              </div>
            </div>
            <div
              data-toggle="tooltip"
              className={classNames("menu-item-bottom", "text-center")}
            >
              <SVGIcon className="svgIconBottom menu-icon" icon={"help-fill"} />
            </div>
          </div>
        </Sider>
      </Layout>
    </div>
  );
}
