import React, { useEffect, useRef, useState } from "react";
import "./BiasInAction.scss";
import { ServiceUtils } from "../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../Shared/Utils/ToasterUtil";
import SVGIcon from "../../../Shared/Components/SVGIcon/SVGIcon";
import { Tooltip, Button, Row, Col, Modal, Collapse, Space, Drawer, Spin } from "antd";
import FormBuilder from "../../../Shared/Components/Ant-FormBuilder/Ant-FormBuilder";
import BiasInActionTable from "./BiasInActionTable/BiasInActionTable";
import AddBiasInAction from "./AddBiasInAction";
import EditBiasInAction from "./EditBiasInAction";
import CommentBiasInAction from "./BiasInActionComment";
import Highlighter from "react-highlight-words";

export default function BiasInAction() {
  const [visible, setVisible] = useState(false);
  const { Panel } = Collapse;
  const [expanded, setExpanded] = useState([" "]);
  const [biasList, setBiasList] = useState([" "]);
  const [selectedBias, setSelectedBias] = useState({}); //on delete biase set biase name and image id
  const [biasDeleteModal, setBiasDeleteModal] = useState(false)
  const [imgModalVisible, setImgModalVisible] = useState(false);
  const [selectedImgData, setSelectedImgData] = useState({});
  const [singleDeleteModal, setSingleDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState('')
  const [biasData, setBiasData] = useState('');
  const editBiasInActionRef = useRef(null);
  const [openPanels, setOpenPanels] = useState([]); // all active pannel
  const commentBiasInActionRef = useRef(null);
  const [preSearch, setPreSearch] = useState('');
  const [biasSortOrder, setBiasSortOrder] = useState('asc');
  const [filterConf, setFilterConfig] = useState([
    [
      {
        key: "bias_ids",
        label: "Bias",
        type: "multi-select",
        search: true,
        placeholder: "Please Select ",
        col: 24,
        maxTagCount: 1,
        allowClear: true,
        options: []
      },
      {
        key: "pharma",
        label: "Pharma/Non-Pharma",
        type: "multi-select",
        search: true,
        placeholder: "Please Select",
        col: 24,
        allowClear: true,
        maxTagCount: 1,
        options: [
          { label: "Pharma", value: true },
          { label: "Non-Pharma", value: false }
        ]
      },
      {
        key: "audience",
        label: "Audience",
        type: "multi-select",
        maxTagCount: 1,
        placeholder: "Please Select",
        col: 24,
        allowClear: true,
        options: [
          { label: "Physicians", value: "physicians" },
          { label: "Patients", value: "patients" },
          { label: "Consumer", value: "consumer" },
        ]
      },
      {
        key: "example_type",
        label: "Activating/Mitigating",
        type: "multi-select",
        maxTagCount: 1,
        placeholder: "Please Select",
        col: 24,
        allowClear: true,
        options: [
          { label: "Activating", value: "activating" },
          { label: "Mitigating", value: "mitigating" }
        ]

      }
    ]
  ]);
  const addBiasInActionRef = useRef(null);
  const [filterPayload, setFilterPayload] = useState({});
  const [selectedFilterPayload, setSelectedFilterPayload] = useState({
    "bias_ids": [],
    "pharma": [],
    "audience": [],
    "example_type": [],
    "search_text": ""
  })
  const [biasInAction, setBiasInAction] = useState({
    body: [],
    filteredData: [],
  });
  const [disableBiasSort, setDisableBiasSort] = useState(false);

  useEffect(() => {
    fetchBiasList();
    fetchBiasInActionBiasList(selectedFilterPayload);
  }, [])

  useEffect(() => {
    applySorting();
  }, [biasSortOrder]);

  useEffect(() => {
    const res = biasInAction?.body.filter((bias) => bias?.loading);
    setDisableBiasSort(res && res?.length);
  }, [biasInAction]);

  /**
   * On searching
   * @param {*} searchText - search value
  */
  const handleSearchText = (searchText) => {
    setSearchText(searchText);
    setSelectedFilterPayload({ ...selectedFilterPayload, search_text: searchText });
  };

  /**
   * To search bias in action
   */
  const searchBiasInaction = () => {
    if (searchText.trim() || preSearch) {
      fetchBiasInActionBiasList({ ...selectedFilterPayload, search_text: searchText });
    }
  }

  const showModal = () => {
    addBiasInActionRef.current.showModal();
  };

  const showBiasInActionFilterDrawer = () => {
    setFilterPayload({ ...selectedFilterPayload });
    setVisible(true);
  };

  const closeBiasInActionFilterDrawer = () => {
    setVisible(false);
  };

  /**
   * On sorting
   */
  const sorting = () => {
    try {
      if (biasSortOrder === 'default') { ///// ascending
        setBiasSortOrder('asc')
      } else if (biasSortOrder === 'asc') { // descending
        setBiasSortOrder('desc')
      } else if (biasSortOrder === 'desc') {
        setBiasSortOrder('default')
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To apply sorting and update data
   */
  const applySorting = (data = undefined) => {
    try {
      if (data || biasInAction?.body?.length) {
        let biasData = data ? data : JSON.parse(JSON.stringify(biasInAction?.body));
        if (biasSortOrder === 'asc') { ///// ascending
          const sortedData = [...biasData].sort((a, b) =>
            a['name'].toLowerCase() > b['name'].toLowerCase() ? 1 : -1
          );
          setBiasInAction({
            body: data ? data : biasInAction?.body,
            filteredData: sortedData
          });
        } else if (biasSortOrder === 'desc') { // descending
          const sortedData = [...biasData].sort((a, b) =>
            a['name'].toLowerCase() < b['name'].toLowerCase() ? 1 : -1
          );
          setBiasInAction({
            body: data ? data : biasInAction?.body,
            filteredData: sortedData
          });
        } else if (biasSortOrder === 'default') {
          setBiasInAction({
            body: data ? data : biasInAction?.body,
            filteredData: biasData
          });
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To fetch bias data
   */
  const fetchBiasList = () => {
    try {
      ServiceUtils.getRequest("biasList",).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data?.bias_data;
            const tempBiasList = [];
            result.forEach(el => {
              tempBiasList.push({
                label: el.bias_name,
                value: el.bias_id
              })
            });
            setBiasList([...tempBiasList]);
            filterConf.map((item) => {
              item.map((el) => {
                if (el.key === 'bias_ids') {
                  el.options = [...tempBiasList];
                }
                return el;
              });
              return item;
            });
            setFilterConfig([...filterConf]);
          } else {
            alertService.error(
              response.data.message || `Error while fetching target audience list`
            );
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const panelHeader = (data, bias_id) => (
    <React.Fragment  >
      <div className={'d-flex'}>
        <SVGIcon onClick={() => { handleOnclick(bias_id) }} icon={expanded?.includes(bias_id) ? 'down-arrow-transparent' : 'right-arrow-transparent'} className="arrowIcon" />
        <span
          className="panelHeader"
          style={{ fontWeight: "500" }}
          onClick={(e) => e.stopPropagation()}
        >
          <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={[preSearch]}
            autoEscape={true}
            textToHighlight={data?.name}
          >
          </Highlighter>
          {/* {data?.name} */}
        </span>
      </div>
    </React.Fragment>
  );

  const handleOnclick = (bias_id) => {
    try {
      let tempArray = [...expanded]
      if (tempArray.includes(bias_id)) {
        let indexDelete = tempArray.indexOf(bias_id)
        tempArray.splice(indexDelete, 1)
      }
      else {
        tempArray.push(bias_id)
      }
      setExpanded(tempArray);
      if (!tempArray?.includes(bias_id)) {
        return;
      }
      fetchBiasTableData(bias_id);
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * T fetch bias table data
   * @param {*} bias_id - bias id
   */
  const fetchBiasTableData = (bias_id) => {
    try {
      updateBiasinActionData(bias_id, true);
      const data = {
        "bias_id": bias_id,
        "pharma": filterPayload?.pharma,
        "audience": filterPayload?.audience,
        "example_type": filterPayload?.example_type,
        "search_text": searchText
      }
      ServiceUtils.postRequest("biasInActionTableList", data, false).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data?.bias_in_action_data;
            updateBiasinActionData(bias_id, false, result);
          } else {
            updateBiasinActionData(bias_id, false);
            alertService.error(
              response.data.message || `Error while fetching target audience list`
            );
          }
        } else {
          updateBiasinActionData(bias_id, false);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To update bias in action data
   */
  const updateBiasinActionData = (bias_id, loading, biasTableData = undefined) => {
    try {
      biasInAction?.body?.map((bias) => {
        if (bias?.bias_id === bias_id) {
          if (biasTableData) {
            bias['biasTableData'] = biasTableData;
          }
          bias['loading'] = loading;
        }
        return bias;
      });
      setBiasInAction({
        ...biasInAction
      });
      applySorting(biasInAction?.body);
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To delete bias image
   * @param {*} isAll 
   */
  const deleteBiasImage = (isAll) => {
    try {
      const data = {
        "bia_id": selectedBias.biasInActionId,
        "is_all": isAll,
        "image_id": selectedBias.imageId
      }
      ServiceUtils.postRequest("biasInActionDelete", data).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            alertService.success(
              response.data.message || `Successfully deleted bias in action`
            );
            setBiasDeleteModal(false);
            setSingleDeleteModal(false);
            if (!isAll) {
              fetchBiasTableData(biasData?.bias_id);
            } else {
              if (selectedBias?.biasDetails) {
                selectedBias?.biasDetails.map((bias) => {
                  if (expanded?.includes(bias?.bias_id)) {
                    fetchBiasTableData(bias?.bias_id);
                  }
                  return bias;
                })
              }
            }
          } else {
            alertService.error(
              response.data.message || `Error while deleting biase in action`
            );
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * Table action events
   * @param {*} type - action type
   */
  const tableActionEvent = (event) => {
    try {
      if (event?.eventType === 'delete') {
        checkImageAvailability(event?.deleteInfo);
      } else if (event?.eventType === 'showImg') {
        let tempData = event?.imgInfo;
        const biasData = biasInAction.filteredData.filter((el) => {
          return el.bias_id === event?.imgInfo?.bias_id
        })
        if (biasData && biasData.length) {
          tempData['bias_name'] = biasData[0]['name'];
        }
        setSelectedImgData(tempData);
        setImgModalVisible(true);
      }
      else if (event?.eventType === 'edit') {
        editBiasInActionRef.current.showEditModal(event?.imgInfo?.data);
      }
      else if (event?.eventType === 'comment') {
        commentBiasInActionRef.current.showModal(event?.imgInfo?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * To check image availability
   * @param {*} deleteInfo - delete data
   */
  const checkImageAvailability = (deleteInfo) => {
    try {
      const biasData = biasInAction.filteredData.filter((el) => {
        return el.bias_id === deleteInfo?.bias_id
      })
      if (biasData && biasData.length) {
        setBiasData(biasData[0]);
      }
      ServiceUtils.postRequest("deleteBiaseDetails", { image_id: deleteInfo?.img_id }).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            if (response?.data && response?.data?.data?.bias_list) {
              let tempData = {
                biasDetails: response?.data?.data?.bias_list,
                imageId: deleteInfo?.img_id,
                biasInActionId: deleteInfo?.bias_in_action_id,
              };
              setSelectedBias(tempData);
              if (response?.data?.data?.bias_list.length === 1) {
                setSingleDeleteModal(true);
              } else {
                setBiasDeleteModal(true);
              }
            }
          } else {
            alertService.error(
              response.data.message || `Error while deleting bias in action`
            );
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * On bias view modal close event
   */
  const onBiasViewClose = () => {
    setImgModalVisible(false);
  }

  /**
   * To refresh table data
   */
  const refreshTableData = ({ biasIds }) => {
    try {
      for (const biasId of biasIds) {
        if (expanded?.includes(biasId)) {
          fetchBiasTableData(biasId);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  /**
   * Bias in action filter
   */
  const handleFilterFormAction = ({ type, payload }) => {
    setFilterPayload({ ...payload.data })
  }

  /**
   * On apply filter
   */
  const applyFilter = () => {
    try {
      if ((filterPayload?.audience.length === 0 && filterPayload?.bias_ids.length === 0 && filterPayload?.example_type.length === 0 && filterPayload?.pharma.length === 0) && (selectedFilterPayload?.audience.length === 0 && selectedFilterPayload?.bias_ids.length === 0 && selectedFilterPayload?.example_type.length === 0 && selectedFilterPayload?.pharma.length === 0)) {
        setVisible(false);
      } else {
        setSelectedFilterPayload({ ...filterPayload });
        fetchBiasInActionBiasList({ ...filterPayload, search_text: preSearch });
        const biasIds = filterPayload?.bias_ids
        refreshTableData({ biasIds })
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * On reset filter
   */
  const resetFilter = () => {
    try {
      const filterReset = {
        "bias_ids": [],
        "pharma": [],
        "audience": [],
        "example_type": [],
        "search_text": preSearch
      }
      if (selectedFilterPayload?.audience.length === 0 && selectedFilterPayload?.bias_ids.length === 0 && selectedFilterPayload?.example_type.length === 0 && selectedFilterPayload?.pharma.length === 0) {
        setVisible(false);
      } else {
        setFilterPayload({ ...filterReset });
        setSelectedFilterPayload({ ...filterReset })
        fetchBiasInActionBiasList(filterReset);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To fetch bias in action bias data
   * @param {*} filterData 
   */
  const fetchBiasInActionBiasList = (filterData = null) => {
    try {
      ServiceUtils.postRequest("biasInActionBiasList", (filterData ? filterData : selectedFilterPayload)).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setExpanded([" "]);
            setOpenPanels([])
            const result = response?.data?.data?.biases;
            setBiasInAction({
              body: result,
              filteredData: result
            });
            applySorting([...result]);
            setTimeout(() => {
              setPreSearch((searchText || "").trim());
            }, 100);
            setVisible(false);
          } else {
            alertService.error(
              response.data.message || `Error while fetching target audience list`
            );
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  const editBiasInActionFromViewState = (imgInfo) => {
    editBiasInActionRef.current.showEditModal(imgInfo);
    setImgModalVisible(false);
  }

  return (
    <>
      <AddBiasInAction ref={addBiasInActionRef} biasList={biasList} refreshTableData={refreshTableData} />
      <EditBiasInAction ref={editBiasInActionRef} biasList={biasList} refreshTableData={refreshTableData} />
      <CommentBiasInAction ref={commentBiasInActionRef} refreshTableData={refreshTableData} />
      <div className="bias-in-action-page">
        <div className="searchMainDiv mb-3 d-flex">
          <div className="searchFilterDiv input-group ps-0 ">
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Search"
              onChange={(e) => {
                handleSearchText(e.target.value);
              }}
            />
            <button className="searchButton text-muted me-2" disabled={searchText.trim() === "" ? (preSearch === "" ? true : false) : false} onClick={searchBiasInaction}>
              <SVGIcon icon={"search-transparent"} />
            </button>
            <div className="filter-div ps-2 pt-2">
              <span className="filtersButton" onClick={showBiasInActionFilterDrawer}>
                <SVGIcon icon={"filter"} />
                &nbsp;
                <span className="filter-text">Filters</span>
              </span>
            </div>
          </div>
          <Button
            type="primary"
            className="new-proj-button d-flex"
            onClick={showModal}
          >
            <SVGIcon className="mb-2 iconPositionInButton" icon={"add-circle-white"} />
            &nbsp;
            <span className="pb-1">Add New Bias-In-Action</span>
          </Button>
        </div>

        <div className="section-collape">
          <div className="header-div ">
            <Row>
              <Col span={12} >
                <span style={{ marginLeft: "1em" }} className={`header-bias ${disableBiasSort ? "sort-disbled" : "cursor-pointer"}`} onClick={!disableBiasSort && sorting}>
                  Bias {""}
                  <span >
                    {biasSortOrder === 'default' && <SVGIcon icon={"sort-default-new"} />}
                    {biasSortOrder === 'asc' && <SVGIcon icon={"sort-up-new"} />}
                    {biasSortOrder === 'desc' && <SVGIcon icon={"sort-down-new"} />}
                  </span>
                </span>
              </Col>
              <Col span={12} className="pharma-section pe-3">
                <span className="pharma-header">Pharma</span>{" "}
                <SVGIcon
                  icon={"add-circle-bowl-fill"}
                  className="pharma-header-icon"
                />&nbsp;&nbsp;
                <span className="non-pharma-header">Non-Pharma&nbsp;&nbsp;
                  <SVGIcon
                    icon={"add-circle-bowl-cancel"}
                    className="non-pharma-icon"
                  />
                </span>
              </Col>
            </Row>
            <div className="bias-text-border"></div>
          </div>
        </div>

        {/* collapse & pannel   */}
        <div className="collapse-data">
          {(biasInAction?.body.length === 0 && !preSearch.length) ? <p className="text-center noDataFound">No data found</p> : ((biasInAction?.filteredData.length === 0 && preSearch.length) ? <p className="text-center noDataFound">No search result found</p> : "")}
          <Collapse
            bordered={false}
            className="div-collapse"
            activeKey={openPanels}
            onChange={setOpenPanels}
          >
            {biasInAction?.filteredData.map((dataSource, key) => {
              return (
                <Panel
                  showArrow={false}
                  header={panelHeader(dataSource, dataSource.bias_id)}
                  collapsible="header"
                  key={dataSource.bias_id}
                >
                  <Spin spinning={dataSource?.loading}>
                    <BiasInActionTable
                      tableData={dataSource?.biasTableData}
                      tableActionEvent={tableActionEvent}
                      searchText={preSearch}
                    />
                  </Spin>
                </Panel>
              );
            })}
          </Collapse>
        </div>

        {/* bias in action filter drawer  */}
        <Drawer
          title="Bias-In-Action Filters"
          placement={'right'}
          width={'28%'}
          onClose={closeBiasInActionFilterDrawer}
          open={visible}
          footer={
            <Space>
              <Button type="primary" className="me-2" onClick={applyFilter}> Apply Filters </Button>
              <Button className="me-2" onClick={resetFilter}>Reset Filters</Button>
              <Button onClick={closeBiasInActionFilterDrawer}>Cancel</Button>
            </Space>
          }
        >
          <div className="px-2 antFormBuilderBiases">
            <FormBuilder
              id="form-biases-filter"
              data={filterPayload}
              onAction={handleFilterFormAction}
              config={filterConf}
            />
          </div>
        </Drawer>
        {/* delete confirmation for deleting fixed table row  */}
        <Modal
          centered
          open={biasDeleteModal}
          className="delete-modal"
          width={'30%'}
          closable={false}
          footer={false}
          onCancel={() => setBiasDeleteModal(false)}
        >
          <div >
            <h6>Delete Bias-in-Action</h6>
          </div>
          <p className="text-muted small m-0 ">This Bias-in-Action is associated to more than one bias:</p>
          <ul> {selectedBias && selectedBias?.biasDetails && selectedBias?.biasDetails.map((item) => {
            return (<li className="text-muted small m-0">{item.name} </li>)
          })} </ul>
          <p className="text-muted small ">Do you want to delete this bias in action associated to all bias or just this {biasData?.bias_name} ?</p>

          <div className="deleteConfirmationButtons">
            <Button type="primary" className="me-2" onClick={() => deleteBiasImage(false)} >  Delete for this Bias only </Button>
            <Button className="me-2" onClick={() => deleteBiasImage(true)} > Delete for All </Button>
            <Button onClick={() => setBiasDeleteModal(false)}> Cancel </Button>
          </div>
        </Modal>

        {/**On bias-in-action image click */}
        <Modal
          title={`${selectedImgData?.bias_name} Bias-in-Action Full View`}
          className="editImageBIA"
          open={imgModalVisible}
          onCancel={onBiasViewClose}
          width={1000}
          centered
          footer={[
            <div className="edit-action">
              <Button
                key="delete"
                type="primary"
                onClick={() => { editBiasInActionFromViewState(selectedImgData) }}
              >
                Edit Bias-in-Action
              </Button>
              <Button
                key="cancel"
                onClick={onBiasViewClose}
              >
                Close
              </Button>
            </div>,
          ]}
        >
          <div className="text-center">
            <img className="image-layout" src={selectedImgData?.bia_image_path} />
          </div>
          <Row className="imageModal pt-2">

            <Col span={11} className="pt-2">
              <h6>How Image Demonstrates Bias</h6>
              <Tooltip placement="topLeft" arrowPointAtCenter={true} overlayClassName={'tooltip-bg tooltip-width'} title={`${selectedImgData?.img_demo_text?.length > 180 ? selectedImgData?.img_demo_text : ""}`}>
                <p className="tableHeaderElipses">
                  {" "}
                  {`${selectedImgData?.img_demo_text}`}
                </p>
              </Tooltip>
            </Col>
            <Col span={12} offset={1} className="pt-2">
              <h6>Bias-in-Action Key Message</h6>
              <Tooltip placement="topLeft" overlayClassName={'tooltip-bg tooltip-width'} title={`${selectedImgData?.key_message?.length > 180 ? selectedImgData?.key_message : ""}`}>
                <p className="tableHeaderElipses">
                  {" "}
                  {`${selectedImgData?.key_message}`}
                </p>
              </Tooltip>
            </Col>
          </Row>

        </Modal>

        <Modal
          centered
          closable={false}
          open={singleDeleteModal}
          footer={null}
          width={400}
          onCancel={() => setSingleDeleteModal(false)}
        >
          <h6  className="confirmation-header-text">Delete Bias-in-Action</h6>
          <p className="text-muted pt-1">
            Are you sure you want to delete this Bias-in-Action?
          </p>
          <Button type="primary" onClick={() => deleteBiasImage(false)} className="me-2 mt-3" >
            Delete Bias-in-Action
          </Button>
          <Button onClick={() => { setSingleDeleteModal(false) }} className='mt-3'>
            {" "} Cancel{" "}
          </Button>
        </Modal>
      </div>
    </>
  );
}


