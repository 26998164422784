import React, { useCallback, useEffect, useState } from 'react';
import { Form, Col, Row, ButtonGroup, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import './FormBuilder.scss';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import classNames from 'classnames';
import Select, { components } from 'react-select';
import { isEmpty } from '../../Utils/CommonUtil';
import { alertService } from '../../Utils/ToasterUtil';
import SVGIcon from '../SVGIcon/SVGIcon';
import ReactTooltip from 'react-tooltip';


export default function FormBuilder({
  data: initialData,
  config,
  onAction,
  formType: type,
  mode,
  id: propsId,
  className: propsClassName,
  dynamicUpdate,
  emptyPlaceholder
}) {
  const [data, setData] = useState();
  const [validated, setValidated] = useState(false);
  const [touched, setTouched] = useState(false);
  const [validationErrors, setValidationErrors] = useState();
  const formType = type ? type.toString().toLowerCase() : 'row';
  const defaultErrorMessages = {
    mobile: "Invalid Phone Number",
    email: "Invalid Email Format",
    postalCode: "Invalid Postal Code"
  }
  const initializeData = useCallback(() => {
    /** This is breaking dynamic form update */
    const tempData = touched && !dynamicUpdate ? { ...data } : { ...initialData };
    if (config && tempData) {
      config.map(row =>
        row.map(el => {
          if (el?.key === "dob") {
            if (initialData?.dob) {
              tempData.dob = ((moment(initialData?.dob).format('DD MMM YYYY')))
            }
          }
          else if (el?.key === "date_of_joining") {
            if (initialData?.date_of_joining) {
              tempData.date_of_joining = (moment(initialData?.date_of_joining).format('DD MMM YYYY'))
            }
          }
          else if (tempData?.[el.key] === undefined) {
            tempData[el.key] = '';
          }
          return tempData[el.key];
        })
      );
    }
    setTimeout(() => {
      setData(tempData);
    }, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, initialData]);

  useEffect(() => {
    initializeData();
  }, [initializeData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    const form = event.currentTarget;

    /** Validation */
    const validation = {};
    config.forEach(row => row.forEach(formEl => {
      const key = formEl?.changeable ? formEl.start_key : (formEl.key || formEl.start_key);
      if (formEl.required && formEl.type !== 'label' && (isEmpty(data[key]) || (formEl.type === 'multi-select' && data[key].length === 0))) {
        validation[key] = 'required';
      } else if (!isEmpty(data?.[key])) {
        if (formEl.validation?.includes("mobile")) {
          // let regex = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
          let regex = new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im);
          if (!regex.test(data?.[key].match(regex))) {
            validation[formEl.key] = "mobile"
          }
        }
        if (formEl.validation?.includes("email")) {
          // let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
          let regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
          if (!regex.test(data?.[key].match(regex))) {
            validation[formEl.key] = "email"
          }
        }
        if (formEl.validation?.includes("postalCode")) {
          let regex = new RegExp("^[a-zA-Z0-9_.-]{6}$");
          if (!regex.test(data?.[key]?.match(regex))) {
            validation[formEl.key] = "postalCode"
          }
        }
      }
    }));
    if (Object.keys(validation).length > 0)
      setValidationErrors(validation);

    if (form.checkValidity()) {
      if (Object.keys(validation).length === 0) {
        const payload = { ...data };
        convertSelectValues(payload);
        if (typeof onAction === 'function')
          onAction({ type: 'onSubmit', payload })
      } else {
        alertService.warning('Please provide all the required data');
      }
    }
  };

  const handleReset = () => {
    setValidated(false);
    initializeData();
  };
  const handleChange = ({ target }, updateState = true) => {
    setTouched(true);
    const payload = {
      key: target.dataset?.key,
      value: target.type === 'checkbox' ? target.checked : target.type === 'file' ? target.files : target.value,
    };
    payload['data'] = { ...data, [payload.key]: payload.value };

    if (updateState) setData({ ...payload['data'] });
    if (typeof onAction === 'function') onAction({ type: 'onChange', payload });
    if (!isEmpty(payload.value)) {
      const tempValidation = { ...validationErrors };
      delete tempValidation[payload.key];
      setValidationErrors(tempValidation)
    }
  };
  const filterCalDate = (date, formEl) => {
    if (formEl.disableWeekends && typeof Array.isArray(formEl.disableWeekends)) {
      const day = moment(date).weekday();
      let show = !formEl.disableWeekends.includes(day);
      if (formEl.minDate) moment(date).isSameOrAfter(formEl.minDate)
      if (formEl.maxDate) moment(date).isSameOrBefore(formEl.maxDate)
      return show;
    } else {
      return true;
    }
  };

  const updateDateRange = (dateRange, formEl) => {
    setData({ ...data, [formEl.start_key]: dateRange[0], [formEl.end_key]: dateRange[1] });
    const key = dateRange[1] ? formEl.end_key : formEl.start_key;
    const value = dateRange[1] || dateRange[0];
    handleChange({ target: { value, dataset: { key } } }, false);
  };

  const getSelectOptions = ({ options, selectAll }) => {
    let finalOptions = [];
    if (Array.isArray(options)) {
      if (options.length > 0) {
        if (['string', 'number'].includes(typeof options[0])) {
          finalOptions = options.map(el => ({ label: el, value: el }));
        } else if (typeof options[0] === 'object') {
          finalOptions = [...options];
        }
      }
    }
    if (selectAll) finalOptions = [{ label: "All", value: "All" }, ...finalOptions];
    return finalOptions;
  }

  const handleChangeSelect = (selectedOption, event, formEl) => {
    const actionEvent = {
      target: {
        dataset: { key: event.name },
        type: 'select',
      }
    };
    if (!Array.isArray(selectedOption)) { // single-select
      actionEvent.target.value = selectedOption?.value;
    } else { // multi-select
      if (event.action === "select-option" && (
        (Array.isArray(data?.[event.name]) && typeof data?.[event.name][0] === 'object' && data?.[event.name][0]?.value?.toLowerCase() === 'all') ||
        (Array.isArray(data?.[event.name]) && typeof data?.[event.name][0] === 'string' && data?.[event.name][0]?.toLowerCase() === 'all') ||
        (!Array.isArray(data?.[event.name]) && data?.[event.name]?.value?.toString()?.toLowerCase() === 'all') ||
        (typeof data?.[event.name] === 'string' && data?.[event.name]?.toString()?.toLowerCase() === 'all') ||
        event.option.value?.toString()?.toLowerCase() === "all"
      )) {
        /** if the old data is all, set */
        actionEvent.target.value = [event.option];
        // } else if (event.action === "deselect-option" && event.option.value?.toLowerCase() === "all") {
      } else if (event.action === "deselect-option") {
        actionEvent.target.value = selectedOption.filter(o => o.value?.toLowerCase() !== "all");
      } else {
        actionEvent.target.value = selectedOption;
      }
    }
    convertSelectValues(actionEvent.target, ['value']);
    handleChange(actionEvent);
  }

  /** Convert select/multi-select dropdown value from object to string  */
  const convertSelectValues = (payload, keys) => {
    /** Convert select/multi-select dropdown value from object to string  */
    let dropdownKeys = keys || [];
    if (!keys) {
      config.forEach(list =>
        list.forEach(el => {
          if (['select', 'multi-select'].includes(el.type)) {
            dropdownKeys.push(el.key);
          }
        })
      );
    }
    dropdownKeys.forEach(key => {
      let value = payload[key];
      if (Array.isArray(payload[key])) { // multi-select
        value = payload[key].map(obj => obj.value);
      } else if (typeof payload[key] === 'object') {
        value = payload[key]?.value;
      }
      payload[key] = value;
    })
  }

  const getDefaultOption = (value, formEl) => {
    let defaultValue;
    const tempOptions = getSelectOptions(formEl);
    if (tempOptions && Array.isArray(tempOptions) && value !== null && value !== undefined) {
      if (typeof value === 'object') { // multi-select will have array
        const temp = tempOptions.filter(el => {
          if (value.length > 0) {
            if (typeof value[0] === 'object') {
              // eslint-disable-next-line eqeqeq
              const temp = value.filter(el2 => el2.value == el.value);
              return temp.length > 0;
            } else {
              return value.includes(el.value)
            }
          } else return false;
        });
        defaultValue = temp;
      } else {
        // eslint-disable-next-line eqeqeq
        const temp = tempOptions.filter(el => el.value == value);
        if (temp.length > 0) defaultValue = temp[0];
      }
    }
    return defaultValue;
  }

  const formatValue = (config, value) => {
    let result;
    if (config?.type === 'date-picker') {
      if (moment(value) !== 'Invalid Date') {
        result = moment(value).format(config?.dateFormat || 'DD-MM-YYYY');
      } else {
        result = new Date(value).toDateString()
      }
    } else if (Array.isArray(config?.options)) { // select
      result = config?.options?.filter(el => el.value === value)?.[0]?.label;
    }
    return result || value;
  }

  const createFormGroup = form => {
    return (
      form.filter(el => mode !== 'view' || el.type !== 'button').map((formEl, i) => {
        let grid = {};
        if (formType !== 'column') {
          if (formEl['grid-column']) {
            if (['string', 'number'].includes(typeof formEl['grid-column'])) {
              grid = {
                lg: formEl['grid-column'],
                md: formEl['grid-column'],
                sm: formEl['grid-column'],
                xs: 12
              };
            } else {
              grid = { ...formEl['grid-column'], xs: formEl['grid-column']?.xs || 12 }
            }
          } else {
            grid = {
              lg: 3,
              md: 4,
              sm: 6,
              xs: 12
            }
          }
        }
        /**
         * styling react select dropdown size 
         */
        const resctSelectCustomStyle = {
          option: (provided) => ({
            ...provided,
            fontSize: 13
          }),
        }
        return (
          !formEl.isHide && <Form.Group key={i} as={Col} className={classNames("form-group", `form-group-${formEl?.type}`, `form-group-${formEl?.key}`, { 'invalid': validationErrors?.[formEl.key] })} {...grid} >
            <Form.Label>{formEl.label || ' '} {mode !== 'view' && formEl.required && <span className="text-red">*</span>}
              {mode !== 'view' && formEl.tooltip &&
                <SVGIcon icon={'info-fill'} className="m-0 ps-1 fontCircleInfo" data-tip data-for={formEl.key} />
              }{formEl.optional && <span >(optional)</span>}
            </Form.Label>
            {formEl.tip &&
              <ReactTooltip id={formEl.key} place="top" className="infoToolTip"  >
                {formEl.tip || ' '}
              </ReactTooltip>
            }
            {(mode === 'view' || formEl.type === 'label') &&
              <div className="text-only">
                {formEl?.link && data?.[formEl.key]
                  ? <a href={`${formEl?.link === 'phone' ? 'tel:' : formEl?.link === 'mail' ? 'mailto:' : ''}${data?.[formEl.key]}`}
                    title={`${formEl?.link === 'phone' ? 'Call to' : formEl?.link === 'mail' ? 'Send mail to' : ''} ${data?.[formEl.key]}`}>
                    {formatValue(formEl, data?.[formEl.key]) || emptyPlaceholder}
                  </a>
                  : formatValue(formEl, data?.[formEl.key]) || emptyPlaceholder
                }
              </div>
            }

            {formEl.type === 'text-box' && mode !== 'view' &&
              <Form.Control type="text" readOnly={formEl.readOnly} disabled={formEl.disabled} data-key={formEl.key} value={data?.[formEl.key]}
                required={formEl.required} onChange={handleChange} placeholder={formEl.placeholder} />}

            {formEl.type === 'password' && mode !== 'view' &&
              <Form.Control type="password" readOnly={formEl.readOnly} disabled={formEl.disabled} data-key={formEl.key} value={data?.[formEl.key]}
                required={formEl.required} onChange={handleChange} placeholder={formEl.placeholder} />}

            {formEl.type === 'text-area' && mode !== 'view' &&
              <Form.Control as="textarea" readOnly={formEl.readOnly} disabled={formEl.disabled}
                data-key={formEl.key} value={data?.[formEl.key]} placeholder={formEl.placeholder}
                required={formEl.required} onChange={handleChange} />}
            {formEl.type === 'number' && mode !== 'view' &&
              <Form.Control type="number" readOnly={formEl.readOnly} disabled={formEl.disabled}
                data-key={formEl.key} value={data?.[formEl.key]} placeholder={formEl.placeholder}
                required={formEl.required} onChange={handleChange} />}
            {(formEl.type === 'select' || formEl.type === 'multi-select') && mode !== 'view' &&
              <Select className={classNames('react-select', { 'invalid': validationErrors?.[formEl.key] })}
                data-key={formEl.key} name={formEl.key} isDisabled={formEl.disabled}
                required={formEl.required} placeholder={formEl.placeholder}
                onChange={(value, event) => handleChangeSelect(value, event, formEl)}
                value={getDefaultOption(data?.[formEl.key], formEl)}
                isMulti={formEl.type === 'multi-select'}
                hideSelectedOptions={formEl.hideSelectedOption}
                options={getSelectOptions(formEl)}
                menuPosition="fixed"
                components={formEl?.hideAllSelected ? { MultiValue } : {}}
                styles={resctSelectCustomStyle}
                closeMenuOnSelect={formEl.closeMenueOnmultiselect}
              />}
            {formEl.type === 'checkbox' && mode !== 'view' &&
              <Form.Check
                // type="switch"
                required={formEl.required}
                data-key={formEl.key}
                name={formEl.key}
                checked={data?.[formEl.key]}
                onChange={handleChange}
              />}
            {formEl.type === 'button-group' && mode !== 'view' &&
              <ButtonGroup aria-label="timeFormat" size="sm">
                {formEl.options && formEl.options.map((el, bi) => {
                  if (typeof el === 'string') {
                    return (<Button key={bi} variant={data?.[formEl.key] === el ? 'primary' : 'secondary'} data-key={formEl.key} name={formEl.key}
                      onClick={handleChange} value={el} >{el}</Button>);
                  } else if (typeof el === 'object' && el.hasOwnProperty('value') && el.hasOwnProperty('label')) {
                    return (<Button key={bi} variant={data?.[formEl.key] === el.value ? 'primary' : 'secondary'} data-key={formEl.key} name={formEl.key}
                      onClick={handleChange} value={el.value} >{el.label}</Button>);
                  } else return '';
                }
                )}
              </ButtonGroup>}

            {formEl.type === 'date-picker' && mode !== 'view' &&
              <DatePicker wrapperClassName={classNames('date-picker', { 'invalid': validationErrors?.[formEl.key] })} portalId="root"
                showMonthDropdown={formEl?.showMonth} showYearDropdown={formEl?.showYear}
                dropdownMode="scroll"
                scrollableYearDropdown={true}
                yearDropdownItemNumber={50}
                selected={data[formEl.key] ? new Date(data[formEl.key]) : null} dateFormat="dd/MM/yyyy" placeholderText={formEl.placeholder}
                maxDate={formEl.maxDate} minDate={formEl.minDate} filterDate={date => filterCalDate(date, formEl)}
                onChange={(date) => handleChange({ target: { value: date, dataset: { key: formEl.key } } })}
              />
            }
            {formEl.type === 'date-time-picker' && mode !== 'view' &&
              <DatePicker wrapperClassName={classNames('date-picker', { 'invalid': validationErrors?.[formEl.key] })} portalId="root"
                showMonthDropdown={formEl?.showMonth} showYearDropdown={formEl?.showYear}
                dropdownMode="select"
                selected={data[formEl.key] ? new Date(data[formEl.key]) : null} placeholderText={formEl.placeholder}
                maxDate={formEl.maxDate} minDate={formEl.minDate} filterDate={date => filterCalDate(date, formEl)}
                timeIntervals={10}
                required={formEl.required}
                showTimeSelect
                onChange={(date) => handleChange({ target: { value: date, dataset: { key: formEl.key } } })}
                dateFormat="dd/MM/yyyy h:mm aa"
              />
            }
            {formEl.type === 'date-range-picker' && mode !== 'view' &&
              <DatePicker className={classNames('date-picker', { 'invalid': validationErrors?.[formEl.key | formEl.start_key] })}
                startDate={data[formEl.start_key] ? new Date(data[formEl.start_key]) : null}
                endDate={data[formEl.end_key] ? new Date(data[formEl.end_key]) : null}
                dateFormat="dd/MM/yyyy" placeholderText={formEl.placeholder}
                maxDate={formEl.maxDate} minDate={formEl.minDate} selectsRange
                filterDate={date => filterCalDate(date, formEl)}
                onChange={(date) => updateDateRange(date, formEl)}
              />
            }

            {formEl.type === 'week-picker' && mode !== 'view' &&
              <DatePicker popperClassName="week-picker" selected={data[formEl.key] ? new Date(data[formEl.key]) : null} dateFormat="dd/MM/yyyy"
                filterDate={date => filterCalDate(date, formEl)}
                dayClassName={(date) => moment(data[formEl.key]).startOf('week').diff(date) <= 0 && moment(data[formEl.key]).endOf('week').diff(date) > 0 ? 'react-datepicker__day--selected' : ''}
                onChange={(date) => handleChange({ target: { value: new Date(moment(date).startOf('week').add(1, 'd')), dataset: { key: formEl.key } } })}
              />
            }

            {formEl.type === 'time-picker' && mode !== 'view' &&
              <DatePicker wrapperClassName={classNames('time-picker', { 'invalid': validationErrors?.[formEl.key] })}
                selected={data[formEl.key] ? new Date(data[formEl.key]) : null}
                timeFormat={formEl.timeFormat || 'h:mm aa'}
                showTimeSelectOnly
                showTimeInput inline
                // showTimeSelect timeIntervals={15}
                onChange={(date) => handleChange({ target: { value: date, dataset: { key: formEl.key } } })}
              />
            }

            {formEl.type === 'button' && mode !== 'view' &&
              <Button className={formEl?.className} variant={formEl?.variant} data-key={formEl.key}
                onClick={(e) => handleChange({ target: { dataset: { key: formEl.key } } })}
                type={formEl?.isSubmit ? 'submit' : 'button'} title={formEl?.title} disabled={formEl?.disabled}>
                {formEl?.icon && formEl?.iconPosition !== 'right' && <SVGIcon icon={formEl?.icon} />}
                {formEl?.text}
                {formEl?.icon && formEl?.iconPosition === 'right' && <SVGIcon icon={formEl?.icon} />}
              </Button>
            }
            {formEl.type === 'slider' && mode !== 'view' &&
              <div>
                <input type="range" min={formEl?.min || 0} max={formEl?.max || 5}
                  style={{ 'width': formEl?.width || '325px' }}
                  value={data[formEl.key] || '0'}
                  onChange={handleChange}
                  required={formEl.required}
                  step={formEl?.step || 1}
                  data-key={formEl.key} />
                <p style={{ textAlign: 'left' }}>{data[formEl.key] || '0'}/{formEl?.max || 5}</p>
              </div>
            }
            {formEl.type === 'upload' && mode !== 'view' &&

              <div>
                <Form.Group controlId="formFileMultiple">
                  <Form.Control type="file" onChange={handleChange} data-key={formEl.key} multiple >
                  </Form.Control>
                </Form.Group>
              </div>
            }
            <Form.Control.Feedback type="invalid">
              {validationErrors
                ? formEl?.errorMessage?.[validationErrors[formEl.key]] || defaultErrorMessages?.[validationErrors[formEl.key]] || 'Required'
                : ""}
            </Form.Control.Feedback>
          </Form.Group>
        )
      })
    )
  };
  return (
    <Form className={classNames("form-builder-root", propsClassName, { 'validated': validated })}
      noValidate
      // validated={validated}
      onSubmit={handleSubmit} onReset={handleReset}
      id={propsId || 'form-builder'}
    >
      {data && config && config.map((list, i) =>
        formType === 'column'
          ? <Col key={i} lg={4} md={6} sm={12} className="form-col">
            {createFormGroup(list)}
          </Col>
          : <Row key={i} className="form-row">
            {createFormGroup(list)}
          </Row>
      )}
      <button type="submit" hidden>Submit</button>
    </Form>
  );
}

const MoreSelectedBadge = ({ items }) => {
  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} selected`;

  return (
    <div className="select-badge" title={title}>
      {label}
    </div>
  );
};

const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 3;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};