import React, { useState, useEffect } from "react";
import "./BiasInActionRepository.scss";
import { DebounceInput } from 'react-debounce-input';
import { Row, Col, Select, Collapse, Button, Tooltip, Image, Spin } from 'antd';
import SVGIcon from "../../../../../Shared/Components/SVGIcon/SVGIcon";
import defaultImage from "../../../../../Assets/images/default.jpg";
import { generateUniqueId } from '../../../../../Shared/Utils/CommonUtil';



export default function BiasInActionRepo({ biasInActionRepoAllData, selectEvent, ...props }) {

    const allAudienceOptions = [
        { label: "Physicians", value: "physicians" },
        { label: "Patients", value: "patients" },
        { label: "Consumer", value: "consumer" },
        { label: "All", value: "all" },
    ];
    const pharmaAudienceOptions = [
        { label: "Physicians", value: "physicians" },
        { label: "Patients", value: "patients" },
        { label: "All", value: "all" }
    ];
    const nonPharmaAudienceOptions = [
        { label: "Consumer", value: "consumer" }
    ];
    const pharmaOptions = [
        { label: "Pharma", value: true },
        { label: "Non-Pharma", value: false },
        { label: "All", value: "all" },
    ];
    const audienceMapping = {
        "physicians": "Physicians",
        "patients": "Patients",
        "consumer": "Consumer"
    };
    const [repositoryData, setRepositoryData] = useState({});
    const [selectedPharma, setSelectedPharma] = useState('all');
    const [selectedAudience, setSelectedAudience] = useState('all');
    const [audienceOptions, setAudienceOptions] = useState(allAudienceOptions);
    const [showLoader, setShowLoader] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const { Panel } = Collapse;

    useEffect(() => {
        setRepositoryData({
            'biasInActionRepositoryData': [...biasInActionRepoAllData],
            'backupRepositorydata': [...biasInActionRepoAllData]
        })
    }, [biasInActionRepoAllData])

    useEffect(() => {
        if (repositoryData?.backupRepositorydata && repositoryData?.backupRepositorydata.length) {
            let filtereData = [];
            if (selectedAudience === 'all' && selectedPharma === 'all') {
                filtereData = [...repositoryData?.backupRepositorydata];
                setRepositoryData({ ...repositoryData, 'biasInActionRepositoryData': filtereData });
            } else if (selectedAudience !== 'all' && selectedPharma === 'all') {
                filtereData = repositoryData?.backupRepositorydata.filter((imgData) => imgData?.audience === selectedAudience);
                setRepositoryData({ ...repositoryData, 'biasInActionRepositoryData': filtereData })
            } else if (selectedPharma !== 'all' && selectedAudience === 'all') {
                let value = selectedPharma;
                filtereData = repositoryData?.backupRepositorydata.filter((imgData) => imgData?.pharmaNonpharma === value);
                setRepositoryData({ ...repositoryData, 'biasInActionRepositoryData': filtereData })
            } else if (selectedPharma !== 'all' && selectedAudience !== 'all') {
                let value = selectedPharma;
                filtereData = repositoryData?.backupRepositorydata.filter((imgData) => (imgData?.pharmaNonpharma === value && imgData?.audience === selectedAudience));
                setRepositoryData({ ...repositoryData, 'biasInActionRepositoryData': filtereData })
            }
            searchData(filtereData, searchValue);
        }
    }, [selectedAudience, selectedPharma, searchValue])

    /**
     * On image select
     * @param {*} imgData 
     */
    const selectImage = (imgData) => {
        try {
            let data = {
                "image_id": imgData?.image_id,
                "bia_image_path": imgData?.bia_image_path,
                "pharmaNonpharma": imgData?.pharmaNonpharma,
                "audience": imgData?.audience,
                "example_type": "activating",
                "demonstrate_bias": "",
                "key_message": "",
                "additional_info": "",
                'container_id': generateUniqueId()
            }
            if (typeof selectEvent === "function") selectEvent({ 'data': data });
        } catch (error) {
            console.log("error, error");
        }
    }

    /**
     * To set filter input
     * @param {*} value - value
     * @param {*} dataKey - key
     */
    const filterData = (value, dataKey) => {
        try {
            if (dataKey === 'audience') {
                setSelectedAudience(value);
            } else {
                setSelectedPharma(value);
                if (value === 'all') {
                    setAudienceOptions([...allAudienceOptions]);
                    setSelectedAudience('all');
                } else {
                    if (value) {
                        setAudienceOptions([...pharmaAudienceOptions]);
                        setSelectedAudience('all');
                    } else {
                        setAudienceOptions([...nonPharmaAudienceOptions]);
                        setSelectedAudience('consumer');
                    }
                }
            }
        } catch (error) {
            console.log("error, error");
        }
    }

    /**
    * To search value over data
    * @param {*} array -  data
    * @param {*} keyword -  search value
    */
    const searchData = (array, keyword) => {
        try {
            setShowLoader(true);
            if (keyword.trim()) {
                const searchTerm = JSON.parse(JSON.stringify(keyword.toString().toLowerCase()));
                const searchData = array.filter((value) => {
                    let filtered = false;
                    value?.formData.filter((imgData) => {
                        if (imgData?.bias_name.toLowerCase()?.includes(searchTerm) || imgData?.image_demo.toLowerCase()?.includes(searchTerm) || imgData?.key_message.toLowerCase()?.includes(searchTerm)) {
                            filtered = true;
                            return;
                        }
                    });
                    if (filtered) {
                        value['children'] = [...value?.formData];
                        return value;
                    }
                });
                setRepositoryData({ ...repositoryData, 'biasInActionRepositoryData': searchData });
            } else {
                setRepositoryData({ ...repositoryData, 'biasInActionRepositoryData': array })
            }
        } catch (error) {
            console.log("error", error);
        }
        setShowLoader(false);
    };

    return (<>
        <div className="top-section">
            <Row>
                <Col span={10}>
                    <div className="searchMainDiv mb-3 d-flex">
                        <div className="searchFilterDiv input-group ps-0 ">
                            <DebounceInput
                                debounceTimeout={500}
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search"
                                onChange={(e) => {
                                    setSearchValue(e.target.value);
                                }}
                            />
                            <button
                                className="searchButton text-muted me-2"
                            >
                                <SVGIcon icon={'search-transparent'} />
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <label className="field-label">Pharma/Non-Pharma</label>
                    <div><Select style={{ width: '100%' }} value={selectedPharma} options={pharmaOptions} onChange={(value) => filterData(value, 'pharma')}></Select></div>
                </Col>
                <Col span={12} className="p-15">
                    <label className="field-label">
                        Audience
                    </label>
                    <div><Select style={{ width: '100%' }} value={selectedAudience} options={audienceOptions} onChange={(value) => filterData(value, 'audience')}></Select></div>
                </Col>
            </Row>
            <hr className="hor-line" />
        </div>
        <Spin spinning={showLoader}>
            <div className="p-3 bias-repo-data">
                {repositoryData?.biasInActionRepositoryData && repositoryData?.biasInActionRepositoryData.length !== 0 && repositoryData?.biasInActionRepositoryData?.map(imgdata => {
                    return (<>
                        <div className="img-section p-3"  key={imgdata?.image_id}>
                            <Row>
                                <Col flex="100px">
                                    <Image
                                        src={imgdata?.bia_image_path}
                                        alt="image"
                                        className="img-size"
                                        preview={false}
                                        placeholder={
                                            <Image
                                                preview={false}
                                                src={defaultImage}
                                                className="img-size"
                                            />
                                        }
                                    />
                                </Col>
                                <Col flex="auto"><Row>
                                    <Col span={12}>
                                        <p><label className="field-label">Pharma/Non-Pharma  : &nbsp;</label>{imgdata?.pharmaNonpharma ? 'Pharma' : 'Non-Pharma'}</p>
                                        <p><label className="field-label">Audience  : &nbsp;</label>{audienceMapping[imgdata?.audience]}</p>
                                    </Col>
                                    <Col span={12} className="align-vertical-right">
                                        <Button type='primary' onClick={() => selectImage(imgdata)}>Add Bias-in-Action & Go Back</Button>
                                    </Col>
                                </Row></Col>
                            </Row>
                            <Collapse className="border-0">
                                {imgdata?.formData.map((bias) => (
                                    <Panel
                                        forceRender={true}
                                        className="ps-1 mb-2"
                                        header={bias?.bias_name}
                                        style={
                                            {
                                                border: "1px solid #d9d9d9",
                                                marginTop: '10px'
                                            }
                                        }
                                        key={bias?.bias_in_action_id}
                                    >
                                        <div>
                                            <label className="field-label">Example Type : &nbsp;</label> <span>{bias?.example_type === 'mitigating' ? 'Mitigating' : 'Activating'}</span>
                                            <div className="pt-2">
                                                <label className="field-label">How Image Demonstrates Bias</label>
                                                <Tooltip placement="topLeft" overlayClassName={'tooltip-bg tooltip-width'} title={`${bias?.image_demo?.length > 180 ? bias?.image_demo : ""}`}>
                                                    <p className="mb-2 description-ellipsis">{bias?.image_demo}</p>
                                                </Tooltip>
                                            </div>
                                            {bias?.key_message && <div className="pt-2">
                                                <label className="field-label">Bias-in-Action Key Message</label>
                                                <Tooltip placement="topLeft" overlayClassName={'tooltip-bg tooltip-width'} title={`${bias?.key_message?.length > 180 ? bias?.key_message : ""}`}>
                                                    <p className="mb-2 description-ellipsis">{bias?.key_message}</p>
                                                </Tooltip>
                                            </div>}
                                            {bias?.additional_info && <div className="pt-2">
                                                <label className="field-label">Bias-in-Action Additional Info</label>
                                                <Tooltip placement="topLeft" overlayClassName={'tooltip-bg tooltip-width'} title={`${bias?.additional_info?.length > 180 ? bias?.additional_info : ""}`}>
                                                    <p className="mb-2 description-ellipsis" >{bias?.additional_info}</p>
                                                </Tooltip>
                                            </div>}
                                        </div>
                                    </Panel>
                                ))}
                            </Collapse>
                        </div>
                    </>)
                })}
            </div>
        </Spin>
    </>)
}