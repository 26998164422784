import { VARIABLES } from "../../Environments/constants";
import { ServiceUtils } from "./ServiceUtils";
import { sessionService } from "./SessionService";
import { alertService } from "./ToasterUtil";
import { IDM_CONF } from '../../Environments/config';
import { DEPLOYED_ENVIRONMENT } from '../../Environments/environments';

function titleCase(text) {
  const input = text || "";
  const temp = input.replaceAll("-", " ");
  const ar = temp.split(" ").map((el) => {
    let t = "";
    t += el.charAt(0).toUpperCase();
    t += el.substr(1);
    return t;
  });
  return ar.join(" ");
}

function htmlToText(content) {
  let tag = document.createElement("div");
  tag.id = "text2html";
  tag.innerHTML = content;
  return tag.innerText;
}

function isEmpty(value) {
  return (
    value === null ||
    value === undefined ||
    value === "" ||
    (typeof value === "string" && value.trim() === "")
  );
}

function fetchList(key, callback) {
  ServiceUtils.getRequest(`list-${key}`).then((_response) => {
    if (_response.status === 200) {
      if (_response.data.status === "success") {
        if (typeof callback === "function") callback(_response.data);
      } else {
        alertService.error(
          _response.data.message || `Error while fetching ${key} list`
        );
      }
    } else {
      alertService.error(
        _response?.data?.message || _response.message || "Internal Server Error"
      );
    }
  });
}

function updateFormConfig(configObj, key, meta) {
  return configObj.map((row) =>
    row.map((formEl) => {
      if (formEl.key === key) return { ...formEl, ...meta };
      return formEl;
    })
  );
}

function getFormConfig(configObj, key) {
  let tempConfig;
  configObj.map((row) =>
    row.map(formEl => {
      if (formEl.key === key) tempConfig = { ...formEl };
      return formEl;
    })
  );
  return tempConfig;
}

const checkAccess = (access) => {
  // ['admin', 'lead']
  const role = sessionService.getSession()?.[VARIABLES.userRole]; // ['Admin', 'Lead']
  return (
    Array.isArray(access) &&
    Array.isArray(role) &&
    role?.filter((el) => access?.includes(el?.toLowerCase()))?.length > 0
  );
};

function deepClone(object) {
  return JSON.parse(JSON.stringify(object));
}

function searchTable(searchText, body, header) {
  let searchColumns = [];
  if (Array.isArray(header)) {
    searchColumns = header.map((el) => el?.key);
  }
  const result = body.filter(
    (row) =>
      searchColumns.filter((key) =>
        row?.[key]
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchText?.toString()?.toLowerCase())
      )?.length > 0
  );
  return result;
}
function defaultAntSort(a, b) {
  const tempA = a? a.toLowerCase() : a;
  const tempB = b? b.toLowerCase() : b; 
  if (tempA < tempB) return -1;
  if (tempB < tempA) return 1;
}

/**
   * To generate unique id
   * @returns - unique id
   */
function generateUniqueId() {
  try {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> c / 4)).toString(16))
    );
  } catch (error) {
    console.log("error", error);
  }
}

/**
 * To get inner content of html
 * @param {*} value - html content
 * @returns inner text
 */
function extractContent(value){
  var div = document.createElement('div')
  div.innerHTML=value;
  var text= div.textContent || div.innerText;            
  return text;
}

/**
 * To scroll to div
 * @param {*} sectionId - section id
 */
function scrollToDiv(sectionId){
  let newSection = document.getElementById(sectionId);
  if(newSection){
    newSection.scrollIntoView({behavior: "smooth", block: "center"});
  }
}

/**
 * in case of failure of IDm login or when need to redirect to login screen 
 */
function redirectToLogin(){
  const idmConfig = IDM_CONF[DEPLOYED_ENVIRONMENT];
  const url = idmConfig.app_login_url;
  localStorage.clear();
  setTimeout(() => {
    window.location.replace(url);
  }, 100);
}

const localStorageAsync = {
  set: function (key, value) {
      return Promise.resolve().then(function () {
          localStorage.setItem(key, value);
      });
  },
  get: function (key) {
      return Promise.resolve().then(function () {
          return localStorage.getItem(key);
      });
  }
};

/**
 * To return new line html data
 * @param {*} data - string
 * @returns new line html data
 */
function newLineHtmlContent(data){
  try {
    return data.split('\n').join('</br>');
  } catch (error) {
    console.log("error", error);
  }
}
export {
  titleCase,
  htmlToText,
  fetchList,
  isEmpty,
  updateFormConfig,
  getFormConfig,
  deepClone,
  checkAccess,
  searchTable,
  defaultAntSort,
  generateUniqueId,
  extractContent,
  scrollToDiv,
  redirectToLogin,
  newLineHtmlContent,
  localStorageAsync
};



