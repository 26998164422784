import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import './Transcripts.scss';
import workInProgress from '../../../../../Assets/images/work-in-progress.png';

export default function Transcripts() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {/* <div className='project-transcript-main'>
        <div className='p-2'>
          <Button variant="primary" onClick={handleShow}>
            Launch
          </Button>
        </div>

        <Drawer title="Respondents Name - Physicians"
        placement={'right'}
        width={830}
        closable={'right'}
        onClose={handleClose} visible={show}
      >
      </Drawer>
      </div> */}
       <div className="form-container">
        <img className='mt-2 workInProgressImage' src={workInProgress} alt="" />
      </div>
    </>
  );
}
