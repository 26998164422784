import React from 'react';
import { Image } from 'react-bootstrap';
import Img from '../../Assets/images/page-not-found.svg';
import { ReactComponent as AngleRight } from '../../Assets/icons/chevron-right.svg';
import './PageNotFound.scss';
import { useHistory } from 'react-router-dom';


export default function PageNotFound() {
  const history = useHistory();

  const gotoHome = () => {
    history.push('/home')
  };

  return (
    <main className="page-not-found">
      <div className="empty-state-container">
        <div className="card">
          <div className="card-body">
            <div className="state-figure">
              <Image className="img-fluid" src={Img} alt="" style={{ maxWidth: 320 }} />
            </div>
            <h3 className="state-header"> Page not found! </h3>
            <p className="state-description lead"> The URL is pointing to something that doesn't exist. </p>
            <div className="state-action">
              <button className="btn btn-lg btn-light" onClick={gotoHome}>
                <AngleRight />&nbsp;Go Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}