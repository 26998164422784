import React from 'react';
import classNames from 'classnames';
import './SVGIcon.scss';
import { ReactComponent as IconChevronUp } from '../../../Assets/icons/chevron-up.svg';
import { ReactComponent as IconChevronDown } from '../../../Assets/icons/chevron-down.svg';
import { ReactComponent as IconChevronRight } from '../../../Assets/icons/chevron-right.svg';
import { ReactComponent as IconChevronLeft } from '../../../Assets/icons/chevron-left.svg';
import { ReactComponent as IconBell } from '../../../Assets/icons/bell.svg';
import { ReactComponent as IconSearch } from '../../../Assets/icons/search.svg';
import { ReactComponent as IconSettings } from '../../../Assets/icons/settings.svg';
import { ReactComponent as IconInfo } from '../../../Assets/icons/information.svg';
import { ReactComponent as IconBuilding } from '../../../Assets/icons/building.svg';
import { ReactComponent as IconUserArrowRight } from '../../../Assets/icons/user-arrow-right.svg';
import { ReactComponent as IconStackH } from '../../../Assets/icons/stack-h.svg';
import { ReactComponent as IconCalendarTimer } from '../../../Assets/icons/calendar-timer.svg';
import { ReactComponent as IconCalendar } from '../../../Assets/icons/calendar.svg';
import { ReactComponent as IconCmd } from '../../../Assets/icons/cmd.svg';
import { ReactComponent as IconUserRound } from '../../../Assets/icons/user-round.svg';
import { ReactComponent as IconFile } from '../../../Assets/icons/file-text.svg';
import { ReactComponent as IconPersonDashed } from '../../../Assets/icons/person-dashed-box.svg';
import { ReactComponent as IconPersonLinesRight } from '../../../Assets/icons/person-lines-right.svg';
import { ReactComponent as IconBriefcase } from '../../../Assets/icons/briefcase.svg';
import { ReactComponent as IconCartPlus } from '../../../Assets/icons/cart-plus.svg';
import { ReactComponent as IconCashCoin } from '../../../Assets/icons/cash-coin.svg';
import { ReactComponent as IconPlusSqDotted } from '../../../Assets/icons/plus-square-dotted.svg';
import { ReactComponent as IconListCheck } from '../../../Assets/icons/list-check.svg';
import { ReactComponent as IconColumnsGap } from '../../../Assets/icons/columns-gap.svg';
// import { ReactComponent as IconCheckCircle } from '../../../Assets/icons/check-circle.svg';
import { ReactComponent as IconCheckCircleApply } from '../../../Assets/icons/check-circle-apply.svg';
import { ReactComponent as IconListDotBorder } from '../../../Assets/icons/list-dot-border.svg';
import { ReactComponent as IconListDot } from '../../../Assets/icons/list-dot.svg';
import { ReactComponent as IconCheckBig } from '../../../Assets/icons/check-big.svg';
import { ReactComponent as IconPencil } from '../../../Assets/icons/pencil.svg';
import { ReactComponent as IconTrash } from '../../../Assets/icons/trash.svg';
// import { ReactComponent as IconCloseCircle } from '../../../Assets/icons/close-circle.svg';
import { ReactComponent as IconCalendarWeek } from '../../../Assets/icons/calendar-week.svg';
import { ReactComponent as IconCheck } from '../../../Assets/icons/check.svg';
import { ReactComponent as IconPencilFill } from '../../../Assets/icons/pencil-fill.svg';
import { ReactComponent as IconSendMail } from '../../../Assets/icons/send-mail.svg';
import { ReactComponent as IconTravel } from '../../../Assets/icons/travel.svg';
import { ReactComponent as IconPhone } from '../../../Assets/icons/phone.svg';
import { ReactComponent as IconPeople } from '../../../Assets/icons/people.svg';
// import { ReactComponent as IconSortAsc } from '../../../Assets/icons/sort-asc.svg';
// import { ReactComponent as IconSortDesc } from '../../../Assets/icons/sort-desc.svg';
// import { ReactComponent as IconSortDefault } from '../../../Assets/icons/sort-default.svg';
import { ReactComponent as IconEyeView } from '../../../Assets/icons/eye-view.svg';
import { ReactComponent as IconGeoLocation } from '../../../Assets/icons/geo-location.svg';
import { ReactComponent as IconPersonDir } from '../../../Assets/icons/person-dir.svg';
import { ReactComponent as IconDiagram } from '../../../Assets/icons/diagram.svg';
import { ReactComponent as IconCloudMoon } from '../../../Assets/icons/cloud-moon.svg';
import { ReactComponent as IconBirthday } from '../../../Assets/icons/birthday-cake.svg';
import { ReactComponent as IconAwardCap } from '../../../Assets/icons/award-cap.svg';
import { ReactComponent as IconClockTimer } from '../../../Assets/icons/clock-history.svg';
import { ReactComponent as IconList } from '../../../Assets/icons/list.svg';
import { ReactComponent as IconLogout } from '../../../Assets/icons/logout.svg';
import { ReactComponent as IconGrid } from '../../../Assets/icons/grid_2x2.svg';
import { ReactComponent as IconDownload } from '../../../Assets/icons/download.svg';
import { ReactComponent as IconCircleCheck } from '../../../Assets/icons/check-circle.svg';
import { ReactComponent as IconCopyClipboard } from '../../../Assets/icons/copy-icon.svg';
import { ReactComponent as IconReload } from '../../../Assets/icons/reload-icon.svg';
import { ReactComponent as IconTable } from '../../../Assets/icons/table-icon.svg';
import { ReactComponent as IconPeopleFill } from '../../../Assets/icons/people-fill.svg';
import { ReactComponent as IconCakeColor } from '../../../Assets/icons/birthday-cake-color.svg';
import { ReactComponent as IconCalender3 } from '../../../Assets/icons/calender3.svg';
import { ReactComponent as IconBorderStyle } from '../../../Assets/icons/border-style.svg';
import { ReactComponent as IconDelete } from '../../../Assets/icons/delete-close.svg';
import { ReactComponent as IconApproval } from '../../../Assets/icons/approval.svg';
import { ReactComponent as Icon3DotVertical } from '../../../Assets/icons/vertical-3-dot.svg';
import { ReactComponent as IconExpiring } from '../../../Assets/icons/expiring-project.svg';
import { ReactComponent as IconChart } from '../../../Assets/icons/chart.svg';
import { ReactComponent as IconArrowup } from '../../../Assets/icons/open-in-new-tap.svg';
import { ReactComponent as RightArrow } from '../../../Assets/icons/right_arrow.svg';
import { ReactComponent as IconChartArrowup } from '../../../Assets/icons/arrow-up.svg';
import { ReactComponent as IconFullView } from '../../../Assets/icons/full-view.svg';
import { ReactComponent as IconFullViewExit } from '../../../Assets/icons/full-view-exit.svg';
import { ReactComponent as WorkFromHome } from '../../../Assets/icons/WorkFromHome.svg';
import { ReactComponent as Map } from '../../../Assets/icons/map.svg';
import { ReactComponent as CrossIcon } from '../../../Assets/icons/cross-icon.svg';

/**
 * updated sidnav ux icons
 */
import { ReactComponent as BraincaseIcon } from '../../../Assets/icons/braincase_icon.svg';
import { ReactComponent as BiasesIcon } from '../../../Assets/icons/sidebar/biases.svg';
import { ReactComponent as Project } from '../../../Assets/icons/sidebar/project.svg';
import { ReactComponent as IconAppNav } from '../../../Assets/icons/sidebar/zs-icon-app-nav.svg';
import { ReactComponent as IconHelpFill } from '../../../Assets/icons/sidebar/zs-icon-help-fill.svg';
import { ReactComponent as IconUserFill } from '../../../Assets/icons/sidebar/zs-icon-user-fill.svg';
/**
 * comon svg icons 
 */
import { ReactComponent as AddCircleFill } from '../../../Assets/icons/common/zs-icon-add-circle-fill.svg';
import { ReactComponent as LogoTransparent } from '../../../Assets/icons/common/zs-logo-transparent.svg';
import { ReactComponent as AlignDisable } from '../../../Assets/icons/common/zs-icon-align-disable.svg';
import { ReactComponent as Align } from '../../../Assets/icons/common/zs-icon-align.svg';
import { ReactComponent as ArchiveFill } from '../../../Assets/icons/common/zs-icon-archive-fill.svg';
import { ReactComponent as ChatFill } from '../../../Assets/icons/common/zs-icon-chat-fill.svg';
import { ReactComponent as CheckCircle } from '../../../Assets/icons/common/zs-icon-check-circle.svg';
import { ReactComponent as CloseCircle } from '../../../Assets/icons/common/zs-icon-close-circle.svg';
import { ReactComponent as CommentHeader } from '../../../Assets/icons/common/zs-icon-comment-header.svg';
import { ReactComponent as DeleteFill } from '../../../Assets/icons/common/zs-icon-delete-fill.svg';
import { ReactComponent as Drag } from '../../../Assets/icons/common/zs-icon-drag.svg';
import { ReactComponent as EditFill } from '../../../Assets/icons/common/zs-icon-edit-fill.svg';
import { ReactComponent as Filter } from '../../../Assets/icons/common/zs-icon-filter.svg';
import { ReactComponent as FilterTransparent } from '../../../Assets/icons/common/zs-icon-filter-transparent.svg';
import { ReactComponent as FrameExpand } from '../../../Assets/icons/common/zs-icon-frame-expand.svg';
import { ReactComponent as InfoDark } from '../../../Assets/icons/common/zs-icon-info-dark.svg';
import { ReactComponent as Info } from '../../../Assets/icons/common/zs-icon-info.svg';
import { ReactComponent as AddCircleTransparent } from '../../../Assets/icons/common/zs-add-circle-transparent.svg';
import { ReactComponent as AddCircleWhite } from '../../../Assets/icons/common/zs-add-circle-white.svg';
import { ReactComponent as ListCheckFill } from '../../../Assets/icons/common/zs-list-check-fill.svg';
import { ReactComponent as ListTree } from '../../../Assets/icons/common/zs-list-tree.svg';
import { ReactComponent as LeftButtonFill } from '../../../Assets/icons/common/zs-left-button-fill.svg';
import { ReactComponent as RightButtonFill } from '../../../Assets/icons/common/zs-right-button-fill.svg';
import { ReactComponent as SearchFileFill } from '../../../Assets/icons/common/zs-search-file-fill.svg';
import { ReactComponent as SearchTransparent } from '../../../Assets/icons/common/zs-search-transparent.svg';
import { ReactComponent as SquareBracket } from '../../../Assets/icons/common/zs-square-bracket.svg';
import { ReactComponent as PeopleListTransparent } from '../../../Assets/icons/common/zs-people-list-transparent.svg';
import { ReactComponent as AlertsTransparent } from '../../../Assets/icons/common/zs-icon-notification-fill.svg';
import { ReactComponent as FolderTransparent } from '../../../Assets/icons/common/zs-icon-folder-closed-fill.svg';
import { ReactComponent as ImgTransparent } from '../../../Assets/icons/common/zs-icon-img.svg';
import { ReactComponent as AddCircleBowlCancel } from '../../../Assets/icons/common/zs-add-circle-bowl-cancel.svg';
import { ReactComponent as AddCircleBowlFill } from '../../../Assets/icons/common/zs-add-circle-bowl-fill.svg';
import { ReactComponent as ChatBlack } from '../../../Assets/icons/common/zs-icon-chat-black.svg';
import { ReactComponent as SortDownNew } from '../../../Assets/icons/common/zs-sort-down.svg';
import { ReactComponent as SortUpNew } from '../../../Assets/icons/common/zs-sort-up.svg';
import { ReactComponent as SortDefaultNew } from '../../../Assets/icons/common/zs-sort-default.svg';
import { ReactComponent as DownArrowTransparent } from '../../../Assets/icons/common/zs-down-arrow-transparent.svg';
import { ReactComponent as DownArrowFill } from '../../../Assets/icons/common/zs-down-arrow-fill.svg';
import { ReactComponent as RightArrowTransparent } from '../../../Assets/icons/common/zs-right-arrow-transparent.svg';
import { ReactComponent as RightArrowFill } from '../../../Assets/icons/common/zs-right-arrow-fill.svg';
import { ReactComponent as Up } from '../../../Assets/icons/common/zs-up.svg';
import { ReactComponent as UpFill } from '../../../Assets/icons/common/zs-up-fill.svg';
import { ReactComponent as Down } from '../../../Assets/icons/common/zs-down.svg';
import { ReactComponent as DownFill } from '../../../Assets/icons/common/zs-down-fill.svg';
import { ReactComponent as Restore } from '../../../Assets/icons/common/zs-restore-icon.svg';
import { ReactComponent as AlignRightTransparent } from '../../../Assets/icons/common/zs-align-left-transparent.svg';
import { ReactComponent as AlignLeftTransparent } from '../../../Assets/icons/common/zs-align-right-transparent.svg';
import { ReactComponent as AlignLeftFill } from '../../../Assets/icons/common/zs-align-left-fill.svg';
import { ReactComponent as AlignRightFill } from '../../../Assets/icons/common/zs-right-align-fill.svg';
import { ReactComponent as CommentRestore } from '../../../Assets/icons/common/zs-icon-arrow-revise.svg';
import { ReactComponent as ExportDoc } from '../../../Assets/icons/common/zs-export-doc.svg';
import { ReactComponent as TagBlack } from '../../../Assets/icons/common/zs-icon-tag-black.svg';
import { ReactComponent as HorizontalLine } from '../../../Assets/icons/horizontal-line.svg';
import { ReactComponent as SortDown } from '../../../Assets/icons/sort-down.svg';
import { ReactComponent as SortUp } from '../../../Assets/icons/sort-up.svg';
import { ReactComponent as SortDefault } from '../../../Assets/icons/sort_default.svg';
import { ReactComponent as Wrapper } from '../../../Assets/icons/common/zs-icon-wrapper.svg';
import { ReactComponent as DeleteDisabled} from '../../../Assets/icons/common/zs-delete-disable.svg';

export const iconMapping = {
  'chevron-up': <IconChevronUp />,
  'chevron-down': <IconChevronDown />,
  'chevron-right': <IconChevronRight />,
  'chevron-left': <IconChevronLeft />,
  'bell': <IconBell />,
  'search': <IconSearch />,
  'settings': <IconSettings />,
  'info': <IconInfo />,
  'building': <IconBuilding />,
  'user-arrow-right': <IconUserArrowRight />,
  'stack-h': <IconStackH />,
  'calendar': <IconCalendar />,
  'calendar-timer': <IconCalendarTimer />,
  'clock-history': <IconClockTimer />,
  'cmd': <IconCmd />,
  'user-round': <IconUserRound />,
  'file-text': <IconFile />,
  'person-dashed-box': <IconPersonDashed />,
  'person-lines-right': <IconPersonLinesRight />,
  'briefcase': <IconBriefcase />,
  'cart-plus': <IconCartPlus />,
  'cash-coin': <IconCashCoin />,
  'plus-square-dotted': <IconPlusSqDotted />,
  'columns-gap': <IconColumnsGap />,
  'grid': <IconGrid />,
  // 'check-circle': <IconCheckCircle />,
  'check-circle-apply': <IconCheckCircleApply />,
  'list': <IconList />,
  'list-dot': <IconListDot />,
  'list-dot-border': <IconListDotBorder />,
  'list-check': <IconListCheck />,
  'check-big': <IconCheckBig />,
  'pencil': <IconPencil />,
  'trash': <IconTrash />,
  // 'close-circle': <IconCloseCircle />,
  'calendar-week': <IconCalendarWeek />,
  'check': <IconCheck />,
  'pencil-fill': <IconPencilFill />,
  'send-mail': <IconSendMail />,
  'travel': <IconTravel />,
  'phone': <IconPhone />,
  'people': <IconPeople />,
  // 'sort-asc': <IconSortAsc />,
  // 'sort-desc': <IconSortDesc />,
  // 'sort-default': <IconSortDefault />,
  'eye-view': <IconEyeView />,
  'geo-location': <IconGeoLocation />,
  'person-dir': <IconPersonDir />,
  'diagram': <IconDiagram />,
  'cloud-moon': <IconCloudMoon />,
  'birthday': <IconBirthday />,
  'award-cap': <IconAwardCap />,
  'logout': <IconLogout />,
  'download': <IconDownload />,
  'check-circle-deact': <IconCircleCheck />,
  'copy-clipboard': <IconCopyClipboard />,
  'reload-circle': <IconReload />,
  'table-list': <IconTable />,
  'people-fill': <IconPeopleFill />,
  'birthday-cake-color': <IconCakeColor />,
  'calender3': <IconCalender3 />,
  'border-style': <IconBorderStyle />,
  'delete': <IconDelete />,
  'approval': <IconApproval />,
  'vertical-3-dot': <Icon3DotVertical />,
  'expiring-project': <IconExpiring />,
  'right-arrow': <RightArrow />,
  'chart': <IconChart />,
  'open-in-new-tap': <IconArrowup />,
  'chart-arrow-up': <IconChartArrowup />,
  'full-view': <IconFullView />,
  'full-view-exit': <IconFullViewExit />,
  'work-from-home': <WorkFromHome />,
  'map': <Map />,
  'cross-icon': <CrossIcon />,
  'braincase-icon': <BraincaseIcon />,
  'biases': <BiasesIcon />,
  'project': <Project />,
  'app-nav': <IconAppNav />,
  'help-fill': <IconHelpFill />,
  'user-fill': <IconUserFill />,
  'add-circle-fill': <AddCircleFill />,
  'add-circle-transparent': <AddCircleTransparent />,
  'add-circle-white': <AddCircleWhite />,
  'list-check-fill': <ListCheckFill />,
  'list-tree': <ListTree />,
  'right-button-fill': <RightButtonFill />,
  'left-button-fill': <LeftButtonFill />,
  'logo-transparent': <LogoTransparent />,
  'search-file-fill': <SearchFileFill />,
  'search-transparent': <SearchTransparent />,
  'square-bracket': <SquareBracket />,
  'people-list-transparent': <PeopleListTransparent />,
  'alerts-transparent': <AlertsTransparent />,
  'folder-transparent': <FolderTransparent />,
  'img-transparent': <ImgTransparent />,
  'add-circle-bowl-cancel': <AddCircleBowlCancel />,
  'add-circle-bowl-fill': <AddCircleBowlFill />,
  'align-disable': <AlignDisable />,
  'align': <Align />,
  'archive-fill': <ArchiveFill />,
  'chat-black': <ChatBlack />,
  'sort-down-new': <SortDownNew />,
  'sort-up-new': <SortUpNew />,
  'sort-default-new': <SortDefaultNew />,
  'down-arrow-transparent': <DownArrowTransparent />,
  'down-arrow-fill': <DownArrowFill />,
  'right-arrow-transparent': <RightArrowTransparent />,
  'right-arrow-fill': <RightArrowFill />,
  'chat-fill': <ChatFill />,
  'check-circle': <CheckCircle />,
  'close-circle': <CloseCircle />,
  'comment-header': <CommentHeader />,
  'delete-fill': <DeleteFill />,
  'drag': <Drag />,
  'edit-fill': <EditFill />,
  'filter': <Filter />,
  'filter-transparent': <FilterTransparent />,
  'frame-expand': <FrameExpand />,
  'info-dark': <InfoDark />,
  'info-fill': <Info />,
  'horizontal-line': <HorizontalLine />,
  'sort-asc': <SortDown />,
  'sort-desc': <SortUp />,
  'sort-default': <SortDefault />,
  'restore': <Restore />,
  'align-left-transparent': <AlignLeftTransparent />,
  'align-right-transparent': <AlignRightTransparent />,
  'align-left-fill': <AlignLeftFill />,
  'align-right-fill': <AlignRightFill />,
  'comment-restore': <CommentRestore />,
  'export-doc': <ExportDoc />,
  'tag-black': <TagBlack />,
  'delete-disabled': <DeleteDisabled/>,
  'up': <Up />,
  'up-fill': <UpFill />,
  'down': <Down />,
  'down-fill': <DownFill />,
  'wrapper': <Wrapper />


};

export default function SVGIcon({ icon, className, innerTag = "div", ...props }) {
  return (<>
    {innerTag === "div" && <div className={classNames('icon', 'icon-' + icon, className,)} {...props} >
      {iconMapping[icon] ? iconMapping[icon] : ''}
    </div>}
    {innerTag === "span" && <span className={classNames('icon', 'icon-' + icon, className,)} {...props} >
      {iconMapping[icon] ? iconMapping[icon] : ''}
    </span>}
  </>);
}
