import React, { useState, useEffect } from "react";
import "./Projects.scss";
import { ServiceUtils } from "../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../Shared/Utils/ToasterUtil";
import SVGIcon from "../../../Shared/Components/SVGIcon/SVGIcon";
import { useHistory } from "react-router-dom";
import { searchTable, defaultAntSort } from "../../../Shared/Utils/CommonUtil";
import { Drawer, Button, Radio, Modal, Table, Tooltip } from 'antd';
import CustomComment from '../../../Shared/Components/CustomComment/CustomComment';
import FormBuilder from "../../../Shared/Components/Ant-FormBuilder/Ant-FormBuilder";
import { DebounceInput } from 'react-debounce-input';
import { sessionService } from "../../../Shared/Utils/SessionService";
import { CONSTANTS, VARIABLES } from '../../../Environments/constants';
import CustomParagraphToolTip from "../../../Shared/Components/CustomParagraphToolTip/CustomParagraphToolTip";


export default function Projects() {
  const logedInUserDetail = sessionService.getSession();
  const role = logedInUserDetail?.role;
  const KM_CONSULTANT = CONSTANTS?.KM_CONSULTANT;

  // Comments categories
  const [commentsData, setCommentsData] = useState({
    project_id: '',
    general_comments: [],
    candidate_bias_comments: [],
    project_attribute_comments: [],
    resolved_comment: []
  });
  const [userDetails] = useState(JSON.parse(localStorage.getItem(VARIABLES.userSession)));
  const [projectId, setProjectId] = useState("");
  const [showCommentOffcanvas, setShowCommentOffcanvas] = useState(false);
  const [showComments, setShowComments] = useState(false);

  // --------- show and hide comments offcanvas
  const handleCommentOffCanvasClose = () => { 
    setShowCommentOffcanvas(false); 
    setTimeout(() => {
      setCommentsData({});
      setShowComments(false);
    }, 500);
  }
  const [projectName, setProjectName] = useState("");
  const [searchText, setSearchText] = useState("");
  const [tableDataText, setTableDataText] = useState('No data found');
  // -------------------------------------------------- searching

  const [projectTable, setProjectTable] = useState({
    body: [],
    header: [],
    filteredData: [],
  });

  const [header] = useState([
    {
      key: "project_name",
    },
    {
      key: "therapy_area",
    },
    {
      key: "client",
    },
    {
      key: "status_step",
    },
    {
      key: "project_code",
    },
    {
      key: "ABI_primaryContact"
    }
  ]);

  const [tableColoumnWidth, setTableColoumnWidth] = useState({ targetAudienceTherapyArea: 200, projectInfo: 215 })
  /**
   * drawer functionality 
   */
  const [projectFilterDrawer, setProjectFilterDrawer] = useState(false);

  const showFilterDrawer = () => {
    setFilterPayload({ ...selectedFilterPayload });
    setProjectFilterDrawer(true);
  };
  const onDrawerClose = () => {
    setProjectFilterDrawer(false);
  };
  const handleCommentOffCanvasShow = (project_id) => {
    setProjectId(project_id);
    getComments(project_id, true);
  };

  /**
   * Modal 
   */
  const [deleteAntdModalVisiblity, setDeleteAntdModalVisiblity] = useState(false);
  const [archiveAntdModalVisiblity, setArchiveAntdModalVisiblity] = useState(false);
  const [restoreAntdModalConfirmation, setRestoreAntdModalVisiblity] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false)

  const resetCommentData = () => {
    setCommentsData({
      project_id: '',
      general_comments: [],
      candidate_bias_comments: [],
      project_attribute_comments: [],
      resolved_comment: []
    })
  }

  const updateAllCommentSections = (newComments) => {
    const sectionKeys = ['general_comments', 'candidate_bias_comments', 'project_attribute_comments', 'resolved_comments'];
    for (const eachKey of sectionKeys) {
      const oldCommentIds = commentsData[eachKey] ? commentsData[eachKey].map((i) => i.id) : [];
      if (oldCommentIds.length === 0) {
        commentsData[eachKey] = newComments[eachKey];
      } else {
        commentsData[eachKey] = newComments[eachKey].map((c) => {
          const indexVal = oldCommentIds.indexOf(c.id);
          if (indexVal > -1) {
            c = commentsData[eachKey][indexVal]
          }
          return c;
        });
      }
    }
    setCommentsData({ ...commentsData });
  }


  /**
   * Get Comments
   * @param {*} project_id - project Id
   */
  const getComments = (project_id, initialLoad = false) => {
    if (commentsData && projectId !== project_id) {
      resetCommentData();
    }
    const data = {
      "project_id": project_id,
      "sections": ["General", "Project Attributes", "Candidate Biases", "Resolved"]
    }
    const showCommonLoader = initialLoad ? true : false;
    ServiceUtils.postRequest("commentsList", data, showCommonLoader).then(
      (response) => {
        try {
          if (response.status === 200) {
            if (response.data.status === "success") {
              const result = response?.data?.data
              result.project_id = project_id;
              if (initialLoad) {
                updateAllCommentSections(result);
                setShowCommentOffcanvas(true);
                setShowComments(true);
              } else {
                setCommentsData(result);
              }
            } else {
              alertService.error(
                response.data.message || `Error while fetching comments`
              );
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    );
  };

  const handleArchiveComment = ({ comment }) => {
    if (!comment || !comment.id) return;
    archiveComment({ commentId: comment.id });
  }

  const handleRestoreComment = ({ comment }) => {
    if (!comment || !comment.id) return;
    restoreComment({ commentId: comment.id });
  }

  const mergeReplies = (oldReply, newReply) => {
    if (!oldReply || !newReply) return;
    if (oldReply.length === 0) return [...newReply];
    const oldReplyCommentIds = oldReply ? oldReply.map((i) => i.id) : [];
    const updatedReplyList = newReply.map((r) => {
      const indexVal = oldReplyCommentIds.indexOf(r.id);
      if (indexVal > -1) {
        r = oldReply[indexVal]
      }
      return r;
    })
    return [...updatedReplyList]
  }

  const pushReplyComments = (parentComment, comments, replies) => {
    try {
      if (!comments) return [];
      comments.map((f) => {
        if (parentComment.level === 0) {
          if (f.id === parentComment.id) {
            f.reply = mergeReplies(f.reply, replies);
          }
        } else {
          f.reply.map((c) => {
            if (c.id === parentComment.id) {
              c.reply = mergeReplies(c.reply, replies);
            }
            return c;
          })
        }
        return f;
      })
      return comments;
    } catch (error) {
      console.log(error);
    }
  }

  const onReplyToToggle = (action, comment, section) => {
    if (!action || !comment || !comment.id) return;
    if (comment.level >= 2) return; // levels are 0, 1, 2 so for level 2 there is no child
    const commentSectionKeyMapping = {
      general: 'general_comments',
      candidate: 'candidate_bias_comments',
      attributes: 'project_attribute_comments'
    };
    if (!commentSectionKeyMapping[section]) return;
    const sectionKey = commentSectionKeyMapping[section];
    const showFlag = action === 'show' ? true : false;
    commentToggleAction('addComment', showFlag, comment, sectionKey)
  }

  const commentToggleAction = (toggleKey, toggleValue, comment, sectionKey) => {
    commentsData[sectionKey].map((c) => {
      if (comment.level === 0) {
        if (c.id === comment.id) c[toggleKey] = toggleValue;
      } else if (comment.level === 1) {
        c.reply.map((r) => {
          if (r.id === comment.id) r[toggleKey] = toggleValue;
          return r;
        })
      }
      return c;
    });
    setCommentsData({ ...commentsData });
  }

  const onLoadReplies = (action, comment, section) => {
    if (!action || !comment || !comment.id) return;
    if (comment.level >= 2) return; // levels are 0, 1, 2 so for level 2 there is no child
    const commentSectionKeyMapping = {
      general: 'general_comments',
      candidate: 'candidate_bias_comments',
      attributes: 'project_attribute_comments',
      resolved: 'resolved_comments'
    };
    if (!commentSectionKeyMapping[section]) return;
    const sectionKey = commentSectionKeyMapping[section];
    const showFlag = action === 'show' ? true : false;
    commentToggleAction('showReply', showFlag, comment, sectionKey);
    if (action === 'hide') return;
    commentToggleAction('isLoading', true, comment, sectionKey);
    try {
      ServiceUtils.postRequest("projectCommentReply", { "comment_id": comment.id, "level": comment.level + 1 }, false).then(
        (response) => {
          try {
            if (response.status === 200) {
              if (response.data.status === "success") {
                const result = response?.data?.data?.replies;
                if (commentsData[sectionKey]) {
                  pushReplyComments(comment, commentsData[sectionKey], result);
                  setCommentsData({ ...commentsData });
                }
              }
            }
          } catch (error) {
            console.log(error)
          }
          commentToggleAction('isLoading', false, comment, sectionKey);
          commentToggleAction('isLoadingNewComment', false, comment, sectionKey);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  const onAddCommentAction = (parentComment, value, callback, section) => {
    try {
      // ["General", "Project Attributes", "Candidate Biases"]
      const commentSectionStepMapping = {
        general: 'General',
        candidate: 'Candidate Biases',
        attributes: 'Project Attributes'
      };
      if (!commentSectionStepMapping[section]) return;
      let data = {
        project_id: projectId,
        user_id: userDetails?.user_id,
        step: commentSectionStepMapping[section],
        comment: value,
        parent_comment_id: 0,
        level: 0,
      };
      if (parentComment) {
        data.parent_comment_id = parentComment.id;
        data.level = parentComment.level + 1;
      }
      const commentSectionKeyMapping = {
        general: 'general_comments',
        candidate: 'candidate_bias_comments',
        attributes: 'project_attribute_comments',
        resolved: 'resolved_comments'
      };
      if (!commentSectionKeyMapping[section]) return;
      const sectionKey = commentSectionKeyMapping[section];
      ServiceUtils.postRequest("createComments", data, false).then((response) => {
        try {
          if (response.status === 200) {
            if (response.data.status === "success") {
              const savedComment = response?.data?.data?.comment_details;
              if (savedComment.level === 0) {
                getComments(projectId)
              }
              if (typeof callback === 'function') callback(true, savedComment);
              commentToggleAction('isLoadingNewComment', true, parentComment, sectionKey);
            } else {
              if (typeof callback === 'function') callback(false)
              alertService.error(
                response.data.message || `Error while adding comment`
              );
            }
          } else {
            if (typeof callback === 'function') callback(false)
            alertService.error(
              `Failed to adding comment. Please try after sometime.`
            );
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * To show and hide Filters offcanvas
   */
  const handleFilterDrawerClose = () => {
    try {
      setProjectFilterDrawer(false);
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * To set search value
   * @param {*} searchText - search value
   */
  const handleSearchText = (searchText) => {
    if (projectTable?.filteredData.length === 0) {
      setTableDataText('No result found');   // if there is no data in project list
    } else {
      setTableDataText('No search result found'); // if  no search result found from project list
    }
    setSearchText(searchText);
  };

  /**
   * To show delete confirmation modal
   * @param {*} projectId -  project id
   */
  const deleteConfirmation = (projectId) => {
    setDeleteAntdModalVisiblity(true)
    setProjectId(projectId);
  };


  const archiveComment = (data) => {
    archiveCommentConfirmation('archive', data?.commentId);
  };
  const restoreComment = (data) => {
    archiveCommentConfirmation('restore', data?.commentId)
  }
  /**To archive comment
   */
  const archiveCommentConfirmation = (type, id) => {
    const isResolved = (type === "archive") ? true : false;
    ServiceUtils.postRequest("archiveComment", { comment_id: id, is_resolved: isResolved }).then(
      (_response) => {
        try {
          if (_response.status === 200) {
            if (_response.data.status === "success") {
              alertService.success(
                _response.data.message || `Successfully archive comment`
              );
              getComments(projectId); // display updated comment list 
            } else {
              alertService.error(
                _response.data.message || `Error while archiving comment`
              );
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    );
  }


  /** Delete Project */
  const deleteProject = () => {
    ServiceUtils.postRequest("deleteProject", { project_id: projectId }).then(
      (_response) => {
        try {
          if (_response.status === 200) {
            setDeleteAntdModalVisiblity(false)
            if (_response.data.status === "success") {
              alertService.success(
                _response.data.message || `Successfully delete project`
              );
              listProject(); // Reload table data
            } else {
              alertService.error(
                _response.data.message || `Error while deleting project`
              );
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    );
  };

  const archiveConfirmation = (projectId) => {
    setProjectId(projectId);
    setArchiveAntdModalVisiblity(true)
  };

  /** Archive Project */
  const archiveProject = () => {
    ServiceUtils.postRequest("archieveProject", { project_id: projectId }).then(
      (_response) => {
        try {
          if (_response.status === 200) {
            if (_response.data.status === "success") {
              listProject(); // Reload table data
              alertService.success(
                _response.data.message || `Successfully archive project `
              );
              setArchiveAntdModalVisiblity(false)
            } else {
              alertService.error(
                _response.data.message || `Error while archiving project `
              );
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    );
  };


  const restoreConfirmation = (projectCode) => {
    setProjectId(projectCode)
    setRestoreAntdModalVisiblity(true)
  }
  /**
   * Restore project
   */
  const restoreProject = () => {
    ServiceUtils.postRequest("archieveProject", { project_id: projectId, "unarchive": true }).then(
      (_response) => {
        try {
          if (_response.status === 200) {
            if (_response.data.status === "success") {
              listProject(); // Reload table data
              alertService.success(
                _response.data.message || `Successfully restore project `
              );
              setRestoreAntdModalVisiblity(false)
            } else {
              alertService.error(
                _response.data.message || `Error while restoring project `
              );
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    );
  }
  /** Table search logic */
  useEffect(() => {
    let headers = JSON.parse(JSON.stringify(projectTable?.header));
    headers.push({ key: "research_methodology" })
    headers.push({ key: "country_value" })
    if (headers.length && headers[1] && headers[1]['key'] === 'therapy_area') {
      headers[1]['key'] = 'therapy_area_value';
    }
    let body = JSON.parse(JSON.stringify(projectTable?.body));
    body.map(record => {
      let tempString = ''
      record.therapy_area.map((ta) => {
        tempString = `${tempString} ${ta?.therapy_name} - ${ta?.disease.join(", ")}`;
        return ta;
      });
      record['therapy_area_value'] = tempString;
      record['country_value'] = record.country.join(', ');
      return record;
    })
    if (searchText.trim().length === 0) {
      setProjectTable({
        ...projectTable,
        filteredData: projectTable.body
      });
    } else {
      setSearchLoader(true);
      setProjectTable({
        ...projectTable,
        filteredData: searchTable(
          searchText,
          body,
          headers
        ),
      });
    }
    if (projectTable?.filteredData) {
      setTimeout(() => {
        setSearchLoader(false)
      }, 500); // added set time out to see the effect 
    }
  }, [searchText]);

  /** Filter form action */
  const [filterPayload, setFilterPayload] = useState({ "status": role === KM_CONSULTANT ? "Both" : "Active", });
  const [selectedFilterPayload, setSelectedFilterPayload] = useState({
    "project_code": [],
    "target_audience": [],
    "research_methodology": [],
    "therapy_area": [],
    "country": [],
    "status": role === KM_CONSULTANT ? "Both" : "Active",
    "client": [],
    "status_step": [],
    "primary_contact": []
  });
  /** Filter Status Change action */
  const onStatusFilterChange = (event) => {
    setFilterPayload({
      ...filterPayload,
      status: event.target.value === "Active" ? 'Active' : event.target.value === "Archived" ? "Archived" : "Both",
    });

  };


  const handleFormAction = ({ type, payload }) => {
    switch (type) {
      case "onChange":
        changeKeyValuPareToValu(payload);
        break;
      default:
        break;
    }
  };
  const changeKeyValuPareToValu = (payload) => {
    const formData = payload.data;
    if (formData.therapy_area.length > 0) {
      let tADropdownData = [];
      formData.therapy_area.map((el) => {
        if (el?.value) {
          tADropdownData.push(el?.value);
        } else {
          tADropdownData.push(el);
        }
        return el;
      });
      formData.therapy_area = tADropdownData;
    }

    if (formData.target_audience.length > 0) {
      let TauDropDownData = [];
      formData.target_audience.map((el) => {
        if (el?.value) {
          TauDropDownData.push(el?.value);
        } else {
          TauDropDownData.push(el);
        }
        return el;
      });
      if (role === KM_CONSULTANT) {
        formData.status = "Both"
      } else {
        formData.status = "Active"
      }
      formData.target_audience = TauDropDownData;
    }
    setFilterPayload({ ...filterPayload, ...formData })

  }

  const filterProject = () => {
    if (isFiterValueSelected(filterPayload) && isFiterValueSelected(selectedFilterPayload)) {
      setProjectFilterDrawer(false);
    } else {
      setSelectedFilterPayload({ ...filterPayload });
      listProject({ ...filterPayload });
    }
  }
  const resetFilter = () => {
    const resetPayload = {
      project_code: [],
      target_audience: [],
      research_methodology: [],
      therapy_area: [],
      country: [],
      status: role === KM_CONSULTANT ? "Both" : "Active",
      client: [],
      status_step: [],
      primary_contact: [],
    }
    if (isFiterValueSelected(selectedFilterPayload)) {
      setProjectFilterDrawer(false);
    } else {
      listProject({ ...resetPayload });
    }

  }
  const isFiterValueSelected = (payload) => {
    let selectedFilter = null;
    if (role === KM_CONSULTANT) {
      selectedFilter = (payload?.project_code.length === 0 && payload?.target_audience.length === 0 && payload?.client.length === 0 && payload?.research_methodology.length === 0 && payload?.therapy_area.length === 0 && payload?.country.length === 0 && (payload?.status === "Both" && selectedFilterPayload?.status === 'Both') && payload?.project_code.length === 0 && payload?.status_step.length === 0 && payload?.primary_contact.length === 0)
    } else {
      selectedFilter = (payload?.project_code.length === 0 && payload?.target_audience.length === 0 && payload?.client.length === 0 && payload?.research_methodology.length === 0 && payload?.therapy_area.length === 0 && payload?.country.length === 0 && (payload?.status === "Active" && selectedFilterPayload?.status === 'Active') && payload?.project_code.length === 0 && payload?.status_step.length === 0)
    }
    return selectedFilter;
  }
  /** For getting project list */
  async function listProject(filterData = null) {
    ServiceUtils.postRequest("listProject", (filterData ? filterData : selectedFilterPayload)).then((_response) => {
      try {
        if (_response.status === 200) {
          if (_response.data.status === "success") {
            const resp = _response?.data?.data;
            resp.projects.map((p, i) => p.key = i);
            let data = {
              body: resp?.projects,
              header: header,
              filteredData: resp?.projects,
            };
            if (searchText.trim().length !== 0) {
              setProjectTable({ ...data });
              let search = searchText;
              setSearchText('');
              setSearchText(search);
            } else {
              setProjectTable({ ...data });
            }
            setProjectFilterDrawer(false);
            setFilterPayload({ ...filterData ? filterData : selectedFilterPayload });
            setSelectedFilterPayload({ ...filterData ? filterData : selectedFilterPayload });
          } else {
            alertService.error(
              _response.data.message || `Error while fetching project list`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  }

  /** Getting project code */
  async function fetchProjectCode() {
    ServiceUtils.getRequest("projectCodes").then((_response) => {
      try {
        if (_response.status === 200) {
          if (_response.data.status === "success") {
            const result = _response?.data?.data;
            const projectCode = result.project_codes;
            const temp = filterConf
            temp?.map((row) => {
              return row.map((el) => {
                if (el.key === "project_code") el.options = projectCode;
                return { ...el };
              });
            });
            setFilterConfig([...temp]);
          } else {
            alertService.error(
              _response.data.message || `Error while fetching project code`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  }

  /** Get country list */
  const fetchCountries = () => {
    ServiceUtils.getRequest("countryList").then((response) => {
      try {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data;
            const country = result.country;
            const temp = filterConf;
            temp?.map((row) => {
              return row.map((el) => {
                if (el.key === "country") el.options = country;
                return { ...el };
              });
            });
            setFilterConfig([...temp])
          } else {
            alertService.error(
              response.data.message || `Error while fetching country list`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  /** Get target audience */
  const fetchAudience = () => {
    ServiceUtils.getRequest("targetAudienceList").then((response) => {
      try {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data;
            const audienceList = result.targeted_audience;
            const temp = filterConf;
            temp?.map((row) => {
              return row.map((el) => {
                if (el.key === "target_audience") el.options = audienceList;
                return { ...el };
              });
            });
            setFilterConfig([...temp])
          } else {
            alertService.error(
              response.data.message ||
              `Error while fetching target audience list`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  /** Get therapy area */
  const fetchTherapyArea = () => {
    ServiceUtils.getRequest("theraphyArea").then((response) => {
      try {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data;
            const audienceList = result.therapy_area;
            const temp = filterConf;
            temp?.map((row) => {
              return row.map((el) => {
                if (el.key === "therapy_area") el.options = audienceList;
                return { ...el };
              });
            });
            setFilterConfig([...temp])
          } else {
            alertService.error(
              response.data.message ||
              `Error while fetching target audience list`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  /** Get clients name */
  const getClientName = () => {
    ServiceUtils.getRequest("clientList").then((response) => {
      try {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data;
            const clients = result.clients;
            const temp = filterConf;
            temp?.map((row) => {
              return row.map((el) => {
                if (el.key === "client") el.options = clients;
                return { ...el };
              });
            });
            setFilterConfig([...temp])
          } else {
            alertService.error(
              response.data.message ||
              `Error while fetching client list`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  }

  /** Get research methodology */
  const fetchResearchMethodology = () => {
    ServiceUtils.getRequest("researchMethodology").then((response) => {
      try {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data;
            const audienceList = result.research_methodology
            const temp = filterConf;
            temp?.map((row) => {
              return row.map((el) => {
                if (el.key === "research_methodology")
                  el.options = audienceList;
                return { ...el };
              });
            });
            setFilterConfig([...temp])
          } else {
            alertService.error(
              response.data.message ||
              `Error while fetching research methodology list`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  };
  /** Get project step [ status step]  */
  const getProjectStep = (value) => {
    ServiceUtils.getRequest("projectStepFilter").then((response) => {
      try {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data;
            const projectStep = result.topMenu;
            const temp = filterConf;
            temp?.map((row) => {
              return row.map((el) => {
                if (el.key === "status_step")
                  el.options = projectStep;
                return { ...el };
              });
            });
            setFilterConfig([...temp])
          } else {
            alertService.error(
              response.data.message ||
              `Error while fetching project steps list`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  }

  const getPrimaryContact = () => {
    ServiceUtils.getRequest("primaryContact").then((response) => {
      try {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const result = response?.data?.data;
            const projectStep = result.primary_contact;
            const temp = filterConf;
            temp?.map((row) => {
              return row.map((el) => {
                if (el.key === "primary_contact")
                  el.options = projectStep;
                return { ...el };
              });
            });
            setFilterConfig([...temp])
          } else {
            alertService.error(
              response.data.message ||
              `Error while fetching project steps list`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  }
  /**get particular project details */
  useEffect(() => {
    // for rendering functions or state on loading or reloading of page
    fetchCountries();
    fetchAudience();
    fetchProjectCode();
    fetchResearchMethodology();
    fetchTherapyArea();
    listProject();
    getClientName();
    getProjectStep('filter');

    if (role === KM_CONSULTANT) {
      setTableColoumnWidth({ targetAudienceTherapyArea: 130, projectInfo: 170 });
      const primaryContact = {
        key: "primary_contact",
        label: "Primary Contact",
        type: "multi-select",
        search: true,
        maxTagCount: 1,
        placeholder: "Please Select ",
        col: 24,
        options: []
      }
      const isPrimaryContact = filterConf[0].filter((el) => {
        return el.key === 'primary_contact';
      })
      if (isPrimaryContact.length === 0) {
        filterConf[0].splice(6, 0, primaryContact);
      }
      getPrimaryContact();
    }
  }, []);

  const [filterConf, setFilterConfig] = useState([
    [
      {
        key: "project_code",
        label: "Project Code",
        type: "multi-select",
        search: true,
        placeholder: "Please Select ",
        col: 24,
        maxTagCount: 1,
        allowClear: true,
        options: []
      },
      {
        key: "research_methodology",
        label: "Research Methodology",
        type: "multi-select",
        search: true,
        placeholder: "Please Select ",
        col: 24,
        allowClear: true,
        maxTagCount: 1,
        options: []
      },
      {
        key: "target_audience",
        label: "Target Audience",
        type: "tree-select",
        treeCheckable: true,
        maxTagCount: 1,
        placeholder: "Please Select ",
        col: 24,
        allowClear: true,
        options: []
      },
      {
        key: "therapy_area",
        label: "Therapy Area",
        type: "tree-select",
        treeCheckable: true,
        maxTagCount: 1,
        placeholder: "Please Select ",
        col: 24,
        allowClear: true,
        options: []

      },
      {
        key: "country",
        label: "Country",
        type: "multi-select",
        search: true,
        maxTagCount: 1,
        placeholder: "Please Select ",
        col: 24,
        allowClear: true,
        options: []
      },
      {
        key: "client",
        label: "Client",
        type: "multi-select",
        search: true,
        maxTagCount: 1,
        placeholder: "Please Select ",
        col: 24,
        allowClear: true,
        options: []
      },
      {
        key: "status_step",
        label: "Status Step",
        type: "multi-select",
        search: true,
        maxTagCount: 1,
        allowClear: true,
        placeholder: "Please Select ",
        col: 24,
        options: []
      },
    ]
  ]);

  const history = useHistory();

  const handleParticularProject = (projectID = undefined) => {
    try {
      if (projectID) {
        history.push({
          pathname: `/home/project/details/${projectID}`
        });
      } else {
        history.push('/home/project/details');
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const TooltipDesign = ({ data, key1, key2 }) => {
    return (
      <>{data?.map((TA, index) => {
        return (
          <span className="p-0 mb-1" key={`ta`+ index}>
            <p className="p-0 m-0"> <strong >{TA[key1]}</strong> - <span>{TA[key2].join(", ")}</span></p>
          </span>
        );
      })}
      </>
    )
  }
  /**
   * Project table implementation using ANTD
   */
  const dataSource = projectTable?.filteredData;
  const columns = [
    {
      title: 'Project Info',
      dataIndex: 'project_name',
      key: 'project_name',
      width: tableColoumnWidth.projectInfo,
      defaultSortOrder: "ascend",
      sorter: (a, b) => defaultAntSort(a.project_name, b.project_name),
      render: (_, record) => (
        < >
          {record?.status_step === "Archived" ?
            <CustomParagraphToolTip className="p-0 m-0 text-muted" ellipsis={{ rows: 3 }}
              tooltipContent={record?.project_name}
              paragrapgContent={record?.project_name}
            />
            :
            <span onClick={() => { handleParticularProject(record?.project_id) }}>
              <CustomParagraphToolTip className={`p-0 m-0 text-clickable`} ellipsis={{ rows: 3 }}
                tooltipContent={record?.project_name}
                paragrapgContent={record?.project_name}
              />
            </span>
          }
          <p className="p-0 m-0 "> {record?.project_code}</p>
          <p className="p-0 m-0 "> {record?.research_methodology}</p>
        </>
      ),
    },
    {
      title: 'Target Audience',
      dataIndex: 'target_audience',
      key: 'target_audience',
      width: tableColoumnWidth.targetAudienceTherapyArea,
      render: (_, { target_audience }) => (
        <>
          <CustomParagraphToolTip ellipsis={{ rows: 3 }}
            tooltipContent={<TooltipDesign data={target_audience} key1={'audience'} key2={'speciality'} />}
            paragrapgContent={target_audience && target_audience.map((TA) => {
              return (
                <> {TA?.audience} - {TA?.speciality.join(", ")}<br/></>
              );
            })}
          />
        </>
      ),
    },
    {
      title: 'Therapy Area ',
      dataIndex: 'therapy_area ',
      key: 'therapy_area',
      width: tableColoumnWidth.targetAudienceTherapyArea,
      render: (_, { therapy_area }) => (
        <>
          <CustomParagraphToolTip ellipsis={{ rows: 3 }}
            tooltipContent={<TooltipDesign data={therapy_area} key1={'therapy_name'} key2={'disease'} />}
            paragrapgContent={therapy_area && therapy_area.map((TA) => {
              return (
                <> {TA?.therapy_name} - {TA?.disease.join(", ")}<br/></>
              );
            })
            } />
        </>
      ),
    },
    {
      title: 'Country',
      dataIndex: 'country ',
      key: 'country',
      width: 100,
      render: (_, { country }) => (
        <>
          <CustomParagraphToolTip ellipsis={{ rows: 3 }} tooltipContent={country.join(', ')} paragrapgContent={country.join(', ')} />
        </>
      ),
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      width: 100,
      sorter: (a, b) => defaultAntSort(a.client, b.client),
    },
    {
      title: 'Status Step',
      dataIndex: 'status_step',
      key: 'status_step',
      width: 140,
      sorter: (a, b) => defaultAntSort(a.status_step, b.status_step),
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 135,
      render: (_, record) => (
        < >
          {record?.status_step === "Archived" ?
            <div >
              <a onClick={() => {
                restoreConfirmation(record?.project_id);
              }} className="text-primary text-decoration-none me-3">
                <SVGIcon
                  className="fontIcon project-restore-icon"
                  icon={'restore'}
                />
                Restore
              </a>
            </div>
            :
            <div >
              <Tooltip placement="topRight" title={'Comment'} overlayClassName={'tooltip-bg'}>
                <SVGIcon icon={'chat-fill'} className="fontIcon" onClick={() => {
                  handleCommentOffCanvasShow(record?.project_id);
                  setProjectName(record?.project_name);
                }} />
              </Tooltip>
              <Tooltip placement="topRight" title={'Archive'} overlayClassName={'tooltip-bg'}>
                <SVGIcon icon={'archive-fill'} className="fontIcon" onClick={() => {
                  archiveConfirmation(record?.project_id);
                }} />
              </Tooltip>
              <Tooltip placement="topRight" title={'Delete'} overlayClassName={'tooltip-bg'}>
                <SVGIcon icon={'delete-fill'} className="fontIcon" onClick={() => {
                  deleteConfirmation(record?.project_id);
                }} />
              </Tooltip>
            </div>
          }
        </>
      ),
    },
  ];

  if (role === KM_CONSULTANT) {
    columns.splice(5, 0, {
      title: 'ABI Primary Contact',
      dataIndex: 'ABI_primaryContact',
      key: 'ABI_primaryContact',
      width: 130,
      sorter: (a, b) => defaultAntSort(a.ABI_primaryContact, b.ABI_primaryContact),
    });
  }
  let noDataFaound = {
    emptyText: tableDataText,
  };
  return (
    <>
      <div className="page-layout project-list-layout">
        <div className="col-12">
          <div className="searchMainDiv mb-3 d-flex">
            <div className="searchFilterDiv input-group ps-0 ">
              <DebounceInput
                debounceTimeout={500}
                type="text"
                className="form-control form-control-sm"
                placeholder="Search"
                onChange={(e) => {
                  handleSearchText(e.target.value);
                }}
              />
              <button
                className="searchButton text-muted me-2"
              >
                <SVGIcon icon={'search-transparent'} />
              </button>
              <div className="ps-2 pt-2">
                <span
                  className="filtersButton"
                  onClick={showFilterDrawer}
                >
                  <SVGIcon icon={'filter'} />{" "}
                  <span className="filter-text">Filters</span>
                </span>
              </div>
            </div>
            <Button type="primary" onClick={() => { handleParticularProject(); }} className={`me-2 d-flex ant-btn-primary`}  >
              <SVGIcon icon={"add-circle-white"} className='me-1 iconPositionInButton' />
              {/* <FontAwesomeIcon icon={faCirclePlus} className="fontIcon text-light " /> */}
              <span className="pb-1">Create New Project</span>
            </Button>
          </div>
          <div className="project-table ">
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              loading={searchLoader}
              scrollToFirstRowOnChange={true}
              scroll={{ y: "calc(100vh - 187px)" }}
              showSorterTooltip={false}
              locale={noDataFaound}
            />
          </div>
        </div>

        {/* Comments offcanvas  */}
        <Drawer title={`Comments - ${projectName}`}
          placement={'right'}
          width={'65%'}
          closable={'right'}
          zIndex={999}
          className={'project-comment-drawer'}
          onClose={handleCommentOffCanvasClose} open={showCommentOffcanvas}
        >
          <div className="commentDrawer">
            {/* ---------------- comment repeat */}
            <div>
              <h5 className="mx-3 mb-3">General</h5>
              {commentsData?.general_comments &&
                <CustomComment
                  visible={showComments}
                  data={commentsData?.general_comments}
                  isArchive={true}
                  onArchiveComment={handleArchiveComment}
                  onLoadReplyToggle={(action, comment) => onLoadReplies(action, comment, 'general')}
                  onToggleReplyTo={(action, comment) => onReplyToToggle(action, comment, 'general')}
                  onSaveComment={(comment, value, callback) => onAddCommentAction(comment, value, callback, 'general')}
                />
              }
            </div>
            <div>
              <h5 className="m-3">Project Attributes</h5>
              {commentsData?.project_attribute_comments &&
                <CustomComment
                  visible={showComments}
                  data={commentsData?.project_attribute_comments}
                  isArchive={true}
                  onArchiveComment={handleArchiveComment}
                  onLoadReplyToggle={(action, comment) => onLoadReplies(action, comment, 'attributes')}
                  onToggleReplyTo={(action, comment) => onReplyToToggle(action, comment, 'attributes')}
                  onSaveComment={(comment, value, callback) => onAddCommentAction(comment, value, callback, 'attributes')}
                />
              }
            </div>
            <div>
              <h5 className="m-3">Candidate Biases </h5>
              {commentsData?.candidate_bias_comments &&
                <CustomComment
                  visible={showComments}
                  data={commentsData?.candidate_bias_comments}
                  isArchive={true}
                  onArchiveComment={handleArchiveComment}
                  onLoadReplyToggle={(action, comment) => onLoadReplies(action, comment, 'candidate')}
                  onToggleReplyTo={(action, comment) => onReplyToToggle(action, comment, 'candidate')}
                  onSaveComment={(comment, value, callback) => onAddCommentAction(comment, value, callback, 'candidate')}
                />
              }
            </div>
            <div>
              <h5 className="m-3">Resolved</h5>
              {commentsData?.resolved_comments &&
                <CustomComment
                  data={commentsData?.resolved_comments}
                  isRestore={true}
                  onRestoreComment={handleRestoreComment}
                  isAddNewComment={false}
                  onLoadReplyToggle={(action, comment) => onLoadReplies(action, comment, 'resolved')}
                />
              }
              {commentsData?.resolved_comments?.length === 0 &&
                <p className='text-muted ms-3'><i>No resolved comment found</i></p>
              }
            </div>
          </div>
        </Drawer>
        {/* filter antd drawer  */}

        <Drawer title="Project Filters"
          placement={'right'}
          width={'28%'}
          closable={'right'}
          zIndex={999}
          onClose={onDrawerClose} open={projectFilterDrawer}
          footer={
            <>
              <Button type="primary" className="me-1" onClick={filterProject}>
                Apply Filters
              </Button>
              <Button onClick={resetFilter} className="me-1"  > Reset Filters
              </Button>
              <Button onClick={() => { handleFilterDrawerClose() }}>
                Cancel
              </Button>
            </>
          }
        >
          <div className="px-2 antFormBuilder">
            <FormBuilder
              id="form-project-filter"
              data={filterPayload}
              onAction={handleFormAction}
              config={filterConf} />

            <label className="d-block input-label" htmlFor="active">
              Active/Archived Projects
            </label>

            <Radio.Group value={filterPayload?.status} name="radiogroup" defaultValue={role === KM_CONSULTANT ? "Both" : "Active"} onChange={onStatusFilterChange}>
              {role === KM_CONSULTANT && <Radio value={'Both'} >Both</Radio>}
              <Radio value={'Active'} >Active</Radio>
              <Radio value={'Archived'} >Archived</Radio>
            </Radio.Group>
          </div>
        </Drawer>

        {/* Restore  project confirmation  antd modal  */}
        <Modal
          centered
          closable={false}
          open={restoreAntdModalConfirmation}
          footer={null}
          width={400}
          onCancel={() => setRestoreAntdModalVisiblity(false)}
        >
          <h6 className="confirmation-header-text">Restore Project</h6>
          <p className="text-muted pt-1">
            Are you sure you would like to restore this project?
          </p>
          <Button type="primary" onClick={restoreProject} className="me-2 mt-3" >
            Restore
          </Button>
          <Button onClick={() => { setRestoreAntdModalVisiblity(false) }} className='mt-3'>
            {" "} Cancel{" "}
          </Button>
        </Modal>

        {/* delete confirmation antd modal  */}
        <Modal
          centered
          closable={false}
          open={deleteAntdModalVisiblity}
          footer={null}
          width={400}
          zIndex={2010}
          onCancel={() => setDeleteAntdModalVisiblity(false)}
        >
          <h6 className="confirmation-header-text">Delete Project</h6>
          <p className="text-muted pt-1">
            Are you sure you would like to delete this project?
          </p>
          <Button type="primary" onClick={deleteProject} className="me-2 mt-3" >
            Delete
          </Button>
          <Button onClick={() => { setDeleteAntdModalVisiblity(false) }} className='mt-3'>
            {" "} Cancel{" "}
          </Button>
        </Modal>

        {/* Project archive confirmation antd modal  */}
        <Modal
          centered
          closable={false}
          open ={archiveAntdModalVisiblity}
          footer={null}
          width={400}
          zIndex={2010}
          onCancel={() => setArchiveAntdModalVisiblity(false)}
        >
          <h6 className="confirmation-header-text">Archive Project</h6>
          <p className="text-muted pt-1">
            Are you sure you would like to archive this project?
          </p>
          <Button type="primary" onClick={archiveProject} className="me-2 mt-3" >
            Archive
          </Button>
          <Button onClick={() => { setArchiveAntdModalVisiblity(false) }} className='mt-3'>
            {" "} Cancel{" "}
          </Button>
        </Modal>

      </div>
    </>
  );
}