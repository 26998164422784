import React, { useEffect, useState } from "react";
import Header from "../../Shared/Components/Header/Header";
import { useHistory, useLocation } from "react-router-dom";
import menuConfig from "../../Assets/json/sidebar-menu.json";
import SideNav from "../../Shared/Components/SideNav/SideNav";
import { sessionService } from "../../Shared/Utils/SessionService";
import AdminPage from "../../Pages/Settings/Admin/Admin";
import BiasInAction from "./BiasInAction/BiasInAction";

export default function Settings() {
  const [selectedMenu, setMenu] = useState({ key: "userManagement" });
  const location = useLocation();
  const history = useHistory();
  const userDetails = sessionService.getSession();
  const role = userDetails?.role;
  useEffect(() => {
    const path = location.pathname;
    const route = path.split("/").pop();
    if (route === "settings") {
      history.replace("settings/admin");
      setMenu({ key: "admin" });
    } else if (route === "admin") {
      setMenu({ key: "admin" });
    } else if (route === "alerts") {
      setMenu({ key: "alerts" });
    } else setMenu({ key: route });
  }, [location]);

  const onMenuSelect = (selected) => {
    setMenu(selected);
  };

  return (
    <div className="m-0 row">
      <div className="m-0 p-0 page-side-nav">
        <SideNav
          active={selectedMenu}
          onClick={onMenuSelect}
          menuConfig={menuConfig?.data[role]}
        />
      </div>
      <div className="m-0 p-0 page-container">
        <Header />
        {selectedMenu.key === "admin" && <AdminPage />}
        {/* { selectedMenu.key === 'alerts' &&  <h1 className="text-center">Alert Page</h1>} */}
        {selectedMenu.key === "bias-in-action" && <BiasInAction />}
      </div>
    </div>
  );
}
