
import React, { useEffect } from "react";
import 'antd/dist/antd.min.css';
import "./App.scss";
import Home from "../Pages/Home/Home";
import PageNotFound from "../Pages/PageNotFound/PageNotFound";
import Loader from '../Shared/Utils/Loader/Loader';
import { Route, Switch, Redirect } from "react-router-dom";
import { ProvideAuth, PrivateRoute, SecureLogin, SecureLogout, SecureIDMLogin } from "../Shared/Utils/ProvideAuth";
import ToasterAlert from '../Shared/Utils/ToasterUtil';
import Settings from '../Pages/Settings/Settings'
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

function App() {
  const screens = useBreakpoint();

  useEffect(() => {
    updateResoltionClass();
  }, [screens]);

  /**
   * To apply class according to resolution
   */
  const updateResoltionClass = () => {
    let currentResolutionList = [];
    {
      Object.entries(screens)
      .filter((screen) => !!screen[1])
      .map((screen) => (
        currentResolutionList.push(screen[0])
      ))
    }
    if (currentResolutionList.length) {
      const bodyClassList = document.body.classList;
      const resClass = `zs-baincase-${currentResolutionList[currentResolutionList.length - 1]}`;
      let addClass = true;
      for (let value of bodyClassList) {
        if (value.includes(resClass)) {
          addClass = false;
        } else if (value.includes('zs-baincase-')) {
          document.body.classList.remove(value);
        }
      }
      if (addClass) {
        document.body.classList.add(resClass);
      }
    }
  }

  return (
    <>
      <Loader />
      <ToasterAlert />
      <ProvideAuth>
        <div className="App">
          <Switch>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route exact path="/login" component={SecureLogin} />
            <Route exact path="/logout" component={SecureLogout} />
            <Route exact path="/authCallBack" component={SecureIDMLogin} />
            <PrivateRoute path="/home">
              <Home />
            </PrivateRoute>
            <PrivateRoute path="/settings">
              <Settings />
            </PrivateRoute>
            <Route path="*" exact={true} component={PageNotFound} />
          </Switch>
        </div>
      </ProvideAuth>
    </>
  );
}

export default App;
