import React, { useEffect, useState } from "react";
import './Loader.scss';
import { Subject } from 'rxjs';
import { Spin} from 'antd';

const subCount = new Subject();
const subForce = new Subject();
let apiCount = 0;
export const loaderService = {
  showLoader: (api = true) => {
    if (api) subCount.next(++apiCount);
    else subForce.next(true);
  },
  hideLoader: (api = true) => {
    if (api) subCount.next(--apiCount)
    subForce.next(false);
  }
};

const countObs = () => subCount.asObservable();
const forceObs = () => subCount.asObservable();

export default function Loader() {
  const [activeAPIs, setActiveAPIs] = useState(0);
  const [force, setForce] = useState(false);

  useEffect(() => {
    countObs().subscribe(count => {
      try {
        setActiveAPIs(count);
      } catch(e) { console.log(e) }
    });
    forceObs().subscribe(count => {
      try {
        setForce(count);
      } catch(e) { console.log(e) }
    });
  }, []);

  if (activeAPIs <= 0 && !force) return null;
  return (
    <div className="loader-root">
      <div className="container">
        {/* <div className="spinner">
        </div> */}
        <Spin size="large" />
        {/* {text} */}
      </div>
    </div>
  )
}