import React, { useState, useEffect, useRef } from "react";
import "./BiasInAction.scss";
import { Row, Col, Tooltip, Drawer, Button, Radio, Modal, Image, Tabs } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import SVGIcon from "../../../../../Shared/Components/SVGIcon/SVGIcon";
import TextArea from "antd/lib/input/TextArea";
import AddBiasInAction from "../add-bias-in-action/AddBiasInAction";
import { ServiceUtils } from "../../../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../../../Shared/Utils/ToasterUtil";
import BiasInActionRepo from "../bias-in-action-repository/BiasInActionRepository";
import defaultImage from "../../../../../Assets/images/default.jpg";
import { scrollToDiv } from '../../../../../Shared/Utils/CommonUtil';
import { DownOutlined } from "@ant-design/icons";

export default function BiasInAction({ description, mode, selectedBiasData, savedData, newRepoData, refreshData, updateBiasInActionData, saveClicked, ...props }) {

  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [newFormData, setNewFormData] = useState({});
  const [newImageData, setNewImageData] = useState();
  const [biasInActionData, setBiasInActionData] = useState([]);
  const [biasInActionRepoData, setBiasInActionRepoData] = useState([]);
  const [deleteData, setDeleteData] = useState({});
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [biasInActionRepoAllData, setBiasInActionRepoAllData] = useState([]);
  const [unsavedChangesModel, setUnsavedChangesModel] = useState(false);
  const [isBiasInActionEdited, setIsBiasInActionEdited] = useState(false);
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
  const [tabSwitchModal, setTabSwitchModal] = useState(false);
  const [clickedTab, setClickedTab] = useState();
  const tabs = [{
    "label": "Add New Bias-in-Action",
    "key": "biasInActionAdd"
  },
  {
    "label": "Bias-in-Action Repository",
    "key": "biasInActionRepo"
  }];

  const [selectedMenu, setMenu] = useState('biasInActionAdd');

  const audienceMapping = {
    "physicians": "Physicians",
    "patients": "Patients",
    "consumer": "Consumer"
  }
  const childRef = useRef();


  useEffect(() => {
    setBiasInActionData(savedData);
  }, [savedData])

  useEffect(() => {
    setBiasInActionRepoData(newRepoData);
  }, [newRepoData])

  useEffect(() => {
    fetchBiasInActionRepositoryData();
  }, [])

  useEffect(() => {
    setNewFormData({ ...newFormData, selected_bias: selectedBiasData?.bias_id });
  }, [selectedBiasData])

  /**
   * To fetch bias repository data
   */
  const fetchBiasInActionRepositoryData = () => {
    try {
      if (!selectedBiasData?.bias_id) return;
      ServiceUtils.postRequest("fetchBiasInActionRepository", { bias_id: selectedBiasData?.bias_id }, false).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            let data = response?.data?.data?.bias_in_action_repo_data?.bias_data;
            setBiasInActionRepoAllData(data);
          } else {
            alertService.error(response.data.message || `Error while fetching bias repository data`);
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
  * Add new behavioural spark
  */
  const addNewBiasInAction = () => {
    try {
      setMenu("biasInActionAdd");
      if ((biasInActionRepoData && biasInActionRepoData.length) || isBiasInActionEdited) {
        setUnsavedChangesModel(true);
      } else {
        setShowAddDrawer(true);
        setIsFormEdited(false);
        if (childRef && childRef.current) {
          childRef.current.resetForm();
        }
      }
    } catch (error) {
      console.log("error, error");
    }
  }

  /**
   * On tab change
   * @param {*} selected 
   */
  const onTabChange = (selected) => {
    if (isFormEdited) {
      setTabSwitchModal(true);
      setClickedTab(selected);
    } else {
      setMenu(selected);
    }
  };

  /**
   * To save bias in action data on edit
   * @param {*} data - new bias in action data
   */
  const saveBiasInAction = (data) => {
    try {
      if (data?.type === 'submit') {
        setNewFormData({ ...newFormData, ...data?.formData });
        if (newImageData) {
          saveNewBiasInAction({ ...newFormData, ...data?.formData });
        }
      } else if (data?.type === 'onChange') {
        setIsFormEdited(true);
        setNewImageData(data?.imgData);
      } else {
        setIsFormEdited(true);
      }
    } catch (error) {
      console.log("error, error");
    }
  }

  /**
   * Save api call  
   * @param {*} formData 
   */
  const saveNewBiasInAction = (formData) => {
    try {
      const postData = new FormData();
      postData.append('imageFile', newImageData);
      postData.append('biasInActionData', JSON.stringify(formData));
      ServiceUtils.postRequest("createBiasInAction", postData).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setShowAddDrawer(false);
            if (response?.data?.data?.bias_in_action_data && response?.data?.data?.bias_in_action_data?.bias_in_actions_id) {
              if (typeof refreshData === "function") refreshData(response?.data?.data?.bias_in_action_data?.bias_in_actions_id);
            }
            setIsFormEdited(false);
          } else {
            alertService.error(
              response.data.message || `Error while adding new bias in action`
            );
          }
        }
      });
    } catch (error) {
      console.log("error, error");
    }
  }

  /**
   * On Click of delete
   * @param {*} id - bias in action id
   * @param {*} index - bias in action index
   * @param {*} dataType - saved or new data
   */
  const deleteBiasInAction = (id, index, dataType) => {
    try {
      setDeleteData({
        "id": id,
        "index": index,
        "dataType": dataType
      });
      setDeleteConfirmation(true);
    } catch (error) {
      console.log("error, error");
    }
  }

  /**
   * On delete proceed
   */
  const proceedDelete = () => {
    try {
      if (!deleteData?.id && deleteData?.dataType !== 'savedData') {
        deleteItem();
      } else {
        ServiceUtils.postRequest("deleteBiasInAction", { id: deleteData?.id }).then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              deleteItem();
              alertService.success(response.data.message || `Successfully deleted Bias-in-Action`);
            } else {
              alertService.error(response.data.message || `Error while deleting Bias-in-Action`);
            }
          }
        });
      }
    } catch (error) {
      console.log("error, error");
    }
  }

  const deleteItem = () => {
    try {
      if (deleteData && deleteData?.index !== undefined && deleteData?.dataType === 'savedData') {
        biasInActionData.splice(deleteData?.index, 1);
        setBiasInActionData([...biasInActionData]);
        if (typeof updateBiasInActionData === 'function') updateBiasInActionData({ 'type': 'bias-in-action', 'bias_in_action_data': [...biasInActionData] });
      } else if(deleteData && deleteData?.index !== undefined && deleteData?.dataType === 'newRepoData'){
        biasInActionRepoData.splice(deleteData?.index, 1);
        setBiasInActionRepoData([...biasInActionRepoData]);
        if (typeof updateBiasInActionData === 'function') updateBiasInActionData({ 'type': 'bias-in-action', 'anotherFormData': [...biasInActionRepoData] });
      }
      setDeleteConfirmation(false);
    } catch (error) {
      console.log("error, error");
    }
  }

  /**
   * On value change of bias in action 
   * @param {*} value - changed vale
   * @param {*} key - field key
   * @param {*} index - bias in action index
   * @param {*} dataType - saved or new data
   */
  const onBiasInActionDataChange = (value, key, index, dataType) => {
    try {
      setIsBiasInActionEdited(true);
      if (index !== undefined) {
        if (dataType === 'savedData') {
          biasInActionData[index][key] = value;
          setBiasInActionData([...biasInActionData]);
          if (typeof updateBiasInActionData === 'function') updateBiasInActionData({ 'type': 'bias-in-action', 'formData': [...biasInActionData] });
        } else {
          biasInActionRepoData[index][key] = value;
          setBiasInActionRepoData([...biasInActionRepoData]);
          if (typeof updateBiasInActionData === 'function') updateBiasInActionData({ 'type': 'bias-in-action', 'anotherFormData': [...biasInActionRepoData] });
        }
      }
    } catch (error) {
      console.log("error, error");
    }
  }

  /**
   * On click of add new button
   */
  const addNewClick = () => {
    try {
      if (selectedMenu === "biasInActionAdd") {
        childRef.current.submitForm();
      }
    } catch (error) {
      console.log("error, error");
    }
  }

  /**
   * On data select in bias in action repository
   * @param {*} eventData - selected image data
   */
  const onRepoDataSelect = (eventData) => {
    try {
      if (eventData?.data) {
        let tempData = eventData?.data;
        tempData['selected_bias'] = selectedBiasData?.bias_id;
        setShowAddDrawer(false);
        setBiasInActionRepoData([tempData]);
        setTimeout(() => {
          if (typeof updateBiasInActionData === 'function') updateBiasInActionData({ 'type': 'bias-in-action', 'anotherFormData': [tempData] });
          scrollToDiv(`NBIA_${tempData?.container_id}`);
        }, 200);
      }
    } catch (error) {
      console.log("error, error");
    }
  }

  /**
  * On add cancel
  */
  const onCancel = () => {
    try {
      if (isFormEdited) {
        setUnsavedChangesModal(true);
      } else {
        setShowAddDrawer(false);
        if (childRef && childRef.current) {
          childRef.current.resetForm();
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const onAddProceed = () => {
    try {
      setShowAddDrawer(true);
      if (childRef && childRef.current) {
        childRef.current.resetForm();
      }
      setUnsavedChangesModel(false);
      // if (typeof refreshData === "function") refreshData({});
    } catch (error) {
      console.log("error, error");
    }
  }

  /**
* On cancel confirmation
*/
  const onCancelAdd = () => {
    try {
      setUnsavedChangesModal(false);
      setIsFormEdited(false);
      setShowAddDrawer(false);
      if (childRef && childRef.current) {
        childRef.current.resetForm();
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
  * Custom more icon of tabs
  * @returns 
  */
  const customMore = () => {
    return <React.Fragment>
      <span style={{ 'display': 'flex' }}>More<DownOutlined className="custom-more-icon" /></span>
    </React.Fragment>
  }

  return (
    <>
      <div className="nav-bias-in-action">
        <div className="bias-tab-header">
          <Row className="border-bottom">
            <Col span={16}>
              <div className="p-1 text-muted description" dangerouslySetInnerHTML={{ __html: description }} ></div>
            </Col>
            {mode === 'edit' && <Col span={8}>
              {<div className="addLink p-1" >
                <span className="fontColor" onClick={addNewBiasInAction}>
                  <FontAwesomeIcon className="fontIcon" icon={faPlusCircle} />
                  <span> Add Bias-in-Action</span>
                </span>
              </div>}
            </Col>}
          </Row>
        </div>
        {biasInActionData && biasInActionData.length !== 0 && biasInActionData?.map((biasdata, index) => {
          return (<>
            <div className="bias-section p-3" key={'BIA_key_' + index} id={'BIAID_' + biasdata?.bias_in_actions_id}>
              <Row>
                <Col>
                  <Image
                    src={biasdata?.bia_image_path}
                    alt="image"
                    className="img-size"
                    preview={false}
                    placeholder={
                      <Image
                        preview={false}
                        src={defaultImage}
                        className="img-size"
                      />
                    }
                  />
                </Col>
                {mode === 'view' && <Col xs={8} sm={10} md={12} lg={16} xl={17}>
                  <h6>
                    How Image Demonstrates Bias
                  </h6>
                  <Tooltip placement="topLeft" overlayClassName={'tooltip-bg tooltip-width'} title={`${biasdata["demonstrate_bias"]?.length > 180 ? biasdata["demonstrate_bias"] : ""}`}>
                    <p className="description-ellipsis">
                      {biasdata["demonstrate_bias"]}
                    </p>
                  </Tooltip>
                </Col>}
                {mode === 'edit' && <><Col xs={3} sm={5} md={8} lg={8} xl={9}>
                  <p><label className="field-label">Pharma/Non-Pharma  : &nbsp;</label>{biasdata?.pharmaNonpharma ? 'Pharma' : 'Non-Pharma'}</p>
                  <p><label className="field-label">Audience  : &nbsp;</label>{audienceMapping[biasdata?.audience]}</p>
                </Col>
                  <Col xs={3} sm={4} md={6} lg={8} xl={8}>
                    <label className="field-label">Example Type : &nbsp;</label>
                    <p className="pt-3">
                      <Radio.Group value={biasdata?.example_type} onChange={(event) => onBiasInActionDataChange(event.target.value, 'example_type', index, 'savedData')}>
                        <Radio value={'activating'} >Activating</Radio>
                        <Radio value={'mitigating'} >Mitigating</Radio>
                      </Radio.Group>
                    </p>
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                    <Tooltip placement="top" title={'Delete'} overlayClassName={'tooltip-bg'}>
                      <SVGIcon icon={'delete-fill'} className="fontIcon" onClick={() => deleteBiasInAction(biasdata?.bias_in_actions_id, index, 'savedData')} />
                    </Tooltip>
                  </Col></>}
              </Row>
              {mode === 'edit' && <><div className="pt-3">
                <label className="field-label">How Image Demonstrates Bias</label>
                <TextArea className="des-area" value={biasdata?.demonstrate_bias} onChange={(event) => onBiasInActionDataChange(event.target.value.replace(/^\s+/g, ''), 'demonstrate_bias', index, 'savedData')}
                  placeholder={"Add Description"} />
                {saveClicked && !biasdata?.demonstrate_bias?.trim() && <h6 className="error-msg">Please add demonstration</h6>}
              </div>
                <div className="pt-3">
                  <label className="field-label">Bias-in-Action Key Message<span className="optional">(optional)</span></label>
                  <TextArea className="des-area" value={biasdata?.key_message} onChange={(event) => onBiasInActionDataChange(event.target.value.replace(/^\s+/g, ''), 'key_message', index, 'savedData')}
                    placeholder={"Add Description"} />
                </div>
                <div className="pt-3">
                  <label className="field-label">Bias-in-Action Additional Info<span className="optional">(optional)</span></label>
                  <TextArea className="des-area" value={biasdata?.additional_info} onChange={(event) => onBiasInActionDataChange(event.target.value.replace(/^\s+/g, ''), 'additional_info', index, 'savedData')}
                    placeholder={"Add Description"} />
                </div></>}
            </div>
          </>)
        })}
        {biasInActionRepoData && biasInActionRepoData.length !== 0 && biasInActionRepoData?.map((imgData, newindex) => {
          return (<>
            <div className="bias-section p-3" key={'NBIA_key_' + newindex} id={'NBIA_' + imgData?.container_id}>
              <Row>
                <Col>
                  <Image
                    src={imgData?.bia_image_path}
                    alt="image"
                    className="img-size"
                    preview={false}
                    placeholder={
                      <Image
                        preview={false}
                        src={defaultImage}
                        className="img-size"
                      />
                    }
                  />
                </Col>
                {mode === 'edit' && <><Col xs={3} sm={5} md={8} lg={8} xl={9} className="p-15">
                  <p><label className="field-label">Pharma/Non-Pharma  : &nbsp;</label>{imgData?.pharmaNonpharma ? 'Pharma' : 'Non-Pharma'}</p>
                  <p><label className="field-label">Audience  : &nbsp;</label>{audienceMapping[imgData?.audience]}</p>
                </Col>
                  <Col xs={3} sm={4} md={6} lg={8} xl={8}>
                    <label className="field-label">Example Type : &nbsp;</label>
                    <p className="pt-3">
                      <Radio.Group value={imgData?.example_type} onChange={(event) => onBiasInActionDataChange(event.target.value, 'example_type', newindex, 'newRepoData')}>
                        <Radio value={'activating'} >Activating1</Radio>
                        <Radio value={'mitigating'} >Mitigating</Radio>
                      </Radio.Group>
                    </p>
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                    <Tooltip placement="top" title={'Delete'} overlayClassName={'tooltip-bg'}>
                      <SVGIcon icon={'delete-fill'} className="fontIcon" onClick={() => deleteBiasInAction(imgData?.bias_in_actions_id, newindex, 'newRepoData')} />
                    </Tooltip>
                  </Col></>}
              </Row>
              {mode === 'edit' && <><div className="pt-3">
                <label className="mb-2 field-label">How Image Demonstrates Bias</label>
                <TextArea className="des-area" value={imgData?.demonstrate_bias} placeholder={"Add Description"} onChange={(event) => onBiasInActionDataChange(event.target.value.replace(/^\s+/g, ''), 'demonstrate_bias', newindex, 'newRepoData')} />
                {saveClicked && !imgData?.demonstrate_bias && <h6 className="error-msg">Please add demonstration</h6>}
              </div>
                <div className="pt-3">
                  <label className="mb-2 field-label">Bias-in-Action Key Message<span className="optional">(optional)</span></label>
                  <TextArea className="des-area" value={imgData?.key_message} placeholder={"Add Description"} onChange={(event) => onBiasInActionDataChange(event.target.value.replace(/^\s+/g, ''), 'key_message', newindex, 'newRepoData')} />
                </div>
                <div className="pt-3">
                  <label className="mb-2 field-label">Bias-in-Action Additional Info<span className="optional">(optional)</span></label>
                  <TextArea className="des-area" value={imgData?.additional_info} placeholder={"Add Description"} onChange={(event) => onBiasInActionDataChange(event.target.value.replace(/^\s+/g, ''), 'additional_info', newindex, 'newRepoData')} />
                </div></>}
            </div>
          </>)
        })}
      </div>
      <Drawer
        title={`Add Examples for ${selectedBiasData?.bias_name}`}
        className="bias-details-offcanvas"
        width={'75%'}
        onClose={onCancel}
        open={showAddDrawer}
        size={"large"}
        zIndex={1050}
        overlayClassName="add-bias"
        footer={
          <>
            {selectedMenu === "biasInActionAdd" && <Button type="primary" htmlType="submit" form="add-bias-in-action" className="me-2" onClick={addNewClick}> Add Bias-in-Action & Go Back</Button>}
            <Button onClick={onCancel}> Cancel & Go Back </Button>
          </>
        }
      >
        <div className="tab-section">
          <Tabs
            activeKey={selectedMenu}
            tabPosition={'top'}
            moreIcon={customMore()}
            onChange = {(key) => onTabChange(key)}
          >
             {tabs.map((tab) => {
            return (
              <Tabs.TabPane tab={tab?.label} key={tab?.key}>
                <>{selectedMenu === "biasInActionAdd" && <AddBiasInAction ref={childRef} onSave={saveBiasInAction} />}
                  {selectedMenu === "biasInActionRepo" && <BiasInActionRepo biasInActionRepoAllData={biasInActionRepoAllData} selectEvent={onRepoDataSelect} />}</>
              </Tabs.TabPane>
            )})}
          </Tabs>
        </div>
      </Drawer>
      <Modal
        centered
        closable={false}
        open={deleteConfirmation}
        footer={null}
        width={400}
        zIndex={2010}
        onCancel={() => setDeleteConfirmation(false)}
      >
        <h6  className="confirmation-header-text">Delete Confirm</h6>
        <p className="text-muted pt-1">
          Are you sure you would like to delete this Bias-in-Action?
        </p>
        <Button type="primary" onClick={proceedDelete} className="me-2 mt-3" >
          Delete
        </Button>
        <Button onClick={() => { setDeleteConfirmation(false) }} className='mt-3'>
          {" "} Cancel{" "}
        </Button>
      </Modal>
      <Modal
        centered
        closable={false}
        open={unsavedChangesModel}
        footer={null}
        width={400}
        zIndex={2010}
        onCancel={() => setUnsavedChangesModel(false)}
      >
        <h6 >Confirm</h6>
        <p className="text-muted">
          There are some unsaved changes on this screen. Save those to proceed with Add New Bias-in-Action.
        </p>
        <Button type="primary" onClick={onAddProceed} className="me-2" >
          Yes
        </Button>
        <Button onClick={() => setUnsavedChangesModel(false)} className="me-2" >
          No
        </Button>
      </Modal>

      <Modal
        centered
        closable={false}
        open={unsavedChangesModal}
        footer={null}
        width={400}
        zIndex={2010}
        onCancel={() => setUnsavedChangesModal(false)}
      >
        <h6  className="confirmation-header-text">Confirm</h6>
        <p className="text-muted pt-1">
          There are unsaved changes on the screen. Are you sure you want to continue?
        </p>
        <Button type="primary" onClick={onCancelAdd} className="me-2 mt-3" >
          Yes
        </Button>
        <Button onClick={() => { setUnsavedChangesModal(false) }} className='mt-3'>
          {" "} No{" "}
        </Button>
      </Modal>

      <Modal
        centered
        closable={false}
        open={tabSwitchModal}
        footer={null}
        width={400}
        zIndex={2010}
        onCancel={() => setTabSwitchModal(false)}
      >
        <h6  className="confirmation-header-text">Confirm</h6>
        <p className="text-muted pt-1">
          There are some unsaved changes on this screen. Are you sure you want to navigate away from the screen?
        </p>
        <Button type="primary" onClick={() => { setMenu(clickedTab); setTabSwitchModal(false); setIsFormEdited(false) }} className="me-2 mt-3" >
          Yes
        </Button>
        <Button onClick={() => { setTabSwitchModal(false) }} className='mt-3'>
          {" "} No{" "}
        </Button>
      </Modal>
    </>
  );
}
