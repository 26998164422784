import React from "react";
import classNames from "classnames";
import "./BiasDetailsSideNav.scss";
import { checkAccess } from "../../../../../Shared/Utils/CommonUtil";
import { Tooltip } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useEffect } from "react";


export default function BiasDetailsSideNav({ onClick, active, sidebarData, biasId, ...props }) {
  const disabledBiasTab = "To move on and activate the other sections please save the Bias first by clicking the save button below";

  useEffect(() => {
    function handleResize() { // resize handler
      updateSideNavWidth();
    }
    handleResize(); // initial time
    window.addEventListener('resize', handleResize); // adding resize event
    return () => {
      window.removeEventListener('resize', handleResize) // detaching the resize event
    }
  }, []);

  const onSelect = (menu) => {
    if (!biasId && menu?.key !== 'summary') {
      return;
    }
    if (typeof onClick === "function") onClick(menu);
  };

  /**
   * Method to update the fixed sidenav width
   */
  const updateSideNavWidth = () => {
    try {
      const sideNavMainEle = document.getElementById("bias-details-side-nav-id");
      const sideNavScrollEle = document.getElementById("side-nav-scroll-id");
      if (sideNavMainEle && sideNavScrollEle) {
        const width = sideNavMainEle.scrollWidth;
        sideNavScrollEle.style.width = width + 'px';
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div
      className="nav-bias h-100"
      data-test="header-container"
    >
      <div className="h-100 side-nav-container">
        <div className="side-nav-scroll" id="side-nav-scroll-id">
          {sidebarData &&
            sidebarData?.map((el) => (!el?.userAccess || checkAccess(el?.userAccess)) && (
              <Tooltip
                key={'tooltip-' + el.key}
                placement="right"
                title={(!biasId && el?.key !== 'summary') ? disabledBiasTab : ""}
                overlayStyle={{ maxWidth: "300px" }}
                color={'black'}
              >
                <div
                  key={el.key}
                  className={classNames("bias-details-menu", {
                    'active': active?.key === el?.key,
                  }, { 'disableTab': (!biasId && el?.key !== 'summary') }
                  )}

                  onClick={() => onSelect(el)}

                >
                  <p className="p-1 m-0">{el?.title}{el?.invalid &&
                    <Tooltip
                      placement="right"
                      color={'black'}
                      title="Please fill all mandatory fields"
                    >
                      <ExclamationCircleOutlined style={{
                        fontSize: '0.8rem',
                        verticalAlign: 'middle',
                        paddingLeft: '5px',
                        color: '#ff8400'
                      }} />
                    </Tooltip>}</p>
                </div>
              </Tooltip>
            ))}
        </div>
      </div>
    </div>
  );
}
