import React, { useState, useRef } from "react";
import "./BiasInActionTable.scss";
import { Table, Space, Tooltip, Image } from "antd";
import SVGIcon from "../../../../Shared/Components/SVGIcon/SVGIcon";
import moment from "moment";
import defaultImage from "../../../../Assets/images/default.jpg";
import Highlighter from "react-highlight-words";

export default function BiasInActionTable({ tableData, tableActionEvent, searchText }) {

  const [arrowOpacity, setArrowOpacity] = useState({ right: 1, left: 0.5 });
  const myRef = useRef(null);
  const tableRef = React.createRef();

  const handleHorisontalScroll = (direction) => {
    if (tableRef) {
      let tableBody = tableRef?.current?.getElementsByClassName('ant-table-body');
      if (tableBody?.length) {
        if (direction === "right") {
          setArrowOpacity({ right: 0.5, left: 1 });
          tableBody[0].scrollLeft += 500;
        } else {
          tableBody[0].scrollLeft -= 500;
          setArrowOpacity({ right: 1, left: 0.5 });
        }
      }
    }
  };


  const getFormatedDate = (dateTime) => {
    try {
      moment.locale("en");
      let tempValue = moment
        .utc(dateTime)
        .local()
        ?.format("MMM D, YYYY | h:mm A");
      return tempValue?.toString()?.trim();
    } catch (error) {
      console.log("error", error);
    }
  };

  const fixedTableColumns = [
    {
      title: "Image",
      width: 100,
      dataIndex: "bia_image_path",
      key: "name",
      align: "center",
      fixed: "left",
      border: "right",
      className: "headerTitle",
      render: (_, record) => {
        // debugger;
        return (
          <>
            <Space size={12}>
              <Image
                className="imageDatasize bias-in-action-img"
                src={record?.bia_image_path}
                alt={record?.image_name}
                onClick={() => tableAction('showImg', record)}
                preview={false}
                placeholder={
                  <Image
                    preview={false}
                    src={defaultImage}
                    style={{
                      height: "70px",
                      width: '100px',
                    }}
                  />
                }
              />
            </Space>
          </>
        );
      },
    },
    {
      title: (
        <>
          <SVGIcon
            icon={"left-button-fill"}
            className="left-icon-arrow"
            style={{ opacity: arrowOpacity.left, cursor: arrowOpacity.left === 1 ? 'pointer' : 'default' }}
            onClick={() => handleHorisontalScroll("left")}
          />
        </>
      ),
      width: 40,
      fixed: "left",
    },
    {
      title: "How Image demonstrates Bias",
      dataIndex: "img_demo_text",
      width: 260,
      key: "render",
      layout: "vertical",
      ellipsis: true,
      className: "image-demonstrestes",
      render: (_, record) => (
        <>
          <Tooltip placement="topLeft" overlayClassName={'tooltip-bg tooltip-width'} title={`${record?.img_demo_text?.length > 120 ? record?.img_demo_text : ""}`}>
            <span className="tableHeaderElipses">
              <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={[searchText]}
                autoEscape={true}
                textToHighlight={record?.img_demo_text}
              >
              </Highlighter>
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      title: "Key Message",
      dataIndex: "key_message",
      className: "key-message",
      ellipsis: true,
      key: "render",
      width: 260,
      render: (_, record) => (
        <>
          <Tooltip placement="topLeft" overlayClassName={'tooltip-bg tooltip-width'} title={`${record?.key_message?.length > 120 ? record?.key_message : ""}`}>
            <span className="tableHeaderElipses">
              <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={[searchText]}
                autoEscape={true}
                textToHighlight={record.key_message}
              >
              </Highlighter>
            </span>
          </Tooltip>
        </>
      ),
    },
    {
      title: "% of Projects Included",
      dataIndex: "% of projects include",
      key: "3",
      width: 100,
      align: "right",
    },
    {
      title: "",
      dataIndex: "bowl_fill",
      key: "3",
      width: 40,
      render: (_, record) => (
        <>
          {record && record.pharma === true ?
            <SVGIcon icon={"add-circle-bowl-fill"} className="fontIcon desableCursor" />
            :
            <SVGIcon icon={"add-circle-bowl-cancel"} className="fontIcon desableCursor" />
          }
        </>
      ),
    },
    {
      title: "Audience",
      dataIndex: "audience",
      key: "4",
      width: 100,
      render: (_, record) => (
        <>
          {capitalFirstLetter(record.audience)}
        </>
      )
    },
    {
      title: "Example Type",
      dataIndex: "example_type",
      key: "5",
      width: 100,
      render: (_, record) => (
        <>
          {capitalFirstLetter(record.example_type)}
        </>
      )
    },
    {
      title: "Last Update",
      dataIndex: "last_updated_time",
      key: "last_update",
      width: 160,
      render: (_, record) => (
        <>
          <p className="p-0 m-0 ">
            {" "}
            {getFormatedDate(record?.last_updated_time)}
          </p>
          <p className="p-0 m-0 "> {record?.first_name}</p>
          <p className="p-0 m-0 "> {record?.last_name}</p>
        </>
      ),
    },
    {
      title: (
        <>
          <SVGIcon
            icon={"right-button-fill"}
            className="fontIcon marginRight"
            style={{ opacity: arrowOpacity.right, cursor: arrowOpacity.right === 1 ? 'pointer' : 'default' }}
            onClick={() => handleHorisontalScroll("right")}
          />
        </>
      ),
      width: 40,
      fixed: "right",
      className: "rowRightButton",
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      align: "center",
      width: 103,
      className: "actionEle",
      dataIndex: "edit_options",
      render: (_, record) => (
        <>
          <Space size="30px">
            <Tooltip placement="topRight" title={"Edit Bias-In-Action"} overlayClassName={'tooltip-bg'}>
              <SVGIcon
                icon={"edit-fill"}
                className="fontIcon"
                src={record?.bia_image_path}
                onClick={() => tableAction('edit', { 'data': record })}
              />
            </Tooltip>
            <Tooltip placement="topRight" title={"Comment"} overlayClassName={'tooltip-bg'}>
              <SVGIcon
                icon={"chat-fill"}
                className="fontIcon"
                onClick={() => tableAction('comment', { 'id': record?.bias_in_action_id, 'data': record })}
              />
            </Tooltip>
            <Tooltip
              placement="topRight"
              title={"Delete Bias-In-Action"}
              overlayClassName={'tooltip-bg'}
            // onClick={() => commentshowmodal()}
            >
              <SVGIcon
                icon={"delete-fill"}
                className="fontIcon"
                onClick={() => tableAction('delete', { 'img_id': record?.img_id, 'bias_id': record?.bias_id, 'bias_in_action_id': record?.bias_in_action_id })}
              />
            </Tooltip>
          </Space>
        </>
      ),
    },
  ];

  /**
   * Table action events
   * @param {*} type - action type
   */
  const tableAction = (type, data) => {
    try {
      if (type === 'delete') {
        if (typeof tableActionEvent === 'function') tableActionEvent({ 'eventType': type, 'deleteInfo': data });
      } else if (type === 'edit') {
        if (typeof tableActionEvent === 'function') tableActionEvent({ 'eventType': type, 'imgInfo': data });
      } else if (type === 'showImg') {
        if (typeof tableActionEvent === 'function') tableActionEvent({ 'eventType': type, 'imgInfo': data });
      } else if (type === 'comment') {
        if (typeof tableActionEvent === 'function') tableActionEvent({ 'eventType': type, 'imgInfo': data });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * Change Word's first letter to caps
   * @param {*} str - string
   * @returns - result string
   */
  const capitalFirstLetter = (str) => {
    str = str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
      return letter.toUpperCase();
    });
    return str;
  };

  return (
    <div>
      {/* Fixed table Component */}
      <div className="p-2 m-1 pb-0 mb-0 fixed-table-layout">
        <Table
          ref={tableRef}
          columns={fixedTableColumns}
          dataSource={tableData}
          scroll={{
            x: 1500,
            y: "100%",
          }}
          pagination={false}
          scrollToFirstRowOnChange={true}
          scrollTop={myRef}
        />
      </div>
    </div>
  );
}
